import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { noTask } from "src/assets";
import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Checkbox,
} from "@mui/material";
import { baseUri } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { set_blank_target } from "src/utils/DomUtils";
import ChangeAssociatePopover from "./ChangeAssociatePopover";
import DatePicker from "react-datepicker";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import moment from "moment";
import {
  updateOptionalSaleAssociates,
  updateSaleStatus,
  updateSaleTargetDate,
  updateSaleType,
} from "src/DAL/Sales/Sales";
import { useSnackbar } from "notistack";
import SaleTypePopover from "./SaleTypePopover";
import MainAssociatePicker from "./MainAssociatePicker";
export default function SaleListAccordion({
  data,
  section,
  expandSection,
  handleOpenMenu,
  openSidebar,
  associates,
  setAssociates,
  setSectionList,
  sectionList,
  getAllSalesListing,
  openAnswer,
  setSelectedAns,
  handleOpenAccordionMenu,
  selectedAns,
  getPhone,
  sectionId,
  isExpanded,
}) {
  const [saleCount, setSaleCount] = useState(0);
  const animated = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (data) => {
    if (data) {
      expandSection(data.section_id);
    }
  };
  const openMenu = (e, data) => {
    handleOpenAccordionMenu(e, data);
  };
  const getSectionSales = () => {
    let sales = data.filter(
      (sale) => String(sale.section_id) === String(section.section_id)
    );
    return sales;
  };
  const getFilteredAssociates = (id) => {
    // let filtered = associateList.filter((member)=>)
  };
  const getCount = () => {
    let count = 0;
    //make state for each sale item!
    setSaleCount(0);
    let sales = data.filter(
      (sale) => String(sale.section_id) === String(section.section_id)
    );
    if (animated.current === false) {
      sales.forEach((sale, index) => {
        setTimeout(() => {
          if (index <= 5) {
            count = count + 1;
            setSaleCount(count);
          }
          if (index > 4) {
            setSaleCount(sales.length);
          }
        }, 1500);
      });
    }
    if (animated.current === true) {
      sales.forEach((sale) => {
        count = count + 1;
        setSaleCount(count);
      });
    }
    animated.current = true;
  };
  const getTooltipHtml = (list) => {
    if (list.answers_detail !== "") {
      return { __html: list.answers_detail };
    }
    if (list.sale_description) {
      return { __html: list.sale_description };
    }
    if (!list.sale_description) {
      return { __html: "<p>No Description added!</p>" };
    }
    if (list.sale_description === "") {
      return { __html: "<p>No Description added!</p>" };
    }
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("sale-description-html");
    }, 200);
  };
  const showAvatar = (data) => {
    if (data.associate_id === null) {
      return false;
    }
    if (String(data.associate_id) === "0") {
      return false;
    }
    if (data.associate_id !== null && data.team_first_name === null) {
      return false;
    }
    if (String(data.associate_id) !== "0" && data.team_first_name === null) {
      return false;
    }
    return true;
  };

  const getAnsList = (list) => {
    let answers = [];
    let fb_id = list.facebook_id;
    let fb_link = `<a target='_blank' href='https://www.facebook.com/${fb_id}'> https://www.facebook.com/${fb_id} </a>`;
    const getDayOneHtml = (html) => {
      if (!fb_id) {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }
      if (fb_id === null) {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }
      if (fb_id === "") {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }

      return `${"<h2>Facebook Profile</h2>" + fb_link + "<br/>" + html}`;
    };
    if (String(list.day1) !== "") {
      answers.push({
        day: 1,
        data: getDayOneHtml(list.day1),
        createdAt: list.created_at,
      });
    }
    if (String(list.day2) !== "") {
      answers.push({ day: 2, data: list.day2, createdAt: list.created_at });
    }
    if (String(list.day3) !== "") {
      answers.push({ day: 3, data: list.day3, createdAt: list.created_at });
    }
    if (String(list.day4) !== "") {
      answers.push({ day: 4, data: list.day4, createdAt: list.created_at });
    }
    if (String(list.day5) !== "") {
      answers.push({ day: 5, data: list.day5, createdAt: list.created_at });
    }
    if (String(list.day6) !== "") {
      answers.push({ day: 6, data: list.day6, createdAt: list.created_at });
    }
    return answers;
  };
  const handleOpenAnswer = (data) => {
    console.log(data);
    setSelectedAns(data);
    openAnswer();
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === "-") {
        date_safari = date_safari.replace("-", "/");
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(
        moment(date_safari).subtract(difference_safari, "m")
      ).format(
        "DD, MMM YYYY "
        // 'DD, MMM YYYY hh:mm:s a '
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(
        moment(serverDate).subtract(difference, "m")
      ).format(
        "DD, MMM YYYY "
        // 'DD, MMM YYYY hh:mm:s a '
      );

      return setTime;
    }
  };

  const handleChangeOptionalAssociates = async (member, action, id) => {
    let sections = Array.from(sectionList);
    console.log(member, action, id, "payload");
    let updated_optional_associates = [];
    const getUpdatedAssociates = (members) => {
      const users = JSON.parse(members);
      if (!users) {
        return members;
      }
      if (action === "pop") {
        let updated = users.filter(
          (u_id) => String(u_id) !== String(member.user_id)
        );
        updated_optional_associates = [...updated];
        return JSON.stringify(updated);
      }
      if (action === "push") {
        let copy = [...users];
        copy.push(member.user_id);
        updated_optional_associates = [...copy];
        return JSON.stringify(copy);
      }
      return members;
    };
    let updated = sections.map((item) => {
      if (String(item.section_id) === String(section.section_id)) {
        return {
          ...item,
          section_sales: item.section_sales.map((sale) => {
            if (String(sale.id) === String(id)) {
              return {
                ...sale,
                ["optional_associate"]: getUpdatedAssociates(
                  sale.optional_associate
                ),
              };
            }
            return sale;
          }),
        };
      }
      return item;
    });
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", id);
    updated_optional_associates.forEach((u_id, i) => {
      formData.append(`optional_associate[${i}]`, u_id);
    });
    console.log(...formData, "opt-assoc-upd");

    const result = await updateOptionalSaleAssociates(formData);
    if (result.code === 200) {
      setSectionList(updated);
      enqueueSnackbar("Associates updated successfully", {
        variant: "success",
      });

      console.log(result, "res-opt-ast");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    getAllSalesListing();
  };
  const handleTargetDate = async (date, id) => {
    let sections = Array.from(sectionList);
    let updated = sections.map((item) => {
      if (String(item.section_id) === String(section.section_id)) {
        return {
          ...item,
          section_sales: item.section_sales.map((sale) => {
            if (String(sale.id) === String(id)) {
              return { ...sale, ["sale_target_date"]: date };
            }
            return sale;
          }),
        };
      }
      return item;
    });
    setSectionList(updated);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", id);
    formData.append("sale_target_date", moment(date).format("YYYY-MM-DD"));
    console.log(...formData, "update-sale-date-req");
    const result = await updateSaleTargetDate(formData);
    if (result.code === 200) {
      enqueueSnackbar("Due Date Updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const showAmount = (amount) => {
    if (!amount) {
      return false;
    }
    for (let i = 0; i < amount.length; i++) {
      amount = amount.replace(",", "");
    }
    let show = false;
    if (Number(amount) > 0) {
      show = true;
    }
    return show;
  };
  const onStatusChange = (e, item) => {
    const { target } = e;
    const data = { checked: target.checked, item: item, section: section };
    handleChangeSaleStatus(data);
  };
  const handleChangeSaleStatus = async (data) => {
    console.log(data, "data");
    const { checked, item, section } = data;

    if (checked === true) {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("sale_id", item.id);
      formData.append("completed_status", "1");
      const result = await updateSaleStatus(formData);
      if (result.code === 200) {
        const updatedSales = (_data) => {
          return _data.map((sale) => {
            if (String(sale.id) === String(item.id)) {
              return { ...sale, completed_status: "1" };
            }
            return sale;
          });
        };
        let updated_section = {
          ...section,
          section_sales: updatedSales(section.section_sales),
        };
        console.log(updated_section, "updated-s");
        let updated_sections = Array.from(sectionList);
        updated_sections = updated_sections.map((_section) => {
          if (String(_section.section_id) === String(section.section_id)) {
            return updated_section;
          }
          return _section;
        });
        console.log(updated_sections, "updateddd");
        setSectionList(updated_sections);
        enqueueSnackbar("Sale marked as completed", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (checked === false) {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("sale_id", item.id);
      formData.append("completed_status", "0");
      const result = await updateSaleStatus(formData);
      if (result.code === 200) {
        enqueueSnackbar("Sale marked as incomplete", { variant: "success" });

        const updatedSales = (_data) => {
          return _data.map((sale) => {
            if (String(sale.id) === String(item.id)) {
              return { ...sale, completed_status: "0" };
            }
            return sale;
          });
        };
        let updated_section = {
          ...section,
          section_sales: updatedSales(section.section_sales),
        };
        console.log(updated_section, "updated-s");
        let updated_sections = Array.from(sectionList);
        updated_sections = updated_sections.map((_section) => {
          if (String(_section.section_id) === String(section.section_id)) {
            return updated_section;
          }
          return _section;
        });
        console.log(updated_sections, "updateddd");
        setSectionList(updated_sections);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const onChangeSaleType = async (data) => {
    const prev_state = Array.from(sectionList);
    console.log(data, "onch");
    const { item, section, newValue } = data;
    const updatedSales = (_data) => {
      return _data.map((sale) => {
        if (String(sale.id) === String(item.id)) {
          return { ...sale, sale_lead_types: newValue };
        }
        return sale;
      });
    };
    let updated_section = {
      ...section,
      section_sales: updatedSales(section.section_sales),
    };
    let updated_sections = Array.from(sectionList);
    updated_sections = updated_sections.map((_section) => {
      if (String(_section.section_id) === String(section.section_id)) {
        return updated_section;
      }
      return _section;
    });
    console.log(updated_sections, "updateddd");
    setSectionList(updated_sections);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", item.id);
    formData.append("sale_lead_types", newValue);
    const result = await updateSaleType(formData);
    if (result.code === 200) {
      enqueueSnackbar("Sale Type Updated", { variant: "success" });
    } else {
      setSectionList(prev_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeSaleType = async (value, data) => {
    console.log(value, data);
    const _data = { item: data, newValue: value, section: section };
    onChangeSaleType(_data);
  };

  return (
    <div
      id="accordion-parent"
      style={{ borderRadius: "4px" }}
      className="border mb-2"
    >
      <Accordion
        square={false}
        expanded={section.expanded}
        onChange={(e) => handleChange(section)}
      >
        <div className="accordion-summary-container position-relative d-flex">
          <AccordionSummary
            style={{ width: "100%", paddingRight: "0px" }}
            expandIcon={
              <ExpandMoreIcon
                style={{ marginRight: "4px", marginLeft: "7px" }}
              />
            }
          >
            <div className="d-flex w-100 align-items-center position-relative">
              <div
                className="position-absolute"
                style={{ zIndex: "2", right: "0" }}
              >
                <Tooltip arrow title="Sales count">
                  {/* <AnimateCounter data={data} animated={animated} section={section} /> */}
                  <span
                    style={{
                      background: "rgb(0, 171, 85)",
                      borderRadius: 3,
                      padding: "1px 5px",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                      minWidth: "8px",
                    }}
                  >
                    {data.length}
                  </span>
                  {/* <span className='count-circle'>{getCount()}</span> */}
                </Tooltip>
              </div>
              <div className="w-100">
                <Typography
                  className="theme-text-color"
                  sx={{
                    width: "33%",
                    flexShrink: 0,
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  {section.section_name}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <div
            className="position-relative d-flex align-items-center"
            style={{ zIndex: "3" }}
          >
            <div id="sale-more-icon-parent d-flex align-items-center">
              <span
                role="button"
                id="sale-more-icon"
                onClick={(e) => openMenu(e, section)}
              >
                <MoreVertIcon
                  htmlColor="#6d7c89"
                  style={{ marginBottom: "2px", marginRight: "8px" }}
                />
              </span>
            </div>
          </div>
        </div>
        <AccordionDetails>
          {getSectionSales().length > 0 ? (
            getSectionSales().map((list, index) => {
              return (
                <Box
                  key={index}
                  borderRadius="4px"
                  boxShadow={3}
                  flexDirection="row"
                  alignItems="center"
                >
                  <div className="row mt-3 py-3">
                    <div className="col-12">
                      <div className="row align-items-center relative">
                        <span
                          style={{
                            position: "absolute",
                            right: 20,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        >
                          <IconButton
                            onClick={(e) => handleOpenMenu(e, list, index)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </span>
                        <div className="product-name col-12 mb-1">
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex align-items-center pl-2">
                                <div>
                                  <Checkbox
                                    value={list.status}
                                    onChange={(e) => onStatusChange(e, list)}
                                    checked={
                                      String(list.completed_status) === "1"
                                    }
                                  />
                                </div>
                                <div>
                                  <Tooltip
                                    onOpen={(e) => handleOpenTooltip(e)}
                                    title={
                                      <div id="task-description-tooltip">
                                        <div
                                          id="sale-description-html"
                                          dangerouslySetInnerHTML={getTooltipHtml(
                                            list
                                          )}
                                        ></div>
                                      </div>
                                    }
                                    arrow
                                  >
                                    <Typography
                                      role="button"
                                      onClick={() => openSidebar(list)}
                                      // className='pl-2'
                                      style={{
                                        display: "inline-block",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {list.sale_title
                                        ? list.sale_title
                                        : "no title"}
                                      {/*  {`${list.first_name} ${list.last_name} - ${
                                    list.client_email
                                  }${getPhone(list.phone)}`} */}
                                    </Typography>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-info col-12">
                          <div className="row align-items-center">
                            <div className="col-md-3 mb-1 mb-md-0">
                              <div className="pl-2 d-flex align-items-center">
                                <div role="button">
                                  <Tooltip arrow title="created at">
                                    <a
                                      style={{
                                        textDecorationLine: "underline",
                                        fontSize: 12,
                                        padding: 0,
                                        color: "#0056b3",
                                        marginLeft: "11px",
                                      }}
                                    >
                                      {getActualDate(list.created_at)}
                                    </a>
                                  </Tooltip>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "0.5rem",
                                    paddingBottom: "2px",
                                  }}
                                >
                                  {list.sale_target_date === null ? (
                                    <DatePicker
                                      // dateFormat="YYYY-MM-DD"
                                      value={list.sale_target_date}
                                      selected={list.sale_target_date}
                                      onSelect={(date) =>
                                        handleTargetDate(date, list.id)
                                      }
                                      minDate={new Date()}
                                      targetTask={list}
                                      customInput={
                                        <ExampleCustomInput targetTask={list} />
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      // dateFormat="YYYY-MM-DD"
                                      value={list.sale_target_date}
                                      selected={new Date(list.sale_target_date)}
                                      onSelect={(date) =>
                                        handleTargetDate(date, list.id)
                                      }
                                      minDate={new Date()}
                                      targetTask={list}
                                      customInput={
                                        <ExampleCustomInput targetTask={list} />
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1 mb-1 mb-md-0">
                              <MainAssociatePicker
                                row={list}
                                membersData={associates}
                                setMembersData={setAssociates}
                                getAllSalesListing={getAllSalesListing}
                              />
                            </div>
                            <div className="col-md-4 mb-2 mb-md-0">
                              <div className="d-flex justify-content-center justify-content-md-center  align-items-center">
                                <div className="ml-2">
                                  <ChangeAssociatePopover
                                    optionalAssociates={JSON.parse(
                                      list.optional_associate
                                    )}
                                    row={list}
                                    membersData={associates}
                                    setMembersData={setAssociates}
                                    getAllSalesListing={getAllSalesListing}
                                    onChangeAssociates={(member, action) =>
                                      handleChangeOptionalAssociates(
                                        member,
                                        action,
                                        list.id
                                      )
                                    }
                                  />
                                </div>
                                <div className="ml-2">
                                  <SaleTypePopover
                                    row={list}
                                    saleType={list.sale_lead_types}
                                    onChangeType={(value, data) =>
                                      handleChangeSaleType(value, data)
                                    }
                                    shouldOpen={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-1 mb-md-0 pl-0">
                              <div className="d-flex justify-content-center justify-content-md-center align-items-center">
                                <div>
                                  <Tooltip arrow title="Product name">
                                    <Typography
                                      role="button"
                                      className="project-name-div mb-1 mb-md-0"
                                      component="span"
                                      // style={{ marginLeft: '0.7rem' }}
                                    >
                                      {list.product_name}
                                    </Typography>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sale-answers col-12">
                          <div
                            style={{ paddingLeft: "10px" }}
                            className="d-flex justify-content-start justify-content-md-start  align-items-center mt-1"
                          >
                            {getAnsList(list).map((item, i) => (
                              <span
                                onClick={() => handleOpenAnswer(item)}
                                role="button"
                                key={i}
                                className={`ans-day ${i === 0 && "pl-2"}`}
                                style={{ margin: 0 }}
                              >
                                <Tooltip title="view" arrow placement="top">
                                  <Typography>{`#Day ${item.day}`}</Typography>
                                </Tooltip>
                              </span>
                            ))}
                            {String(list.agreement_pdf) !== "" && (
                              <Typography>
                                <a
                                  style={{
                                    listStyle: "none",
                                    textDecoration: "none",
                                  }}
                                  className="sale-link ml-2"
                                  href={list.agreement_pdf}
                                  target="_blank"
                                >
                                  #agreement pdf
                                </a>
                              </Typography>
                            )}
                            {String(list.payment_invoice) !== "" && (
                              <Typography>
                                <a
                                  style={{
                                    listStyle: "none",
                                    textDecoration: "none",
                                  }}
                                  className="sale-link"
                                  href={list.payment_invoice}
                                  target="_blank"
                                >
                                  #payment invoice
                                </a>
                              </Typography>
                            )}
                            {showAmount(list.amount) && (
                              <span className="ml-2">
                                <Tooltip title="Amount" arrow placement="top">
                                  <Typography className="theme-text-color">{`Amount: ${list.currency}${list.amount} `}</Typography>
                                </Tooltip>
                              </span>
                            )}
                          </div>
                          <div className="d-flex justify-content-start justify-content-md-start  align-items-center mt-1">
                            {list.package && (
                              <span className="ml-2">
                                <Tooltip title="package" arrow placement="top">
                                  <Typography className="theme-text-color">{` ${list.package} `}</Typography>
                                </Tooltip>
                              </span>
                            )}
                            {list.payment_plan && (
                              <span className="ml-2">
                                <Tooltip
                                  title="payment plan"
                                  arrow
                                  placement="top"
                                >
                                  <Typography className="theme-text-color">{` ${list.payment_plan} `}</Typography>
                                </Tooltip>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              );
            })
          ) : (
            <div className="col-12 mt-5">
              <img
                width="124px"
                height="124px"
                src={noTask}
                alt="no task"
                style={{ margin: "auto" }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Sales Found
              </Typography>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip arrow title="Due Date">
      {value !== "" ? (
        <>
          <Tooltip arrow title="due date">
            <a
              role="button"
              tabIndex="0"
              className="btn"
              onClick={onClick}
              onKeyDown={onClick}
              ref={ref}
              style={{
                textDecorationLine: "underline",
                fontSize: 12,
                //  color: 'green',
                color: getDateColor(value),
                padding: 0,
              }}
            >
              {getActualDate(value)}
              {/* {moment(value).format('DD MMM, YYYY')} */}
            </a>
          </Tooltip>
          {showDangerIcon(value) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          className="btn"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{
            textDecorationLine: "underline",
            fontSize: 12,
            padding: 0,
            //  color: 'green'
            color: getDateColor(value),
          }}
        >
          Select date
        </a>
      )}
    </Tooltip>
  )
);
const getDateColor = (targetDate) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    return true;
  }
  return false;
};
const detectSafariBrowser = () => {
  let is_safari = false;
  if (navigator.vendor === "Apple Computer, Inc.") {
    is_safari = true;
  }
  return is_safari;
};
const getActualDate = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("DD, MM YYYY");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "DD, MM YYYY"
    );

    return setTime;
  }
};
const getActual = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("YYYY MM DD");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "YYYY MM DD"
    );

    return setTime;
  }
};
const AnimateCounter = ({ data, section, animated }) => {
  const [count, setCount] = useState(0);
  const getCount = () => {
    let count = 0;
    //make state for each sale item!
    // setCount(0);
    let sales = data.filter(
      (sale) => String(sale.section_id) === String(section.section_id)
    );
    if (animated.current === false) {
      sales.forEach((sale, index) => {
        setTimeout(() => {
          if (index <= 5) {
            count = count + 1;
            setCount(count);
          }
          if (index > 4) {
            setCount(sales.length);
          }
        }, 1500);
      });
    }
    if (animated.current === true) {
      sales.forEach((sale) => {
        count = count + 1;
        setCount(count);
      });
    }
    animated.current = true;
  };

  useEffect(() => {
    setTimeout(() => {
      getCount();
    }, 3000);
  }, [data]);
  return (
    <span
      style={{
        background: "rgb(0, 171, 85)",
        borderRadius: 3,
        padding: "1px 5px",
        color: "white",
        fontWeight: "bold",
        fontSize: "12px",
        minWidth: "8px",
      }}
    >
      {String(count)}
    </span>
  );
};
