import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { globalTaskSearch } from '../../DAL/Project/Project';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 42;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleChangeLoading = (value) => {
    setIsLoading(value);
  };
  const handleSearch = async () => {
    // not fully functional yet
    //handle search on press enter button
    handleSearchOpen(false);
    return;
    handleChangeLoading(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_name', search);
    const result = await globalTaskSearch(formData);
    console.log(result, 'global');
    if (result.code === 200) {
      if (result.task_list) {
        setSearchData(result.task_list);
        if (result.task_list.length > 0) {
          navigate('/global_task_search', { state: result.task_list });
          handleSearchOpen(false);
        }
      }
      handleChangeLoading(false);
    }
    handleChangeLoading(false);
  };
  const handleSearchOpen = (value) => {
    setIsSearchOpen(value);
  };
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary', marginRight: 'auto' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar
          isSearchOpen={isSearchOpen}
          handleSearchOpen={handleSearchOpen}
          isLoading={isLoading}
          handleChangeLoading={handleChangeLoading}
          search={search}
          onChangeSearch={handleChangeSearch}
          searchData={searchData}
          onSearch={handleSearch}
        /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <NotificationsPopover />
          {/* <AccountPopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
