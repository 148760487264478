import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import { logout } from "../../DAL/Login/Login";
import { baseUri } from "../../config/config";
import { userProfile } from "src/DAL/Project/Project";
import { useSocket } from "src/Hooks/socketContext";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const { disconnect_socket, fetchUnreadMessagesCount } = useSocket();
  const navigate = useNavigate();
  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
  const handleLogout = async () => {
    let token = localStorage.getItem("token");
    disconnect_socket();
    if (!token) {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
      return;
    }
    if (token === "") {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
      return;
    }
    const formdata = new FormData();
    formdata.append("token", token);
    const result = await logout(formdata);
    if (result.code === 200) {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const pushRoute = () => {
    let filtered_routes = sidebarConfig;
    let exist = false;
    sidebarConfig.map((route) => {
      if (String(route.path) === "/sales") {
        exist = true;
      }
    });
    if (String(localStorage.getItem("allowViewSales")) === "1") {
      if (!exist) {
        if (String(localStorage.getItem("allowViewSales")) === "1") {
          filtered_routes.push(
            {
            title: "Sale Leads",
            path: "/sales",
            icon: <PaidIcon width={22} height={22} />,
            children: [
              {
                title: "Sections",
                path: "/sales/sections",
                icon: getIcon("carbon:list-boxes"),
              },
              {
                title: "All Sales",
                path: "/sales/all",
                icon: <PaidIcon width={22} height={22} />,
              },
              {
                title: "Products",
                path: "/sales/products",
                icon: <PaidIcon width={22} height={22} />,
              },
              
            ],
            },
            {
              title: "Private Notes",
              path: "/Notes/AllNotes",
              icon: getIcon("material-symbols:menu-book-outline-rounded"),
            }
          
          );
        }
      }
      setFilteredRoutes(filtered_routes);
    }
    if (String(localStorage.getItem("allowViewSales")) === "0") {
      let filtered = filtered_routes.filter((route) => {
        if (String(route.path) !== "/sales") {
          return route;
        }
      });
      // console.log(filtered, 'not-allowed-sales-routes');
      setFilteredRoutes(filtered);
    }

    // return filtered_routes;
  };

  const getName = () => {
    let name = localStorage.getItem("first_name");
    if (!name) {
      localStorage.clear();
      window.location.reload();
    }
    if (name === "") {
      localStorage.clear();
      window.location.reload();
    }
    if (name === null) {
      localStorage.clear();
      window.location.reload();
    }
    if (name) {
      return name;
    }
  };
  const getUserProfile = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await userProfile(formData);
    console.log(result, "user-profile");
    const { user_detail } = result;
    console.log(user_detail, "user-profile-dashboard");
    if (result.code === 200) {
      localStorage.setItem("allowViewSales", String(user_detail.sales_option));
      pushRoute();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    fetchUnreadMessagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    pushRoute();
    getUserProfile();
    // console.log('path-changed', filteredRoutes);
  }, []);
  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box sx={{ px: 2.5, py: 3 }}>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Logo />
          </Box>
        </Box>

        <Box sx={{ mb: 5, mx: 2.5 }}>
          {/* <Link underline="none" component={RouterLink} to="#"> */}
          <AccountStyle onClick={() => navigate("/user_profile")}>
            {localStorage.getItem("image") ? (
              <Avatar
                src={baseUri + localStorage.getItem("image")}
                alt="photoURL"
              />
            ) : (
              <Avatar>{getName().substring(0, 1)}</Avatar>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {localStorage.getItem("first_name")}{" "}
                {(" ", localStorage.getItem("last_name"))}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                role
              </Typography> */}
            </Box>
          </AccountStyle>
          {/* </Link> */}
        </Box>

        <NavSection navConfig={filteredRoutes} />
        {/* <NavSection navConfig={sidebarConfig} /> */}

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>

      <Box sx={{ px: 2.5, pb: 3, mt: 3 }}>
        <Button fullWidth onClick={() => handleLogout()} variant="contained">
          Logout
        </Button>
      </Box>
    </>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
