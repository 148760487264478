import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SideMenuPopover from './SideMenuPopover';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export default function PopoverSectionMenu({
  isOpen,
  onOpen,
  onClose,
  anchorEl,
  handleOpenDialog,
  sections,
  handleMoveSection,
  selectedRow,
  isDragDisabled,
  hasSections,
  data,
  sectionSelected,
  setSectionSelected,
}) {
  const moveToRef = React.useRef();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const handleClose = () => {
    onClose();
  };
  const handleOpenSubMenu = (e, task, index) => {
    console.log(task, 'target-task');
    console.log(index, 'target-index');
    setSubMenuAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
    // setRowData(task);
  };
  const handleCloseSubMenu = () => {
    setIsSubMenuOpen(false);
    setSubMenuAnchorEl(null);
  };
  const isDragButtonDisabled = () => {
    let result = isDragDisabled();
    return result;
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem
            onClick={() => {
              handleOpenDialog();
              // handleOpenDialog(task, index);
            }}
            sx={{ padding: '.2rem' }}
          >
            <ListItemButton>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Delete</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                {/* <DeleteIcon /> */}
                <DeleteForeverOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {hasSections && (
            <>
              {String(selectedRow.taskType) !== String(2) && (
                <ListItem ref={moveToRef} sx={{ padding: '.2rem' }}>
                  <ListItemButton disabled={isDragButtonDisabled()} onClick={handleOpenSubMenu}>
                    <ListItemText>
                      <Typography sx={{ fontSize: '0.8rem' }}>Move to</Typography>
                    </ListItemText>
                    <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                      <ChangeCircleIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )}
            </>
          )}
        </List>
      </Popover>
      {hasSections && (
        <SideMenuPopover
          isOpen={isSubMenuOpen}
          onOpen={handleOpenSubMenu}
          onClose={handleCloseSubMenu}
          anchorEl={subMenuAnchorEl}
          sections={sections}
          handleMoveSection={handleMoveSection}
          closeParentPopover={handleClose}
          selectedRow={selectedRow}
          data={data}
          sectionSelected={sectionSelected}
          setSectionSelected={setSectionSelected}
          // handleOpenDialog={}
        />
      )}
    </div>
  );
}
