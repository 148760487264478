import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
// components
import MenuPopover from '../../../components/MenuPopover';
import { priority_color_and_value } from 'src/constants';

// ----------------------------------------------------------------------

export default function PriorityPopover(props) {
  const anchorRef = useRef(null);
  const [openpriority, setOpenPriority] = useState(false);

  const handleClose = () => {
    setOpenPriority(false);
  };

  const handlePriority = (e, data) => {
    let task = props.id;
    props.handleTaskPriority(data.value, task);
    handleClose();
  };

  const handleOpenPriority = () => {
    if (props.shouldOpen === false) {
      return;
    }
    setOpenPriority(true);
  };

  const getFlagColor = (value) => {
    const flag = priority_color_and_value.find((flag) => String(flag.value) === String(value));
    if (flag) {
      return flag.color;
    }
  };

  const getFlagLabel = (value) => {
    const flag = priority_color_and_value.find((flag) => String(flag.value) === String(value));
    if (flag) {
      return flag.label;
    }
  };

  return (
    <>
      <Tooltip arrow title={`Priority ${getFlagLabel(props.priority)}`}>
        <IconButton
          style={{
            backgroundColor: 'rgba(0, 171, 85, 0.08)',
            marginLeft: '.3rem',
          }}
          ref={anchorRef}
          onClick={handleOpenPriority}
        >
          <FlagIcon style={{ fontSize: '0.6em' }} htmlColor={getFlagColor(props.priority)} />
        </IconButton>
      </Tooltip>

      <MenuPopover open={openpriority} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {priority_color_and_value.map((option, i) => (
            <MenuItem
              key={option.value}
              selected={props.priority === priority_color_and_value[i].value}
              sx={{ py: 1, px: 2.5 }}
              onClick={(e) => handlePriority(e, option)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <FlagIcon htmlColor={getFlagColor(option.value)} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2', fontSize: '12' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
