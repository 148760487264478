import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  FormLabel,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {
  detailProject,
  editProject,
  activeTeamList,
  getProjectTemplateDetail,
  editProjectTemplate,
} from '../../../DAL/Project/Project';
import { teamList } from '../../../DAL/Team/Team';
import { baseUri } from '../../../config/config';
import { useSocket } from '../../../Hooks/socketContext';
import CkEditor from 'src/components/editor/CKEditor';
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

/* <<======================= STYLING =======================>> */

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
  img: {
    width: '100%',
  },
}));

function EditProjectTemplate(props) {
  const { sendSocketNotification } = useSocket();
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  /* <<======================= STATES =======================>> */
  const [isWaiting, setIsWaiting] = useState(true);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(true);
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  /* <<======================= HANDLING =======================>> */
  const handleChange = (data) => {
    console.log(data);
    setMembers(data);
    // const {
    //   target: { value }
    // } = event;
    // setMembers(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    setIsWaiting(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_name', name);
    formData.append('project_temp_id', id);
    // formData.append('start_date', startDate);
    // formData.append('end_date', endDate);
    formData.append('project_description', description);
    formData.append('status', _status);
    if (Image.name) {
      formData.append('project_image', Image);
    }
    // const arr = members.map((members, index) => {
    //   formData.append(`team[${index}]`, members.user_id);
    //   return members;
    // });
    const result = await editProjectTemplate(formData);
    if (result.code === 200) {
      // setData(result.Project_list);
      setIsWaiting(false);
      navigate(-1);
      members.map((member, index) => {
        const payload = {
          receiver_id: member.user_id,
          message: `${name} project team has been changed`,
          project_id: id,
          project_name: name,
          notification_type: 'task',
        };
        sendSocketNotification(payload);
        return member;
      });
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_temp_id', id);
    console.log(id, 'params');
    const result = await getProjectTemplateDetail(formData);
    console.log(result, 'template-detail');
    if (result.code === 200) {
      setName(result.template_data.project_name);
      setDescription(result.template_data.project_description);
      setImage(result.template_data.project_image);
      if (result.template_data.status === '0') {
        setStatus(false);
      } else {
        setStatus(true);
      }
      if (result.template_data.project_image) {
        setProfileImage(baseUri + result.template_data.project_image);
      } else {
        setProfileImage(
          'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
        );
      }
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await activeTeamList(formData);
    if (result.code === 200) {
      setMembersData(result.Team_member_list);
      console.log(result.Team_member_list, 'overall listing');
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIsWaiting(false);
    }
  };

  useEffect(async () => {
    fetchTeamListing();
    fetchProjectDetail();
  }, []);

  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <>
      <Container maxWidth='sm'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Edit template
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name'
                label='Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Description :</FormLabel>
              <div className='project-description'>
                <CkEditor content={description} setContent={(value) => setDescription(value)} />
              </div>
              {/* <TextField
                margin='dense'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id='name'
                label='Description'
                type='description'
                fullWidth
                variant='outlined'
                multiline
                rows={4}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team members' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditProjectTemplate;
