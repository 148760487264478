import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
// routes
import Routers from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
//Mui
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        maxSnack={3}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon htmlColor='white' />
          </IconButton>
        )}
      >
        <Routers />
      </SnackbarProvider>
    </ThemeConfig>
  );
}
