import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import clockFill from '@iconify/icons-eva/clock-fill';
import { LoadingButton } from '@mui/lab';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
//

import CkEditor from 'src/components/editor/CKEditor';
import { set_blank_target } from 'src/utils/DomUtils';
import Scrollbar from 'src/components/Scrollbar';
import { updateSaleDescription } from 'src/DAL/Project/Project';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

AnswerSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));
export default function AnswerSidebar({ isOpen, onClose, selectedAns }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };

  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm')).format(
        'DD, MMM YYYY hh:mm:s a '
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm')).format(
        'DD, MMM YYYY hh:mm:s a '
      );

      return setTime;
    }
  };

  const getQuestionHtml = (html) => {
    return { __html: html };
  };
  useEffect(() => {
    //
  }, [isOpen]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: { xs: '100%', sm: '60vw', md: '50vw' }, border: 'none', overflow: 'hidden' },
          // sx: { width: { xs: 340, md: 540 }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            {selectedAns && <div dangerouslySetInnerHTML={getHtml(selectedAns.data)}></div>}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
