import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import {
  teamNotification,
  teamNotificationStatusChange,
  teamNotificationAllRead,
} from '../../DAL/Notification/Notification';
import { baseUri } from '../../config/config';
import { useSocket } from '../../Hooks/socketContext';

// ----------------------------------------------------------------------

const detectSafariBrowser = () => {
  let is_safari = false;
  if (navigator.vendor === 'Apple Computer, Inc.') {
    is_safari = true;
  }
  return is_safari;
};

function renderContent(notification) {
  const title = (
    <>
      <Typography variant='subtitle2'>{notification.project_name}</Typography>
      <Typography variant='subtitle2'>
        {notification.team_first_name} {notification.Team_last_names}
        <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
          &nbsp; {noCase(notification.message_describtion)}
        </Typography>
      </Typography>
    </>
  );

  // if (notification.notify_type === '3') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
  //     title
  //   };
  // }
  // if (notification.notify_type === '2') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
  //     title
  //   };
  // }
  // if (notification.notify_type === '1') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
  //     title
  //   };
  // }
  // if (notification.notify_type === '0') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
  //     title
  //   };
  // }
  return {
    avatar: <Avatar alt={notification.title} src={baseUri + notification.team_image} />,
    title,
    detail: notification,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification, ChangeNotificationStatus }) {
  const { avatar, title, detail } = renderContent(notification);

  const setTimeDiffernce = (date) => {
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }
    const serverDate = new Date(date); // todays date
    const serverDate_safari = new Date(date_safari); // todays date
    const difference = serverDate.getTimezoneOffset(); // another date
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm')).format(
        'DD, MMM YYYY hh:mm:sa'
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm')).format(
        'DD, MMM YYYY hh:mm:sa'
      );
      return setTime;
    }
  };

  return (
    <ListItemButton
      // to="#"
      disableGutters
      onClick={() => ChangeNotificationStatus(detail)}
      // component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.notify_status === '0' && {
          bgcolor: 'action.selected',
        }),
        background:
          String(notification.notify_type) === String(19) &&
          String(notification.notify_status) === String(0) &&
          '#e7eb043a',
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant='caption'
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/* {formatDistanceToNow(new Date(notification.created_at))} */}
            {setTimeDiffernce(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const {
    notifications,
    fetchNotificationListing,
    recieveSocketNotification,
    sendSocketNotification,
    showSocketNotification,
  } = useSocket();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.notify_status === '0').length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     notify_status: '1'
    //   }))
    // );
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await teamNotificationAllRead(formData);
    if (result.code === 200) {
      console.log('notification', result);
      fetchNotificationListing();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const ChangeNotificationStatus = async (data) => {
    console.log(data, 'data');
    let refresh = false;

    if (data.notify_status === '0') {
      //runs when notification is unread
      console.log('when-notify_status is 0');
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('notify_id', data.notify_id);
      formData.append('notify_status', 1);
      console.log(...formData, 'teamNotifi-status-change');
      const result = await teamNotificationStatusChange(formData);
      if (result.code === 200) {
        console.log('notification', result);
        fetchNotificationListing();
        // navigate(`/project/${data.project_id}`, { state: data });
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
    handleClose();
    if (String(data.notify_type) === '8') {
      navigate(`/projects/categories`);
      // navigate(`/project`);
      setTimeout(() => {
        navigate(`/projects/verified-tasks`, {
          state: { project_id: data.project_id, task_id: data.task_id },
        });
      }, 0);
    } else {
      navigate(`/projects/categories`);
      // navigate(`/project`);
      setTimeout(() => {
        navigate(`/projects/project/${data.project_id}`, { state: data });
        // navigate(`/project/${data.project_id}`, { state: data });
      }, 0);
    }
  };

  // const fetchNotificationListing = async () => {
  //   const formData = new FormData();
  //   formData.append('token', localStorage.getItem('token'));
  //   const result = await teamNotification(formData);
  //   if (result.code === 200) {
  //     console.log('notification', result);
  //     setNotifications(result.notify_list);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: 'error' });
  //   }
  // };

  useEffect(() => {
    fetchNotificationListing();
  }, [open]);

  return (
    <>
      {/* <Button onClick={() => sendSocketNotification()}>send Notification</Button>
      <Button onClick={() => showSocketNotification()}>show Notification</Button> */}
      <IconButton
        ref={anchorRef}
        size='large'
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
          // marginRight: 3
        }}
      >
        <Badge badgeContent={totalUnRead} color='error'>
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle1'>Notifications</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=' Mark all as read'>
              <IconButton color='primary' onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.notify_id}
                notification={notification}
                ChangeNotificationStatus={ChangeNotificationStatus}
              />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
