import { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { IconButton, Button } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { ContextSocket } from '../../Hooks/socketContext';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 50;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  let go_to_login = false;
  let token = localStorage.getItem('token');
  let first_name = localStorage.getItem('first_name');
  let last_name = localStorage.getItem('last_name');
  let user_id = localStorage.getItem('user_id');
  let user = { fName: first_name, lName: last_name, userId: user_id, token: token };
  Object.values(user).forEach((val) => {
    if (!val) {
      localStorage.clear();
      go_to_login = true;
    }
    if (val === '') {
      localStorage.clear();
      go_to_login = true;
    }
  });
  if (go_to_login === true) {
    return <Navigate to='/login'> </Navigate>;
  }
  // if (!localStorage.getItem('token')) {
  // return <Navigate to='/login'> </Navigate>;
  // }

  return (
    <RootStyle>
      <ContextSocket>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </ContextSocket>
    </RootStyle>
  );
}
