import { Avatar, Badge, Divider, Typography } from '@mui/material';
import React from 'react';
import { baseUri } from 'src/config/config';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { useParams, useNavigate } from 'react-router-dom';
const GroupList = ({
  chatListData,
  onGetDetail,
  isLoadingGroups,
  isChatActive,
  directMessages,
  currentItem,
  setActiveItem,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const getMessageCount = (item) => {
    if (!item.unread_message_count) {
      return 0;
    }
    if (item.unread_message_count === '') {
      return 0;
    }
    if (item.unread_message_count > 0) {
      return item.unread_message_count;
    }
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');
      return result;
    }
    return string;
  };
  const handleDetail = (e, item) => {
    setActiveItem(item);
    let show_loader = true;
    onGetDetail(item, show_loader);
  };
  const isBadgeInVisible = (item) => {
    if (String(item.chat_type) === '1') {
      return false;
    }
    return true;
  };
  const getBadgeColor = (item) => {
    if (String(item.is_online) === '0') {
      return 'inactive';
    }
    if (String(item.is_online) === '1') {
      return 'primary';
    }
  };
  return (
    <>
      <Divider className='mt-2' />
      {chatListData.map((item, index) => {
        /* map group messages */
        return (
          <div
            onClick={(e) => handleDetail(e, item)}
            key={index}
            className={`user-card-parent d-flex align-items-center py-2 position-relative ${
              item.isActive && 'active-group'
            }`}
          >
            <div>
              {item.image !== '' ? (
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap='circular'
                  variant='dot'
                  invisible={isBadgeInVisible(item)}
                  color={getBadgeColor(item)}
                >
                  <Avatar
                    alt='Remy Sharp'
                    src={baseUri + item.image}
                    className='chat-avatar'
                    // style={{ height: 30, width: 30, border: 'solid 1px #adabab', margin: '3px' }}
                  />
                </Badge>
              ) : (
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap='circular'
                  variant='dot'
                  invisible={isBadgeInVisible(item)}
                  color={getBadgeColor(item)}
                >
                  <Avatar
                    className='chat-avatar'
                    alt='Remy Sharp'
                    style={{ height: 30, width: 30 }}
                  >
                    {item.name.substring(0, 1)}
                  </Avatar>
                </Badge>
              )}
            </div>
            <div className='ml-1'>
              <Tooltip arrow title={item.name}>
                <Typography>{truncateText(item.name, 33)}</Typography>
              </Tooltip>
            </div>

            {getMessageCount(item) > 0 && (
              <span className='messages-count'>
                <Tooltip arrow title={`${getMessageCount(item)} unread`}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    color='error'
                    badgeContent={getMessageCount(item)}
                    max={99}
                  >
                    <div style={{ minHight: '2px' }}></div>
                  </Badge>
                  {/* <Typography fontSize={10}>{getMessageCount(item)}</Typography> */}
                </Tooltip>
              </span>
            )}
          </div>
        );
      })}
      {/* map direct messages */}
    </>
  );
};

export default GroupList;
