import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  FormLabel,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {
  detailProject,
  editProject,
  activeTeamList,
  getProjectTemplates,
  activeProjectCategories,
} from '../../../DAL/Project/Project';
import { teamList } from '../../../DAL/Team/Team';
import { baseUri } from '../../../config/config';
import { useSocket } from '../../../Hooks/socketContext';
import CkEditor from 'src/components/editor/CKEditor';
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

/* <<======================= STYLING =======================>> */

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
  img: {
    margin: 'auto',
  },
}));

function EditProject(props) {
  const { sendSocketNotification } = useSocket();
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  /* <<======================= STATES =======================>> */
  const [isWaiting, setIsWaiting] = useState(true);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(true);
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [projectClientsList, setProjectClientsList] = useState([]);
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [activeCategory, setActiveCategory] = useState([]);
  const [projectTemplates, setProjectTemplates] = useState([]);
  /* <<======================= HANDLING =======================>> */
  const handleChange = (data) => {
    console.log(data);
    setMembers(data);
    // const {
    //   target: { value }
    // } = event;
    // setMembers(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_name', name);
    formData.append('project_id', id);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('project_description', description);
    formData.append('status', _status);
    formData.append('project_category', selectedCategory);
    formData.append('project_template', '');
    formData.append('client_id', selectedClient);
    // formData.append('project_template', selectedTemplate);
    projectTemplates.map((template, index) => {
      let project_id = '';
      if (String(template.project_temp_id) === String(selectedTemplate)) {
        project_id = template.project_id;
        console.log(project_id, 'proj-id');
        formData.append('temp_project_id', project_id);
      }
    });
    if (Image.name) {
      formData.append('project_image', Image);
    }
    const arr = members.map((members, index) => {
      formData.append(`team[${index}]`, members.user_id);
      return members;
    });
    console.log(...formData, 'edit-proj-req');
    // return;
    setIsWaiting(true);

    const result = await editProject(formData);
    console.log(result, 'edit-project');
    console.log(selectedTemplate, selectedCategory, 'sl');
    if (result.code === 200) {
      // setData(result.Project_list);
      setIsWaiting(false);
      navigate(-1);
      members.map((member, index) => {
        const payload = {
          receiver_id: member.user_id,
          message: `${name} project team has been changed`,
          project_id: id,
          project_name: name,
          notification_type: 'task',
        };
        sendSocketNotification(payload);
        return member;
      });
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setImage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };

  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);

    const result = await detailProject(formData);
    console.log(result, 'project-detail');
    if (result.code === 200) {
      // setMembersData(result.Team_member_list);
      setName(result.Team_Data.project_name);
      setStartDate(result.Team_Data.start_date);
      setEndDate(result.Team_Data.end_date);
      setDescription(result.Team_Data.project_description);
      setImage(result.Team_Data.project_image);
      if (result.Team_Data.client_id) {
        setSelectedClient(result.Team_Data.client_id);
      }
      if (String(result.Team_Data.project_category) !== '0') {
        setSelectedCategory(result.Team_Data.project_category);
      }
      setSelectedTemplate(result.Team_Data.project_template);
      if (String(result.Team_Data.project_template) === '0') {
        setSelectedTemplate('');
      }
      if (result.Team_Data.status === '0') {
        setStatus(false);
      } else {
        setStatus(true);
      }
      // setStatus(result.Team_Data.status === '0' ? true : false);
      if (result.Team_Data.project_image) {
        setProfileImage(baseUri + result.Team_Data.project_image);
      } else {
        setProfileImage(
          'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
        );
      }
      setMembers(result.Team_Project);
      console.log(result.Team_Project, 'listing');
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await activeTeamList(formData);
    console.log(result, 'active-team');
    if (result.code === 200) {
      setMembersData(result.Team_member_list);
      setProjectClientsList(result.client_list);
      console.log(result.Team_member_list, 'overall listing');
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIsWaiting(false);
    }
  };
  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleChangeClient = (event) => {
    setSelectedClient(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setSelectedTemplate(event.target.value);
  };
  const fetchTemplates = async () => {
    let formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const templateResult = await getProjectTemplates(formData);
    console.log(templateResult, 'template-listing');
    if (templateResult.code === 200) {
      setProjectTemplates(templateResult.project_template_list);
    } else {
      enqueueSnackbar(templateResult.message, { variant: 'error' });
    }
  };
  const fetchCategories = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const categoryResult = await activeProjectCategories(formData);
    console.log(categoryResult, 'active-category');
    if (categoryResult.code === 200) {
      setActiveCategory(categoryResult.category_list);
    } else {
      enqueueSnackbar(categoryResult.message, { variant: 'error' });
    }
    fetchTemplates();
  };
  useEffect(async () => {
    fetchCategories();
    fetchTemplates();
    fetchTeamListing();
    fetchProjectDetail();
  }, []);

  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <>
      <Container maxWidth='lg'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Edit Project
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name'
                label='Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                id='name'
                label='Start Date'
                required
                type='date'
                fullWidth
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                id='name'
                label='End Date'
                required
                type='date'
                fullWidth
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id='tags-filled'
                options={membersData}
                value={members}
                // defaultValue={members}
                getOptionLabel={(option) => `${option.first_name}  ${' '}  ${option.last_name}`}
                filterSelectedOptions
                // getOptionSelected={(option, value) => option.id === value.id}
                // filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => handleChange(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={`${option.first_name}${' '}${option.last_name}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' label='Select Team Members' />
                )}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Team Member</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={members}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Team Member" />}
                  renderValue={(selected) => (
                    <>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.first_name} />
                      ))}
                    </>
                  )}
                >
                  {membersData.map((x, i) => (
                    <MenuItem key={x.id} value={x}>
                      {x.first_name} {(' ', x.last_name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Client</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedClient}
                  label='Client'
                  onChange={handleChangeClient}
                >
                  {projectClientsList.map((item, index) => (
                    <MenuItem key={index} value={item.user_id}>
                      {item.first_name + ' ' + item.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Project category</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedCategory}
                  label='Project category'
                  onChange={handleChangeCategory}
                >
                  {activeCategory.map((category, index) => (
                    <MenuItem key={index} value={category.id}>
                      {category.catagory_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Project template</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedTemplate}
                  label='Project category'
                  onChange={handleChangeTemplate}
                >
                  <MenuItem  value='0'>
                      No template
                    </MenuItem>
                  {projectTemplates.map((template, index) => (
                    <MenuItem key={index} value={template.project_temp_id}>
                      {template.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12}>
              <FormLabel>Project Description :</FormLabel>
              <div className='project-description'>
                <CkEditor content={description} setContent={(value) => setDescription(value)} />
              </div>
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team members' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditProject;
