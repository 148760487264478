import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import closeFill from '@iconify/icons-eva/close-fill';
import CkEditor from 'src/components/editor/CKEditor';
import Scrollbar from 'src/components/Scrollbar';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import {
  Avatar,
  Badge,
  ButtonBase,
  Chip,
  CircularProgress,
  DialogContentText,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { baseUri } from 'src/config/config';
import { filter } from 'lodash';

export default function ChatDetailSidebar({ detail, isOpen, toggle }) {
  const getFilteredList = () => {
    if (!detail.detail.team) {
      return [];
    }
    return detail.detail.team;
  };
  const hasChatTime = () => {
    if (!detail.detail.chat_start_time) {
      return false;
    }
    if (!detail.detail.chat_end_time) {
      return false;
    }
    return true;
  };
  const getChatTime = (time) => {
    if (!detail.detail.chat_start_time) {
      return '';
    }
    if (!detail.detail.chat_end_time) {
      return '';
    }
    let moment_obj = moment(time);
    let formatted = moment(moment_obj).format('hh:mm a');
    return formatted;
  };
  if (!detail) {
    return (
      <div className='no-data'>
        <></>
      </div>
    );
  }
  const getBadgeColor = (item) => {
    if (String(item.is_online) === '0') {
      return 'inactive';
    }
    if (String(item.is_online) === '1') {
      return 'primary';
    }
  };
  return (
    <Drawer
      disableEnforceFocus={true}
      anchor='right'
      open={isOpen}
      onClose={toggle}
      PaperProps={{
        sx: { width: { xs: '100%', sm: '390px', md: '390px' }, border: 'none', overflow: 'hidden' },
      }}
    >
      <div style={{ position: 'relative' }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={toggle}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <div className='mt-3 mb-2 col'>
          <Typography fontWeight='bold'>Group Detail</Typography>
        </div>
        <Scrollbar className='new-msg-sidebar-mobile'>
          <Stack spacing={2} sx={{ p: 2, paddingTop: 0 }}>
            <form>
              <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
                {hasChatTime() && (
                  <Grid item xs={12}>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-5'>
                            <Typography fontWeight='bold'>Chat start time :</Typography>
                          </div>
                          <div className='col-7'>
                            <Typography fontWeight='light'>
                              {getChatTime(detail.detail.chat_start_time)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-5'>
                            <Typography fontWeight='bold'>Chat end time :</Typography>
                          </div>
                          <div className='col-7'>
                            <Typography fontWeight='light'>
                              {getChatTime(detail.detail.chat_end_time)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography fontWeight='bold'>Group Members</Typography>
                  {getFilteredList().map((item, index) => {
                    return (
                      <ButtonBase
                        key={index}
                        className='team-list-parent my-2 border w-100'
                        onClick={(e) => null}
                      >
                        <div className='row'>
                          <div className='col-12'>
                            <div className='d-flex align-items-center'>
                              <div>
                                {item.image !== '' ? (
                                  <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                    <Badge
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      overlap='circular'
                                      variant='dot'
                                      invisible={false}
                                      color={getBadgeColor(item)}
                                    >
                                      <Avatar
                                        alt='Remy Sharp'
                                        src={baseUri + item.image}
                                        className='chat-avatar'
                                      />
                                    </Badge>
                                  </Tooltip>
                                ) : (
                                  <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                    <Badge
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      overlap='circular'
                                      variant='dot'
                                      invisible={false}
                                      color={getBadgeColor(item)}
                                    >
                                      <Avatar alt='Remy Sharp' className='chat-avatar'>
                                        {item.first_name.substring(0, 1)}
                                      </Avatar>
                                    </Badge>
                                  </Tooltip>
                                )}
                              </div>
                              <div className='ml-2'>
                                <Typography>{item.first_name + ' ' + item.last_name} </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ButtonBase>
                    );
                  })}
                </Grid>
              </Grid>
              {/* 
            <LoadingButton
              type='submit'
              loading={isLoading}
              // onClick={() => handleUploadFile()}
            >
              Send
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button> */}
            </form>
          </Stack>
        </Scrollbar>
      </div>
    </Drawer>
  );
}
