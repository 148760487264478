import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Container,
  CircularProgress,
  Stack,
  Typography,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import { filter } from 'lodash';
import Page from 'src/components/Page';
import { makeStyles } from '@mui/styles';
import {
  activeProjectCategories,
  archiveProject,
  getArchiveProjects,
} from 'src/DAL/Project/Project';
import StyledSearch from 'src/components/search/StyledSearch';
import SearchNotFound from 'src/components/SearchNotFound';
import ProjectCard from './ProjectCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));
const ArchivedProjectList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesList, setCategoriesList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isWaiting, setIsWaiting] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUnArchiving, setIsUnArchiving] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_category) => _category.project_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (e) => {
    const filtered = handleLocalTaskSearch(searchValue, projectList);
    return filtered;
  };

  const getArchived = async () => {
    let formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await getArchiveProjects(formData);
    console.log(result, 'arch');
    if (result.code === 200) {
      setProjectList(result.Project_list);
      setIsWaiting(false);
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handelUnArchive = async () => {
    console.log(selectedProject);
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('project_id', selectedProject.id);
    data.append('is_archive', '0');
    console.log(...data, 'unarchive-req');
    setIsUnArchiving(true);
    const result = await archiveProject(data);
    if (result.code === 200) {
      enqueueSnackbar('Project Un-Archived', { variant: 'success' });
      setIsUnArchiving(false);
      setIsDialogOpen(false);
      setProjectList((prev) => {
        return prev.filter((data) => String(data.id) !== String(selectedProject.id));
      });
    } else {
      setIsUnArchiving(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    // getActiveList();
    getArchived();
  }, []);
  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <Page title='Archived Projects | Support Task Portal'>
      <Container maxWidth='xl'>
        <Stack direction='row' alignItems='center' mb={3}>
          <IconButton className='mr-2' onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h4'>Archived Projects</Typography>
        </Stack>
        <Stack mb={3} direction='row' alignItems='center'>
          <div className='d-flex w-100 align-items-center'>
            <span>
              <StyledSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeHolder='Search...'
              />
            </span>
          </div>
        </Stack>
        <Grid container spacing={3}>
          {getFilteredList().length > 0 ? (
            getFilteredList().map((project, index) => (
              <Grid key={project.id} item xs={12} sm={6} md={3}>
                <ProjectCard
                  selectProject={(data) => setSelectedProject(data)}
                  onUnArchive={() => setIsDialogOpen(true)}
                  category={project}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <SearchNotFound />
            </Grid>
          )}
        </Grid>
        {/* confirmation dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Un-Archive Project</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Project will be moved to Projects Page, Are you sure you want to Un-Archive this
              Project?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isUnArchiving} onClick={() => handelUnArchive()}>
              Yes
            </LoadingButton>
            <Button
              onClick={() => setIsDialogOpen(false)}
              //    autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ArchivedProjectList;
