import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import { string } from 'yup';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { taskSectionsList } from 'src/DAL/Project/Project';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'none', label: 'none', type: 'default' },
  { value: 'oldest', label: 'Oldest First', type: 'date' },
  { value: 'newest', label: 'Newest First', type: 'date' },
  { value: 'priorityDesc', label: 'Priority: High-Low', type: 'priority' },
  { value: 'priorityAsc', label: 'Priority: Low-High', type: 'priority' },
  {
    value: 'priorityAsc',
    label: 'Pick last project from array instead of first.',
    type: 'priority',
  },
];
export default function SectionSelectV2({
  data,
  onChange,
  selectedIndex,
  setSelectedIndex,
  setSelectedSection,
  taskSectionsListing,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const [sectionsList, setSectionsList] = useState([]);
  const [title, setTitle] = useState('');
  const { id } = useParams();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = async (event, index) => {
    console.log('isindex', index);
    setSelectedIndex(index);
    let text = getText(index);
    setTitle(text);
    setOpen(null);
    onChange(taskSectionsListing[index]);
    setSelectedSection(taskSectionsListing[index]);
  };
  const isSelected = (index) => {
    let selected = false;
    if (index === selectedIndex) {
      selected = true;
    }
    return selected;
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');

      return result;
    }
    return string;
  };
  const getText = (index) => {
    console.log(index, 'at-get');
    if (index === -1) {
      return '';
    }
    const text = taskSectionsListing[index].section_name;
    return truncateText(text, 20);
  };
  const getFilteredSections = (data) => {
    let filtered = data.filter((item) => String(item.vasibility_status) === '1');
    return filtered;
  };
  return (
    <>
      <Button
        color='inherit'
        disableRipple
        onClick={handleOpen}
        sx={{
          backgroundColor: '#f6f7f8',
          width: '100%',
          overflow: 'hidden',
          height: '52px',
          padding: '2px',
          overflow: 'hidden',
        }}
        endIcon={<Icon color='#00ab55' icon={open ? chevronUpFill : chevronDownFill} />}
      >
        <Typography
          component='span'
          sx={{ color: '#00ab55', fontWeight: '600', textTransform: 'none' }}
        >
          {/* {title === '' && 'Choose Section'} */}
          {taskSectionsListing.length <= 0 && 'Choose Section'}
          {/* &nbsp; */}
        </Typography>
        <Typography
          component='span'
          overflow='hidden'
          variant='subtitle2'
          sx={{ color: 'text.secondary', textTransform: 'none' }}
        >
          {/* {title} */}
          {taskSectionsListing.length > 0 &&
            truncateText(taskSectionsListing[selectedIndex].section_name, 25)}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            // maxWidth: '18rem',
          },
        }}
      >
        {getFilteredSections(taskSectionsListing).length > 0 ? (
          <span>
            {getFilteredSections(taskSectionsListing).map((option, index) => (
              <MenuItem
                key={option.id}
                // selected={option.value === 'newest'}
                // onClick={handleClose}
                sx={{ typography: 'body2' }}
                selected={isSelected(index)}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {truncateText(option.section_name, 30)}
                {/* {option.task} */}
              </MenuItem>
            ))}
          </span>
        ) : (
          <MenuItem disabled sx={{ typography: 'body2' }}>
            No sections yet
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
