import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import TemplateCard from './TemplateCard';
import SearchNotFound from './SearchNotFound';

// ----------------------------------------------------------------------

Template_List.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default function Template_List({ templates, onDeleteProject, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {templates.length > 0 ? (
        templates.map((template, index) => (
          <Grid ke={index} key={template.project_temp_id} item xs={12} sm={6} md={3}>
            <TemplateCard template={template} onDeleteProject={onDeleteProject} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <SearchNotFound />
        </Grid>
      )}
    </Grid>
  );
}
