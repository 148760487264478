import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Input,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Page from "src/components/Page";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filter, uniq } from "lodash";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { baseUri } from "src/config/config";
import StyledSearch from "src/components/search/StyledSearch";
import moment from "moment";
import { noTask } from "src/assets";
import {
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Loader from "src/pages/Loader";
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskCard from "src/components/TaskCard";
import { useSocket } from "src/Hooks/socketContext";
import { set_blank_target } from "src/utils/DomUtils";
//icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CommentIcon from "@mui/icons-material/Comment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CircleIcon from "@mui/icons-material/Circle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SquareIcon from "@mui/icons-material/Square";

//components
import PriorityPopover from "src/pages/Tasks/components/Priority";
import StatusPopover from "src/pages/Tasks/components/Status";
import MembersListing from "src/pages/Tasks/components/MembersListing";
import AddMembers from "src/pages/Tasks/components/AddMembers";
import {
  changeStatus,
  changePriority,
  addTaskMembers,
  deleteTaskMembers,
  addTaskTargetDate,
} from "src/DAL/Project/Project";
import AddMembersV2 from "./components/AddMembersV2";
import MembersListingV2 from "./components/MembersListingV2";
import SectionEditSidebar from "./components/SectionEditSidebar";
import { SORT_BY_OPTIONS } from "src/constants";
import { TaskDateTimePicker, TaskTimePicker } from "src/components";
import { format, parseISO } from "date-fns";
import Linkify from 'react-linkify';
import { LinkIt , UrlComponent,LinkItUrl} from 'react-linkify-it';
import { SecureLink } from "react-secure-link";

const regexToMatch = /(:^|[^@\.\w-])([-\w:.]{1,256}\.[\w()]{1,6}\b)/;

const TaskSectionAccordion = ({
  section,
  expandSection,
  fetchTaskListing,
  projectMembers,
  projectDetail,
  setOpenEditTask,
  setIsSectionMenuOpen,
  setSectionMenuAnchorEl,
  searchValue,
  setSearchValue,
  setTaskData,
  taskSortStatus,
  setRowData,
  setIsCommentDrawerOpen,
  setTaskSectionsData,
  data,
  handleOnDragEnd,
  openAccordionMenu,
  accordionIndex,
  selectedIndex,
}) => {
  //hooks
  const { sendSocketNotification } = useSocket();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (data) => {
    if (data) {
      expandSection(data.section_id);
    }
  };
  const openMenu = (e, section) => {
    openAccordionMenu(e, section);
  };

  const shouldStatusOpen = () => {
    let should = true;

    return should;
  };
  //status handling
  const handleStatusChecked = async (status, data) => {
    // console.log(status, data, 'at-status-checked');
    // return;
    const get_checked_tasks = (_status, _data, _tasks) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return { ...task, status: 3 };
        }
        return task;
      });
      return updated;
    };
    const get_unchecked_tasks = (_status, _data, _tasks) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return { ...task, status: 1 };
        }
        return task;
      });
      return updated;
    };
    if (status === true) {
      setTaskSectionsData((sections) =>
        sections.map((section) => {
          return {
            ...section,
            section_tasks: get_checked_tasks(
              status,
              data,
              section.section_tasks
            ),
          };
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 3);
      formData.append("task_id", data.task_id);
      console.log(...formData, "change-status-req");
      const result = await changeStatus(formData);
      if (result.code === 200) {
        fetchTaskListing();
        enqueueSnackbar("Task status changed", { variant: "success" });
        data.members.map((z, k) => {
          const payload = {
            receiver_id: z.user_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
          return z;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      setTaskSectionsData((sections) =>
        sections.map((section) => {
          return {
            ...section,
            section_tasks: get_unchecked_tasks(
              status,
              data,
              section.section_tasks
            ),
          };
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 1);
      formData.append("task_id", data.task_id);
      console.log(...formData, "change-status-req");
      const result = await changeStatus(formData);
      if (result.code === 200) {
        fetchTaskListing();
        enqueueSnackbar("Task status changed", { variant: "success" });
        data.members.map((z, k) => {
          const payload = {
            receiver_id: z.user_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
          return z;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing();
      }
    }
  };
  const handleTaskStatus = async (value, data) => {
    const get_updated_tasks = (_value, _data, _tasks) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return { ...task, status: _value };
        }
        return task;
      });
      return updated;
    };
    // console.log(value, data);
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: get_updated_tasks(value, data, section.section_tasks),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", data.task_id);
    console.log(...formData, "change-task-status-req");
    const result = await changeStatus(formData);
    if (result.code === 200) {
      // enqueueSnackbar('Task status changed', { variant: 'success' });

      if (String(value) === "5") {
        // let message = 'Task moved to verified list';
        // showCustomSnackbar(data.task_id, message);
        enqueueSnackbar("Task moved to verified list", { variant: "info" });
      }
      if (String(value) !== "5") {
        // let message = 'Task moved to verified list';
        // showCustomSnackbar(data.task_id, message);
        enqueueSnackbar("Task status changed", { variant: "success" });
      }
      data.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Status has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };

        sendSocketNotification(payload);
        return z;
      });

      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  const navigateToVerifiedListing = (taskId) => {
    navigate(`/verified-tasks`, {
      state: { project_id: id, project_detail: projectDetail, task_id: taskId },
    });
  };
  const navigateToProject = (taskId) => {
    // console.log(location.state, 'state');
    if (location.state) {
      if (location.state.project_id) {
        navigate(`/project/${location.state.project_id}`, {
          state: {
            project_id: location.state.project_id,
            project_detail: projectDetail,
            task_id: taskId,
          },
        });
      }
    } else {
      navigate(`/project/${id}`, {
        state: {
          project_id: id,
          project_detail: projectDetail,
          task_id: taskId,
        },
      });
    }
  };
  const showCustomSnackbar = (taskId, message) => {
    const action = (key) => (
      <>
        <Button
          onClick={() => {
            // console.log(`Moved task id is ${taskId}`);
            navigateToProject(taskId);
          }}
          style={{ color: "white" }}
        >
          View
        </Button>
      </>
    );
    enqueueSnackbar(message, {
      variant: "info",
      autoHideDuration: 3000,
      action: action,
    });
  };
  //priority handling
  const handlePriority = async (value, data) => {
    const get_updated_tasks = (_value, _data, _tasks) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return {
            ...task,
            priority: _value,
          };
        }
        return task;
      });
      return updated;
    };
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: get_updated_tasks(value, data, section.section_tasks),
        };
      })
    );

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_priority", value);
    formData.append("task_id", data.task_id);
    console.log(...formData, "change-priority-req");
    // return;
    const result = await changePriority(formData);
    if (result.code === 200) {
      enqueueSnackbar("Task priority changed", { variant: "success" });
      data.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Priority has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
        return z;
      });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //members handling
  const handleAddMembers = async (value, data) => {
    const get_updated_tasks = (tasks, _data, _value) => {
      let updated = tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          task.members.push(_value);
          return task;
        }
        return task;
      });
      return updated;
    };
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: get_updated_tasks(section.section_tasks, data, value),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", data.task_id);
    // console.log(data, 'add-member');
    console.log(...formData, "add-task-members-req");
    const result = await addTaskMembers(formData);
    if (result.code === 200) {
      data.members.map((z, k) => {
        if (String(z.user_id) === String(value.user_id)) {
          // console.log(z, 'sent-to');
          const payload = {
            receiver_id: z.user_id,
            message: "You are added in another task",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
        }
      });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  const handleDeleteMembers = async (value, data) => {
    const get_updated_tasks = (_tasks, _data, _value) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return {
            ...task,
            members: task.members.filter(
              (y) => String(y.user_id) !== String(_value.user_id)
            ),
          };
        }
        return task;
      });
      return updated;
    };
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: get_updated_tasks(section.section_tasks, data, value),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", data.task_id);
    console.log(...formData, "del-task-members-req");
    const result = await deleteTaskMembers(formData);
    if (result.code === 200) {
      data.members.map((z, k) => {
        if (String(z.user_id) === String(value.user_id)) {
          // console.log(z, 'sent-to');
          const payload = {
            receiver_id: z.user_id,
            message: "You are removed from one task",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
        }
      });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //handle date
  const handleChangeEndTime = async (_task, new_end_date) => {
    /* disable time if no target date */
    if (!_task.targetDate) {
      console.log("no target date!");
      return;
    }
    console.log(new_end_date, "new_end_date");
    console.log(_task, "tsk");
    let target_task = null;
    console.log(new_end_date, "end-date");
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_task.task_id)) {
              target_task = task;
              return {
                ...task,
                end_time: new_end_date,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("end_time", new_end_date);
    formData.append("target_date", _task.targetDate);
    formData.append("task_id", _task.task_id);
    console.log(...formData, "time-req");
    const result = await addTaskTargetDate(formData);
    console.log(result, "time-es");
    if (result.code === 200) {
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getFormattedDate = (date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (!date) {
      return "Select date";
    }
    return format(parseISO(date), date_format);
  };
  const handleTargetDate = async (date, data) => {
    const get_updated_tasks = (_tasks, _data, _date) => {
      let updated = _tasks.map((task) => {
        if (String(task.task_id) === String(_data.task_id)) {
          return { ...task, targetDate: _date };
        }
        return task;
      });
      return updated;
    };
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: get_updated_tasks(section.section_tasks, data, date),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", data.task_id);
    formData.append("target_date", date);
    // formData.append("target_date", moment(date).format("YYYY-MM-DD"));
    console.log(...formData, "change-date-req");
    const result = await addTaskTargetDate(formData);
    if (result.code === 200) {
      data.members.map((z) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Target date has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
      });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //filtering
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_task) => _task.task.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };

  //section handling
  const isSection = (task) => {
    return false;
    let is_section = false;
    if (String(task.taskType) === String(2)) {
      is_section = true;
    }
    return is_section;
  };
  //toggles
  const handleOpenSectionMenu = (e, task, index) => {
    // console.log(task, 'target-task');
    // console.log(index, 'target-index');
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleOpenEditTask = (data) => {
    // console.log(data, 'handle-edit');
    setRowData(data);
    setOpenEditTask(true);
  };
  //drawers
  const openCommentsDrawer = (data) => {
    // console.log(data, 'handle-comment-open');
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  //sorting
  const handleSort = (type, option, data) => {
    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === "oldest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === "newest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
      //
    }
  };

  //drag handling
  const isDragDisabled = () => {
    let disabled = false;
    let filter = JSON.parse(localStorage.getItem(`project_${id}`))?.filter;
    if (filter) {
      if (filter.length > 0) {
        disabled = true;
      }
    }
    if (taskSortStatus?.status === true) {
      disabled = true;
    }
    if (searchValue !== "") {
      disabled = true;
    }
    if (String(SORT_BY_OPTIONS[selectedIndex].value) !== "0") {
      disabled = true;
    }
    return disabled;
  };

  //other
  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = id;
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    // console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: "<p>No Description added!</p>" };
    }

    return { __html: html };
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("task-description-html");
    }, 500);
  };
  const showNewCommentIndicator = (count) => {
    if (!count) return false;
    let show = false;
    let unread_count = Number(count);
    if (unread_count > 0) {
      return true;
    }
  };
  const getTaskCount = () => {
    return data.length;
  };
  const getPosition = () => {
    let count = getTaskCount();
    if (Number(count) < 10) {
      return "29px";
    }
    if (Number(count) >= 1 && Number(count) <= 10) {
      return "34px";
    }
    if (Number(count) > 10 && Number(count) <= 99) {
      return "34px";
    }
    if (Number(count) > 99 && Number(count) < 1000) {
      return "40px";
    }
    if (Number(count) >= 1000 && Number(count) < 10000) {
      return "48px";
    }
  };
  const getUnreadCount = (count) => {
    let message = "0 unread comments";
    if (Number(count) === 1) {
      message = `${count} unread comment`;
      return message;
    }
    if (Number(count) > 0) {
      message = `${count} unread comments`;
      return message;
    }
    return message;
  };
  const onTargetDateTimeChange = (new_date_time, task) => {
    console.log(new_date_time, "new-date");

    handleTargetDate(new_date_time, task);
  };
  useEffect(() => {
    //
  }, []);

  return (
    <div
      id="accordion-parent"
      style={{ borderRadius: "4px" }}
      className="border mb-2"
    >
      <Accordion
        square={false}
        expanded={section.expanded}
        onChange={(e) => handleChange(section)}
      >
        <div className="accordion-summary-container position-relative d-flex">
          <AccordionSummary
            style={{ width: "100%", paddingRight: "0px" }}
            expandIcon={
              <ExpandMoreIcon
                style={{ marginRight: "4px", marginLeft: "7px" }}
              />
            }
          >
            <div className="d-flex w-100 align-items-center position-relative">
              {String(section.is_default) === "1" && (
                <div
                  className="position-absolute"
                  style={{ zIndex: "2", right: `${getPosition()}` }}
                >
                  <span
                    style={{
                      background: "rgb(0, 171, 85)",
                      borderRadius: 3,
                      padding: "1px 5px",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                      minWidth: "8px",
                    }}
                  >
                    Default
                  </span>
                  {/* <span className='count-circle'>{getCount()}</span> */}
                </div>
              )}
              <div
                className="position-absolute"
                style={{ zIndex: "2", right: "0" }}
              >
                <span
                  style={{
                    background: "rgb(0, 171, 85)",
                    borderRadius: 3,
                    padding: "1px 5px",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "12px",
                    minWidth: "8px",
                    marginLeft: "12px",
                  }}
                >
                  {getTaskCount()}
                </span>
                {/* <span className='count-circle'>{getCount()}</span> */}
              </div>
              <div className="w-100">
                <Typography
                  className="theme-text-color"
                  sx={{
                    width: "90%",
                    flexShrink: 0,
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  {section.section_name}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <div
            className="position-relative d-flex align-items-center"
            style={{ zIndex: "3" }}
          >
            <div id="sale-more-icon-parent d-flex align-items-center">
              <span
                role="button"
                id="sale-more-icon"
                onClick={(e) => openMenu(e, section)}
              >
                <MoreVertIcon
                  htmlColor="#6d7c89"
                  style={{ marginBottom: "2px", marginRight: "8px" }}
                />
              </span>
            </div>
          </div>
        </div>
        <AccordionDetails>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="row1">
              {(provided) => (
                <>
                  <div
                    className="mt-3"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {section.section_tasks.length > 0 ? (
                      data.map((task, i) => (
                        <Draggable
                          key={String(task.task_id)}
                          draggableId={String(task.task_id)}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="row"
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <div className="col-12">
                                <Box
                                  id={task.task_id}
                                  sx={{ px: 5, py: 1, boxShadow: 3 }}
                                  className={
                                    snapshot.isDragging
                                      ? "onDrag col-12 relative "
                                      : `not-drag col-12 relative `
                                  }
                                  role="button"
                                  tabIndex="0"
                                  style={{
                                    borderLeft: "3px solid transparent",
                                    borderRadius: "4px",
                                    // marginBlock: '10px',
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    paddingBlock: "5px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <div className="box-inner-div">
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: 10,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        zIndex: 2,
                                      }}
                                    >
                                      <>
                                        {isDragDisabled() ? (
                                          <Tooltip title="please remove filter">
                                            <IconButton
                                              style={{ float: "right" }}
                                            >
                                              <DragIndicatorIcon
                                                style={{ color: "#C4CDD5" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <IconButton
                                            {...provided.dragHandleProps}
                                            style={{ float: "right" }}
                                          >
                                            <DragIndicatorIcon />
                                          </IconButton>
                                        )}
                                      </>

                                      <IconButton
                                        onClick={(e) =>
                                          handleOpenSectionMenu(e, task, i)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </span>
                                    <div
                                      className="d-flex  align-items-center"
                                      style={{ paddingBottom: "5px" }}
                                    >
                                      <span style={{ marginLeft: "-9px" }}>
                                      <Tooltip title={
                                String(task.status) ===
                                String(0) ? "Pending" : String(task.status) ===
                                String(1) ? 'In Progress' : String(task.status) ===
                                String(2) ? 'In Reviewe' : String(task.status) ===
                                String(3) ? 'Live Completed' : String(task.status) ===
                                String(4) ? 'Cancelled' : String(task.status) ===
                                String(5) ? 'Live Verified' : String(task.status) ===
                                String(6) ? 'Dev Completed' : String(task.status) ===
                                String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                              }> 
                                        <Checkbox
                                          // style={{ paddingLeft: '0px' }}
                                          style={{color: String(task.status) ===
                                            String(0) ? "#f5c900" : String(task.status) ===
                                            String(1) ? '#000' : String(task.status) ===
                                            String(2) ? '#fff' : String(task.status) ===
                                            String(3) ? '#51d821' : String(task.status) ===
                                            String(4) ? '#960031' : String(task.status) ===
                                            String(6) ? '#3792cb' : String(task.status) ===
                                            String(7) ? '#f96300' : '#8C8B10' }}
                                            icon={< SquareIcon />} 
                                          checked={
                                            String(task.status) === String(3) ||
                                            (String(task.status) ===
                                              String(5) &&
                                              true)
                                          }
                                          onChange={(e) =>
                                            handleStatusChecked(
                                              e.target.checked,
                                              task
                                            )
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        </Tooltip>
                                      </span>
                                      <Linkify 
                                      componentDecorator={(
                                        decoratedHref: string,
                                        decoratedText: string,
                                        key: number
                                      ) => (
                                        <SecureLink href={decoratedHref} key={key} target="_blank">
                                          {decoratedText}
                                        </SecureLink>
                                      )}
                                      >
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        onOpen={(e) => handleOpenTooltip(e)}
                                        title={
                                          <div id="task-description-tooltip">
                                            <div
                                              id="task-description-html"
                                              dangerouslySetInnerHTML={getTooltipHtml(
                                                task.description
                                              )}
                                            ></div>
                                          </div>
                                        }
                                      >
                                        <Typography
                                          component="span"
                                          onClick={() =>
                                            handleOpenEditTask(task)
                                          }
                                          sx={{
                                            paddingRight: "4rem",
                                            // marginLeft: verifiedListing ? '8px' : '',
                                            // marginTop: verifiedListing ? '4px' : '',
                                          }}
                                          // className={`${isTemplateListing && ' mt-1'}`}
                                        >
                                             {task.task}
                                        </Typography>
                                      </Tooltip>
                                      </Linkify>
                                    </div>

                                    <div className="row align-items-center pb-1 ">
                                      {/* ================================================= date picker ===================================== */}

                                      <div
                                        className="col-md-4 mb-2 mb-md-0"
                                        style={{ paddingLeft: "17px" }}
                                      >
                                        {/* <div className='col-lg-2 col-md-3 col-sm-3'> */}
                                        <span>
                                          <TaskDateTimePicker
                                            value={task.targetDate}
                                            onChange={(new_date) =>
                                              onTargetDateTimeChange(
                                                new_date,
                                                task
                                              )
                                            }
                                            task={task}
                                          />
                                        </span>
                                        {/* {task.targetDate === "" ? (
                                          <DatePicker
                                            value={task.targetDate}
                                            onSelect={(date) =>
                                              handleTargetDate(date, task)
                                            }
                                            minDate={new Date()}
                                            targetTask={task}
                                            customInput={
                                              <ExampleCustomInput
                                                targetTask={task}
                                              />
                                            }
                                          />
                                        ) : (
                                          <DatePicker
                                            selected={new Date(task.targetDate)}
                                            onSelect={(date) =>
                                              handleTargetDate(date, task)
                                            }
                                            minDate={new Date()}
                                            targetTask={task}
                                            customInput={
                                              <ExampleCustomInput
                                                targetTask={task}
                                              />
                                            }
                                          />
                                        )} */}
                                      </div>

                                      <div
                                        className="col-md-4 mb-2 mb-md-0"
                                        style={{ paddingLeft: "17px" }}
                                      >
                                        <div
                                          className={`d-flex align-items-center justify-content-start`}
                                          // className={`d-flex align-items-center ${
                                          //   isTemplateListing
                                          //     ? 'justify-content-center'
                                          //     : 'justify-content-start'
                                          // }`}
                                        >
                                          <div className="mr-1">
                                            {/* <div className='col-lg-1 col-md-3 col-sm-3 '> */}
                                            <Tooltip
                                              title={`Created by ${task.taskCreatorName}`}
                                              placement="bottom"
                                            >
                                              {task.taskCreatorImage !== "" ? (
                                                <Avatar
                                                  alt="Remy Sharp"
                                                  src={
                                                    baseUri +
                                                    task.taskCreatorImage
                                                  }
                                                  style={{
                                                    height: 30,
                                                    width: 30,
                                                  }}
                                                />
                                              ) : (
                                                <Avatar
                                                  alt="Remy Sharp"
                                                  style={{
                                                    height: 30,
                                                    width: 30,
                                                  }}
                                                >
                                                  {task.taskCreatorName.substring(
                                                    0,
                                                    1
                                                  )}
                                                </Avatar>
                                              )}
                                            </Tooltip>
                                          </div>

                                          <div className="mr-2">
                                            <PriorityPopover
                                              priority={task.priority}
                                              id={task}
                                              handleTaskPriority={
                                                handlePriority
                                              }
                                              shouldOpen={true}
                                            />
                                          </div>

                                          <div className="mr-2">
                                            <StatusPopover
                                              status={task.status}
                                              // status={task.status ? task.status : '3'}
                                              id={task}
                                              handleStatus={handleTaskStatus}
                                              onStatusChecked={
                                                handleStatusChecked
                                              }
                                              shouldOpen={true}
                                            />
                                          </div>

                                          <div className="mr-1">
                                            <div className="d-flex  align-items-center">
                                              <div
                                                className="d-flex align-items-center relative"
                                                style={{
                                                  marginLeft: "auto",
                                                  position: "relative",
                                                }}
                                              >
                                                <Tooltip
                                                  onClick={() =>
                                                    openCommentsDrawer(task)
                                                  }
                                                  // title='Comments'
                                                  title={getUnreadCount(
                                                    task.unreadComments
                                                  )}
                                                >
                                                  <CommentIcon
                                                    style={{ fontSize: "1em" }}
                                                    // style={{ fontSize: '0.8em' }}
                                                    htmlColor="#9f9f9f"
                                                  />
                                                </Tooltip>
                                                {showNewCommentIndicator(
                                                  task.unreadComments
                                                ) && (
                                                  <span className="new-comment-indicator">
                                                    <CircleIcon
                                                      htmlColor="red"
                                                      style={{
                                                        fontSize: "0.5rem",
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                                <Tooltip
                                                  // title='Comments'
                                                  title={getUnreadCount(
                                                    task.unreadComments
                                                  )}
                                                >
                                                  <div>
                                                    <Typography
                                                      onClick={() =>
                                                        openCommentsDrawer(task)
                                                      }
                                                      color="black"
                                                      sx={{
                                                        cursor: "pointer",
                                                        fontSize: 12,
                                                      }}
                                                      style={{
                                                        paddingInline: ".1rem",
                                                      }}
                                                    >
                                                      {task.commentsCount}
                                                    </Typography>
                                                  </div>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>

                                          {String(task.visibilityStatus) ===
                                            String(1) && (
                                            <div
                                              onClick={() =>
                                                handleCopyTaskLink(task)
                                              }
                                              className="mr-2"
                                            >
                                              <Tooltip title="Copy task link">
                                                <ContentCopyIcon
                                                  style={{
                                                    // marginLeft: '1rem',
                                                    color: "grey",
                                                    fontSize: "0.9rem",
                                                  }}
                                                />
                                              </Tooltip>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        className="col-md-4 d-flex"
                                        style={{ paddingLeft: "17px" }}
                                      >
                                        <AddMembersV2
                                          onAddMember={handleAddMembers}
                                          onDeleteMember={handleDeleteMembers}
                                          membersData={projectMembers}
                                          onId={task}
                                          assignMembers={task.members}
                                        />

                                        <MembersListingV2
                                          onId={task}
                                          onAddMember={handleAddMembers}
                                          onDeleteMember={handleDeleteMembers}
                                          membersData={projectMembers}
                                          assignMembers={task.members.filter((value)=> value.allow_check_comments == '0')}
                                          membersList={task.members.filter((value)=> value.allow_check_comments == '0')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Box>
                              </div>
                              {/* {provided.placeholder} */}
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <div className="col-12">
                        <img
                          height="124px"
                          width="124px"
                          src={noTask}
                          alt="no task"
                          style={{ margin: "auto" }}
                        />
                        <Typography
                          style={{
                            textAlign: "center",
                            paddingLeft: 50,
                            paddingTop: 10,
                          }}
                        >
                          No Tasks Found
                        </Typography>
                      </div>
                    )}
                  </div>
                  {provided.placeholder}
                </>
              )}
            </Droppable>
          </DragDropContext>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TaskSectionAccordion;
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip arrow title="Target Date">
      {value !== "" ? (
        <>
          <a
            role="button"
            tabIndex="0"
            className="btn"
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              //  color: 'green',
              color: getDateColor(value, targetTask.status),
              padding: 0,
            }}
          >
            {getActualDate(value)}
            {/* {moment(value).format('DD MM, YYYY')} */}
          </a>
          {showDangerIcon(value, targetTask.status) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          className="btn"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{
            textDecorationLine: "underline",
            fontSize: 12,
            padding: 0,
            //  color: 'green'
            color: getDateColor(value),
          }}
        >
          Select date
        </a>
      )}
    </Tooltip>
  )
);
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
const detectSafariBrowser = () => {
  let is_safari = false;
  if (navigator.vendor === "Apple Computer, Inc.") {
    is_safari = true;
  }
  return is_safari;
};
const getActualDate = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("DD, MM YYYY");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "DD, MM YYYY"
    );

    return setTime;
  }
};
const getActual = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("YYYY MM DD");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "YYYY MM DD"
    );

    return setTime;
  }
};
