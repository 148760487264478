import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
// components
import MenuPopover from '../../../components/MenuPopover';
import { sale_lead_type_color_and_value } from 'src/constants';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

export default function SaleTypePopover(props) {
  const anchorRef = useRef(null);
  const [openpriority, setOpenPriority] = useState(false);

  const handleClose = () => {
    setOpenPriority(false);
  };

  const handlePriority = (e, data) => {
    let sale = props.row;
    props.onChangeType(data.value, sale);
    handleClose();
  };

  const handleOpenPriority = () => {
    if (props.shouldOpen === false) {
      return;
    }
    setOpenPriority(true);
  };

  const getFlagColor = (value) => {
    const flag = sale_lead_type_color_and_value.find(
      (flag) => String(flag.value) === String(value)
    );
    if (flag) {
      return flag.color;
    }
  };
  const getIcon = (value) => {
    const flag = sale_lead_type_color_and_value.find(
      (flag) => String(flag.value) === String(value)
    );
    if (flag) {
      return flag.icon;
    }
  };

  const getFlagLabel = (value) => {
    const flag = sale_lead_type_color_and_value.find(
      (flag) => String(flag.value) === String(value)
    );
    if (flag) {
      return flag.label;
    }
  };

  return (
    <>
      <Tooltip arrow title={`Sale Type ${getFlagLabel(props.saleType)}`}>
        <IconButton
          style={{
            backgroundColor: 'rgba(0, 171, 85, 0.08)',
            marginLeft: '.3rem',
          }}
          ref={anchorRef}
          onClick={handleOpenPriority}
        >
          {/* <FlagIcon htmlColor={getFlagColor(props.saleType)} /> */}
          <Icon style={{ fontSize: '0.6em' }} icon={getIcon(props.saleType)} />
          {/* <FlagIcon style={{ fontSize: '0.6em' }} htmlColor={getFlagColor(props.saleType)} /> */}
        </IconButton>
      </Tooltip>

      <MenuPopover open={openpriority} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {sale_lead_type_color_and_value.map((option, i) => (
            <MenuItem
              key={option.value}
              selected={String(props.saleType) === String(sale_lead_type_color_and_value[i].value)}
              sx={{ py: 1, px: 2.5 }}
              onClick={(e) => handlePriority(e, option)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <Icon icon={getIcon(option.value)} />
                {/* <FlagIcon htmlColor={getFlagColor(option.value)} /> */}
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2', fontSize: '12' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
