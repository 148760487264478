import React from 'react';
import { useEffect } from 'react';
// material
import {
  Box,
  Card,
  Checkbox,
  CardHeader,
  Typography,
  FormControlLabel,
  Stack,
  Divider,
  Skeleton,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Avatar,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { noTask } from '../../assets/index';
// import { noTask } from '../../assets/images/undraw_no_data_re_kwbl.svg';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useSocket } from '../Hooks/../../Hooks/socketContext';

import { changeStatus, detailProject } from 'src/DAL/Project/Project';
import { baseUri } from '../../config/config';
import { useNavigate, useLocation } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// ----------------------------------------------------------------------

const StatusArray = [
  {
    value: 0,
    label: 'Pending',
    color: '#FFC107',
    // icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 1,
    label: 'In Progress',
    color: '#3366FF',
    // icon: '/static/icons/ic_flag_de.svg'
  },
  // {
  //   value: 2,
  //   label: 'In Review',
  //   color: 'warning'
  //   // icon: '/static/icons/ic_flag_fr.svg'
  // },
  {
    value: 3,
    label: 'Completed',
    color: '#00AB55',
    // icon: '/static/icons/ic_flag_fr.svg'
  },
  {
    value: 4,
    label: 'Cancelled',
    color: '#FF4842',
    // icon: '/static/icons/ic_flag_fr.svg'
  },
];
const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Tooltip title='Target Date'>
    {value !== '' ? (
      <a
        role='button'
        tabIndex='0'
        className='btn theme-text-color'
        // onClick={onClick}
        // onKeyDown={onClick}
        ref={ref}
        style={{
          textDecorationLine: 'underline',
          fontSize: 12,
          padding: 0,
        }}
      >
        {moment(value).format('DD MMM, YYYY')}
      </a>
    ) : (
      <a
        role='button'
        tabIndex='0'
        onClick={onClick}
        onKeyDown={onClick}
        ref={ref}
        style={{ textDecorationLine: 'underline', fontSize: 12, padding: 0 }}
        className='theme-text-color'
      >
        Select Date
      </a>
    )}
  </Tooltip>
));
// ----------------------------------------------------------------------
const getAssignedTask = (data) => {
  if (!data) return [];
  const findAssign = data.find(
    (t) => String(t.user_id) === String(localStorage.getItem('user_id'))
  );
  if (findAssign) {
    return true;
  }
  return false;
};

export default function GlobalTaskListing({ data, title, getTasks }) {
  const [taskData, setTaskData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { recieveSocketNotification, sendSocketNotification, showSocketNotification } = useSocket();
  console.log(data, 'at card');
  const getStatusColor = (value) => {
    const chip = StatusArray.find((chip) => String(chip.value) === String(value));
    return chip.color;
  };
  const getProjectDetail = async (id) => {
    console.log(id, 'id');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);
    const result = await detailProject(formData);
    return result;
  };
  const handleVisibleDetail = (status, id) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            detailVisible: status,
          };
        }
        return x;
      })
    );
  };
  const handlePriority = () => {
    //
  };
  const handleTargetDate = async (date, id) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            targetDate: date,
          };
        }
        return x;
      })
    );
  };
  const getModifiedData = (data) => {
    let modified = [];
    data.map((task, index) => {
      const userId = localStorage.getItem('user_id');
      let isAllowed = false;
      if (String(userId) === String(task.task_creator_id)) {
        isAllowed = true;
      }
      task = {
        // ...task,
        task_id: task.task_id,
        task: task.task_name,
        status: task.task_status,
        description: task.task_distribution,
        task_creator_id: task.task_creator_id,
        taskOrder: task.task_order,
        targetDate: task.target_date,
        projectId: task.project_id,
        projectManagerId: task.project_manager_id,
        creatorImage: task.task_creator_image,
        creatorName: task.task_creator_name,
        endDate: '',
        priority: task.task_priority,
        detailVisible: true,
        isAssigned: true,
        visibilityStatus: task.vasibility_status,
        isAllowed: isAllowed,
        isCreator: isAllowed,
        createdDate: task.created_at,
        backToDashboard: true,
        projectName: task.project_name,
      };
      modified.push(task);
    });
    return modified;
  };
  const handleTaskStatus = async (value, data) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            status: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('status', value);
    formData.append('task_id', data.task_id);

    const result = await changeStatus(formData);
    if (result.code === 200) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: value,
            };
          }
          return x;
        })
      );
      const payload = {
        receiver_id: data.projectManagerId,
        message: 'Your Task Status has been Changed',
        project_id: data.projectId,
        task_id: data.task_id,
        project_name: data.projectName,
      };
      sendSocketNotification(payload);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      //   getTasks();
    }
    // setTaskStatus(value);
  };
  const handleStatusChecked = async (status, data) => {
    // console.log(status, data, 'AT STATUS');
    // let projectDetail = await getProjectDetail(data.projectId);
    // console.log(projectDetail, 'detail');
    if (status === true) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 3,
            };
          }
          return x;
        })
      );
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('status', 3);
      formData.append('task_id', data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        // fetchTaskListing();
        // getTasks();
        setTaskData((task) =>
          task.map((x) => {
            if (x.task_id === data.task_id) {
              return {
                ...x,
                status: 3,
              };
            }
            return x;
          })
        );
        const payload = {
          receiver_id: data.projectManagerId,
          message: 'Your Task Status has been Changed',
          project_id: data.projectId,
          task_id: data.task_id,
          project_name: data.projectName,
        };
        sendSocketNotification(payload);
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        // fetchTaskListing();
      }
    } else {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 1,
            };
          }
          return x;
        })
      );

      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('status', 1);
      formData.append('task_id', data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        // fetchTaskListing();
        // getTasks();
        setTaskData((task) =>
          task.map((x) => {
            if (x.task_id === data.task_id) {
              return {
                ...x,
                status: 1,
              };
            }
            return x;
          })
        );
        const payload = {
          receiver_id: data.projectManagerId,
          message: 'Your Task Status has been Changed',
          project_id: data.projectId,
          task_id: data.task_id,
          project_name: data.projectName,
        };
        sendSocketNotification(payload);
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        // getTasks();
        // fetchTaskListing();
      }
    }
  };
  const handleProjectNavigate = (task) => {
    navigate(`/project/${task.projectId}`, { state: task });
  };
  const handleNavigate = (task) => {
    console.log(task, 'navigate');
    navigate(`/project/${task.projectId}`, { state: task });
  };
  useEffect(() => {
    console.log(location.state, 'loc');
    const result = getModifiedData(location.state);
    setTaskData(result);
    console.log(result, 'modified');
  }, [data]);
  return (
    <Card sx={{ boxShadow: 0, border: 'solid 1px #c5c2c2', margin: 4 }}>
      <div style={{ marginLeft: '1rem' }}>
        <IconButton onClick={() => navigate('/projects')}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <CardHeader title={'results'} />
      <Box sx={{ px: 5, py: 1, minHeight: '13rem' }}>
        <div className='row ' style={{ minHeight: '13rem', minHeight: '100%' }}>
          {taskData.length > 0 ? (
            taskData.map((task, index) => (
              <Box
                key={index}
                className='not-drag col-12  pl-1 not-drag-col-outer'
                style={
                  {
                    // borderLeft: `3px solid ${getStatusColor(task.status)}`,
                  }
                }
                sx={{ boxShadow: 3 }}
              >
                <div
                  className='not-drag col-12 p-0 not-drag-col-inner'
                  role='button'
                  tabIndex='0'
                  // style={{
                  //   borderLeft: `3px solid ${getStatusColor(task.status)}`,
                  //   borderTopLeftRadius: 4,
                  //   borderBottomLeftRadius: 4,
                  // }}
                >
                  <div className='d-flex align-items-center p-2' style={{ paddingBottom: '5px' }}>
                    {/* <Checkbox
                      checked={String(task.status) === String(3) && true}
                      onChange={(e) => handleStatusChecked(e.target.checked, task)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}

                    <Typography
                      component='div'
                      color={'#5a5a5a'}
                      // className={task.isAssigned === false && 'col-disabled'}
                      // onClick={() => handleOpenEditTask(task)}
                      onClick={() => handleNavigate(task)}
                    >
                      {task.task}&nbsp;&nbsp;&nbsp;
                    </Typography>

                    <div
                      className='d-flex align-items-center'
                      style={{
                        marginLeft: 'auto',
                        marginRight: '.5rem',
                        flexWrap: 'no-wrap',
                      }}
                    ></div>
                  </div>

                  <div className='row'>
                    {/* ================================================= date picker ===================================== */}

                    <div className='col-4 d-flex flex-col align-items-center '>
                      <div style={{ marginLeft: '.7rem' }} className='theme-text-color'></div>
                    </div>

                    <div className='col py-1 '>
                      <Tooltip title='Project name'>
                        <Typography
                          onClick={() => handleProjectNavigate(task)}
                          className='project-name-div'
                          component='span'
                        >
                          {task.projectName}
                        </Typography>
                      </Tooltip>
                    </div>
                  </div>

                  {/* <Divider /> */}
                </div>
              </Box>
            ))
          ) : (
            <div className='col-12 mt-5' style={{ minHeight: '100%' }}>
              <img
                src={noTask}
                alt='no task'
                width='64rem'
                height='64rem'
                style={{ margin: 'auto' }}
              />
              <Typography
                style={{
                  textAlign: 'center',
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Task Found
              </Typography>
            </div>
          )}
        </div>
      </Box>
    </Card>
  );
}
