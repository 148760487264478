import { Card, Tooltip } from "@mui/material";
import {
  formatISO,
  format,
  parseISO,
  isToday,
  setHours,
  setMinutes,
} from "date-fns";
import { isPast } from "date-fns/esm";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Iconify } from ".";
/* <<--------------------------------------------------------->> */
// const TaskDatePick = () => {
const TaskDateTimePicker = ({ task, value, onChange, disabled }) => {
  /* <<--------------------------------------------------------->> */
  // const [value, setValue] = useState("");
  // const [value, setValue] = useState("2022-08-17T19:00:00.000Z");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (_date) => {
    const fns_iso_date = formatISO(_date, { representation: "complete" });
    // setValue(fns_iso_date);
    let expired = isPast(fns_iso_date);
    console.log(expired, "expired?");
    /* expired not working */
    if (expired === true) {
      console.log("expired!");
      return;
    }
    onChange(fns_iso_date);

    handleClose();
  };

  const handleFilterTimes = (time) => {
    if (value === "") {
      return false; /* disable times */
    }
    if (isToday(parseISO(value))) {
      return true;
    }
    let expired = isPast(parseISO(value));
    if (expired === true) {
      return false; /* disable times */
    }
    return true;
  };
  return (
    <div id="task-date-pick">
      <DatePicker
        value={value ? value : ""}
        open={open}
        timeIntervals={5}
        showTimeSelect
        filterTime={handleFilterTimes}
        onClickOutside={handleClose}
        onChange={handleChange}
        selected={value ? parseISO(value) : new Date()}
        minDate={new Date()}
        disabled={disabled}
        portalId="date-picker-root-portal"
        // injectTimes={[setHours(setMinutes(new Date(), 10), 0)]}
        customInput={
          <CustomPickerInput
            onOpen={handleOpen}
            onClose={handleClose}
            task={task}
            open={open}
            disabled={disabled}
          />
        }
      />
    </div>
  );
};

export default TaskDateTimePicker;

const CustomPickerInput = React.forwardRef((props, ref) => {
  const { task, value, onOpen, open, onClose, disabled } = props;
  // console.log(task, "task-");
  const handleOpen = () => {
    if (disabled) {
      return;
    }
    onOpen();
  };
  const getFormattedDate = (_date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (!_date) {
      return "Select date/time";
    }
    const formatted = format(parseISO(_date), date_format);

    return formatted;
  };
  if (open === true) {
    return (
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{ color: isPast(parseISO(value)) ? "red" : "" }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) && (
          <Iconify
            icon="material-symbols:priority-high"
            sx={{ color: "red", ml: 0.2, height: "1rem", width: "1rem" }}
          />
        )}
      </span>
    );
  }
  return (
    <Tooltip title="Target date/time">
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{ color: isPast(parseISO(value)) ? "red" : "" }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) && (
          <Iconify
            icon="material-symbols:priority-high"
            sx={{ color: "red", ml: 0.2, height: "1rem", width: "1rem" }}
          />
        )}
      </span>
    </Tooltip>
  );
});
