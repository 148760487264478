import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
// utils
// import { fCurrency } from '../../../utils/formatNumber';
//
// import Label from '../../Label';
// import ColorPreview from '../../ColorPreview';
import { baseUri } from '../../../config/config';
import { saveProjectAsTemplate } from 'src/DAL/Project/Project';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

TemplateCard.propTypes = {
  product: PropTypes.object,
};

export default function TemplateCard({ template, onDeleteProject }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;
  console.log(template, 'template-detail');
  const handleOpenDialig = (data) => {
    setOpenDialog(true);
    setProjectData(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Card>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <Menu
            key='left'
            direction='right'
            align='center'
            position='anchor'
            viewScroll='auto'
            menuButton={
              <IconButton
                style={{
                  zIndex: 9,
                  top: 10,
                  right: 9,
                  position: 'absolute',
                }}
              >
                <MoreVertIcon
                  style={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    color: 'black',
                  }}
                />
              </IconButton>
            }
          >
            <MenuItem
              onClick={() =>
                navigate(`/project_templates/edit_template/${template.project_temp_id}`)
              }
              sx={{ color: 'text.secondary' }}
            >
              <ListItemText primary='Edit' primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem onClick={() => handleOpenDialig(template)} sx={{ color: 'text.secondary' }}>
              <ListItemText primary='Delete' primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </Menu>
          {template.project_image ? (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: 'pointer' }}
              src={baseUri + template.project_image}
              className='image-hov'
              onClick={() =>
                navigate(`/project_templates/${template.project_temp_id}`, { state: template })
              }
            />
          ) : (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: 'pointer' }}
              src='https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
              className='image-hov'
              onClick={() =>
                navigate(`/project_templates/${template.project_temp_id}`, { state: template })
              }
            />
          )}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography
            variant='subtitle2'
            noWrap
            style={{ cursor: 'pointer' }}
            onClick={() =>
                navigate(`/project_templates/${template.project_temp_id}`, { state: template })
              }
          >
            {template.project_name}
          </Typography>

          <Stack direction='row' alignItems='center' justifyContent='space-between'></Stack>
        </Stack>
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to delete this project ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => onDeleteProject(template.project_id, template.project_temp_id)}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
