import { Avatar, Badge, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import { baseUri } from 'src/config/config';
import EditGroupPopover from './EditGroupPopover';
import EditGroupDialog from './EditGroupDialog';
import { deleteChatGroup, deleteDirectMessageChat, editChatGroup } from 'src/DAL/chat/chat';
import { useSnackbar } from 'notistack';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';
const ChatHeader = ({
  toggleDetailSidebar,
  getChatGroupList,
  currentGroup,
  toggleSideChatList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [groupOptionAnchorEl, setGroupOptionAnchorEl] = useState(null);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isDeletingChat, setIsDeletingChat] = useState(false);
  const [isDelDialogOpen, setIsDelDialogOpen] = React.useState(false);
  const toggleDelDialog = (value) => {
    setIsDelDialogOpen(value);
  };
  const handleClick = (event) => {
    if (!currentGroup) {
      return;
    }
    setGroupOptionAnchorEl(event.currentTarget);
  };

  const handleOnEdit = async (form_data) => {
    if (!currentGroup) {
      return;
    }
    const { detail } = currentGroup;
    console.log(detail);
    console.log(...form_data, 'edit-group-req');

    setIsEditingGroup(true);
    const result = await editChatGroup(form_data);
    if (result.code === 200) {
      setIsEditingGroup(false);
      enqueueSnackbar('Group updated', { variant: 'success' });
      toggleEditGroup();
      getChatGroupList();
    } else {
      setIsEditingGroup(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const toggleEditGroup = () => {
    setEditGroupOpen(!editGroupOpen);
  };
  const handleOnDel = async () => {
    if (!currentGroup) {
      return;
    }
    const { detail } = currentGroup;
    console.log(detail);
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('chat_id', detail.id);
    console.log(...data, 'del-chat-req');
    setIsDeletingChat(true);
    const result = await deleteDirectMessageChat(data);
    if (result.code === 200) {
      toggleDelDialog(false);
      setIsDeletingChat(false);
      setGroupOptionAnchorEl(null);
      getChatGroupList();
      enqueueSnackbar('Chat deleted', { variant: 'success' });
    } else {
      setIsDeletingChat(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleInfo = (event) => {
    if (!currentGroup) {
      return;
    }
    toggleDetailSidebar();
  };
  const showInfo = () => {
    if (!currentGroup) {
      return false;
    }
    if (currentGroup.detail.team) {
      return true;
    }
  };
  const getName = () => {
    if (!currentGroup) {
      return '';
    }
    if (currentGroup) {
      if (currentGroup.detail.group_name) {
        return currentGroup.detail.group_name;
      } else return `${currentGroup.detail.first_name} ${currentGroup.detail.last_name}`;
    }
  };
  const getFirstName = () => {
    if (!currentGroup) {
      return '';
    }
    if (currentGroup) {
      if (currentGroup.detail.group_name) {
        return currentGroup.detail.group_name;
      } else return `${currentGroup.detail.first_name}`;
    }
  };
  const getImageUrl = () => {
    if (!currentGroup) {
      return '';
    }
    if (currentGroup) {
      if (currentGroup.detail.group_image) {
        return currentGroup.detail.group_image;
      } else return `${currentGroup.detail.image}`;
    }
  };
  const isBadgeInVisible = () => {
    if (!currentGroup) {
      return true;
    }
    if (String(currentGroup.detail.chat_type) === '0') {
      return true;
    }
    if (String(currentGroup.detail.chat_type) === '1') {
      return false;
    }
  };
  const getBadgeColor = () => {
    if (String(currentGroup.detail.is_online) === '0') {
      return 'inactive';
    }
    if (String(currentGroup.detail.is_online) === '1') {
      return 'primary';
    }
  };
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='d-flex align-items-center' style={{ paddingBottom: '8px' }}>
          <div className='chat-list-button-container'>
            <Tooltip arrow title='Chat List'>
              <IconButton onClick={toggleSideChatList}>
                <MenuOpenIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className='group-menu-icon'>
            {currentGroup && (
              <div className='d-flex align-items-center'>
                <div>
                  {getImageUrl() !== '' ? (
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      overlap='circular'
                      variant='dot'
                      invisible={isBadgeInVisible()}
                      color={getBadgeColor()}
                    >
                      <Avatar
                        alt='Remy Sharp'
                        src={baseUri + getImageUrl()}
                        className='chat-avatar'
                      />
                    </Badge>
                  ) : (
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      overlap='circular'
                      variant='dot'
                      invisible={isBadgeInVisible()}
                      color={getBadgeColor()}
                    >
                      <Avatar alt='Remy Sharp' className='chat-avatar'>
                        {getFirstName().substring(0, 1)}
                      </Avatar>
                    </Badge>
                  )}
                </div>
                <div className='ml-1'>
                  <Typography fontWeight='bold'>{getName()}</Typography>
                </div>
              </div>
            )}
          </div>
          <div className='ml-auto group-menu-icon'>
            {showInfo() && (
              <>
                <IconButton disabled={!currentGroup} onClick={handleInfo}>
                  <InfoIcon />
                </IconButton>

                {/* {String(currentGroup.detail.chat_type) !== '0' && (
                  <IconButton disabled={!currentGroup} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                )} */}
              </>
            )}
          </div>
        </div>
        {/* <Divider style={{ marginTop: '4.5px' }} /> */}
      </div>
      {/* popovers */}
      <EditGroupPopover
        // handleOnEdit={handleOnEdit}
        handleOnDel={handleOnDel}
        anchorEl={groupOptionAnchorEl}
        setAnchorEl={setGroupOptionAnchorEl}
        toggle={toggleEditGroup}
        isDelDialogOpen={isDelDialogOpen}
        toggleDelDialog={toggleDelDialog}
        isDeleting={isDeletingChat}
      />
      {/* dialogs */}
      <EditGroupDialog
        isEditing={isEditingGroup}
        isDeleting={isDeletingChat}
        isOpen={editGroupOpen}
        toggle={toggleEditGroup}
        onEditGroup={handleOnEdit}
        currentGroup={currentGroup}
      />
    </div>
  );
};

export default ChatHeader;
