import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useSnackbar } from 'notistack';
import { filter } from 'lodash';
// material
import { Container, CircularProgress, Stack, Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import Page from '../../components/Page';
import Template_List from './components/Template_List';
import { getProjectTemplates, deleteProjectTemplate } from '../../DAL/Project/Project';
import StyledSearch from 'src/components/search/StyledSearch';

/* <<======================= STYLING =======================>> */

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));

export default function TemplateListing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  /* <<======================= STATES =======================>> */
  const [isWaiting, setIsWaiting] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchValue, setSearchValue] = useState('');
  /* <<======================= HANDLING =======================>> */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteTemplate = async (id, project_temp_id) => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);
    formData.append('project_temp_id', project_temp_id);
    const result = await deleteProjectTemplate(formData);
    console.log(result, 'delete-template');
    if (result.code === 200) {
      enqueueSnackbar('Template project deleted', { variant: 'success' });
      fetchListing();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await getProjectTemplates(formData);
    console.log(result.project_template_list, 'template-listing');
    if (result.code === 200) {
      setData(result.project_template_list);
      setIsWaiting(false);
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getFilteredTemplates = (data) => {
    if (searchValue !== '') {
      const filtered_ = handleLocalTaskSearch(searchValue, data);
      return filtered_;
    }
    return data;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_project) => _project.project_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  React.useEffect(() => {
    fetchListing();
  }, []);

  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <Page title='Projects | Support Task Portal'>
      <Container maxWidth='xl'>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <Typography variant='h4'>Project templates</Typography>
          <Button
            variant='contained'
            onClick={() => navigate('/project_templates/new_template')}
            startIcon={<Icon icon={plusFill} />}
          >
            New template
          </Button>
        </Stack>
        <Stack mb={3} direction='row' alignItems='center'>
          <div className='d-flex w-100'>
            <span>
              <StyledSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeHolder='Search Template...'
              />
            </span>
          </div>
        </Stack>
        <Template_List templates={getFilteredTemplates(data)} onDeleteProject={deleteTemplate} />
      </Container>
    </Page>
  );
}
