import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";
// components
import MenuPopover from "../../../components/MenuPopover";
import { status_color_and_value } from "src/constants";
import VerifiedIcon from "src/components/VerifiedIcon";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function StatusPopover(props) {
  const anchorRef = useRef(null);
  const [openStatus, setOpenStatus] = useState(false);
  // const [taskstatus, setTaskStatus] = useState(LANGS[0]);

  const handleStatusValue = (e, data) => {
    props.handleStatus(data.value, props.id);
    handleClose();
  };

  const handleClose = () => {
    setOpenStatus(false);
  };

  const handleOpenStatus = () => {
    if (props.shouldOpen === false) {
      return;
    }
    setOpenStatus(true);
  };

  const getChipColor = (value) => {
    const chip = status_color_and_value.find(
      (chip) => String(chip.value) === String(value)
    );

    if (chip) {
      return chip.color;
    }
  };

  const getChipLabel = (value) => {
    const chip = status_color_and_value.find(
      (chip) => String(chip.value) === String(value)
    );
    if (chip) {
      return chip.label;
    }
  };
  const getFilteredOptions = (data) => {
    let filtered = data.filter((item) => String(item.value) !== "2");
    return filtered;
  };
  return (
    <>
      <Tooltip arrow title={getChipLabel(props.status)}>
        <IconButton
          onClick={handleOpenStatus}
          style={{ backgroundColor: "rgba(0, 171, 85, 0.08)" }}
        >
          {String(props.status) !== String(5) && (
            <CircleIcon
              sx={{ fontSize: "0.7rem" }}
              ref={anchorRef}
              color={getChipColor(props.status)}
              // color={'#e3e3e3'}
              htmlColor={
                String(props.status) === "2" ? getChipColor(props.status) : getChipColor(props.status)
              }
            />
          )}
          {String(props.status) === String(5) && (
            <VerifiedIcon forIconButton={true} anchorRef={anchorRef} />
          )}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={openStatus}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {getFilteredOptions(status_color_and_value).map((option, i) => (
            <MenuItem
              key={option.value}
              selected={props.status === status_color_and_value[i].value}
              sx={{ py: 1, px: 2.5 }}
              onClick={(e) => handleStatusValue(e, option)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                {/* <CircleIcon color={option.color} /> */}
                {String(option.value) !== String(5) && (
                  <CircleIcon 
                  htmlColor={option.color} 
                  // htmlColor={
                  //   getChipColor(props.status)
                  // }
                  />
                )}
                {String(option.value) === String(5) && (
                  <VerifiedIcon forIconButton={false} />
                )}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "body2", fontSize: "12" }}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
