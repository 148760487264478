import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  RadioGroup,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
//
import moment from 'moment';

import CkEditor from 'src/components/editor/CKEditor';
import { set_blank_target } from 'src/utils/DomUtils';
import Scrollbar from 'src/components/Scrollbar';
import { updateSaleSection } from 'src/DAL/Project/Project';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

EditSaleSectionSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
  orderField: {
    '& *': {
      textAlign: 'center !important',
    },
  },
}));
export default function EditSaleSectionSidebar({
  isOpenEditSaleSidebar,
  onCloseSaleSidebar,
  selectedItem,
  getSectionListing,
  prevSections,
  sections,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [saleDescription, setSaleDescription] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [sectionCreateTime, setSectionCreateTime] = useState('');
  const [sectionStatus, setSectionStatus] = useState(false);
  const [defPaidStatus, setDefPaidStatus] = useState('0');
  const [isUpdating, setIsUpdating] = useState(false);
  const [sectionOrder, setSectionOrder] = useState('');
  const [showStats, setShowStats] = useState(false);
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prevSections) {
      prevSections.current = sections;
    }
    if (!selectedItem) return;
    let status = '0';
    if (sectionStatus === true) {
      status = '1';
    }
    let show_stats = '0';
    if (showStats === true) {
      show_stats = '1';
    }
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('sale_section_name', sectionName);
    formData.append('sale_section_status', status);
    formData.append('sale_section_id', selectedItem.sale_section_id);
    formData.append('default_status', defPaidStatus);
    formData.append('show_stat', show_stats);
    formData.append('section_order', sectionOrder);
    console.log(...formData, 'update-section-req');
    setIsUpdating(true);
    const result = await updateSaleSection(formData);
    if (result.code === 200) {
      getSectionListing();
      enqueueSnackbar('Sale section updated', { variant: 'success' });
      setIsUpdating(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIsUpdating(false);
      console.log(result, 'error');
    }
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (date === '') {
      return '-';
    }
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm')).format(
        'DD, MMM YYYY hh:mm:s a '
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm')).format(
        'DD, MMM YYYY hh:mm:s a '
      );

      return setTime;
    }
  };
  const getPhone = (phone) => {
    if (phone === '') {
      return '-';
    }
    return phone;
  };

  useEffect(() => {
    //
    // console.log(selectedItem, 'slc');
  }, [isOpenEditSaleSidebar]);

  useEffect(async () => {
    if (isOpenEditSaleSidebar) {
      if (selectedItem) {
        console.log(selectedItem, 'br');
        setSectionCreateTime(selectedItem.created_at);
        setSectionName(selectedItem.sale_section_name);
        setDefPaidStatus(selectedItem.default_status);
        setSectionOrder(selectedItem.section_order);
        if (String(selectedItem.sale_section_status) === '1') {
          setSectionStatus(true);
        }
        if (String(selectedItem.show_stat) === '1') {
          setShowStats(true);
        }
      }
    }
    if (!isOpenEditSaleSidebar) {
      setSectionCreateTime('');
      setSectionName('');
      setSectionStatus(false);
      setShowStats(false);
      setDefPaidStatus('0');
      setSectionOrder('');
    }
  }, [isOpenEditSaleSidebar]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isOpenEditSaleSidebar}
        onClose={onCloseSaleSidebar}
        PaperProps={{
          sx: { width: { xs: 340, md: 380 }, border: 'none', overflow: 'hidden' },
          // sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onCloseSaleSidebar}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <div className='mb-2'>
                <Typography style={{ fontsize: 16, fontWeight: 'bold', marginTop: 0 }}>
                  Created At :
                </Typography>
                <Typography>{getActualTime(sectionCreateTime)}</Typography>
              </div>
              <textarea
                value={sectionName}
                style={{
                  border: 'hidden',
                  backgroundColor: 'rgb(0 42 21 / 8%) ',
                  fontSize: 12,
                  minHeight: '15vh',
                }}
                className='form-control text-area-task mb-2'
                onChange={(e) => setSectionName(e.target.value)}
                id='exampleFormControlInput1'
              ></textarea>
              <div className='row align-items-center'>
                <div className='col-12'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                        Active Status :
                      </Typography>
                    </div>
                    <div className='col-7'>
                      <Switch
                        checked={sectionStatus}
                        onChange={(e) => setSectionStatus(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='row align-items-center'>
                <div className='col-12'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                        Show Stats :
                      </Typography>
                    </div>
                    <div className='col-7'>
                      <Switch
                        checked={showStats}
                        onChange={(e) => setShowStats(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='row align-items-center'>
                <div className='col-12'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                        Section Order :
                      </Typography>
                    </div>
                    <div className='col-7'>
                      <TextField
                        className={classes.orderField}
                        value={sectionOrder}
                        variant='standard'
                        type='number'
                        size='small'
                        onChange={(e) => setSectionOrder(e.target.value)}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        style={{ width: '45px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}

              {/* <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                Default Paid Status :
              </Typography> */}

              {/* <Switch
                checked={defPaidStatus}
                onChange={(e) => setDefPaidStatus(e.target.checked)}
              /> */}
              <div>
                <LoadingButton
                  fullWidth
                  size='medium'
                  type='submit'
                  color='primary'
                  variant='outlined'
                  startIcon={<Icon icon={editFill} />}
                  className='mt-2'
                  loading={isUpdating}
                >
                  Update
                </LoadingButton>
              </div>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
