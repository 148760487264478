import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Avatar,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import editFill from "@iconify/icons-eva/edit-fill";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
// utils
import { fCurrency } from "../../../utils/formatNumber";
//
import Label from "../../Label";
import ColorPreview from "../../ColorPreview";
import { baseUri } from "../../../config/config";
import { archiveProject, saveProjectAsTemplate } from "src/DAL/Project/Project";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

ProjectCard.propTypes = {
  product: PropTypes.object,
};

export default function ProjectCard({
  project,
  onDeleteProject,
  isDeleting,
  selectProject,
  onArchive,
  onChangeAsImportant,
  dragListeners,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;
  // console.log(project, 'project-detail');
  const handleOpenDialig = (data) => {
    setOpenDialog(true);
    setProjectData(data);
  };
  const handleChangeImportant = (type) => {
    onChangeAsImportant(type, project);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const addAsTemplate = async () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", project.id);
    let result = await saveProjectAsTemplate(formData);
    console.log(result, "save-as-template");
    console.log(project.id, "template-task");
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleArchive = async (data) => {
    selectProject(data);
    onArchive();
  };
  return (
    <>
      <Card>
        <Box sx={{ pt: "100%", position: "relative" }}>
          <Menu
            key="left"
            direction="right"
            align="center"
            position="anchor"
            viewScroll="auto"
            menuButton={
              <IconButton
                style={{
                  zIndex: 9,
                  top: 10,
                  right: 9,
                  position: "absolute",
                }}
              >
                <MoreVertIcon
                  style={{
                    border: "1px solid black",
                    borderRadius: "50%",
                    color: "black",
                  }}
                />
              </IconButton>
            }
          >
            <MenuItem
              onClick={() => navigate(`/projects/edit-project/${project.id}`)}
              sx={{ color: "text.secondary" }}
            >
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
              {/* Edit */}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenDialig(project)}
              sx={{ color: "text.secondary" }}
            >
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem onClick={() => addAsTemplate()}>
              <ListItemText
                primary="Save as template"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleArchive(project)}>
              <ListItemText
                primary="Archive Project"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
          {project.is_important === "1" ? (
            <Tooltip title="Remove as important">
              <IconButton
                size="small"
                onClick={() => handleChangeImportant("0")}
                sx={{
                  zIndex: 10,
                  top: 41,
                  right: 11,
                  position: "absolute",
                }}
              >
                <StarRateIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Mark as important">
              <IconButton
                size="small"
                onClick={() => handleChangeImportant("1")}
                sx={{
                  zIndex: 10,
                  top: 41,
                  right: 11,
                  position: "absolute",
                }}
              >
                <StarBorderIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Reorder">
            <IconButton
              {...dragListeners}
              size="small"
              sx={{
                zIndex: 10,
                top: 68,
                right: 11,
                position: "absolute",
                cursor: "grab",
              }}
            >
              <DragIndicatorIcon
                sx={{ cursor: "grab" }}
                // sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
          {project.project_image ? (
            <ProjectImgStyle
              style={{ cursor: "pointer" }}
              src={baseUri + project.project_image}
              className="image-hov"
              onClick={() =>
                navigate(`/projects/project/${project.id}`, {
                  state: project,
                })
              }
            />
          ) : (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: "pointer" }}
              src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
              className="image-hov"
              onClick={() =>
                navigate(`/projects/project/${project.id}`, {
                  state: project,
                })
              }
            />
          )}
          {/* <Avatar className="image-hov">N</Avatar> */}
          {/* </Link> */}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/projects/project/${project.id}`, {
                state: project,
              })
            }
          >
            {project.project_name}
          </Typography>
          {/* </Link> */}
          {/* <Typography variant="subtitle2" noWrap>
          {id}
        </Typography> */}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <ColorPreview colors={colors} /> */}
            <AvatarGroup max={3}>
              {project.member_info.map((memberInfo, index) => (
                <span key={index}>
                  {memberInfo.image ? (
                    <Avatar
                      src={baseUri + memberInfo.image}
                      style={{ width: 30, height: 30 }}
                    />
                  ) : (
                    <Avatar style={{ width: 30, height: 30 }}>
                      {memberInfo.first_name &&
                        memberInfo.first_name.substring(0, 1)}
                    </Avatar>
                    // <Avatar src={baseUri + x.image} style={{ width: 30, height: 30 }} />
                  )}
                </span>
              ))}
            </AvatarGroup>
            <Typography variant="subtitle1">
              {project.completed_task}/{parseInt(project.completed_task) + parseInt(project.pending_task) + parseInt(project.in_progress_task)}
            </Typography>
          </Stack>
        </Stack>
      </Card>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Are you sure you want to delete this project ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton
            loading={isDeleting}
            onClick={() => onDeleteProject(projectData.id)}
          >
            Yes, Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
