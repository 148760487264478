export const set_blank_target = (id) => {
  if (!id || id === '') {
    console.log('no id provided');

    return;
  }
  const htmlElement = document.getElementById(id);
  if (!htmlElement) return;
  let links = htmlElement.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    let item = links.item(i);
    item.setAttribute('target', '_blank');
  }
};
export const make_clickable_url_by_className = (className) => {
  let elements = document.getElementsByClassName(className);
  if (elements) {
    for (let element of elements) {
      if (element) {
        let text = element.innerText;
        findLink(text, element);
      }
    }
  }
  ///
  function findLink(text, element) {
    let reg = /(http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
    let res = reg.test(text);
    if (res) {
      let clickable_url = replaceURLs(text);
      if (element) {
        element.innerHTML = clickable_url;
        return;
      }
    }
    return text;
  }
  ///
  function replaceURLs(message) {
    if (!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      let res =
        '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
      return res;
    });
  }
};
