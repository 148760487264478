import { invokeApi } from 'src/bl_libs/invokeApi';

export const createGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/add_chat_group.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const chatGroupList = async (data) => {
  const requestObj = {
    path: `api/chat_group/chat_group_list.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const readGroupMessages = async (data) => {
  const requestObj = {
    path: `api/chat_group/user_group_messages_mark_as_read.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const readDirectMessages = async (data) => {
  const requestObj = {
    path: `api/chat/user_messages_mark_as_read.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteMessage = async (data) => {
  const requestObj = {
    path: `api/chat/delete_message.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const chatGroupsList = async (data) => {
  const requestObj = {
    path: `api/chat_group/group_list.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const chatGroupDetail = async (data) => {
  const requestObj = {
    path: `api/chat_group/chat_group_detail.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailWithMessages = async (data) => {
  const requestObj = {
    path: `api/chat_group/chat_group_detail_with_messages.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const unreadMessagesCount = async (data) => {
  const requestObj = {
    path: `api/chat/user_unread_messages.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailDirectMessages = async (data) => {
  const requestObj = {
    path: `api/chat/chat_details.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sendDirectMessage = async (data) => {
  const requestObj = {
    path: `api/chat/add_message.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const setMessageReaction = async (data) => {
  const requestObj = {
    path: `api/chat/add_message_reaction.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteDirectMessageChat = async (data) => {
  const requestObj = {
    path: `api/chat/delete_chat.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteChatGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/delete_chat_group.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editChatGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/edit_chat_group.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sendGroupChatMessage = async (data) => {
  const requestObj = {
    path: `api/chat_group/add_message.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const initialChatMessage = async (data) => {
  const requestObj = {
    path: `api/chat/initiallize_chat.php`,
    method: 'POST',
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
