import { invokeApi } from "../../bl_libs/invokeApi";

export const projectListing = async (data) => {
  const requestObj = {
    path: `api/projects/project_listing.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addProject = async (data) => {
  const requestObj = {
    path: `api/projects/add_project_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const userProfile = async (data) => {
  const requestObj = {
    path: `api/user_profile/detail_profile.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailProject = async (data) => {
  const requestObj = {
    path: `api/projects/detail_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailProjectV2 = async (data) => {
  const requestObj = {
    path: `api/projects/detail_project_with_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const reOrderProject = async (data) => {
  const requestObj = {
    path: `api/projects/swipe_project_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editProject = async (data) => {
  const requestObj = {
    path: `api/projects/edit_project_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteProject = async (data) => {
  const requestObj = {
    path: `api/projects/delete_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const taskList = async (data) => {
//   const requestObj = {
//     path: `api/task/task_list.php`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing purpose */
export const taskList = async (data) => {
  const requestObj = {
    path: `api/task/task_list_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
/* for testing collapsable task section */
export const taskListCollapsable = async (data) => {
  const requestObj = {
    path: `api/task/task_list_v2.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskSectionsList = async (data) => {
  const requestObj = {
    path: `api/task_section/active_task_section_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskFilterV1 = async (data) => {
  const requestObj = {
    path: `api/task/task_filter_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/delete_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSectionOrder = async (data) => {
  const requestObj = {
    path: `api/task_section/update_task_section_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const globalTaskSearch = async (data) => {
  const requestObj = {
    path: `api/search/search_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const verifiedTaskList = async (data) => {
  const requestObj = {
    path: `api/task/get_verified_task_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTask = async (data) => {
  const requestObj = {
    path: `api/task/add_task_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeProjectImportantStatus = async (data) => {
  const requestObj = {
    path: `api/projects/update_important_status.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeCategoryImportantStatus = async (data) => {
  const requestObj = {
    path: `api/category/update_important_status.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/add_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const makeSectionAsDefault = async (data) => {
  const requestObj = {
    path: `api/task_section/update_is_default.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/edit_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTemplateTask = async (data) => {
  const requestObj = {
    path: `api/project_template/add_template_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTemplateTaskV1 = async (data) => {
  const requestObj = {
    path: `api/project_template/add_template_task_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTemplateSection = async (data) => {
  const requestObj = {
    path: `api/project_template/add_template_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTemplateSection = async (data) => {
  const requestObj = {
    path: `api/project_template/delete_template_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTask = async (data) => {
  const requestObj = {
    path: `api/task/delete_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTemplateTask = async (data) => {
  const requestObj = {
    path: `api/project_template/delete_task_template.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const changePriority = async (data) => {
  const requestObj = {
    path: `api/task/change_task_priority.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeTemplateTaskPriority = async (data) => {
  const requestObj = {
    path: `api/project_template/change_template_priority.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTemplateTaskDetail = async (data) => {
  const requestObj = {
    path: `api/project_template/edit_template_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const createProjectTemplate = async (data) => {
  const requestObj = {
    path: `api/project_template/add_project_template.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeTemplateTaskOrder = async (data) => {
  const requestObj = {
    path: `api/project_template/change_template_task_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const changeStatus = async (data) => {
//   const requestObj = {
//     path: `api/task/change_task_status.php`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing purpose */
export const changeStatus = async (data) => {
  const requestObj = {
    path: `api/task/change_task_status_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/add_member_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/delete_member_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTaskTargetDate = async (data) => {
  const requestObj = {
    path: `api/task/change_target_date.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskFilter = async (data) => {
  const requestObj = {
    path: `api/task/task_filter.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskOrder = async (data) => {
  const requestObj = {
    path: `api/task/change_task_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const moveTaskToSection = async (data) => {
  const requestObj = {
    path: `api/task/update_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/add_task_files.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const removeTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/delete_task_files.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const markAllCommentsAsRead = async (data) => {
  const requestObj = {
    path: `api/task_comment/delete_view_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/task_files_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const changeTaskDetail = async (data) => {
  const requestObj = {
    path: `api/task/edit_task_text.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const activeTeamList = async (data) => {
  const requestObj = {
    path: `api/team/team_list_active_status.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeProjectCategories = async (data) => {
  const requestObj = {
    path: `api/category/category_list_active.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getArchiveProjects = async (data) => {
  const requestObj = {
    path: `api/projects/archived_projects.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const archiveProject = async (data) => {
  const requestObj = {
    path: `api/projects/archive_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addProjectCategory = async (data) => {
  const requestObj = {
    path: `api/category/add_category.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getProjectCategory = async (data) => {
  const requestObj = {
    path: `api/category/category_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getProjectCategoryDetail = async (data) => {
  const requestObj = {
    path: `api/category/category_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saveProjectAsTemplate = async (data) => {
  const requestObj = {
    path: `api/project_template/create_project_template_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const makeDefaultProjectTemplate = async (data) => {
  const requestObj = {
    path: `api/project_template/make_section_default.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTemplateSection = async (data) => {
  const requestObj = {
    path: `api/project_template/update_template_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTemplateSectionOrder = async (data) => {
  const requestObj = {
    path: `api/project_template/update_template_section_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getProjectTemplates = async (data) => {
  const requestObj = {
    path: `api/project_template/project_template_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getProjectTemplateDetail = async (data) => {
  const requestObj = {
    path: `api/project_template/project_template_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProjectTemplate = async (data) => {
  const requestObj = {
    path: `api/project_template/edit_project_template.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTemplateTasks = async (data) => {
  const requestObj = {
    path: `api/project_template/task_template_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTemplateTasksV1 = async (data) => {
  const requestObj = {
    path: `api/project_template/template_section_task_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTemplateSectionsList = async (data) => {
  const requestObj = {
    path: `api/project_template/template_section_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProjectTemplate = async (data) => {
  const requestObj = {
    path: `api/project_template/delete_project_template.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProjectCategory = async (data) => {
  const requestObj = {
    path: `api/category/edit_category.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProjectCategory = async (data) => {
  const requestObj = {
    path: `api/category/delete_category.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskComments = async (data) => {
  const requestObj = {
    path: `api/task_comment/comment_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskComments = async (data) => {
  const requestObj = {
    path: `api/task_comment/add_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditComment = async (data) => {
  const requestObj = {
    path: `api/task_comment/edit_task_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteComment = async (data) => {
  const requestObj = {
    path: `api/task_comment/delete_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadEditorImage = async (data) => {
  const requestObj = {
    path: `api/general_image/editor_images.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allSalesListing = async (data) => {
  const requestObj = {
    path: `api/sales/manager_sales_list_new.php`,
    // path: `api/sales/manager_sales_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleDescription = async (data) => {
  const requestObj = {
    path: `api/sales/update_description.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeSalePaidStatus = async (data) => {
  const requestObj = {
    path: `api/sales/change_sale_status.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleSection = async (data) => {
  const requestObj = {
    path: `api/sale_section/edit_sale_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteSaleSection = async (data) => {
  const requestObj = {
    path: `api/sale_section/delete_sale_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSaleSection = async (data) => {
  const requestObj = {
    path: `api/sale_section/add_sale_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionListing = async (data) => {
  const requestObj = {
    path: `api/sale_section/section_sale_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeProductListing = async (data) => {
  const requestObj = {
    path: `api/product/active_products_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeSaleSectionListing = async (data) => {
  const requestObj = {
    path: `api/sale_section/section_sale_list_active.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSaleByManager = async (data) => {
  const requestObj = {
    path: `api/sales/add_sale_by_manager.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteSaleByManager = async (data) => {
  const requestObj = {
    path: `api/sales/delete_sale.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleAssociate = async (data) => {
  const requestObj = {
    path: `api/sales/update_associate.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadSaleFile = async (data) => {
  const requestObj = {
    path: `api/sales/import_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allowedSalesActiveTeam = async (data) => {
  const requestObj = {
    path: `api/team/get_sale_enable_team_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const moveSaleToSection = async (data) => {
  const requestObj = {
    path: `api/sales/update_section_id.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleSectionType = async (data) => {
  const requestObj = {
    path: `api/sale_section/update_sale_section_default_staus.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionDetail = async (data) => {
  const requestObj = {
    path: `api/sale_section/sale_section_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const reorderProjects = async (data) => {
  const requestObj = {
    path: `api/projects/change_project_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const allNotesList = async (data) => {
  const requestObj = {
    path: `api/notes/note_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addNotes = async (data) => {
  const requestObj = {
    path: `api/notes/add_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};


export const editNotes = async (data) => {
  const requestObj = {
    path: `api/notes/edit_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteNotes = async (data) => {
  const requestObj = {
    path: `api/notes/delete_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};







