import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { addProduct } from "src/DAL/Sales/Sales";
import { useSnackbar } from "notistack";

const AddProduct = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("product_name", name?.trim());
    formData.append("status", "1");
    const result = await addProduct(formData);
    if (result.code === 200) {
      enqueueSnackbar("Product added successfully", { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <Container maxWidth="lg">
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
        Add new product
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={8}>
            <Stack direction="column" spacing={3}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                label="Product name"
              />
              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "fit-content" }}
                >
                  Add
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddProduct;
