import React, { useState, useEffect } from "react";
import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Menu,
  Input,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Switch,
  Stack,
} from "@mui/material";
import {
  formatISO,
  format,
  parseISO,
  isToday,
  setHours,
  setMinutes,
} from "date-fns";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import CkEditor from "src/components/editor/CKEditor";
import { useSnackbar } from "notistack";
import { css } from "@emotion/css";

import { addNotes, editNotes } from "../../DAL/Project/Project";



function AddNotes() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  console.log(location.state.data, "route");
  const [description, setdescription] = useState(
    location.state.data == "" ? "" : location.state.data.description
  );
  const [titletext, settitletext] = useState(
    location.state.data == "" ? "" : location.state.data.title
  );
  const [targetdate, settargetdate] = useState(
    location.state.data == ""
      ? format(new Date(), "yyyy-MM-dd")
      : location.state.data.target_date
  );
  const [targettime, settargettime] = useState(
    location.state.data == ""
      ? format(new Date(), "HH:mm:ss")
      : location.state.data.target_time
  );

  const handeltexttitle = (e) => {
    console.log("Text Title value is ", e);
    settitletext(e);
  };

  const handeltaregetdate = (e) => {
    console.log("Target Date value is ", e);
    settargetdate(e);
  };

  const handeltaregettime = (e) => {
    console.log("Target time value is ", e);
    settargettime(e);
  };

  useEffect(() => {
    // AddNotesinlist();
    return () => {};
  }, []);

  const AddNotesinlist = async () => {
    const formData = new FormData();
    formData.append("title", titletext);
    formData.append("description", description);
    formData.append("target_date", targetdate);
    formData.append("target_time", targettime);
    formData.append("token", localStorage.getItem("token"));
    console.log(...formData, "AddNotesinlist-req");
    const result = await addNotes(formData);
    console.log(result, "AddNotesinlist-res");
    if (result.code === 200) {
      // setdataNotes(result.note_list)
      enqueueSnackbar("Note Added Successfully", { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const EditNotesinlist = async () => {
    const formData = new FormData();
    formData.append("_id", location.state.data._id);
    formData.append("title", titletext);
    formData.append("description", description);
    formData.append("target_date", targetdate);
    formData.append("target_time", targettime);
    formData.append("token", localStorage.getItem("token"));
    console.log(...formData, "EditNotesinlist--req");
    const result = await editNotes(formData);
    console.log(result, "EditNotesinlist-res");
    if (result.code === 200) {
      // setdataNotes(result.note_list)
      enqueueSnackbar("Note Edited Successfully", { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <div style={{ display: "flex", marginRight: 50 }}>
      <div style={{ backgroundColor: "#0000" }}>
        <Tooltip title="Go back to all Notes" arrow>
          <Button
            style={{ borderRadius: "60px", marginBottom: "20px" }}
            component="span"
            onClick={() => navigate(-1)}
            startIcon={<Icon icon="material-symbols:arrow-back-rounded" />}
          ></Button>
        </Tooltip>
      </div>

      <div
        style={{
          display: "flex",
          backgroundColor: "#0000",
          flexDirection: "column",
          marginTop: 50,
          flexGrow: 1,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ flexGrow: 1 }}>
            <TextField
              size="small"
              id="title"
              label="Title"
              type="text"
              defaultValue={titletext}
              placeholder="Enter you title here..."
              sx={{ width: "100%" }}
              inputProps={{
                readOnly: location.state.comefrom == "Nview" ? true : false,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handeltexttitle(e.target.value)}
            />
          </p>
          <TextField
            size="small"
            id="date"
            label="Target Date"
            type="date"
            defaultValue={targetdate}
            sx={{ width: 220, marginRight: "1rem", marginLeft: "1rem" }}
            inputProps={{
              readOnly: location.state.comefrom == "Nview" ? true : false,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handeltaregetdate(e.target.value)}
          />
          <TextField
            size="small"
            id="time"
            label="Target Time"
            type="time"
            defaultValue={targettime}
            sx={{ width: 220 }}
            inputProps={{
              readOnly: location.state.comefrom == "Nview" ? true : false,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handeltaregettime(e.target.value)}
          />
        </div>
        <div className="project-description1">
          <CkEditor
            mode={location.state.comefrom == "Nview" ? true : false}
            content={description}
            setContent={(value) => setdescription(value)}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          {location.state.comefrom == "add" && (
            <Button
              style={{
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              component="span"
              onClick={() => {
                location.state.comefrom == "add"
                  ? AddNotesinlist()
                  : EditNotesinlist();
              }}
              variant="contained"
              color="primary"
              // startIcon={<Icon icon="material-symbols:arrow-back-rounded" />}
            >
              {location.state.comefrom == "add" ? "Save Note" : "Save Edit"}
            </Button>
          )}
          {location.state.comefrom == "edit" && (
            <Button
              style={{
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              component="span"
              onClick={() => {
                location.state.comefrom == "add"
                  ? AddNotesinlist()
                  : EditNotesinlist();
              }}
              variant="contained"
              color="primary"
              // startIcon={<Icon icon="material-symbols:arrow-back-rounded" />}
            >
              {location.state.comefrom == "add" ? "Save Note" : "Save Edit"}
            </Button>
          )}

          {location.state.comefrom == "Nview" && (
            <Button
              style={{
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
              }}
              component="span"
              onClick={() => {
                navigate(-1);
              }}
              variant="contained"
              color="primary"
              // startIcon={<Icon icon="material-symbols:arrow-back-rounded" />}
            >
              Go Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddNotes;
