import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
//

import CkEditor from 'src/components/editor/CKEditor';
import { set_blank_target } from 'src/utils/DomUtils';
import Scrollbar from 'src/components/Scrollbar';
import { addSaleSection, updateSaleSection } from 'src/DAL/Project/Project';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

AddSaleSectionSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));
export default function AddSaleSectionSidebar({ isOpen, onClose, getSectionListing }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [sectionName, setSectionName] = useState('');
  const [sectionStatus, setSectionStatus] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [showStats, setShowStats] = useState(false);

  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (sectionName.trim() === '') {
      enqueueSnackbar("Section name can't be empty", { variant: 'error' });
      setSectionName('');
      return;
    }
    let status = '0';
    if (sectionStatus === true) {
      status = '1';
    }
    let show_stats = '0';
    if (showStats === true) {
      show_stats = '1';
    }

    setIsAdding(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('sale_section_name', sectionName);
    formData.append('sale_section_status', status);
    formData.append('default_status', '0');
    formData.append('show_stat', show_stats);
    console.log(...formData, 'add-section-req');
    const result = await addSaleSection(formData);
    if (result.code === 200) {
      getSectionListing();
      enqueueSnackbar('Sale section added', { variant: 'success' });
      setIsAdding(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      console.log(result, 'error');
      setIsAdding(false);
    }
  };

  useEffect(() => {
    //
    // console.log(selectedItem, 'slc');
  }, [isOpen]);

  useEffect(async () => {
    if (isOpen) {
      //
    }
    if (!isOpen) {
      setSectionName('');
      setSectionStatus(true);
    }
  }, [isOpen]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: { xs: 340, md: 380 }, border: 'none', overflow: 'hidden' },
          // sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <div className='mb-2'>
                <Typography style={{ fontsize: 16, fontWeight: 'bold', marginTop: 0 }}>
                  Section name :
                </Typography>
              </div>
              <textarea
                value={sectionName}
                style={{
                  border: 'hidden',
                  backgroundColor: 'rgb(0 42 21 / 8%) ',
                  fontSize: 12,
                  minHeight: '15vh',
                }}
                className='form-control text-area-task mb-2'
                onChange={(e) => setSectionName(e.target.value)}
                id='exampleFormControlInput1'
                required
              ></textarea>
              <div className='row align-items-center'>
                <div className='col-12'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>Status :</Typography>
                    </div>
                    <div className='col-7'>
                      <Switch
                        checked={sectionStatus}
                        onChange={(e) => setSectionStatus(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='row align-items-center'>
                <div className='col-12'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                        Show Stats :
                      </Typography>
                    </div>
                    <div className='col-7'>
                      <Switch
                        checked={showStats}
                        onChange={(e) => setShowStats(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}

              <div>
                {/* <Button
                  fullWidth
                  size='medium'
                  type='submit'
                  color='primary'
                  variant='outlined'
                  onClick={handleSubmit}
                  startIcon={<Icon icon='ph:plus-fill' />}
                  className='mt-2'
                >
                  Add Section
                </Button> */}
                <LoadingButton
                  fullWidth
                  size='medium'
                  type='submit'
                  color='primary'
                  variant='outlined'
                  startIcon={<Icon icon={editFill} />}
                  className='mt-2'
                  loading={isAdding}
                >
                  Add Section
                </LoadingButton>
              </div>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
