import * as React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { filter } from "lodash";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import {
  Container,
  Stack,
  Typography,
  Avatar,
  Button,
  Box,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  IconButton,
  Popover,
  AvatarGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
// components
import Page from "../../components/Page";
import {
  ProjectFilterSidebar,
  EditTaskSidebar,
} from "../../components/_dashboard/projects";
import CommentsDrawer from "./components/CommentsDrawer";
import Label from "../../components/Label";
import Sekeleton from "../../components/Sekeleton";
import TaskSort from "./components/TaskSort";
import { useSocket } from "../../Hooks/socketContext";
import {
  addTask,
  taskList,
  deleteTask,
  taskFilter,
  taskOrder,
  changeTaskDetail,
  detailProject,
  taskListCollapsable,
  taskSectionsList,
  updateTaskSectionOrder,
  taskFilterV1,
  addTaskSection,
  moveTaskToSection,
  detailProjectV2,
} from "../../DAL/Project/Project";
import { baseUri } from "../../config/config";
import ProjectDrawer from "./components/ProjectDrawer";
import StyledSearch from "src/components/search/StyledSearch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SectionSelect from "./components/SectionSelect";
import PopoverSectionMenu from "./components/PopoverSectionMenu";
import TaskCard from "../../components/TaskCard";
import { LoadingButton } from "@mui/lab";
import TaskSectionAccordion from "./TaskSectionAccordion";
import SectionSelectV2 from "./components/SectionSelectV2";
import AccordionMenu from "./components/AccordionMenu";
import TaskSortV2 from "./components/TaskSortV2";
import SectionEditSidebar from "./components/SectionEditSidebar";
import PopoverSectionMenuV2 from "./components/PopoverSectionMenuV2";
import { SORT_BY_OPTIONS } from "src/constants";
import ProjectSetting from "src/components/_dashboard/projects/ProjectSetting";
import Searchbar from "src/layouts/dashboard/Searchbar";
import TaskCreateField from "./components/TaskCreateField";
const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

const filterStatus = [
  {
    label: "Pending",
    value: "not_started",
    color: "warning",
    filterValue: "0",
    type: "status",
  },
  {
    label: "In Progress",
    value: "in_progress",
    color: "secondary",
    filterValue: "1",
    type: "status",
  },
  // { label: 'In Review', value: 'in_review', color: 'warning', filterValue: '2', type: 'status' },
  {
    label: "Completed",
    value: "completed",
    color: "primary",
    filterValue: "3",
    type: "status",
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "error",
    filterValue: "4",
    type: "status",
  },
  {
    label: "Critical",
    value: "critical",
    color: "error",
    filterValue: "0",
    type: "priority",
  },
  {
    label: "High",
    value: "high",
    color: "primary",
    filterValue: "1",
    type: "priority",
  },
  {
    label: "Medium",
    value: "medium",
    color: "success",
    filterValue: "2",
    type: "priority",
  },
  {
    label: "Low",
    value: "low",
    color: "info",
    filterValue: "3",
    type: "priority",
  },
];

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ height: 35, width: 35 }}
      />
      <Tooltip title="Progress">
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid #c5c5c5",
            borderRadius: "50%",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
export default function CollapsableTaskListing(props) {
  /* <<======================= STATES =======================>> */
  const { sendSocketNotification } = useSocket();
  const navigate = useNavigate();
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });

  const { id } = useParams();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const anchorTotalMembers = useRef(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [taskStats, setTaskStats] = useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterSetting, setOpenFilterSetting] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const loc = useLocation();
  const [filterData, setFilterData] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    not_started: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [filterDataSetting, setFilterDataSetting] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    not_started: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [filterChip, setFilterChip] = useState([]);
  const [filterChipSetting, setFilterChipSetting] = useState([]);
  const [myFilterSetting, setMyFilterSetting] = useState(false);
  const [taskField, setTaskField] = React.useState(false);
  const [taskName, setTaskName] = React.useState("");
  const [progress, setProgress] = React.useState(25);
  const [selectedRow, setRowData] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openMemberListing, setOpenMemberListing] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [openTotalMemberListing, setOpenTotalMemberListing] = useState(false);
  const [taskData, setTaskData] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);
  const [taskSections, setTaskSections] = React.useState([]);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  const [taskSortStatus, setTaskSortStatus] = useState({
    type: "",
    value: "",
    status: false,
  });
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
  const [isTaskDetailUpdating, setIsTaskDetailUpdating] = useState(false);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  //new feature
  const [taskSectionsData, setTaskSectionsData] = useState([]);
  const [taskSectionsListing, setTaskSectionsListing] = useState([]);
  const prevSections = useRef([]);
  const hasExpanded = useRef(false);
  const [accordionMenuAnchorEl, setAccordionMenuAnchorEl] = useState(null);
  const [selectedTargetSection, setSelectedTargetSection] = useState(null);
  const [isAccordionMenuOpen, setIsAccordionMenuOpen] = useState(false);
  const [isMoveUpButtonDisabled, setIsMoveUpButtonDisabled] = useState(false);
  const [isMoveDownButtonDisabled, setIsMoveDownButtonDisabled] =
    useState(false);
  const [isSectionSidebarOpen, setIsSectionSidebarOpen] = useState(false);
  const [defaultExpand, setDefaultExpand] = useState(false);
  const shouldScroll = useRef(true);
  /* <<======================= HANDLING =======================>> */
  const isExpanded = (id, _index) => {
    let proj_id = params.id;
    console.log(proj_id, "proj-params");
    let states = localStorage.getItem("localExpandStates");
    let should_expand = false;
    let found_item = false;
    if (states) {
      states = JSON.parse(states);
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          if (item.expand_all === true) {
            found_item = true;
            should_expand = item.expand_all;
          }
        }
      });
    }
    if (found_item === true) {
      return should_expand;
    }

    const local_sections_data = localStorage.getItem("localSectionsData");
    const parsed_local_sections_data = JSON.parse(local_sections_data);
    if (parsed_local_sections_data.length === 0) {
      if (_index === 0) {
        return true;
      } else {
        return false;
      }
    }
    const first_expand = localStorage.getItem("firstExpand");

    if (String(first_expand) === "false") {
      let parsed = JSON.parse(local_sections_data);
      let expand = false;
      parsed.map((item) => {
        if (String(item.section_id) === String(id)) {
          expand = item.expanded;
        }
      });
      return expand;
    }
    if (String(first_expand) === "true") {
      if (local_sections_data) {
        let parsed = JSON.parse(local_sections_data);
        let expand = false;
        parsed.map((item) => {
          if (String(item.section_id) === String(id)) {
            if (_index === 0) {
              expand = true;
            } else {
              expand = false;
            }
          }
        });

        return expand;
      }
      if (!local_sections_data) {
        let expand = false;

        if (_index === 0) {
          expand = true;
        } else {
          expand = false;
        }
        return expand;
      }
    }
  };
  const getDefaultExpand = () => {
    let proj_id = params.id;
    let states = localStorage.getItem("localExpandStates");
    if (!states) {
      setDefaultExpand(false);
      return;
    }
    if (states) {
      states = JSON.parse(states);
      console.log(states, "states--");
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          if (item.expand_all === true) {
            setDefaultExpand(true);
          }
          if (item.expand_all === false) {
            setDefaultExpand(false);
          }
        }
      });
    }
    //
  };
  const handleDefaultExpand = (e) => {
    let proj_id = params.id;
    console.log(projectDetail, "project-detail-");
    let local_expand_states = localStorage.getItem("localExpandStates");
    let states = [];
    let already_exist = false;
    if (local_expand_states) {
      local_expand_states = JSON.parse(local_expand_states);
      states = local_expand_states;
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          already_exist = true;
        }
      });
    }

    if (e.target.checked === true) {
      if (already_exist) {
        //update
        let updated = states.map((item) => {
          if (String(item.id) === String(proj_id)) {
            return { ...item, expand_all: true };
          }
          return item;
        });
        console.log(updated, "updated--");
        localStorage.setItem("localExpandStates", JSON.stringify(updated));
      }
      if (!already_exist) {
        //push
        let item = { id: projectDetail.id, expand_all: true };
        states.push(item);
        localStorage.setItem("localExpandStates", JSON.stringify(states));
      }
      setDefaultExpand(e.target.checked);
      let section_data = taskSectionsData.map((section, index) => {
        return {
          ...section,
          expanded: true,
        };
      });
      setTaskSectionsData(section_data);
    }
    if (e.target.checked === false) {
      if (already_exist) {
        //update
        let updated = states.map((item) => {
          if (String(item.id) === String(proj_id)) {
            return { ...item, expand_all: false };
          }
          return item;
        });

        localStorage.setItem("localExpandStates", JSON.stringify(updated));
      }
      if (!already_exist) {
        //push
        let item = { id: proj_id, expand_all: false };
        states.push(item);
        localStorage.setItem("localExpandStates", JSON.stringify(states));
      }
      setDefaultExpand(e.target.checked);
      let section_data = taskSectionsData.map((section, index) => {
        return {
          ...section,
          expanded: index === 0 ? true : false,
        };
      });
      setTaskSectionsData(section_data);
    }
  };
  const openProjectDrawer = () => {
    setIsProjectDrawerOpen(true);
  };
  const closeProjectDrawer = () => {
    setIsProjectDrawerOpen(false);
  };
  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };

  const handleSubmitUpdateTask = async (name, description, data) => {
    setIsTaskDetailUpdating(true);
    const task_type = getTaskType(name);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", data.task_id);
    formData.append("task_text", name);
    formData.append("description", description);
    formData.append("task_type", task_type);
    console.log(...formData, "update-task-req");
    const result = await changeTaskDetail(formData);
    if (result.code === 200) {
      handleCloseUpdate();
      // handleCloseEditTask();
      fetchTaskListing();
      data.members.map((member, index) => {
        const payload = {
          receiver_id: member.user_id,
          message: "Your Task has been Updated",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
        return member;
      });
      enqueueSnackbar("Task details updated", { variant: "success" });
      setIsTaskDetailUpdating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsTaskDetailUpdating(false);
    }
  };
  const handleOpenEditTask = (data) => {
    // console.log(data, 'handle-edit');
    setRowData(data);
    setOpenEditTask(true);
  };
  const openCommentsDrawer = (data) => {
    // console.log(data, 'handle-comment-open');
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };

  const closeCommentsDrawer = () => {
    setIsCommentDrawerOpen(false);
  };
  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleOpenFilterSetting = () => {
    setOpenFilterSetting(true);
  };
  const handleCloseFilterSetting = () => {
    setOpenFilterSetting(false);
  };
  const handleChangeFilterData = (value, key) => {
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };
  const handleChangeFilterDataSetting = (value, key) => {
    setFilterDataSetting({
      ...filterData,
      [key]: value,
    });
  };
  const handleApplyLocalFilterSetting = (e) => {
    setMyFilterSetting(e.target.checked);
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: e.target.checked,
        filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
        chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
        filterData: JSON.parse(localStorage.getItem(`project_${id}`))
          .filterData,
      })
    );
  };

  const handleFilterData = async (data, chipdata) => {
    // setOpenFilter(false); taskFilter
    const _arr = [];
    const updatedStatus = chipdata.map((status) => {
      if (status.value === true) {
        _arr.push(filterStatus.find((x) => x.value === status.key));
      }
      return status;
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        filter: _arr,
        chipData: chipdata,
        filterData: data,
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    const filter = _arr.map((y, j) => {
      if (y.type === "status") {
        formData.append(`status[${j}]`, y.filterValue);
      }
      if (y.type === "priority") {
        formData.append(`priority[${j}]`, y.filterValue);
      }
      return y;
    });
    const result = await taskFilterV1(formData);
    // const result = await taskFilter(formData);
    console.log(...formData, "filter-req");
    console.log(result, "filter-res");
    // return;
    if (result.code === 200) {
      setFilterData(data);
      setFilterChip(chipdata);
      let section_data = result.tasks_sections.map((section, index) => {
        return {
          ...section,
          expanded: isExpanded(section.section_id, index),
          section_tasks: get_modified(section.section_tasks),
        };
      });
      console.log(section_data, "modified-filtered-tasks");
      setTaskSectionsData(section_data);
      localStorage.setItem("firstExpand", false);
      // setTaskData(tasks);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsWaiting(false);
    }
  };

  const handleResetFilter = () => {
    setFilterData({
      critical: false,
      high: false,
      medium: false,
      low: false,
      not_started: false,
      in_progress: false,
      in_review: false,
      completed: false,
      cancelled: false,
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        filter: [],
        chipData: [],
      })
    );
    const tempState = [...filterChip];
    setFilterChip(
      tempState.map((x) => {
        console.log();
        return {
          ...x,
          value: false,
        };
      })
    );
    fetchTaskListing();
    // handleCloseFilter();
    setOpenFilter(false);
  };

  const handleResetFilterSetting = () => {
    setFilterData({
      critical: false,
      high: false,
      medium: false,
      low: false,
      not_started: false,
      in_progress: false,
      in_review: false,
      completed: false,
      cancelled: false,
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        filter: [],
        chipData: [],
      })
    );
    const tempState = [...filterChip];
    setFilterChipSetting(
      tempState.map((x) => {
        console.log();
        return {
          ...x,
          value: false,
        };
      })
    );
    fetchTaskListing();
    // handleCloseFilter();
    setOpenFilterSetting(false);
  };
  const handleResetEditTask = () => {};

  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ":") {
      return "2";
    }
    return "1";
  };
  const removeColon = (text) => {
    let target_text = text;
    for (let i = 0; i < text.length; i++) {
      target_text = target_text.replace(":", "");
    }
    return target_text;
  };
  const createSection = async (taskName) => {
    const removed_colon = removeColon(taskName.trim());
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("section_name", removed_colon);
    formData.append("project_id", id);
    formData.append("description", "");
    formData.append("vasibility_status", "1");
    const result = await addTaskSection(formData);
    if (result.code === 200) {
      setSelectedSectionIndex(0);
      fetchTaskListing();
      getTaskSections();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return result;
  };
  const createTask = async (taskName, task_Type) => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_name", taskName);
    formData.append("project_id", id);
    formData.append("vasibility_status", "1");
    formData.append("task_type", task_Type);
    if (taskSectionsListing) {
      if (taskSectionsListing[selectedSectionIndex]) {
        let section = taskSectionsListing[selectedSectionIndex];
        formData.append("task_id", section.id);
      }
    }
    console.log(...formData, "add-task-req");
    const result = await addTask(formData);
    if (result.code === 200) {
      fetchTaskListing();
      projectMembers.map((member, index) => {
        const payload = {
          receiver_id: member.user_id,
          message: "A new task is added in your project",
          project_id: id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        console.log(payload, "members-notification-payload");
        sendSocketNotification(payload);
        return member;
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return result;
  };
  const handleAddTask = async (taskName) => {
    const task_Type = getTaskType(taskName);

    console.log(task_Type);
    if (String(task_Type) === "2") {
      return await createSection(taskName);
    }
    if (String(task_Type) === "1") {
      return await createTask(taskName, task_Type);
    }
  };

  const handleDeleteTask = async () => {
    // deleteTask
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", selectedRow.task_id);
    formData.append("project_id", id);
    formData.append("task_order", selectedRow.taskOrder);
    formData.append("vasibility_status", "1");
    console.log(...formData, "delete-task-req");
    // return;
    setIsDeletingTask(true);
    const result = await deleteTask(formData);
    if (result.code === 200) {
      setIsDeletingTask(false);
      fetchTaskListing();
      handleCloseDialog();
      // getSections(taskData);
      handleCloseSectionMenu();
      selectedRow.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task has been Deleted",
          project_id: id,
          task_id: selectedRow.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
        return z;
      });
      enqueueSnackbar("Task deleted", { variant: "success" });
    } else {
      setIsDeletingTask(false);
      fetchTaskListing();
      handleCloseDialog();
      handleCloseSectionMenu();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenTotalMemberListing = () => {
    setOpenTotalMemberListing(true);
  };
  const handleSearchTask = (val) => {
    setSearchValue(val);
  };
  const handleClose = (id) => {
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenTotalMemberListing(false);
    setTaskData((task) =>
      task.map((x) => {
        if (x.id === id) {
          return {
            ...x,
            openMemberSelect: false,
            openMemberListing: false,
          };
        }
        return x;
      })
    );
  };

  const handleOpenDialog = (data, i) => {
    // console.log(selectedRow, 'specific');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const showAddTask = () => {
    setTaskField(!taskField);
  };
  const handleOpenAccordionMenu = (e, section, index) => {
    // console.log(section, 'target-section');
    setAccordionMenuAnchorEl(e.currentTarget);
    setIsAccordionMenuOpen(true);
    setSelectedTargetSection(section);
    let is_first = false;
    let is_last = false;
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === 0) {
          is_first = true;
        }
      }
    });
    if (is_first) {
      setIsMoveUpButtonDisabled(true);
    }
    if (!is_first) {
      setIsMoveUpButtonDisabled(false);
    }
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === taskSectionsData.length - 1) {
          is_last = true;
        }
      }
    });
    if (is_last) {
      setIsMoveDownButtonDisabled(true);
    }
    if (!is_last) {
      setIsMoveDownButtonDisabled(false);
    }
  };
  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);

    const result = await detailProjectV2(formData);
    console.log(result, "project-detail");
    console.log(result.Team_Project, 'project-members');
    if (result.code === 200) {
      setTaskStats(result.task_stats);
      const teamproject = result.Team_Project
      let teamproject_array = teamproject.filter(function(value) {
        return value.allow_check_comments == "0" });
        console.log(teamproject_array,'teamproject_array');
      setProjectMembers(teamproject_array);
      setProjectDetail(result.Team_Data);
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const get_public_tasks = (data) => {
    let filtered = data.filter((task) => String(task.visibilityStatus) === "1");
    return filtered;
  };
  const getUpperSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (
          String(section.section_id) ===
          String(selectedTargetSection.section_id)
        ) {
          target_index = index - 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const getLowerSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (
          String(section.section_id) ===
          String(selectedTargetSection.section_id)
        ) {
          target_index = index + 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const handleChangeSectionOrder = async (data, type) => {
    // console.log(selectedTargetSection, 'target-row');
    let order = undefined;
    let swap_section_id = undefined;
    if (type === 1) {
      const upper_section = getUpperSection();
      // console.log(upper_section, 'needed');
      order = Number(selectedTargetSection.section_order) - 1;
      swap_section_id = upper_section.section_id;
    }
    if (type === 2) {
      const lower_section = getLowerSection();
      // console.log(lower_section, 'needed');
      order = Number(selectedTargetSection.section_order) + 1;
      swap_section_id = lower_section.section_id;
    }

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("section_order", order);
    formData.append("swap_section_id", swap_section_id);
    formData.append("task_section_id", selectedTargetSection.section_id);
    console.log(...formData, "move-section-req");
    setIsAccordionMenuOpen(false);
    const result = await updateTaskSectionOrder(formData);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      enqueueSnackbar(`Section moved ${type === 1 ? "up" : "down"}`, {
        variant: "success",
      });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOnDragEnd = async (result) => {
    console.log(result, "res");
    if (!result.destination) return;
    let filtered = [];
    let public_sections = taskSectionsData.map((section, index) => {
      return {
        ...section,
        section_tasks: get_public_tasks(section.section_tasks),
      };
    });
    // console.log(public_sections, 'pubb');
    const items = Array.from(public_sections);
    let destination_task = null;
    let source_task = null;
    let source_section_id = null;
    let source_section = null;
    items.map((section) => {
      section.section_tasks.map((task, index) => {
        if (String(task.task_id) === String(result.draggableId)) {
          source_task = task;
          source_section_id = task.sectionId;
        }
      });
      //
    });
    items.map((section) => {
      if (String(section.section_id) === String(source_section_id)) {
        source_section = section;
      }
    });

    source_section.section_tasks.map((task, index) => {
      if (String(index) === String(result.destination.index)) {
        destination_task = task;
      }
    });

    if (String(source_task.task_id) === String(destination_task.task_id)) {
      return;
    }
    let spliced_section_tasks = Array.from(source_section.section_tasks);
    // console.log(spliced_section_tasks, 'spl');
    const [splicedItem] = spliced_section_tasks.splice(result.source.index, 1);
    spliced_section_tasks.splice(result.destination.index, 0, splicedItem);
    let task_sections_data = Array.from(taskSectionsData).map((section) => {
      if (String(section.section_id) === String(source_section.section_id)) {
        return { ...section, ["section_tasks"]: spliced_section_tasks };
      }
      return section;
    });

    // console.log(task_sections_data, 'new-data');
    setTaskSectionsData(task_sections_data);

    // console.log(spliced_section_tasks, 're-ordered');
    // console.log(source_task, 'source-task');
    // console.log(destination_task, 'destination-task');
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", result.draggableId);
    formData.append("project_id", id);
    formData.append("task_order", destination_task.taskOrder);
    formData.append("vasibility_status", splicedItem.visibilityStatus);
    console.log(...formData, "task-reorder-req");
    const resp = await taskOrder(formData);
    if (resp.code === 200) {
      fetchTaskListing();
      enqueueSnackbar("Task order changed", { variant: "success" });
    } else {
      fetchTaskListing();
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const handleMoveToSection = async (section) => {
    // console.log(section, 'ss');
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", selectedRow.task_id);
    formData.append("task_section_id", section.id);
    console.log(...formData, "move-task-req");
    const resp = await moveTaskToSection(formData);
    if (resp.code === 200) {
      setSelectedSectionIndex(0);
      enqueueSnackbar(`Task moved to ${section.section_name}`, {
        variant: "success",
      });
      fetchTaskListing();
    } else {
      fetchTaskListing();
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const fetchTaskListing = async (sortValue = 0) => {
    if (JSON.parse(localStorage.getItem(`project_${id}`))?.filter?.length > 0) {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", id);
      const filter = JSON.parse(
        localStorage.getItem(`project_${id}`)
      ).filter.map((y, j) => {
        if (y.type === "status") {
          formData.append(`status[${j}]`, y.filterValue);
        }
        if (y.type === "priority") {
          formData.append(`priority[${j}]`, y.filterValue);
        }
        return y;
      });
      console.log(...formData, "filter-req");
      const result = await taskFilterV1(formData);
      console.log(result, "task-list-filter");
      if (result.code === 200) {
        setFilterChip(
          JSON.parse(localStorage.getItem(`project_${id}`)).chipData
        );
        setFilterData(
          JSON.parse(localStorage.getItem(`project_${id}`)).filterData
        );
        let section_data = result.tasks_sections.map((section, index) => {
          return {
            ...section,
            expanded: isExpanded(section.section_id, index),
            section_tasks: get_modified(section.section_tasks),
          };
        });
        console.log(section_data, "modified-filtered-tasks");
        setTaskSectionsData(section_data);
        localStorage.setItem("firstExpand", false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", id);
      formData.append("sort_value", sortValue);
      console.log(...formData, "task-listing-req");
      // return;
      const result = await taskListCollapsable(formData);
      console.log(result, "task-listing");
      if (result.code === 200) {
        let section_data = result.tasks_sections.map((section, index) => {
          return {
            ...section,
            expanded: isExpanded(section.section_id, index),
            section_tasks: get_modified(section.section_tasks),
          };
        });
        console.log(section_data, "sdata");
        setTaskSectionsData(section_data);
        localStorage.setItem("firstExpand", false);
        setIsWaiting(false);
        setTimeout(() => {
          if (shouldScroll.current === true) {
            // handleScroll();
            if (!location.state) return;
            if (!location.state.task_id) return;
            if (location.state.task_id === null) return;
            let taskId = location.state.task_id;
            section_data.map((section) => {
              section.section_tasks.map((task) => {
                if (String(task.task_id) === String(taskId)) {
                  onlyExpandSection(section.section_id);
                }
              });
            });
            handleScrollWithExpand();
          }
        }, 500);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsWaiting(false);
      }
    }
  };
  const get_modified = (data) => {
    let modified_tasks = data.map((x, i) => {
      return {
        task_id: x.task_id,
        task: x.task_name,
        targetDate: x.target_date === "0000-00-00" ? "" : x.target_date,
        endDate: "",
        end_time: x.end_time,
        status: x.task_status,
        priority: x.task_priority,
        taskOrder: x.task_order,
        detailVisible: true,
        members: x.assign_member,
        images: x.task_images,
        visibilityStatus: x.vasibility_status,
        description: x.task_distribution,
        taskCreatorName: x.task_creator_name,
        taskCreatorImage: x.task_creator_image,
        commentsCount: x.comment_count,
        createdDate: x.created_at,
        taskType: x.task_type,
        sectionId: x.section_id,
        description: x.task_distribution,
        unreadComments: x.unread_comment_count,
      };
    });
    return modified_tasks;
  };
  const handleScroll = () => {
    if (!location.state) return;
    if (!location.state.task_id) return;
    if (location.state.task_id === null) return;
    let taskId = location.state.task_id;
    let task = document.getElementById(taskId);
    if (!task) {
      console.log("no element found");
      return;
    }
    const rects = task.getBoundingClientRect();
    const yAxis = rects.y;
    const height = rects.height;
    const xAxis = rects.x;
    // task.scrollIntoView({ block: 'end', inline: 'nearest' });
    task.style.transition = "all .5s linear";
    task.style.border = "solid 3px rgb(10, 238, 124)";
    window.scrollTo(xAxis, yAxis - height);
    shouldScroll.current = false;
    setTimeout(() => {
      task.style.border = "solid 1px transparent ";
    }, 3000);
  };
  const handleScrollWithExpand = () => {
    console.log(location.state, "at-scroll");
    if (!location.state) return;
    if (!location.state.task_id) return;
    if (location.state.task_id === null) return;
    let taskId = location.state.task_id;
    let task = document.getElementById(taskId);
    if (!task) {
      console.log("no element found");
      return;
    }
    if (task) {
      setTimeout(() => {
        const rects = task.getBoundingClientRect();
        const yAxis = rects.y;
        const height = rects.height;
        const xAxis = rects.x;
        // task.scrollIntoView({ block: 'end', inline: 'nearest' });
        task.style.transition = "all .5s linear";
        task.style.border = "solid 3px rgb(10, 238, 124)";
        window.scrollTo(xAxis, yAxis - height);
        shouldScroll.current = false;
        setTimeout(() => {
          task.style.border = "solid 1px transparent ";
        }, 3000);
      }, 500);
    }
  };
  const getTitle = () => {
    let title = projectDetail.project_name;
    title = `${title} | Projects`;
    return title;
  };

  const setFirstLocalStorage = () => {
    if (localStorage.getItem(`project_${id}`)) {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
          chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
          filterData: JSON.parse(localStorage.getItem(`project_${id}`))
            .filterData,
        })
      );
    } else {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          filter: [],
          chipData: [],
          filterData: [],
        })
      );
    }
  };
  const truncateText = (string, maxLength) => {
    if (!string) {
      return "";
    }
    if (string === "") {
      return "";
    }
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };
  const calculateTaskStats = () => {
    let filtered = [];
    taskSectionsData.map((section) => {
      section.section_tasks.map((task) => {
        if (
          String(task.taskType) !== String(2) &&
          String(task.visibilityStatus) !== String(0)
        ) {
          filtered.push(task);
        }
      });
    });
    const all = filtered.length;
    let completed = 0;
    let pending = 0;
    filtered.forEach((task) => {
      if (String(task.status) === "3" || String(task.status) === "5") {
        completed += 1;
      }
    });
    pending = all - completed;
    setProjectStats({ all, pending, completed });
  };

  const getFilteredList = (data) => {
    let filtered = [];
    data.map((task, index) => {
      if (String(task.visibilityStatus) !== String(0)) {
        filtered.push(task);
      }
    });

    const filtered_ = handleLocalTaskSearch(searchValue, filtered);
    return filtered_;
  };
  const handleSort = (type, option, data) => {
    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === "oldest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === "newest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
      //
    }
  };

  const handleSortChange = (type, value) => {
    console.log(type, value);
    fetchTaskListing(value);
    // let status = true;
    // if (type === 'default') {
    //   status = false;
    // }
    // setTaskSortStatus({ ...taskSortStatus, ['status']: status, ['type']: type, ['value']: value });
  };
  const isSection = (task) => {
    let is_section = false;
    if (String(task.taskType) === String(2)) {
      is_section = true;
    }
    return is_section;
  };
  const handleLocalTaskSearch = (input, array) => {
    const task_search_with_team_names = (task) => {
      //
      let merged_names = task.task;
      task.members.map((member) => {
        merged_names =
          merged_names + " " + member.first_name + " " + member.last_name;
      });
      return merged_names;
      // return task.task;
    };
    let filtered = filter(
      array,
      (_task) =>
        task_search_with_team_names(_task)
          .toLowerCase()
          .indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getHtml = (e, html) => {
    return { __html: html };
  };
  const onSectionChange = (value) => {
    console.log(value, "section-change");
  };
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, "target-task");
    console.log(index, "target-index");
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };

  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = id;
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const showAddTaskButton = () => {
    if (taskName === "") {
      return false;
    }
    return true;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleTaskInputBlur = () => {
    if (taskName === "") {
      setTaskField(false);
    }
  };
  const handleEnter = () => {
    //
  };
  const handleLeave = () => {
    //
  };
  const isDragDisabled = () => {
    let disabled = false;
    let filter = JSON.parse(localStorage.getItem(`project_${id}`))?.filter;
    if (searchValue !== "") {
      disabled = false;
    }
    if (filter) {
      if (filter.length > 0) {
        disabled = true;
      }
    }
    if (taskSortStatus.status === true) {
      disabled = true;
    }
    if (String(SORT_BY_OPTIONS[selectedIndex].value) !== "0") {
      disabled = true;
    }
    // if (searchValue !== '') {
    //   disabled = true;
    // }
    return disabled;
  };
  const markCommentAsRead = (taskId) => {
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(taskId)) {
              // console.log(task, 'add-member');
              return { ...task, unreadComments: 0 };
            }
            return task;
          }),
        };
      })
    );
  };
  const getProjectName = () => {
    let name = projectDetail.project_name;
    return name;
    let truncated = truncateText(name, 13);
    return truncated;
  };
  const expandSection = (id) => {
    let changed_expand_state = taskSectionsData.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      }
      return item;
    });
    prevSections.current = changed_expand_state;
    setTaskSectionsData((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: !item.expanded,
          };
        }
        return item;
      })
    );
  };
  const onlyExpandSection = (id) => {
    let changed_expand_state = taskSectionsData.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: true,
        };
      }
      return item;
    });
    prevSections.current = changed_expand_state;
    setTaskSectionsData((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: true,
          };
        }
        return item;
      })
    );
  };
  const getTaskSections = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    console.log(...formData, "get-sections-req");
    const result = await taskSectionsList(formData);
    console.log(result, "sections-res");
    if (result.code === 200) {
      setTaskSectionsListing(result.active_task_section);
      let def_section_index = undefined;
      let [def_section] = result.active_task_section.filter((item, index) => {
        if (item.is_default === String(1)) {
          def_section_index = index;
          return item;
        }
      });
      console.log(def_section, "def-section");
      if (def_section) {
        setSelectedSection(def_section);
        setSelectedSectionIndex(def_section_index);
      } else {
        setSelectedSection(result.active_task_section[0]);
        setSelectedSectionIndex(0);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  React.useEffect(() => {
    localStorage.setItem("firstExpand", true);
    getDefaultExpand();
  }, []);
  React.useEffect(() => {
    document.title = getTitle();
    getDefaultExpand();
  }, [projectDetail]);
  React.useEffect(() => {
    setFirstLocalStorage();
    fetchTaskListing();
    fetchProjectDetail();
    getTaskSections();
  }, []);

  React.useEffect(() => {
    calculateTaskStats();
  }, [taskSectionsData]);
  React.useEffect(() => {
    let sections_data = localStorage.getItem("localSectionsData");
    if (sections_data) {
      let parsed = JSON.parse(sections_data);
      setTaskSectionsData(parsed);
    }
    localStorage.setItem("localSectionsData", sections_data);
    return () => {
      localStorage.removeItem("localSectionsData");
    };
  }, []);
  React.useEffect(() => {
    //runs on every render
    let sections_data = JSON.stringify(taskSectionsData);
    localStorage.setItem("localSectionsData", sections_data);
  });
  /* <<======================= JSX =======================>> */
  return (
    <Page title="Tasks | Support Task Portal">
      <Container maxWidth="xl" style={{ backgroundColor: "#fff" }}>
        <div className="row main-header">
          <div
            className="col-lg-3 col-md-12 col-sm-12 pl-0 align-items-center"
            style={{ display: "flex" }}
          >
            <IconButton
              onClick={() =>
                location.state?.is_copied_link
                  ? navigate("/projects/categories")
                  : navigate(-1)
              }
            >
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title="Click to View Description">
              <span role="button" onClick={() => openProjectDrawer()}>
                <Typography
                  style={{ fontsize: 16, fontWeight: "bold", paddingLeft: 8 }}
                >
                  {getProjectName()}
                </Typography>
              </span>
            </Tooltip>
          </div>
          <Stack
            direction="row"
            spacing={1}
            flexShrink={0}
            sx={{ my: 1 }}
            className="d-flex ml-auto pr-10"
          >
            <ProjectFilterSidebar
              isOpenFilter={openFilter}
              isFilterData={filterData}
              isFilterChip={filterChip}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              onFilterData={handleFilterData}
              onChangeFilterData={handleChangeFilterData}
            />
            <div>
              <ProjectSetting
                onResetFilterSetting={handleResetFilterSetting}
                onOpenFilterSetting={handleOpenFilterSetting}
                onCloseFilterSetting={handleCloseFilterSetting}
                // isTaskPersonal={personal}
                isFilterSettingOn={myFilterSetting}
                onChangeSettingSwitch={handleApplyLocalFilterSetting}
                isOpenFilterSetting={openFilterSetting}
                Expand={
                  <React.Fragment>
                    <div>
                      <Tooltip arrow title="expand sections by default">
                        <FormControlLabel
                          value="end"
                          control={
                            <Switch
                              color="primary"
                              checked={defaultExpand}
                              onChange={(e) => handleDefaultExpand(e)}
                              sx={{ marginLeft: "10px" }}
                            />
                          }
                          label="Expand"
                          labelPlacement="end"
                        />
                      </Tooltip>
                    </div>
                  </React.Fragment>
                }
                ProjectStats={
                  <React.Fragment>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                      <div
                        className="status-circle"
                        style={{
                          backgroundColor: "#3366FF",
                          marginRight: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <Typography sx={{ fontSize: 14 }}>
                          {projectStats.pending}
                        </Typography>
                      </div>
                      <Typography
                        sx={{ cursor: "pointer", fontSize: 14, mb: "5px" }}
                        className="date-css"
                      >
                        Pending&nbsp;
                      </Typography>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                      <div
                        className="status-circle"
                        style={{
                          marginRight: "20px",
                          marginBottom: "5px",
                          backgroundColor: "#00AB55",
                        }}
                      >
                        <Typography sx={{ fontSize: 14 }}>
                          {projectStats.completed}
                        </Typography>
                      </div>
                      <Typography
                        sx={{ cursor: "pointer", fontSize: 14 }}
                        className="date-css"
                      >
                        Complete&nbsp;&nbsp;
                      </Typography>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                      <div
                        className="status-circle"
                        style={{
                          marginRight: "20px",
                          marginBottom: "5px",
                          backgroundColor: "#c5c5c5",
                        }}
                      >
                        <Typography sx={{ fontSize: 14 }}>
                          {projectStats.all}
                        </Typography>
                      </div>
                      <Typography
                        sx={{ cursor: "pointer", fontSize: 14 }}
                        className="date-css"
                      >
                        Total&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              />
            </div>
            <div>
              <TaskSortV2
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                onSortChange={handleSortChange}
              />
            </div>

            <EditTaskSidebar
              isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onOpenEditTask={handleOpenEditTask}
              onCloseEditTask={handleCloseEditTask}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              isUpdating={isTaskDetailUpdating}
            />
            <CommentsDrawer
              isCommentOpen={isCommentDrawerOpen}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onCommentOpen={openCommentsDrawer}
              onCommentClose={closeCommentsDrawer}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              getTaskList={fetchTaskListing}
              markCommentAsRead={markCommentAsRead}
              projectDetail={projectDetail}
              verifiedListing={false}
            />
            <ProjectDrawer
              onClose={closeProjectDrawer}
              isOpen={isProjectDrawerOpen}
              projectDetail={projectDetail}
            />
          </Stack>
          <div className="mr-1">
            <Searchbar onChange={handleSearchTask} />
          </div>
        </div>

        <Stack
          direction="row"
          position="relative"
          sx={{ width: "100%" }}
          mt={2}
        >
          <TaskCreateField onCreateTask={handleAddTask} />
          <div className="col-sm-3 pr-0 d-flex flex-col justify-content-end align-items-center">
            <SectionSelectV2
              data={taskSections}
              onChange={onSectionChange}
              selectedIndex={selectedSectionIndex}
              setSelectedIndex={setSelectedSectionIndex}
              setSelectedSection={setSelectedSection}
              taskSectionsListing={taskSectionsListing}
            />
          </div>
        </Stack>
        <div className="mt-3">
          {isWaiting === false ? (
            taskSectionsData.map((section, index) => {
              return (
                <>
                <TaskSectionAccordion
                  projectMembers={projectMembers}
                  projectDetail={projectDetail}
                  fetchTaskListing={fetchTaskListing}
                  expandSection={expandSection}
                  setOpenEditTask={setOpenEditTask}
                  setIsSectionMenuOpen={setIsSectionMenuOpen}
                  setSectionMenuAnchorEl={setSectionMenuAnchorEl}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  setTaskData={setTaskData}
                  taskSortStatus={taskSortStatus}
                  setRowData={setRowData}
                  setIsCommentDrawerOpen={setIsCommentDrawerOpen}
                  key={index}
                  handleOnDragEnd={handleOnDragEnd}
                  section={section}
                  data={getFilteredList(section.section_tasks)}
                  setTaskSectionsData={setTaskSectionsData}
                  openAccordionMenu={handleOpenAccordionMenu}
                  selectedIndex={selectedIndex}
                />
                </>

              );
            })
          ) : (
            <Sekeleton totalTask={projectStats.all} />
          )}
        </div>

        {/* ============================================== Add Project component========================= */}

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <LoadingButton loading={isDeletingTask} onClick={handleDeleteTask}>
              Yes, Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* <========Popovers========> */}
        <PopoverSectionMenuV2
          isOpen={isSectionMenuOpen}
          onOpen={handleOpenSectionMenu}
          onClose={handleCloseSectionMenu}
          anchorEl={sectionMenuAnchorEl}
          setAnchorEl={handleSectionAnchorEl}
          handleOpenDialog={handleOpenDialog}
          sections={taskSections}
          handleMoveSection={handleMoveToSection}
          selectedRow={selectedRow}
          isDragDisabled={isDragDisabled}
          hasSections={true}
          data={taskSectionsListing}
        />
        <AccordionMenu
          isOpen={isAccordionMenuOpen}
          onOpen={() => setIsAccordionMenuOpen(true)}
          onClose={() => setIsAccordionMenuOpen(false)}
          anchorEl={accordionMenuAnchorEl}
          selectedSection={selectedTargetSection}
          handleChangeSectionOrder={handleChangeSectionOrder}
          sectionList={taskSectionsData}
          isMoveUpButtonDisabled={isMoveUpButtonDisabled}
          isMoveDownButtonDisabled={isMoveDownButtonDisabled}
          fetchTaskListing={fetchTaskListing}
          getTaskSections={getTaskSections}
          openEditSection={() => {
            setIsSectionSidebarOpen(true);
            setIsAccordionMenuOpen(false);
          }}
          setSelectedSectionIndex={setSelectedSectionIndex}
        />
        {/* section edit sidebar */}
        <SectionEditSidebar
          isOpen={isSectionSidebarOpen}
          onClose={() => setIsSectionSidebarOpen(false)}
          isSectionData={selectedTargetSection}
          fetchTaskListing={fetchTaskListing}
        />
      </Container>
    </Page>
  );
}
