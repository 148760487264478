import * as React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState, useRef } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import moment from 'moment';
// material
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { animate, AnimatePresence, motion } from 'framer-motion';
// components
import SendIcon from '@mui/icons-material/Send';
import { noTask } from 'src/assets';
import PriorityPopover from 'src/pages/Tasks/components/Priority';
import StatusPopover from 'src/pages/Tasks/components/Status';
import {
  getTemplateTasks,
  deleteTemplateTask,
  changeTemplateTaskPriority,
  updateTemplateTaskDetail,
  addTemplateTask,
  changeTemplateTaskOrder,
} from '../../../DAL/Project/Project';
import { set_blank_target } from 'src/utils/DomUtils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import TaskCard from 'src/components/TaskCard';
// import PopoverSectionMenu from 'src/pages/Tasks/components/PopoverSectionMenu';
import { LoadingButton } from '@mui/lab';
// import TemplateAccordion from '../components/TemplateAccordion';
export default function TemplateAccordion({
  anchorTotalMembers,
  isWaiting,
  setIsWaiting,
  taskStats,
  setTaskStats,
  openEdit,
  setOpenEdit,
  openFilter,
  setOpenFilter,
  openEditTask,
  setOpenEditTask,
  filterData,
  setFilterData,
  taskField,
  setTaskField,
  taskName,
  setTaskName,
  selectedRow,
  setRowData,
  openDialog,
  setOpenDialog,
  projectMembers,
  setProjectMembers,
  projectDetail,
  setProjectDetail,
  taskData,
  setTaskData,
  sectionMenuAnchorEl,
  setSectionMenuAnchorEl,
  isSectionMenuOpen,
  setIsSectionMenuOpen,
  isDeletingTask,
  setIsDeletingTask,
  data,
  section,
  setTaskSectionsData,
  openAccordionMenu,
  expandSection,
  fetchTaskListing,
}) {
  /* <<======================= STATES =======================>> */
  const navigate = useNavigate();
  const params = useLocation();
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const templateDetails = params.state;
  const { project_id } = templateDetails;
  const { project_temp_id } = templateDetails;
  /* <<======================= HANDLING =======================>> */
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: '<p>No Description added!</p>' };
    }

    return { __html: html };
  };
  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };
  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ':') {
      return '2';
    }
    return '1';
  };
  const handleSubmitUpdateTask = async (name, description, data) => {
    const task_Type = getTaskType(name);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_temp_id', data.taskTempId);
    formData.append('task_text', name);
    formData.append('description', description);
    formData.append('task_type', task_Type);
    console.log(...formData, 'form-data');
    const result = await updateTemplateTaskDetail(formData);
    if (result.code === 200) {
      handleCloseUpdate();
      handleCloseEditTask();
      fetchTaskListing();
      enqueueSnackbar('Template task details updated', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleOpenEditTask = (data) => {
    console.log(data, 'handle-edit');
    setOpenEditTask(true);
    setRowData(data);
  };
  const verifiedListing = false;
  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };

  const handleResetEditTask = () => {};
  const handleAddTask = async (e) => {
    e.preventDefault();
    if (!taskName) {
      enqueueSnackbar('task should not be empty', { variant: 'error' });
      return;
    }
    const task_Type = getTaskType(taskName);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_name', taskName);
    formData.append('project_id', project_id);
    formData.append('project_temp_id', project_temp_id);
    formData.append('task_type', task_Type);
    console.log(...formData, 'addTask-request');
    const result = await addTemplateTask(formData);
    if (result.code === 200) {
      console.log('template-task-added');
      setTaskField(false);
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }

    setTaskName('');
    setTaskField(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handlePriority = async (value, data) => {
    console.log(value, data, 'priority--');
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_task: section.section_task.map((task) => {
            if (String(task.taskTempId) === String(data.taskTempId)) {
              // console.log(task, 'add-member');
              return {
                ...task,
                priority: value,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_priority', value);
    formData.append('task_temp_id', data.taskTempId);

    const result = await changeTemplateTaskPriority(formData);
    if (result.code === 200) {
      enqueueSnackbar('Template task priority changed', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      fetchTaskListing();
    }
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    console.log(result, 'res');
    const items = Array.from(data);
    let destination_task = null;
    let source_task = null;
    let source_section_id = null;
    let source_section = null;
    items.map((section) => {
      section.section_task.map((task, index) => {
        if (String(task.taskTempId) === String(result.draggableId)) {
          source_task = task;
          source_section_id = task.sectionId;
        }
      });
      //
    });

    items.map((section) => {
      if (String(section.section_id) === String(source_section_id)) {
        source_section = section;
      }
    });

    source_section.section_task.map((task, index) => {
      if (String(index) === String(result.destination.index)) {
        destination_task = task;
      }
    });

    if (String(source_task.taskTempId) === String(destination_task.taskTempId)) {
      return;
    }
    let spliced_section_tasks = Array.from(source_section.section_task);
    console.log(spliced_section_tasks, 'spl');
    const [splicedItem] = spliced_section_tasks.splice(result.source.index, 1);
    spliced_section_tasks.splice(result.destination.index, 0, splicedItem);
    let task_sections_data = Array.from(data).map((section) => {
      if (String(section.section_id) === String(source_section.section_id)) {
        return { ...section, ['section_task']: spliced_section_tasks };
      }
      return section;
    });

    console.log(task_sections_data, 'new-data');
    setTaskSectionsData(task_sections_data);

    console.log(spliced_section_tasks, 're-ordered');
    console.log(source_task, 'source-task');
    console.log(destination_task, 'destination-task');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('temp_task_id', result.draggableId);
    formData.append('project_temp_id', project_temp_id);
    formData.append('task_order', destination_task.taskOrder);
    // formData.append('vasibility_status', splicedItem.visibilityStatus);
    console.log(...formData, 'task-reorder-req');
    const res = await changeTemplateTaskOrder(formData);
    if (res.code === 200) {
      fetchTaskListing();
      enqueueSnackbar('Template task order changed', { variant: 'success' });
    } else {
      enqueueSnackbar(res.message, { variant: 'error' });
    }
  };

  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, 'target-task');
    console.log(index, 'target-index');
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target('task-description-html');
    }, 500);
  };
  const openMenu = (e, section) => {
    openAccordionMenu(e, section);
  };
  const handleChange = (data) => {
    if (data) {
      expandSection(data.section_id);
    }
  };
  const getTaskCount = () => {
    return section.section_task.length;
  };
  const getPosition = () => {
    let count = getTaskCount();
    if (Number(count) < 10) {
      return '29px';
    }
    if (Number(count) >= 1 && Number(count) <= 10) {
      return '34px';
    }
    if (Number(count) > 10 && Number(count) <= 99) {
      return '34px';
    }
    if (Number(count) > 99 && Number(count) < 1000) {
      return '40px';
    }
    if (Number(count) >= 1000 && Number(count) < 10000) {
      return '48px';
    }
  };
  const isDragDisabled = () => false;
  const getFilteredTasks = (data) => {
    return data;
  };
  React.useEffect(() => {
    //
  }, []);
  return (
    <div id='accordion-parent' style={{ borderRadius: '4px' }} className='border mb-2 mt-3'>
      <Accordion square={false} expanded={section.expanded} onChange={(e) => handleChange(section)}>
        <div className='accordion-summary-container position-relative d-flex'>
          <AccordionSummary
            style={{ width: '100%', paddingRight: '0px' }}
            expandIcon={<ExpandMoreIcon style={{ marginRight: '4px', marginLeft: '7px' }} />}
          >
            <div className='d-flex w-100 align-items-center position-relative'>
              {String(section.is_default) === '1' && (
                <div
                  className='position-absolute'
                  style={{ zIndex: '2', right: `${getPosition()}` }}
                >
                  <span
                    style={{
                      background: 'rgb(0, 171, 85)',
                      borderRadius: 3,
                      padding: '1px 5px',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      minWidth: '8px',
                    }}
                  >
                    Default
                  </span>
                  {/* <span className='count-circle'>{getCount()}</span> */}
                </div>
              )}
              <div className='position-absolute' style={{ zIndex: '2', right: '0' }}>
                <span
                  style={{
                    background: 'rgb(0, 171, 85)',
                    borderRadius: 3,
                    padding: '1px 5px',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    minWidth: '8px',
                    marginLeft: '12px',
                  }}
                >
                  {getTaskCount()}
                </span>
                {/* <span className='count-circle'>{getCount()}</span> */}
              </div>
              <div className='w-100'>
                <Typography
                  className='theme-text-color'
                  sx={{ width: '90%', flexShrink: 0, fontWeight: 'bold', position: 'relative' }}
                >
                  {section.section_name}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <div className='position-relative d-flex align-items-center' style={{ zIndex: '3' }}>
            <div id='sale-more-icon-parent d-flex align-items-center'>
              <span role='button' id='sale-more-icon' onClick={(e) => openMenu(e, section)}>
                <MoreVertIcon
                  htmlColor='#6d7c89'
                  style={{ marginBottom: '2px', marginRight: '8px' }}
                />
              </span>
            </div>
          </div>
        </div>

        <AccordionDetails>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='row1'>
              {(provided) => (
                <div className='mt-3' {...provided.droppableProps} ref={provided.innerRef}>
                  {section.section_task.length > 0 ? (
                    getFilteredTasks(section.section_task).map((task, i) => (
                      <Draggable
                        key={String(task.taskTempId)}
                        draggableId={String(task.taskTempId)}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div className='row' {...provided.draggableProps} ref={provided.innerRef}>
                            <div className='col-12'>
                              <Box
                                id={task.taskTempId}
                                sx={{ px: 5, py: 1, boxShadow: 3 }}
                                className={
                                  snapshot.isDragging
                                    ? 'onDrag col-12 relative '
                                    : `not-drag col-12 relative`
                                }
                                role='button'
                                tabIndex='0'
                                style={{
                                  borderLeft: '3px solid transparent',
                                  borderRadius: '4px',
                                  // marginBlock: '10px',
                                  marginTop: '10px',
                                  marginBottom: '10px',
                                  paddingBlock: '5px',
                                  paddingBottom: '10px',
                                }}
                              >
                                <div className='box-inner-div'>
                                  <span
                                    style={{
                                      position: 'absolute',
                                      right: 10,
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      zIndex: 2,
                                    }}
                                  >
                                    {!verifiedListing && (
                                      <>
                                        {isDragDisabled() ? (
                                          <Tooltip arrow title='please remove filter'>
                                            <IconButton style={{ float: 'right' }}>
                                              <DragIndicatorIcon style={{ color: '#C4CDD5' }} />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <IconButton
                                            {...provided.dragHandleProps}
                                            style={{ float: 'right' }}
                                          >
                                            <DragIndicatorIcon />
                                          </IconButton>
                                        )}
                                      </>
                                    )}
                                    <IconButton onClick={(e) => handleOpenSectionMenu(e, task, i)}>
                                      <MoreVertIcon />
                                    </IconButton>
                                  </span>
                                  <div
                                    className='d-flex  align-items-center'
                                    style={{ paddingBottom: '5px' }}
                                  >
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      onOpen={(e) => handleOpenTooltip(e)}
                                      title={
                                        <div id='task-description-tooltip'>
                                          <div
                                            id='task-description-html'
                                            dangerouslySetInnerHTML={getTooltipHtml(
                                              task.description
                                            )}
                                          ></div>
                                        </div>
                                      }
                                    >
                                      <Typography
                                        component='span'
                                        onClick={() => openEditTask(task)}
                                        sx={{
                                          paddingRight: '4rem',
                                          // marginLeft: verifiedListing ? '8px' : '',
                                          marginTop: verifiedListing ? '4px' : '',
                                        }}
                                        className=' mt-1'
                                      >
                                        {task.task}
                                      </Typography>
                                    </Tooltip>
                                  </div>

                                  <div className='row align-items-center pb-1 '>
                                    {/* ================================================= date picker ===================================== */}

                                    <div
                                      className='col-md-4 mb-2 mb-md-0'
                                      style={{ paddingLeft: '17px' }}
                                    >
                                      <div
                                        className={`d-flex align-items-center justify-content-center`}
                                      >
                                        <div className='mr-2'>
                                          <PriorityPopover
                                            priority={task.priority}
                                            id={task}
                                            handleTaskPriority={handlePriority}
                                            shouldOpen={true}
                                          />
                                        </div>

                                        <div className='mr-2'>
                                          <StatusPopover
                                            status={task.status}
                                            id={task}
                                            handleStatus={() => null}
                                            onStatusChecked={() => null}
                                            shouldOpen={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <div className='col-12 mt-5'>
                      <img
                        width='124px'
                        height='124px'
                        src={noTask}
                        alt='no task'
                        style={{ margin: 'auto' }}
                      />
                      <Typography style={{ textAlign: 'center', paddingLeft: 50, paddingTop: 10 }}>
                        No Task Found
                      </Typography>
                    </div>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
