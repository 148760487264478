import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SaleSectionsMenu from './SaleSectionsMenu';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
export default function AccordionMenuPopover({
  isOpen,
  onOpen,
  onClose,
  anchorEl,
  selectedSection,
  sections,
  handleChangeSectionOrder,
  sectionList,
  isMoveUpButtonDisabled,
  isMoveDownButtonDisabled,
}) {
  const moveToRef = React.useRef();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const handleClose = () => {
    onClose();
  };
  const handleMove = (type) => {
    if (type === 1) {
      console.log('Move', type, 'up');
      handleChangeSectionOrder(selectedSection, 1);
    }
    if (type === 2) {
      console.log('Move', type, 'down');
      handleChangeSectionOrder(selectedSection, 2);
    }
    // setRowData(task);
  };
  const handleCloseSubMenu = () => {
    setIsSubMenuOpen(false);
    setSubMenuAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem disabled={isMoveUpButtonDisabled} ref={moveToRef} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={isMoveUpButtonDisabled} onClick={() => handleMove(1)}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Move Up</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <ArrowUpwardIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disabled={isMoveDownButtonDisabled} ref={moveToRef} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={isMoveDownButtonDisabled} onClick={() => handleMove(2)}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Move Down</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <ArrowDownwardIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}
