import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
// material
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import {
  TeamListHead,
  TeamListToolbar,
  TeamMoreMenu,
} from "../../components/_dashboard/team";
//
import USERLIST from "../../_mocks_/user";
import { teamList, teamDelete } from "../../DAL/Team/Team";
import { baseUri } from "../../config/config";
import { MuiDrawer } from "src/components";
import StyledSearch from "src/components/search/StyledSearch";
import StyledChatSearch from "src/components/search/StyledChatSearch";

// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "no", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "projects", label: "Projects", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "department", label: "Department", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (b.id < a.id) {
    return -1;
  }
  if (b.id > a.id) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
// function applySortFilter(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   const stableCmp = (a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   };
//   stabilizedThis.sort(stableCmp);
//   return stabilizedThis.map((el) => el[0]);
// }

export default function Team() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [selectedRow, setRowData] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedTableRow, setSelectedTableRow] = useState(undefined);
  const [projectsDrawerOpen, setProjectsDrawerOpen] = useState(undefined);
  const [orderBy, setOrderBy] = useState("first_name");
  const [filterName, setFilterName] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [opendialog, setOpenDialog] = React.useState(false);

  const handleDeleteTeam = async (data) => {
    setData([]);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("user_id", data.user_id);
    const result = await teamDelete(formData);
    if (result.code === 200) {
      // setData(result.Team_member_list);
      handleCloseDialog();
      await fetchListing();
    } else {
      handleCloseDialog();
      enqueueSnackbar(result.message, { variant: "error" });
      await fetchListing();
    }
  };

  const handleOpenDialog = (data) => {
    setOpenDialog(true);
    setRowData(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const fetchListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await teamList(formData);
    console.log(result, "team-list");
    if (result.code === 200) {
      setData(result.Team_member_list);
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const openProjectsDrawer = () => {
    setProjectsDrawerOpen(true);
  };
  const closeProjectsDrawer = () => {
    setProjectsDrawerOpen(false);
  };
  const handleViewProjects = (item) => {
    console.log(item, "view-proj");
    setSelectedTableRow(item);
    openProjectsDrawer();
  };

  const getProjectsDrawerTitle = () => {
    if (!selectedTableRow) {
      return "Loading...";
    }
    let plural = selectedTableRow.projects.length > 1;
    if (selectedTableRow.projects.length > 0) {
      return `User is assigned in ${selectedTableRow.projects.length} ${
        plural ? "projects" : "project"
      }`;
    }
    if (selectedTableRow.projects.length > 0) {
      return `User is not assigned in any project`;
    }
  };
  const getFilteredUserProjects = (_projects) => {
    return filter(
      _projects,
      (item) =>
        item.project_name.toLowerCase().indexOf(projectSearch.toLowerCase()) !==
        -1
    );
  };
  useEffect(() => {
    fetchListing();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Team | Support Task Portal">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Team
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/team/add_team")}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Team
          </Button>
        </Stack>

        <Card>
          <TeamListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TeamListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, role, status, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return ( */}
                  {filteredUsers.map((x, i) => (
                    <TableRow
                      hover
                      key={i}
                      tabIndex={-1}
                      role="checkbox"
                      // selected={isItemSelected}
                      // aria-checked={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Avatar alt={name} src={avatarUrl} /> */}
                          <Typography variant="subtitle2" noWrap>
                            {x.first_name} {(" ", x.last_name)}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {x.image != "" ? (
                          <a href={baseUri + x.image} target="_blank">
                            <Avatar src={baseUri + x.image} />
                          </a>
                        ) : (
                          <Avatar src={baseUri + x.image} />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {x.email ? x.email : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          onClick={() => handleViewProjects(x)}
                          role="button"
                          variant="ghost"
                          color={"info"}
                        >
                          View
                        </Label>
                      </TableCell>
                      <TableCell align="left">{x.role}</TableCell>
                      <TableCell align="left">
                        {x.user_department.length > 0
                          ? x.user_department
                              .map((y, j) => y.department_name)
                              .join(", ")
                          : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={String(x.status) === "0" ? "error" : "success"}
                        >
                          {x.status === "1" ? "Active" : "Inactive"}
                        </Label>
                      </TableCell>
                      <TableCell align="right">
                        <TeamMoreMenu
                          isUserData={x}
                          onOpenDeleteDialog={handleOpenDialog}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <DialogTitle>
            Are you sure you want to delete this team member ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={() => handleDeleteTeam(selectedRow)}>
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
        <MuiDrawer
          title={getProjectsDrawerTitle()}
          isOpen={projectsDrawerOpen}
          onClose={closeProjectsDrawer}
        >
          <Stack sx={{ mb: 3 }}>
            <StyledChatSearch
              value={projectSearch}
              placeHolder="search..."
              onChange={(e) => setProjectSearch(e.target.value)}
              sx={{ py: 1 }}
            />
          </Stack>
          {selectedTableRow?.projects && (
            <Stack direction="column" spacing={3}>
              {getFilteredUserProjects(selectedTableRow.projects).map(
                (item, i) => (
                  <ProjectCard key={i} item={item} />
                )
              )}
            </Stack>
          )}
        </MuiDrawer>
      </Container>
    </Page>
  );
}
const ProjectCard = ({ item }) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    // /projects/edit-project/465
    navigate(`/projects/edit-project/${item.id}`);
  };
  return (
    <Card elevation={1} sx={{ p: 1 }}>
      <Stack direction="column" spacing={2}>
        <Typography fontWeight="bold">{item.project_name}</Typography>
        <div className="d-flex align-items-center justify-content-center">
          <Button onClick={handleEdit} size="small" variant="contained">
            Edit project
          </Button>
        </div>
      </Stack>
    </Card>
  );
};
