import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";
import SearchNotFound from "../../SearchNotFound";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { archiveProject } from "src/DAL/Project/Project";
import { useSnackbar } from "notistack";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

// ----------------------------------------------------------------------

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default function ProjectList({
  projects,
  onDeleteProject,
  isDeleting,
  onChangeAsImportant,
  onArchive,
  dragDisabled,
  reorderState,
  toggleReOrder,
  afterSort,
  ...other
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [isArchiving, setIsArchiving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleArchive = async () => {
    setIsArchiving(true);
    await onArchive(selectedProject);
    setIsArchiving(false);
    setIsDialogOpen(false);
  };

  const handleReorder = (result) => {
    console.log(result, "drag-end");
    const active = result.active;
    const over = result.over;
    if (!active || !over) {
      return;
    }
    const active_index = active.data.current.sortable.index;
    const over_index = over.data.current.sortable.index;
    if (active_index === over_index) {
      return;
    }
    // else sort the array
    console.log(active_index, "active_index");
    console.log(over_index, "over_index");
    let sorted = sortArrayItems({
      destination_index: over_index,
      source_index: active_index,
      data: projects,
    });
    let destination_item = projects[over_index];
    let source_item = projects[active_index];

    afterSort(sorted, source_item, destination_item);
  };
  const sortArrayItems = ({
    destination_index = 0,
    source_index = 0,
    data = [],
  }) => {
    let spliced_items = [...data];

    const [removed_item] = spliced_items.splice(source_index, 1);
    spliced_items.splice(destination_index, 0, removed_item);

    return spliced_items;
  };
  return (
    <div {...other} style={{ overflow: "hidden" }}>
      {projects.length > 0 ? (
        <DndContext
          onDragEnd={handleReorder}
          onDragStart={() => console.log("dst")}
        >
          <SortableContext disabled={!dragDisabled} items={projects}>
            <Grid container spacing={3} sx={{ px: 0.5, pb: 2, pt: 2 }}>
              {projects.map((item, i) => (
                <SortableItem
                  isDeleting={isDeleting}
                  onDeleteProject={onDeleteProject}
                  selectProject={(data) => setSelectedProject(data)}
                  onArchive={() => setIsDialogOpen(true)}
                  onChangeAsImportant={onChangeAsImportant}
                  key={item.id}
                  id={item.id}
                  item={item}
                />
              ))}
            </Grid>
          </SortableContext>
        </DndContext>
      ) : (
        <Grid item xs={12}>
          <SearchNotFound />
        </Grid>
      )}

      {/* confirmation dialog */}
      <Dialog open={isDialogOpen}>
        <DialogTitle>Archive Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Project will be moved to Archived Projects Page, Are you sure you
            want to Archive this Project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isArchiving} onClick={() => handleArchive()}>
            Yes
          </LoadingButton>
          <Button
            onClick={() => setIsDialogOpen(false)}
            //    autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function SortableItem(props) {
  const item = props.item;
  const {
    isDeleting,
    onDeleteProject,
    selectProject,
    onArchive,
    onChangeAsImportant,
  } = props;
  const {
    transform,
    transition,
    setDraggableNodeRef,
    setNodeRef,
    listeners,
    attributes,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ zIndex: isDragging ? "2" : "1" }}>
      <div ref={setNodeRef}>
        <div
          // {...listeners}
          {...attributes}
          style={style}
          ref={setDraggableNodeRef}
        >
          <ProjectCard
            isDeleting={isDeleting}
            onDeleteProject={onDeleteProject}
            selectProject={(data) => selectProject(data)}
            onArchive={() => onArchive(true)}
            onChangeAsImportant={onChangeAsImportant}
            dragListeners={listeners}
            project={item}
          />
        </div>
      </div>
    </Grid>
  );
}
