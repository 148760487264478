import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import {
  Container,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';

import { baseUri } from '../../config/config';
import { createProjectTemplate } from '../../DAL/Project/Project';

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 300,
  },
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));

const CreateNewTemplate = () => {
  const classes = useStyles();
  const [file, setProfileImage] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [Image, setimage] = React.useState('');
  const [status, setStatus] = useState(true);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    formData.append('status', _status);
    formData.append('project_description', description);
    formData.append('project_name', name);
    if (Image) {
      formData.append('project_image', Image);
    }
    console.log(...formData, 'create-template-request');
    const result = await createProjectTemplate(formData);
    console.log(result, 'template-create-result');
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: 'success' });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
  };

  return (
    <>
      <Container maxWidth='sm'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Create new template
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name'
                label='Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin='dense'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id='name'
                label='Description'
                type='description'
                fullWidth
                variant='outlined'
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team member' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default CreateNewTemplate;
