import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarBorderIcon from "@mui/icons-material/StarBorder";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Avatar,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import editFill from "@iconify/icons-eva/edit-fill";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
// utils
//
import { baseUri } from "../../config/config";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

CategoryListCard.propTypes = {
  product: PropTypes.object,
};

export default function CategoryListCard({ category, onChangeAsImportant }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;
  // console.log(project, 'project-detail');
  const handleOpenDialig = (data) => {
    setOpenDialog(true);
    setProjectData(data);
  };
  const handleChangeImportant = (type) => {
    onChangeAsImportant(type, category);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleNav = (id) => {
    navigate(`/projects/project/${id}`);
  };
  return (
    <>
      <Tooltip
        placement="right"
        arrow
        title={
          <React.Fragment>
            {category.projects.length > 0 ? (
              <>
                <Typography fontWeight="bold">Category Projects:</Typography>
                {category.projects.map((item, i) => (
                  <Typography
                    onClick={() => handleNav(item.id)}
                    role="button"
                    sx={{ my: "5px" }}
                    key={i}
                  >
                    {item.project_name}
                  </Typography>
                ))}
              </>
            ) : (
              <>
                <Typography>No Projects</Typography>
              </>
            )}
          </React.Fragment>
        }
      >
        <Card>
          <Box sx={{ pt: "100%", position: "relative" }}>
            {category.is_important === "1" ? (
              <Tooltip title="Remove as important">
                <IconButton
                  size="small"
                  onClick={() => handleChangeImportant("0")}
                  sx={{
                    zIndex: 10,
                    top: 9,
                    right: 11,
                    position: "absolute",
                  }}
                >
                  <StarRateIcon
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                  {/* <StarBorderIcon/> */}
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Mark as important">
                <IconButton
                  size="small"
                  onClick={() => handleChangeImportant("1")}
                  sx={{
                    zIndex: 10,
                    top: 9,
                    right: 11,
                    position: "absolute",
                  }}
                >
                  <StarBorderIcon
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
            {category.image !== "" ? (
              <ProjectImgStyle
                // alt={name}
                style={{ cursor: "pointer" }}
                src={baseUri + category.image}
                className="image-hov"
                onClick={() =>
                  navigate(`/projects/categories/${category.id}`, {
                    state: category,
                  })
                }
              />
            ) : (
              <ProjectImgStyle
                // alt={name}
                style={{ cursor: "pointer" }}
                src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
                className="image-hov"
                onClick={() =>
                  navigate(`/projects/categories/${category.id}`, {
                    state: category,
                  })
                }
              />
            )}
            {/* <Avatar className="image-hov">N</Avatar> */}
            {/* </Link> */}
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
            {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
            <Typography
              variant="subtitle2"
              noWrap
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/projects/categories/${category.id}`, {
                  state: category,
                })
              }
            >
              {category.catagory_name}
            </Typography>
            {/* </Link> */}
            {/* <Typography variant="subtitle2" noWrap>
          {id}
        </Typography> */}
          </Stack>
        </Card>
      </Tooltip>
    </>
  );
}
