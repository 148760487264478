import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@szhsin/react-menu';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import '@szhsin/react-menu/dist/index.css';
// utils
//
import { baseUri } from '../../config/config';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  filter: 'grayscale(70%)',
});

// ----------------------------------------------------------------------

ProjectCard.propTypes = {
  product: PropTypes.object,
};

export default function ProjectCard({ category, onUnArchive, selectProject }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;
  // console.log(project, 'project-detail');
  const handleOpenDialig = (data) => {
    setOpenDialog(true);
    setProjectData(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handelUnArchive = (data) => {
    selectProject(data);
    onUnArchive();
  };
  return (
    <>
      <Card style={{ backgroundColor: '#e9ecef' }}>
        <Box sx={{ pt: '100%', position: 'relative', backgroundColor: '#e9ecef' }}>
          <Menu
            key='left'
            direction='right'
            align='center'
            position='anchor'
            viewScroll='auto'
            menuButton={
              <IconButton
                style={{
                  zIndex: 9,
                  top: 2,
                  right: 1,
                  position: 'absolute',
                }}
              >
                <MoreVertIcon
                  style={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    color: 'black',
                  }}
                />
              </IconButton>
            }
          >
            <MenuItem onClick={() => handelUnArchive(category)} sx={{ color: 'text.secondary' }}>
              <ListItemText primary='Un-Archive' primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </Menu>
          {category.project_image !== '' ? (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: 'pointer' }}
              src={baseUri + category.project_image}
              className='image-hov'
              // onClick={() =>
              //   navigate(`/projects/categories/${category.id}`, {
              //     state: category,
              //   })
              // }
            />
          ) : (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: 'pointer' }}
              src='https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
              className='image-hov'
              // onClick={() =>
              //   navigate(`/projects/categories/${category.id}`, {
              //     state: category,
              //   })
              // }
            />
          )}
          {/* <Avatar className="image-hov">N</Avatar> */}
          {/* </Link> */}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
          <Typography
            variant='subtitle2'
            noWrap
            style={{ cursor: 'pointer' }}
            // onClick={() =>
            //   navigate(`/projects/categories/${category.id}`, {
            //     state: category,
            //   })
            // }
          >
            {category.project_name}
          </Typography>
        </Stack>
      </Card>
    </>
  );
}
