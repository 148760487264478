import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import ChangeSectionTypeMenu from './ChangeSectionTypeMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSnackbar } from 'notistack';
import { updateSaleSection, updateSaleSectionType } from 'src/DAL/Project/Project';
// ----------------------------------------------------------------------

export default function SectionMoreMenu({
  onOpenDeleteDialog,
  setIsSidebarOpen,
  isUserData,
  fetchSectionListing,
  setTargetRow,
  targetRow,
}) {
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [makingDefault, setMakingDefault] = useState(false);
  //sideMenu
  const sideMenuRef = useRef(null);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [newType, setNewType] = useState('0');
  const handleOpen = () => {
    setTargetRow(isUserData);
    setIsOpen(true);
  };
  //sideMenu
  const handleOpenSideMenu = () => {
    setTargetRow(isUserData);
    setIsSideMenuOpen(true);
  };
  //
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (value) => {
    setOpenDialog(true);
    setIsSideMenuOpen(false);
    setIsOpen(false);
    setNewType(value);
  };
  const handleChangeType = async () => {
    //
    console.log(isUserData, newType, 'is-data');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('sale_section_id', isUserData.sale_section_id);
    formData.append('default_status', newType);
    console.log(...formData, 'change-type-req');
    const result = await updateSaleSectionType(formData);
    if (result.code === 200) {
      console.log(result, 'changed');
      enqueueSnackbar('Section type changed', { variant: 'success' });
      handleCloseDialog();
      fetchSectionListing();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleDeleteClick = () => {
    onOpenDeleteDialog(isUserData);
    setIsOpen(false);
  };
  const handleEditClick = () => {
    handleOpenSidebar();
    setIsOpen(false);
  };
  const handleMakeDefault = async () => {
    setIsOpen(false);
    if (makingDefault) {
      return;
    }
    let target_section = isUserData;
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('sale_section_name', target_section.sale_section_name);
    formData.append('sale_section_status', target_section.sale_section_status);
    formData.append('sale_section_id', target_section.sale_section_id);
    formData.append('default_status', target_section.default_status);
    formData.append('show_stat', target_section.show_stat);
    formData.append('section_order', target_section.section_order);
    formData.append('is_default', '1');
    console.log(...formData, 'update-section-req');
    setMakingDefault(true);
    const result = await updateSaleSection(formData);
    if (result.code === 200) {
      fetchSectionListing();
      setMakingDefault(false);
      enqueueSnackbar('Section set as default successfully', { variant: 'success' });
    } else {
      setMakingDefault(false);
      enqueueSnackbar(result.message, { variant: 'success' });
    }
  };
  const handleOpenSidebar = () => {
    setIsOpen(false);
    setIsSidebarOpen(true);
  };
  return (
    <>
      <IconButton ref={ref} onClick={handleOpen}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          // component={RouterLink}
          onClick={() => handleEditClick()}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary='Edit' primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDeleteClick()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary='Delete' primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} ref={sideMenuRef} onClick={handleOpenSideMenu}>
          <ListItemIcon>
            <Icon icon={'carbon:settings-adjust'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary='Change Type' primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {isUserData.is_default === '0' && (
          <MenuItem onClick={handleMakeDefault} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={'fluent:settings-24-regular'} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary='Make Default' primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
      {/* sideMenu */}
      <ChangeSectionTypeMenu
        targetRow={targetRow}
        targetRef={sideMenuRef}
        isOpen={isSideMenuOpen}
        onClose={() => setIsSideMenuOpen(false)}
        handleOpenDialog={handleOpenDialog}
      />
      {/* dialogs */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to change section type?</DialogTitle>
        <DialogContent>
          If you change a section type to Paid, the previous Paid section type will be changed to
          Normal and vice versa.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleChangeType}>Yes, Sure</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
