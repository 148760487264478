import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function ClientMoreMenu({ onOpenDeleteDialog, isUserData }) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* {String(isUserData.is_internal) === "1" ? ( */}
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton>
      {/* // ) : (
      //   <Tooltip title="Cannot edit sale leads added outside of DONE portal">
      //     <span>
      //       <IconButton disabled ref={ref}>
      //         <Icon icon={moreVerticalFill} width={20} height={20} />
      //       </IconButton>
      //     </span>
      //   </Tooltip>
      // )} */}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          // component={RouterLink}
          onClick={() => navigate(`/clients/edit-client/${isUserData.user_id}`)}
          // to="/team/edit_team"
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => onOpenDeleteDialog(isUserData)}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
