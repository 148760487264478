import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LoadingButton } from '@mui/lab';
import {
  deleteTemplateSection,
  makeDefaultProjectTemplate,
  makeSectionAsDefault,
} from 'src/DAL/Project/Project';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
export default function TempAccordionMenu({
  isOpen,
  onOpen,
  onClose,
  anchorEl,
  selectedSection,
  sections,
  handleChangeSectionOrder,
  sectionList,
  isMoveUpButtonDisabled,
  isMoveDownButtonDisabled,
  fetchTaskListing,
  openEditSection,
  getTaskSections,
  setSelectedSectionIndex,
  project_temp_id,
  project_id,
}) {
  const moveToRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const params = useParams();
  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };
  const makeDefault = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('template_section_id', selectedSection.section_id);
    formData.append('is_default', '1');
    formData.append('project_temp_id', project_temp_id);
    console.log(...formData, 'del-section-req');

    console.log(selectedSection, 'to-def');
    const result = await makeDefaultProjectTemplate(formData);
    if (result.code === 200) {
      handleClose();
      enqueueSnackbar('Section set as default', { variant: 'success' });
      fetchTaskListing();
      getTaskSections();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      handleClose();
    }
  };
  const handleDelSection = async () => {
    setIsDeleting(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('template_section_id', selectedSection.section_id);
    formData.append('del_order', selectedSection.section_order);
    console.log(...formData, 'del-section-req');
    console.log(selectedSection, 'to-del');
    // return;
    const result = await deleteTemplateSection(formData);
    console.log(result, 'del-res');
    if (result.code === 200) {
      setSelectedSectionIndex(0);
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);
      handleClose();
      enqueueSnackbar('Section deleted', { variant: 'success' });
      fetchTaskListing();
      getTaskSections();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);
      handleClose();
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleMove = (type) => {
    if (type === 1) {
      console.log('Move', type, 'up');
      handleChangeSectionOrder(selectedSection, 1);
    }
    if (type === 2) {
      console.log('Move', type, 'down');
      handleChangeSectionOrder(selectedSection, 2);
    }
    // setRowData(task);
  };
  const handleDelete = (section) => {
    setIsDeleteDialogOpen(true);
  };
  const handleCloseSubMenu = () => {
    setIsSubMenuOpen(false);
    setSubMenuAnchorEl(null);
  };
  const openEdit = () => {
    openEditSection();
    //
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem disabled={isMoveUpButtonDisabled} ref={moveToRef} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={isMoveUpButtonDisabled} onClick={() => handleMove(1)}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Move Up</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <ArrowUpwardIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disabled={isMoveDownButtonDisabled} ref={moveToRef} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={isMoveDownButtonDisabled} onClick={() => handleMove(2)}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Move Down</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <ArrowDownwardIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disabled={false} ref={moveToRef} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={false} onClick={() => openEdit()}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Edit</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <Icon icon={editFill} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {String(selectedSection?.is_default) !== '1' && (
            <ListItem disabled={false} ref={moveToRef} sx={{ padding: '.2rem' }}>
              <ListItemButton disabled={false} onClick={() => makeDefault()}>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.8rem' }}>Make Default</Typography>
                </ListItemText>
                <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                  <SettingsApplicationsIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
          {String(selectedSection?.is_default) !== '1' && (
            <ListItem disabled={false} ref={moveToRef} sx={{ padding: '.2rem' }}>
              <ListItemButton disabled={false} onClick={() => handleDelete()}>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.8rem' }}>Delete</Typography>
                </ListItemText>
                <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                  <DeleteForeverOutlinedIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
      {/*  */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Deleting section will move its tasks to Default section. Are you sure you want to delete
            this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isDeleting} onClick={handleDelSection}>
            Yes
          </LoadingButton>
          <Button
            onClick={handleCloseDialog}
            //    autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
