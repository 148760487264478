import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import {
  Container,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Chip,
  OutlinedInput,
  Autocomplete,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { teamDetail, editTeam, activeDepartmentList } from '../../DAL/Team/Team';
import { departmentList } from '../../DAL/Department/Department';
import { baseUri } from '../../config/config';
import { useSocket } from 'src/Hooks/socketContext';
import { activeTeamList } from '../../DAL/Project/Project';
const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 300,
  },
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));

function EditClient(props) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { sendUpdateProfileNotification } = useSocket();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [teamChatMembers, setTeamChatMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [Image, setimage] = React.useState('');
  const [role, setRole] = useState('');
  const [file, setProfileImage] = React.useState('');
  const [status, setStatus] = useState(true);
  const [allowStatus, setAllowStatus] = useState(false);
  const [allowChangeTaskOrder, setAllowChangeTaskOrder] = useState(false);
  const [allowChat, setAllowChat] = useState(false);
  const [allowViewSales, setAllowViewSales] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let allowProject = '0';
    if (allowStatus === true) {
      allowProject = '1';
    }
    let allow_view_sales = '0';
    if (allowViewSales === true) {
      allow_view_sales = '1';
    }
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    let allowChangeOrder = '0';
    if (allowChangeTaskOrder === true) {
      allowChangeOrder = '1';
    }
    let _allow_chat;
    if (allowChat === true) {
      _allow_chat = 1;
    } else {
      _allow_chat = 0;
    }
    // setIswaiting(true);
    let team_email = email.trim();
    team_email = team_email.toLowerCase();
    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', team_email);
    formData.append('password', password);
    formData.append('role', role);
    formData.append('status', _status);
    formData.append('allow_project', allowProject);
    formData.append('allow_reorder_task', allowChangeOrder);
    formData.append('sales_option', allow_view_sales);
    formData.append('allow_chat', _allow_chat);
    formData.append('token', localStorage.getItem('token'));
    formData.append('user_id', id);
    if (Image) {
      formData.append('team_image', Image);
    }
    if (allowChat) {
      members.map((item, i) => {
        formData.append(`team[${i}]`, item.user_id);
      });
    }
    if (allowChat) {
      if (members.length === 0) {
        enqueueSnackbar('Please choose at least one member', { variant: 'error' });
        return;
      }
    }

    console.log(...formData, 'edit-team-req');
    setIswaiting(true);
    const result = await editTeam(formData);
    if (result.code === 200) {
      //hit socket
      const payload = {
        receiver_id: id,
        notification_type: 'team_edit',
      };
      sendUpdateProfileNotification(payload);
      setIswaiting(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
  };

  const fetchTeamDetail = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('user_id', id);
    const result = await teamDetail(formData);
    console.log(result, 'team-detail');
    if (result.code === 200) {
      console.log(result.Team_Data.allow_project, 'is-allow?');
      let allow = false;
      if (String(result.Team_Data.allow_project) === '1') {
        allow = true;
      }
      setAllowStatus(allow);
      setIswaiting(false);
      setFirstName(result.Team_Data.first_name);
      setLastName(result.Team_Data.last_name);
      setEmail(result.Team_Data.email);
      if (result.Team_Data.status === '1') {
        setStatus(true);
      } else {
        setStatus(false);
      }
      if (result.Team_Data.allow_reorder_task === '1') {
        setAllowChangeTaskOrder(true);
      } else {
        setAllowChangeTaskOrder(false);
      }
      if (result.Team_Data.sales_option === '1') {
        setAllowViewSales(true);
      } else {
        setAllowViewSales(false);
      }
      if (result.Team_Data.allow_chat === '1') {
        setAllowChat(true);
      } else {
        setAllowChat(false);
      }
      setMembers(result.Team_Data.chat_team);
      setRole(result.Team_Data.role);
      setimage(result.Team_Data.image);
      if (result.Team_Data.image) {
        setProfileImage(baseUri + result.Team_Data.image);
      } else {
        setProfileImage(
          'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
        );
      }
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };
  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await activeTeamList(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setTeamChatMembers(result.Team_member_list);
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    fetchTeamDetail();
    fetchTeamListing();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }

  return (
    <>
      <Container maxWidth='lg'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Edit Client
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id='name'
                label='First Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id='name'
                label='Last Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id='email'
                label='Email'
                type='email'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={6}>
              Allow create project :
              <Switch
                checked={allowStatus}
                onChange={(e) => setAllowStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={6}>
              Drag-Drop/Section:
              <Switch
                checked={allowChangeTaskOrder}
                onChange={(e) => setAllowChangeTaskOrder(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={6}>
              Allow Chat:
              <Switch
                checked={allowChat}
                onChange={(e) => setAllowChat(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            {localStorage.getItem('allowViewSales') === '1' && (
              <Grid item xs={6}>
                Allow View Sales:
                <Switch
                  checked={allowViewSales}
                  onChange={(e) => setAllowViewSales(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {allowChat && (
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id='tags-filled'
                    options={teamChatMembers}
                    value={members}
                    getOptionLabel={(option) => `${option.first_name}  ${' '}  ${option.last_name}`}
                    isOptionEqualToValue={(option, value) =>
                      String(option.user_id) === String(value.user_id)
                    }
                    onChange={(event, value) => setMembers(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={`${option.first_name}${' '}${option.last_name}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='Select Members' />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team member' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label
                htmlFor='contained-button-file'
                className='d-flex align-items-center justify-content-center'
              >
                <Button
                  component='span'
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Edit Client
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditClient;
