import { invokeApi } from "src/bl_libs/invokeApi";
export const changeSectionOrder = async (data) => {
  const requestObj = {
    path: `api/sale_section/update_sale_section_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const importDay1Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day1_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importDay2Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day2_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importDay3Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day3_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importDay4Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day4_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importDay5Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day5_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importDay6Sales = async (data) => {
  const requestObj = {
    path: `api/sales/import_day6_sales.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleTargetDate = async (data) => {
  const requestObj = {
    path: `api/sales/update_sale_target_date.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleStatus = async (data) => {
  const requestObj = {
    path: `api/sales/update_sale_completed_status.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleType = async (data) => {
  const requestObj = {
    path: `api/sales/change_sale_lead_types.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addProduct = async (data) => {
  const requestObj = {
    path: `api/product/add_product_by_admin.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const productDetail = async (data) => {
  const requestObj = {
    path: `api/product/product_details.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProduct = async (data) => {
  const requestObj = {
    path: `api/product/edit_product.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProduct = async (data) => {
  const requestObj = {
    path: `api/product/delete_product.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allProductListing = async (data) => {
  const requestObj = {
    path: `api/product/products_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateOptionalSaleAssociates = async (data) => {
  const requestObj = {
    path: `api/sales/update_optional_associate.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
