import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, FormikProvider } from 'formik';
import PinInput from 'react-pin-input';
import { useSnackbar } from 'notistack';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function PinCodeForm({ onhandlePinCodeSubmit, isloading }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [pinCode, setPinCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!pinCode) {
      enqueueSnackbar('pin code should not be empty', { variant: 'error' });
      return;
    }
    onhandlePinCodeSubmit(pinCode);
  };
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {/* <TextField
          fullWidth
          required
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
          autoComplete="username"
          type="text"
          label="Pin Code"
        /> */}
        <PinInput
          length={6}
          initialValue=""
          secret={false}
          onChange={(value, index) => setPinCode(value)}
          type="numeric"
          inputMode="number"
          style={{ display: 'flex', justifyContent: 'space-between' }}
          inputStyle={{ borderColor: 'red' }}
          inputFocusStyle={{ borderColor: 'blue' }}
          onComplete={(value, index) => {}}
          // autoSelect={true}
          // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isloading}>
          Submit
        </LoadingButton>
      </Stack>
    </form>
  );
}
