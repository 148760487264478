import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import EditGroupDialog from 'src/pages/teamChat/components/EditGroupDialog';
import { editChatGroup } from 'src/DAL/chat/chat';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ChatGroupMoreMenu({
  onOpenDeleteDialog,
  isUserData,
  getChatGroupList,
  handleNotification,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const toggleEditGroup = () => {
    setEditGroupOpen(!editGroupOpen);
  };
  const handleOnEdit = async (form_data) => {
    console.log(...form_data, 'edit-group-req');

    setIsEditingGroup(true);
    const result = await editChatGroup(form_data);
    if (result.code === 200) {
      setIsEditingGroup(false);
      enqueueSnackbar('Group updated', { variant: 'success' });
      toggleEditGroup();
      getChatGroupList();
      return true;
    } else {
      setIsEditingGroup(false);
      enqueueSnackbar(result.message, { variant: 'error' });
      return false;
    }
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          // component={RouterLink}
          onClick={() => toggleEditGroup()}
          // onClick={() => navigate(`/team/edit_team/${isUserData.user_id}`)}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary='Edit' primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onOpenDeleteDialog(isUserData)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary='Delete' primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
      {/* dialogs */}
      <EditGroupDialog
        isEditing={isEditingGroup}
        isOpen={editGroupOpen}
        toggle={toggleEditGroup}
        onEditGroup={handleOnEdit}
        currentGroup={isUserData}
        handleNotification={handleNotification}
      />
    </>
  );
}
