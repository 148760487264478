import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import {
  Container,
  TextField,
  Grid,
  IconButton,
  MenuItem,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput
} from '@mui/material';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { teamChangePassword } from '../../DAL/Team/Team';

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 300
  },
  loading: {
    marginLeft: '50%',
    marginTop: '20%'
  }
}));

function TeamChangePassword(props) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIswaiting(true);
    const formData = new FormData();

    formData.append('new_password', newPassword);
    formData.append('confirm_password', confirmPassword);
    formData.append('user_id', id);
    formData.append('token', localStorage.getItem('token'));

    const result = await teamChangePassword(formData);
    if (result.code === 200) {
      setIswaiting(false);
      navigate(-1);
      enqueueSnackbar('Password Updated Successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <Container maxWidth="sm">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Change Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="password"
                label="New Password"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="password"
                label="Confirm Password"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default TeamChangePassword;
