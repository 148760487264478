import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  FormLabel,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {
  addProject,
  activeTeamList,
  activeProjectCategories,
  getProjectTemplates,
} from '../../DAL/Project/Project';
import { teamList } from '../../DAL/Team/Team';
import { useSocket } from '../../Hooks/socketContext';
import CkEditor from 'src/components/editor/CKEditor';
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
  img: {
    margin: 'auto',
  },
}));

function AddProject(props) {
  const { sendSocketNotification } = useSocket();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  /* <<======================= STATES =======================>> */
  const [isWaiting, setIsWaiting] = useState(false);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(true);
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [activeCategory, setActiveCategory] = useState([]);
  const [projectTemplates, setProjectTemplates] = useState([]);
  const [projectTeamList, setProjectTeamList] = useState([]);
  const [projectClientList, setProjectClientList] = useState([]);
  /* <<======================= HANDLING =======================>> */
  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setSelectedTemplate(event.target.value);
  };
  const handleChangeClient = (event) => {
    setSelectedClient(event.target.value);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMembers(
      // On autofill we get the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_name', name);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('project_description', description);
    formData.append('status', _status);
    formData.append('project_category', selectedCategory);
    formData.append('project_template', selectedTemplate);
    formData.append('client_id', selectedClient);
    projectTemplates.map((template, index) => {
      let project_id = '';
      if (String(template.project_temp_id) === String(selectedTemplate)) {
        project_id = template.project_id;
        console.log(project_id, 'proj-id');
        formData.append('temp_project_id', project_id);
      }
    });
    if (Image) {
      formData.append('project_image', Image);
    }
    const arr = members.map((member, index) => {
      formData.append(`team[${index}]`, member.user_id);
      return member;
    });
    console.log(...formData, 'add-project-req');
    setIsWaiting(true);
    const result = await addProject(formData);
    console.log(result, 'add-project');
    if (result.code === 200) {
      setIsWaiting(false);
      navigate(-1);
      members.map((members, index) => {
        const payload = {
          receiver_id: members.user_id,
          message: `You are added in ${name} project`,
          project_id: result.project_data.id,
          project_name: result.project_data.project_name,
          notification_type: 'task',
        };
        sendSocketNotification(payload);
        return members;
      });
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };
  const fetchTemplates = async () => {
    let formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const templateResult = await getProjectTemplates(formData);
    console.log(templateResult, 'template-listing');
    if (templateResult.code === 200) {
      setProjectTemplates(templateResult.project_template_list);
    } else {
      enqueueSnackbar(templateResult.message, { variant: 'error' });
    }
  };
  const fetchListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await activeTeamList(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setProjectTeamList(result.Team_member_list);
      setProjectClientList(result.client_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await activeTeamList(formData);
    if (result.code === 200) {
      setMembersData(result.Team_member_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
    const categoryResult = await activeProjectCategories(formData);
    console.log(categoryResult, 'active-category');
    if (categoryResult.code === 200) {
      setActiveCategory(categoryResult.category_list);
    } else {
      enqueueSnackbar(categoryResult.message, { variant: 'error' });
    }
    fetchTemplates();
    fetchListing();
  }, []);

  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <>
      <Container maxWidth='lg'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Add Project
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin='dense'
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name'
                label='Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                id='name'
                label='Start Date'
                type='date'
                fullWidth
                variant='outlined'
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                required
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                id='name'
                label='End Date'
                type='date'
                fullWidth
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-multiple-chip-label'>Team Member</InputLabel>
                <Select
                  labelId='demo-multiple-chip-label'
                  id='demo-multiple-chip'
                  multiple
                  value={members}
                  onChange={handleChange}
                  input={<OutlinedInput id='select-multiple-chip' label='Team Member' />}
                  renderValue={(selected) => (
                    <>
                      {selected.map((value) => (
                        <Chip
                          key={value.id}
                          label={`${value.first_name}${' '} ${value.last_name}`}
                        />
                      ))}
                    </>
                  )}
                >
                  {membersData.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x.first_name} {(' ', x.last_name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Client</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedClient}
                  label='Client'
                  onChange={handleChangeClient}
                >
                  {/* <MenuItem  value='0'>
                      No template
                    </MenuItem> */}
                  {projectClientList.map((item, index) => (
                    <MenuItem key={index} value={item.user_id}>
                      {item.first_name + ' ' + item.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Project template</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedTemplate}
                  label='Project category'
                  onChange={handleChangeTemplate}
                >
                  {/* <MenuItem  value='0'>
                      No template
                    </MenuItem> */}
                  {projectTemplates.map((template, index) => (
                    <MenuItem key={index} value={template.project_temp_id}>
                      {template.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Project category</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedCategory}
                  label='Project category'
                  onChange={handleChangeCategory}
                >
                  {activeCategory.map((category, index) => (
                    <MenuItem key={index} value={category.id}>
                      {category.catagory_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id='demo-multiple-chip-label'>Project category </InputLabel>
                <Select
                  labelId='demo-multiple-chip-label'
                  id='demo-multiple-chip'
                  multiple
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  input={<OutlinedInput id='select-multiple-chip' label='Project category' />}
                  renderValue={(selected) => (
                    <>
                      {selected.map((value) => (
                        <Chip key={value.id} label={`${value.catagory_name}`} />
                      ))}
                    </>
                  )}
                >
                  {activeCategory.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x.catagory_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Project Description :</FormLabel>
              <div className='project-description'>
                <CkEditor content={description} setContent={(value) => setDescription(value)} />
              </div>

              {/* <TextField
                margin='dense'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id='name'
                label='Description'
                type='description'
                fullWidth
                variant='outlined'
                multiline
                rows={4}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  <img className={classes.img} src={file} alt='team members' />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Add Project
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default AddProject;
