import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CkEditor from 'src/components/editor/CKEditor';
import { LoadingButton } from '@mui/lab';
import {
  Chip,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { activeTeamList } from 'src/DAL/Project/Project';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewMessageDialog({ onInitializeMessage, isOpen, toggle, isLoading }) {
  const [projectTeamList, setProjectTeamList] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [message, setMessage] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const handleChangeUser = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleClose = () => {
    setSelectedUser('');
    setMembers([]);
    setMessage('');
    toggle();
    // setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message || message === '') {
      enqueueSnackbar('Message cannot be empty', { variant: 'error' });
      return;
    }
    console.log('submit');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('member_id', selectedUser.user_id);
    data.append('message', message.trim());
    console.log(...data, 'initial-msg-req');
    let result = onInitializeMessage(data);
    if (result) {
      setSelectedUser('');
      setMembers([]);
      setMessage('');
    }
  };
  const fetchListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await activeTeamList(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setProjectTeamList(result.Team_member_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      fetchListing();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      //   onClose={handleClose}
      aria-labelledby='file-dialog-title'
      aria-describedby='file-dialog-description'
      maxWidth='md'
      fullWidth
      // PaperProps={{ sx: { width: { md: '40rem', sm: '30rem', xs: '25rem' } } }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id='file-dialog-title'>New Message</DialogTitle>
        <DialogContent>
          <DialogContentText id='file-dialog-description'></DialogContentText>
          <DialogContent>
            <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
              <Grid item xs={12}>
                <FormControl required fullWidth>
                  Message
                  <div className='new-message-editor'>
                    <CkEditor content={message} setContent={(value) => setMessage(value)} />
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth>
                  <InputLabel id='demo-multiple-chip-label'>Team Member</InputLabel>
                  <Select
                    labelId='demo-multiple-chip-label'
                    id='demo-multiple-chip'
                    size='medium'
                    value={selectedUser}
                    onChange={handleChangeUser}
                    label='Team Member'
                  >
                    {projectTeamList.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x.first_name} {(' ', x.last_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type='submit'
            loading={isLoading}
            // onClick={() => handleUploadFile()}
          >
            Send
          </LoadingButton>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
