import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import editFill from "@iconify/icons-eva/edit-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import clockFill from "@iconify/icons-eva/clock-fill";
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
  FormControl,
  TextField,
  Tooltip,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  ListItem,
  List,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  CircularProgress,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { makeStyles } from "@mui/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ScrollToBottom from "react-scroll-to-bottom";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Menu } from "@szhsin/react-menu";
//
import Scrollbar from "../../Scrollbar";
import ColorManyPicker from "../../ColorManyPicker";
// import Messages from './Messages/Messages';
import InfoBar from "./InfoBar/InfoBar";
import Input from "./Input/Input";
import {
  uploadTaskFiles,
  removeTaskFiles,
  detailTaskFiles,
  addTaskComments,
  taskComments,
  EditComment,
  deleteComment,
} from "../../../DAL/Project/Project";
import { baseUri } from "../../../config/config";
import ConfirmDeleteComment from "src/components/Confirmation/ConfirmDeleteComment";
import CommentPopover from "src/components/Popovers/CommentPopover";
import { LoadingButton, timelineDotClasses } from "@mui/lab";
import CkEditor from "src/components/editor/CKEditor";
import { set_blank_target } from "src/utils/DomUtils";
import {
  ALLOWED_TASK_FILES_WITH_ICONS,
  FILE_ACCEPT_TYPE_STRING,
} from "src/constants";

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: "featured", label: "Featured" },
  { value: "newest", label: "Newest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];
export const PRIORITY_OPTIONS = [
  { label: "Critical", value: "critical", color: "error", filterValue: "0" },
  { label: "High", value: "high", color: "primary", filterValue: "1" },
  { label: "Medium", value: "medium", color: "success", filterValue: "2" },
  { label: "Low", value: "low", color: "info", filterValue: "3" },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  {
    label: "Not Started",
    value: "not_started",
    color: "success",
    filterValue: "0",
  },
  {
    label: "In Progress",
    value: "in_progress",
    color: "secondary",
    filterValue: "1",
  },
  {
    label: "In Review",
    value: "in_review",
    color: "warning",
    filterValue: "2",
  },
  {
    label: "Completed",
    value: "completed",
    color: "primary",
    filterValue: "3",
  },
  { label: "Cancelled", value: "cancelled", color: "error", filterValue: "4" },
];
export const FILTER_RATING_OPTIONS = [
  "up4Star",
  "up3Star",
  "up2Star",
  "up1Star",
];
export const FILTER_PRICE_OPTIONS = [
  { value: "below", label: "Below $25" },
  { value: "between", label: "Between $25 - $75" },
  { value: "above", label: "Above $75" },
];
export const FILTER_COLOR_OPTIONS = [
  "#00AB55",
  "#000000",
  "#FFFFFF",
  "#FFC0CB",
  "#FF4842",
  "#1890FF",
  "#94D82D",
  "#FFC107",
];

// ----------------------------------------------------------------------

EditTaskSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    "&.Mui-focused": { outline: "green" },
  },
  edit: {
    marginLeft: ".2rem",
    "& :hover": {
      cursor: "pointer",
    },
  },
  editCommentIcon: {
    fontSize: "1rem",
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: "100%",
    marginBlock: ".15rem",
    alignSelf: "center",
    marginLeft: ".5rem",
    marginRight: ".1rem",
    "&:hover": {
      cursor: "pointer",
      color: "green",
    },
  },
  noShadow: {
    boxShadow: "none",
  },
}));

export default function EditTaskSidebar({
  isOpenEditTask,
  onResetEditTask,
  onOpenEditTask,
  onCloseEditTask,
  isTaskData,
  openUpdate,
  openEdit,
  SubmitTask,
  targetTask,
  setTargetTask,
  isUpdating,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = React.useState("");
  const [taskImages, setTaskImages] = React.useState();
  const [imagesDetail, setImagesDetail] = React.useState([]);
  const [updateName, setUpdateName] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [status, setStatus] = React.useState("1");
  const [selectedCommentStatus, setSelectedCommentStatus] = React.useState("0");
  const [commentData, setCommentData] = React.useState([]);
  const [updateDescription, setUpdateDescription] = React.useState("");
  const addCommentRef = React.useRef();
  const [isCommentUpdating, setIsCommentUpdating] = useState(false);
  const [editComment, setEditComment] = useState();
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [deleteImg, setDeleteImg] = useState();
  const [uploadingFile, setUploadingFile] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    SubmitTask(updateName, updateDescription, isTaskData);
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    /* FILE_ACCEPT_TYPE_STRING
    ALLOWED_TASK_FILES_WITH_ICONS */
    if (!e.target) {
      return;
    }
    if (!e.target.files) {
      return;
    }
    if (!e.target.files[0]) {
      return;
    }
    /* image/png,image/jpeg,image/jpg */
    console.log(e.target.files[0], "files-");
    let can_upload = false;
    let file_type = "0";
    const { type } = e.target.files[0];
    const is_video_type = type.startsWith("video/");
    const is_audio_type = type.startsWith("audio/");
    console.log(is_video_type, "is_video_type");
    console.log(is_audio_type, "is_audio_type");

    let allowed_types = FILE_ACCEPT_TYPE_STRING.split(",");
    // let allowed_types = [
    //   "image/png",
    //   "image/jpeg",
    //   "image/jpg",
    //   "application/pdf",
    //   "text/pdf",
    // ];
    allowed_types.map((item) => {
      if (item === String(type)) {
        console.log(type, "matched");
        can_upload = true;
      }
    });
    if (is_video_type === true || is_audio_type === true) {
      can_upload = true;
    }
    if (String(type) === "text/pdf" || String(type) === "application/pdf") {
      file_type = "1";
    }
    if (
      String(type).startsWith("application/") ||
      is_video_type === true ||
      is_audio_type === true
    ) {
      file_type = "1";
    }
    if (!can_upload) {
      enqueueSnackbar("Please chose an image or pdf file", {
        variant: "error",
      });
      return;
    }
    setUploadingFile(true);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_type", file_type);
    formData.append("task_id", isTaskData.task_id);
    formData.append("task_images", e.target.files[0]);
    console.log(...formData, "task-files-req");

    const resp = await uploadTaskFiles(formData);
    setUploadingFile(false);

    if (resp.code === 200) {
      getTaskImages();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const removeImage = (data, index) => {
    setIsImageDialogOpen(true);
    setDeleteImg({ data: data, index: index });
  };
  const handleRemoveImage = async (data, index) => {
    const formData = new FormData(); // formdata object
    formData.append("token", localStorage.getItem("token"));
    // formData.append('file_type', '0');
    formData.append("task_id", deleteImg.data.task_id);
    formData.append("image_id", deleteImg.data.id);
    console.log(...formData, "del-img-req");
    const resp = await removeTaskFiles(formData);
    // setimage(upload_image_resp.path);
    if (resp.code === 200) {
      enqueueSnackbar(resp.message, { variant: "success" });
      getTaskImages();
      setIsImageDialogOpen(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setIsImageDialogOpen(false);
    }
  };

  const setTaskDetails = (data) => {
    setUpdateName(data.task_text_and_detail.task_name);
    setUpdateDescription(data.task_text_and_detail.task_distribution);
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  const getTaskDetail = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", isTaskData.task_id);
    console.log(...formData, "get-task-detail-req");
    const result = await detailTaskFiles(formData);
    console.log(result, "detail-task-files");
    if (result.code === 200) {
      setUpdateName(result.task_text_and_detail.task_name);
      setUpdateDescription(result.task_text_and_detail.task_distribution);
      setTimeout(() => {
        //to open link in new tab
        applyBlankTargetOnAnchorTag("task-description-admin");
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getTaskImages = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", isTaskData.task_id);
    console.log(...formData, "get-task-detail-req");
    const result = await detailTaskFiles(formData);
    console.log(result, "detail-task-files");
    if (result.code === 200) {
      setImagesDetail(result.task_files);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleShowEditor = (value) => {
    setShowEditor(value);
  };
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (!date) return "-";
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === "-") {
        date_safari = date_safari.replace("-", "/");
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(
        moment(date_safari).subtract(difference_safari, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(
        moment(serverDate).subtract(difference, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");

      return setTime;
    }
  };
  const truncateText = (string, maxLength) => {
    if (!string) {
      return "";
    }
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");

      return result;
    }
    return string;
  };
  useEffect(() => {
    setTaskImages(isTaskData);
  }, [isOpenEditTask]);

  useEffect(async () => {
    if (isOpenEditTask) {
      handleShowEditor(false);
      getTaskDetail();
      getTaskImages();
    }
  }, [isOpenEditTask]);
  useEffect(() => {
    if (isOpenEditTask) {
      setIsCommentUpdating(false);
      setComment("");
      setIsTextFieldFocused(false);
    }
    if (!isOpenEditTask) {
      setUpdateDescription("");
      setCommentData([]);
    }
  }, [isOpenEditTask]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={isOpenEditTask}
        onClose={onCloseEditTask}
        PaperProps={{
          sx: { width: 380, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1 }}
        >
          {" "}
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={onCloseEditTask}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
          {uploadingFile && (
            <div className="uploading-file">
              <Typography sx={{ m: 0 }} variant="body1">
                Uploading file...
              </Typography>

              <CircularProgress size="1.3rem" />
            </div>
          )}
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2, position: "relative" }}>
            <div className="taskNameEditorParent">
              <div>
                <Typography style={{ fontsize: 16, fontWeight: "bold" }}>
                  Task created on :
                </Typography>
                <Typography className="mb-2">
                  {getActualTime(isTaskData.createdDate)}
                </Typography>
              </div>
              <textarea
                value={updateName}
                style={{
                  border: "hidden",
                  backgroundColor: "rgb(0 42 21 / 8%) ",
                  fontSize: 12,
                  minHeight: "15vh",
                }}
                className="form-control text-area-task"
                onChange={(e) => setUpdateName(e.target.value)}
                id="exampleFormControlInput1"
              ></textarea>
            </div>
            <div className="task-description-editor-parent ck-editor-div">
              {/* <div className='row justify-content-end align-items-center'>
                <div className='col d-flex flex-col justify-content-end my-1'>
                  {updateDescription === '' && (
                    <Typography className='mr-auto'>
                      {!showEditor && 'Click the Edit icon to add description'}
                    </Typography>
                  )}
                  {showEditor === false ? (
                    <Tooltip title='show editor' placement='top'>
                      <span role='button'>
                        <Icon onClick={() => handleShowEditor(true)} icon={editFill} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title='close editor' placement='top'>
                      <span role='button'>
                        <CloseIcon onClick={() => handleShowEditor(false)} />
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div> */}
              {/* {showEditor === false ? (
                <div
                  id='task-description-admin'
                  dangerouslySetInnerHTML={getHtml(updateDescription)}
                ></div>
              ) : (
                <CkEditor
                  content={updateDescription}
                  setContent={(value) => setUpdateDescription(value)}
                />
                )} */}
              <CkEditor
                content={updateDescription}
                setContent={(value) => setUpdateDescription(value)}
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                {imagesDetail && (
                  <>
                    {imagesDetail.length > 0 && (
                      <Typography className="mb-2" fontWeight="bold">
                        Task Files
                      </Typography>
                    )}
                  </>
                )}
                <div className="row">
                  {imagesDetail &&
                    imagesDetail.map((x, index) => {
                      return (
                        <div key={index} className="col-3 mt-2">
                          <CancelIcon
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: 40,
                              bottom: 45,
                            }}
                            tabIndex="0"
                            role="button"
                            onClick={() => removeImage(x, index)}
                            onKeyDown={() => removeImage(x, index)}
                          />

                          <a
                            href={baseUri + x.task_image}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {String(x.file_type) === "0" ? (
                              <img
                                src={baseUri + x.task_image}
                                alt="task images"
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: 5,
                                  cursor: "pointer",
                                  backgroundSize: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <Tooltip
                                  arrow
                                  title={x.file_name ? x.file_name : ""}
                                >
                                  <Icon
                                    width="50px"
                                    height="50px"
                                    icon="akar-icons:file"
                                    // icon="bxs:file-pdf"
                                  />

                                  {/* <Typography>{truncateText(x.task_image, 15)}</Typography> */}
                                </Tooltip>
                              </div>
                            )}
                          </a>
                        </div>
                      );
                    })}
                  <div className="col-3 mt-2">
                    <input
                      // accept='image/png,image/jpeg,image/jpg,application/pdf,text/pdf'
                      accept={FILE_ACCEPT_TYPE_STRING}
                      hidden
                      id="contained-button-file"
                      type="file"
                      onChange={fileChangedHandler}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ display: "flex" }}
                    >
                      <Tooltip title="Attach file">
                        <IconButton
                          color="inherit"
                          aria-label="upload picture"
                          component="span"
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #c5c5c5",
                            borderRadius: 5,
                            width: 45,
                            height: 50,
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                </div>
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon icon={editFill} />}
                  // className='mt-2'
                  loading={isUpdating}
                >
                  Update
                </LoadingButton>
              </div>
            </form>
          </Stack>
        </Scrollbar>

        {/* confirmation dialog */}
        <Dialog
          open={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete file</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleRemoveImage()}>Yes</Button>
            <Button
              onClick={() => setIsImageDialogOpen(false)}
              //    autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
}
