import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
  FormControl,
  TextField,
  Tooltip,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  ListItem,
  List,
  ListItemIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ScrollToBottom from 'react-scroll-to-bottom';
import moment from 'moment';

//
import Scrollbar from '../../../../components/Scrollbar';

import {
  uploadTaskFiles,
  removeTaskFiles,
  detailTaskFiles,
  addTaskComments,
  taskComments,
  EditComment,
  deleteComment,
} from '../../../../DAL/Project/Project';
import { baseUri } from '../../../../config/config';
import ConfirmDeleteComment from 'src/components/Confirmation/ConfirmDeleteComment';
import CommentPopover from 'src/components/Popovers/CommentPopover';
import { timelineDotClasses } from '@mui/lab';
import CkEditor from 'src/components/editor/CKEditor';
import { set_blank_target } from 'src/utils/DomUtils';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const PRIORITY_OPTIONS = [
  { label: 'Critical', value: 'critical', color: 'error', filterValue: '0' },
  { label: 'High', value: 'high', color: 'primary', filterValue: '1' },
  { label: 'Medium', value: 'medium', color: 'success', filterValue: '2' },
  { label: 'Low', value: 'low', color: 'info', filterValue: '3' },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  {
    label: 'Not Started',
    value: 'not_started',
    color: 'success',
    filterValue: '0',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'secondary',
    filterValue: '1',
  },
  {
    label: 'In Review',
    value: 'in_review',
    color: 'warning',
    filterValue: '2',
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'primary',
    filterValue: '3',
  },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

TemplateTaskSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

function RenderContent(data) {
  /* shift menu button here */
  const classes = useStyles();
  const getActualTime = (date) => {
    const serverDate = new Date(date); // todays date
    const difference = serverDate.getTimezoneOffset(); // another date
    const time = moment(moment(serverDate).subtract(difference, 'm'));
    return time;
  };
  const title = (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle2'>{data.user_name}</Typography>

        <Typography
          alignSelf='center'
          component='span'
          variant='body2'
          sx={{ color: 'text.secondary', fontSize: 10 }}
        >
          {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 14, height: 14 }} /> */}
          {data && moment(getActualTime(data.created_date)).fromNow()}
          {/* {data && moment(data.created_date).fromNow()} */}
          {/* {data && moment(data.created_date).format('DD, MMM YYYY hh:mm:sa')} */}
        </Typography>
      </div>
      <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        {/* &nbsp; {noCase(notification.message_describtion)} */}
        {data && data.comment}
        {/* {isCreator(data) && (
          <Tooltip title='edit comment' placement='top'>
            <span onClick={() => console.log('commm')} className={classes.edit}>
              <RateReviewIcon className={classes.editCommentIcon} />
            </span>
          </Tooltip>
        )} */}
      </Typography>
    </>
  );

  return {
    avatar: <Avatar alt='title' src={baseUri + data.user_image} />,
    title,
    // detail: notification
  };
}
function NotificationItem({
  commentObj,
  setEditComment,
  targetRef,
  commentObjIndex,
  setComment,
  commentsData,
  setCommentData,
  setCommentUpdate,
  getCommentsList,
  isTextFieldFocused,
  setIsTextFieldFocused,
  radioStatus,
  setRadioStatus,
  setSelectedCommentStatus,
  selectedCommentStatus,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { avatar, title } = RenderContent(commentObj);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const buttonRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(commentObj, 'comment')
  const handleEditComment = (commentObj) => {
    console.log(commentObj, 'commentObj');
    setSelectedCommentStatus(commentObj.status);
    console.log(commentObj, commentObjIndex, 'obj');
    setComment(commentObj.comment);
    setEditComment(commentObj);
    setCommentUpdate(true);
    setAnchorEl(null);
    // targetRef.current.focus()
  };
  const handlePopover = () => {
    setIsPopoverOpen(true);
    setAnchorEl(buttonRef.current);
  };
  const handleDeleteDialogOpen = (state) => {
    setIsDeleteDialogOpen(state);
  };
  const handleDeleteComment = async () => {
    setIsDeleteDialogOpen(true);
    console.log(commentsData, 'obj');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('comment_id', commentObj.id);
    /* delete locally */
    let data = [...commentsData];
    commentsData.map((comment, index) => {
      if (comment.id === commentObj.id) {
        console.log(comment, 'to be deleted');
        data.splice(index, 1);
      }
    });
    setCommentData(data);
    console.log(data, 'after-del');
    setIsDeleteDialogOpen(false);
    /* delete through API */
    // setCommentData
    let response = await deleteComment(formData);
    console.log(response, 'response-delete');
    if (response.code === 200) {
      // enqueueSnackbar('Comment edited', { variant: 'success' })
    } else {
      getCommentsList();
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };
  const isCreator = (commentObj) => {
    let userId = localStorage.getItem('user_id');
    const { action_perform_by } = commentObj;
    let creator = false;
    if (action_perform_by === userId) {
      creator = true;
    }
    return creator;
  };

  return (
    <Stack direction='row' alignItems='flex-start'>
      <ListItem
        disableGutters
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          // bgcolor: '#eaeaea'
        }}
      >
        {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
        <ListItemText primary={title} />
      </ListItem>

      <div className='pt-1'>
        {/* <IconButton
          id='targetIconBtn'
          ref={buttonRef}
          onClick={() => handlePopover()}
          style={{ float: 'right' }}
        >
          <MoreVertIcon /> */}
        <CommentPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isCreator={isCreator}
          handleEditComment={handleEditComment}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          commentObj={commentObj}
        />
        {/* </IconButton> */}
        {/* <Menu
          key='left'
          direction='right'
          align='center'
          position='anchor'
          viewScroll='auto'
          menuButton={
            <IconButton style={{ float: 'right' }}>
              <MoreVertIcon />
            </IconButton>
          }
        >
          <MenuItem
            disabled={isCreator(commentObj) ? false : true}
            onClick={() => handleEditComment(commentObj)}
            // onClick={() => handleOpenDialog(task, i)}
          >
            <ListItemIcon>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary='Edit' primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
            <ListItemText primary='Delete' primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </Menu> */}
      </div>
      <ConfirmDeleteComment
        handleDelete={handleDeleteComment}
        commentObj={commentObj}
        commentsData={commentsData}
        setCommentData={setCommentData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        setAnchorEl={setAnchorEl}
      />
    </Stack>
  );
}

export default function TemplateTaskSidebar({
  isOpenEditTask,
  onResetEditTask,
  onOpenEditTask,
  onCloseEditTask,
  isTaskData,
  openUpdate,
  openEdit,
  SubmitTask,
  targetTask,
  setTargetTask,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = React.useState('');
  const [taskImages, setTaskImages] = React.useState();
  const [imagesDetail, setImagesDetail] = React.useState([]);
  const [updateName, setUpdateName] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [status, setStatus] = React.useState('0');
  const [selectedCommentStatus, setSelectedCommentStatus] = React.useState('0');
  const [commentData, setCommentData] = React.useState([]);
  const [updateDescription, setUpdateDescription] = React.useState('');
  const addCommentRef = React.useRef();
  const [isCommentUpdating, setIsCommentUpdating] = useState(false);
  const [editComment, setEditComment] = useState();
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const handleSubmit = () => {
    SubmitTask(updateName, updateDescription, isTaskData);
  };
  const handleShowEditor = (value) => {
    setShowEditor(value);
  };
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  useEffect(() => {
    setUpdateName(isTaskData.task);
    setUpdateDescription(isTaskData.description);
    if (isTaskData.description !== '' || isTaskData.description !== null) {
      setTimeout(() => {
        //to open link in new tab
        applyBlankTargetOnAnchorTag('template-task-description');
      }, 200);
    }
  }, [isTaskData]);
  useEffect(() => {
    if (isOpenEditTask) {
      setIsCommentUpdating(false);
      setComment('');
      setIsTextFieldFocused(false);
      handleShowEditor(false);
    }
  }, [isOpenEditTask]);
  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenEditTask}
        onClose={onCloseEditTask}
        PaperProps={{
          sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={onCloseEditTask}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div>
              <textarea
                style={{
                  border: 'hidden',
                  backgroundColor: 'rgb(0 42 21 / 8%) ',
                  fontSize: 12,
                  minHeight: '15vh',
                }}
                // rows="3"
                className='form-control text-area-task'
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
                id='exampleFormControlInput1'
              />
            </div>
            <div>
              <div className='task-description-editor-parent ck-editor-div'>
                <div className='row justify-content-end align-items-center'>
                  <div className='col d-flex flex-col justify-content-end my-1'>
                    {showEditor === false ? (
                      <Tooltip title='show editor' placement='top'>
                        <span role='button'>
                          <Icon onClick={() => handleShowEditor(true)} icon={editFill} />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title='close editor' placement='top'>
                        <span role='button'>
                          <CloseIcon onClick={() => handleShowEditor(false)} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
                {showEditor === false ? (
                  <div
                    id='template-task-description'
                    dangerouslySetInnerHTML={getHtml(updateDescription)}
                  ></div>
                ) : (
                  <CkEditor
                    content={updateDescription}
                    setContent={(value) => setUpdateDescription(value)}
                  />
                )}
              </div>
              {/* <textarea
                rows="4"
                style={{
                  border: "hidden",
                  backgroundColor: "rgb(0 42 21 / 8%) ",
                  fontSize: 12,
                }}
                placeholder="Add Some Description"
                className="form-control text-area-task"
                value={updateDescription}
                onChange={(e) => setUpdateDescription(e.target.value)}
                id="exampleFormControlInput1"
              /> */}
            </div>

            <div className='d-flex'>
              <Button
                className='ml-auto'
                size='small'
                type='submit'
                color='primary'
                variant='outlined'
                onClick={handleSubmit}
                startIcon={<Icon icon={editFill} />}
                fullWidth
              >
                Update
              </Button>
            </div>
          </Stack>
        </Scrollbar>

        {/* confirmation dialog */}
      </Drawer>
    </>
  );
}
{
  /* <div className='comments-outerContainer'>
  <div className='comments-container'>
   
    <ScrollToBottom className='messages'>
      <Scrollbar>
        {commentData.map((commentObj, index) => (
          <NotificationItem
            getCommentsList={getCommentsList}
            commentsData={commentData}
            setCommentData={setCommentData}
            targetRef={addCommentRef}
            setEditComment={setEditComment}
            commentStatus={status}
            commentObj={commentObj}
            setComment={setComment}
            commentObjIndex={index}
            setCommentUpdate={setIsCommentUpdating}
            isTextFieldFocused={isTextFieldFocused}
            setIsTextFieldFocused={setIsTextFieldFocused}
            setSelectedCommentStatus={setSelectedCommentStatus}
            selectedCommentStatus={selectedCommentStatus}
        
          />
        ))}
      </Scrollbar>
    </ScrollToBottom>

    <form className={`form ${classes.formMargin}`} onSubmit={handleAddComments}>
      <div className='comments-input'>
        <textarea
                  ref={addCommentRef}
       
                  style={{ width: '70%' }}
                  type='text'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className='form-control comments-field'
                  placeholder='Enter comments here'
                />
        <TextField
     
          inputRef={addCommentRef}
          type='text'

          value={comment}
          fullWidth
          onChange={(e) => setComment(e.target.value)}
          autoComplete='new-password'
          variant='outlined'
          label='Comment'
          name='userComment'
          sx={{ marginRight: '.5rem' }}
        />
        {!isCommentUpdating ? (
    
          <Button
            className={classes.noShadow}
            variant='contained'
            type='submit'
            style={{ width: '30%' }}
          >
            Send
          </Button>
        ) : (
          <Button
            className={classes.noShadow}
            variant='contained'
            type='submit'
            style={{ width: '30%' }}
          >
            Update
          </Button>
        )}
      </div>
      <Stack direction='row' justifyContent='space-between'>
        <FormControl component='fieldset'>
          {!isCommentUpdating ? (
            <RadioGroup
              row
              aria-label='gender'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              name='row-radio-buttons-group'
            >
              <FormControlLabel
                value='1'
                control={<Radio />}
                label='Public'
                style={{ marginBottom: 0, fontSize: 10 }}
              />
              <FormControlLabel
                value='0'
                control={<Radio />}
                label='Private'
                style={{ marginBottom: 0, fontSize: 10 }}
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              row
              aria-label='gender'
              value={selectedCommentStatus}
              onChange={(e) => setSelectedCommentStatus(e.target.value)}
              name='row-radio-buttons-group'
            >
              <FormControlLabel
                value='1'
                control={<Radio />}
                label='Public'
                style={{ marginBottom: 0, fontSize: 10 }}
              />
              <FormControlLabel
                value='0'
                control={<Radio />}
                label='Private'
                style={{ marginBottom: 0, fontSize: 10 }}
              />
            </RadioGroup>
          )}
        </FormControl>
        <Stack direction='row'>
          {isCommentUpdating && (
            <span onClick={handleCancelUpdate} className={classes.cancelBtn}>
              cancel
            </span>
          )}
        </Stack>
      </Stack>
    </form>
  </div>
</div> */
}
