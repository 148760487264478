import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Container,
  TextField,
  Grid,
  IconButton,
  MenuItem,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addTeam, activeDepartmentList } from "../../DAL/Team/Team";
import { departmentList } from "../../DAL/Department/Department";

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AddTeam(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [role, setRole] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = useState(true);
  const [allowStatus, setAllowStatus] = useState(true);
  const [allowChangeTaskOrder, setAllowChangeTaskOrder] = useState(false);
  const [allowedDevTeam, setallowedDevTeam] = useState(false);
  const [allowViewSales, setAllowViewSales] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDepartment(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(value);
  };

  const handleSubmit = async (e) => {
    let allowProject = "0";
    if (allowStatus === true) {
      allowProject = "1";
    }
    let allow_view_sales = "0";
    if (allowViewSales === true) {
      allow_view_sales = "1";
    }

    let allow_dev_team = "0";
    if (allowedDevTeam === true) {
      allow_dev_team = "1";
    }

    let allowChangeOrder = "0";
    if (allowChangeTaskOrder === true) {
      allowChangeOrder = "1";
    }
    e.preventDefault();

    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    // setIswaiting(true);
    let team_email = email.trim();
    team_email = team_email.toLowerCase();
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", team_email);
    formData.append("allow_check_comments", allow_dev_team);
    // formData.append('email', email);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("status", _status);
    formData.append("allow_project", allowProject);
    formData.append("allow_reorder_task", allowChangeOrder);
    formData.append("sales_option", allow_view_sales);
    const arr = department.map((x, i) => {
      formData.append(`team_department[${i}]`, x.id);
      return x;
    });
    // formData.append('team_department', department);
    if (Image) {
      formData.append("team_image", Image);
    }
    console.log(...formData, "create-team-request");
    const result = await addTeam(formData);
    if (result.code === 200) {
      setIswaiting(false);
      navigate(-1);
      enqueueSnackbar("Team added", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIswaiting(false);
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setimage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };

  const fetchDepartment = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeDepartmentList(formData);
    if (result.code === 200) {
      setDepartmentData(result.department_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <Container maxWidth="lg">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
          Add Team
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="name"
                label="First Name"
                type="name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id="name"
                label="Last Name"
                type="name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">
                  Select Department
                </InputLabel>
                <Select
                  required
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={department}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Select Department"
                    />
                  }
                  renderValue={(selected) => (
                    <>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.department_name} />
                      ))}
                    </>
                  )}
                >
                  {departmentData.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Department</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={department}
                  label="Department"
                  onChange={(e, value) => setDepartment(value.props.value)}
                >
                  {departmentData.map((x, i) => (
                    <MenuItem key={i} value={x.id}>
                      {x.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                required
                value={role}
                onChange={(e) => setRole(e.target.value)}
                id="role"
                label="Role"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className="d-flex align-items-center justify-content-end"
            >
              Allow create project :
              <Switch
                checked={allowStatus}
                onChange={(e) => setAllowStatus(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            <Grid item xs={6}>
              Drag-Drop/Section:
              <Switch
                checked={allowChangeTaskOrder}
                onChange={(e) => setAllowChangeTaskOrder(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            {localStorage.getItem("allowViewSales") === "1" && (
              <Grid
                item
                xs={6}
                className="d-flex align-items-center justify-content-end"
              >
                Allow View Sales:
                <Switch
                  checked={allowViewSales}
                  onChange={(e) => setAllowViewSales(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              Dev Team:
              <Switch
                checked={allowedDevTeam}
                onChange={(e) => setallowedDevTeam(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            {/* <Grid item xs={6}>
              Allow change task order :
              <Switch
                checked={allowChangeTaskOrder}
                onChange={(e) => setAllowChangeTaskOrder(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid> */}
            <Grid item xs={12}>
              {file ? (
                <>
                  {" "}
                  <img
                    className={classes.img}
                    src={file}
                    alt="team member"
                  />{" "}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/png,image/jpeg,image/jpg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label
                htmlFor="contained-button-file"
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  component="span"
                  variant="outlined"
                  color="primary"
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" type="submit">
                Add Team
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default AddTeam;
