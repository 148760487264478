import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { paid_status_color_and_value } from 'src/constants';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function ChangeSectionTypeMenu({
  targetRef,
  isOpen,
  onClose,
  handleOpenDialog,
  targetRow,
}) {
  const handleClick = (value) => {
    //
    handleOpenDialog(value);
  };
  const getFilteredItems = () => {
    if (targetRow) {
      let filtered = [];
      paid_status_color_and_value.map((item) => {
        if (String(targetRow.default_status) !== String(item.value)) {
          filtered.push(item);
        }
      });
      return filtered;
    }
    return paid_status_color_and_value;
  };
  return (
    <Menu
      open={isOpen}
      anchorEl={targetRef.current}
      onClose={() => onClose()}
      PaperProps={{
        sx: { marginLeft: '-0.5rem', marginTop: '1rem', width: 150 },
      }}
      //   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      //   transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      {getFilteredItems().map((item, index) => (
        <MenuItem
          key={index}
          // component={RouterLink}
          onClick={() => handleClick(item.value)}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemText
            style={{ textAlign: 'left' }}
            primary={item.label}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      ))}
      {/* <MenuItem
        // component={RouterLink}
        onClick={() => handleClick()}
        sx={{ color: 'text.secondary' }}
      >
        <ListItemText
          style={{ textAlign: 'left' }}
          primary='Default paid'
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>

      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleClick()}>
        <ListItemText
          style={{ textAlign: 'left' }}
          primary='Unpaid'
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleClick()}>
        <ListItemText
          style={{ textAlign: 'left' }}
          primary='Paid'
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleClick()}>
        <ListItemText
          style={{ textAlign: 'left' }}
          primary='Paid'
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem> */}
    </Menu>
  );
}
