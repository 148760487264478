import React, { useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography, IconButton, Tooltip, Popover, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
import { baseUri } from '../../../config/config';
// components
import MenuPopover from '../../../components/MenuPopover';
import { Icon } from '@iconify/react';
function FilterByProduct(props) {
  const anchorRef = useRef(null);
  const [openProductSelect, setOpenProductSelect] = useState(false);
  const handleClose = () => {
    setOpenProductSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenProductSelect(true);
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');

      return result;
    }
    return string;
  };
  const handleAdd = (value) => {
    props.onSelectProduct(value, props.onId);
  };
  const handleDelete = (value) => {
    props.onUnselectProduct(value, props.onId);
  };
  const getSelectedNames = () => {
    let selected = props.productList.filter((product) => product.isSelected);

    if (selected.length > 0) {
      let text = '';
      selected.map((product) => {
        text = `${text} ${product.label}`;
      });
      return truncateText(text, 14);
    }
    return '';
  };
  const getTooltipText = () => {
    let selected = props.productList.filter((product) => product.isSelected);
    if (selected.length > 0) {
      let text = '';
      selected.map((product, index) => {
        let show_comma = selected.length === 1 ? false : true;
        let is_last = index === selected.length - 1 ? true : false;
        let comma_visible = false;
        if (!is_last && show_comma) {
          comma_visible = true;
        }
        text = `${text} ${product.label} ${comma_visible ? ',' : ''}`;
      });
      return text;
    }
    return 'No product selected';
  };
  const getSelectedProduct = (productList) => {
    console.log('Get selected product', productList);
    return productList;
  };

  return (
    <>
      <Tooltip arrow title={getTooltipText()}>
        <Button
          ref={anchorRef}
          color='inherit'
          disableRipple
          onClick={() => handleOpenMemberSelect()}
          sx={{ backgroundColor: '#f6f7f8' }}
          endIcon={
            <Icon color='#00ab55' icon={openProductSelect ? chevronUpFill : chevronDownFill} />
          }
        >
          <Typography component='span' sx={{ color: '#00ab55', fontWeight: '600' }}>
            Product&nbsp; : {getSelectedNames()}
          </Typography>
        </Button>
      </Tooltip>
      <MenuPopover
        sx={{ maxHeight: '14rem', overflowY: 'auto', overflowX: 'hidden' }}
        open={openProductSelect}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <List>
          {getSelectedProduct(props.productList).length > 0 ? (
            getSelectedProduct(props.productList).map((project, index) => (
              <React.Fragment key={index}>
                {project.isSelected === false ? (
                  <ListItem
                    onClick={() => handleAdd(project)}
                    style={{ cursor: 'pointer' }}
                    className='list-hov'
                  >
                    <ListItemText>{project.label}</ListItemText>
                  </ListItem>
                ) : (
                  <ListItem
                    style={{
                      borderLeft: '2px solid #00AB55',
                      borderRadius: 3,
                      backgroundColor: 'rgba(0, 171, 85, 0.08)',
                      margin: 2,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDelete(project)}
                  >
                    <ListItemText>{project.label}</ListItemText>
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography className='p-1'>No products yet</Typography>
          )}
        </List>
      </MenuPopover>
    </>
  );
}

export default FilterByProduct;
