import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
  Tooltip,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
//
import Scrollbar from '../../Scrollbar';
import ColorManyPicker from '../../ColorManyPicker';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const PRIORITY_OPTIONS = [
  { label: 'Critical', value: 'critical', color: 'error', filterValue: '0' },
  { label: 'High', value: 'high', color: 'primary', filterValue: '1' },
  { label: 'Medium', value: 'medium', color: 'success', filterValue: '2' },
  { label: 'Low', value: 'low', color: 'info', filterValue: '3' },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  { label: 'Pending', value: 'not_started', color: 'warning', filterValue: '0' },
  { label: 'In Progress', value: 'in_progress', color: 'secondary', filterValue: '1' },
  // { label: 'In Review', value: 'in_review', color: 'warning', filterValue: '2' },
  { label: 'Completed', value: 'completed', color: 'primary', filterValue: '3' },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

ProjectFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

export default function ProjectFilterSidebar({
  isOpenFilter,
  isFilterChip,
  isFilterData,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  onFilterData,
  onChangeFilterData,
  // formik
}) {
  // const { values, getFieldProps, handleChange } = formik;
  // const [filterData, setFilterData] = useState({
  //   critical: false,
  //   high: false,
  //   medium: false,
  //   low: false,
  //   not_started: false,
  //   in_progress: false,
  //   in_review: false,
  //   completed: false,
  //   cancelled: false
  // });
  const [filtered, setFiltered] = useState([]);
  const getChecked = (value) => {};
  const navigate = useNavigate();
  const { id } = useParams();
  const params = useParams();
  const [projectDetail, setProjectDetail] = useState({});

  const handleChangeStatus = (value, key) => {
    // setFilterData({
    //   ...filterData,
    //   [key]: value
    // });
    onChangeFilterData(value, key);
  };

  const handleSubmitFilter = () => {
    const arr = [];
    Object.keys(isFilterData).forEach((key) => {
      arr.push({ key, value: isFilterData[key] });
    });
    // setFiltered(arr);
    onFilterData(isFilterData, arr);
    onCloseFilter();
  };
  const getFilterTooltipLabel = () => {
    let filters = [];
    if (!isFilterChip) {
      return (
        <React.Fragment>
          <Typography>No filters applied</Typography>
        </React.Fragment>
      );
    }
    isFilterChip.map((item) => {
      if (item.value === true) {
        filters.push(item);
      }
    });
    if (filters.length > 0) {
      return (
        <React.Fragment>
          {filters.map((x, index) => (
            <Typography key={index}>{getLabel(x.key)}</Typography>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Typography>No filters applied</Typography>
      </React.Fragment>
    );
  };
  const getLimited = (items) => {
    let limited = [];
    items.map((item) => {
      if (item.value === true) {
        if (limited.length < 2) {
          limited.push(item);
        }
      }
    });
    return limited;
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        {getLimited(isFilterChip).map((x, index) => (
          <React.Fragment key={index}>
            {x.value === true && (
              <Chip
                label={getLabel(x.key)}
                color={getChipColor(x.key)}
                style={{ height: 20, marginTop: 8, marginLeft: 1, marginRight: 3 }}
              />
            )}
          </React.Fragment>
        ))}
        <Tooltip disableFocusListener title={getFilterTooltipLabel()}>
          <Button
            disableRipple
            color='inherit'
            endIcon={<Icon icon={roundFilterList} />}
            onClick={onOpenFilter}
            sx={{ backgroundColor: '#f6f7f8', fontWeight: '600' }}
          >
            FILTERS&nbsp;
          </Button>
        </Tooltip>
      </div>

      {/* <FormikProvider value={formik}> */}
      {/* <Form autoComplete="off" noValidate> */}
      <Drawer
        anchor='right'
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Box sx={{ pl: '0' }}>
              <Button
                onClick={() =>
                  navigate(`/projects/verified-tasks`, {
                    state: { project_id: id, project_detail: projectDetail },
                  })
                }
                style={{ paddingInline: '1rem', marginLeft: '20px' }}
                startIcon={<CheckCircleIcon />}
              >
                View Verified Tasks
              </Button>
            </Box>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                Priority
              </Typography>
              <FormGroup>
                {PRIORITY_OPTIONS.map((item, index) => (
                  <FormControlLabel
                    checked={isFilterData[item.value]}
                    key={index}
                    control={
                      <Checkbox
                        // {...getFieldProps('status')}
                        // value={true}
                        // checked={}
                        onChange={(e) => handleChangeStatus(e.target.checked, item.value)}
                      />
                    }
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                Status
              </Typography>
              <FormGroup>
                {STATUS_OPTIONS.map((item, index) => (
                  <FormControlLabel
                    checked={isFilterData[item.value]}
                    key={index}
                    control={
                      <Checkbox
                        // {...getFieldProps('priority')}
                        // value={item.value}
                        // checked={getChecked(item.value)}
                        onChange={(e) => handleChangeStatus(e.target.checked, item.value)}
                      />
                    }
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ pl: 3, pr: 3, pt: 2 }}>
          <Button
            fullWidth
            size='large'
            type='submit'
            color='inherit'
            variant='outlined'
            onClick={handleSubmitFilter}
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
        </Box>

        <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 1 }}>
          <Button
            fullWidth
            size='large'
            type='submit'
            color='inherit'
            variant='outlined'
            onClick={onResetFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
