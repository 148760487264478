import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Menu,
  Input,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Switch,
  Stack,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import Page from "src/components/Page";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filter, uniq } from "lodash";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  allSalesListing,
  changeSalePaidStatus,
  activeSaleSectionListing,
  moveSaleToSection,
  deleteSaleByManager,
  allowedSalesActiveTeam,
  uploadSaleFile,
  activeProductListing,
  updateSaleAssociate,
} from "src/DAL/Project/Project";
import {
  changeSectionOrder,
  importDay1Sales,
  importDay2Sales,
  importDay3Sales,
  importDay4Sales,
  importDay5Sales,
  importDay6Sales,
  updateOptionalSaleAssociates,
  updateSaleStatus,
  updateSaleTargetDate,
  updateSaleType,
} from "src/DAL/Sales/Sales";
import { useState } from "react";
import { baseUri } from "src/config/config";
import StyledSearch from "src/components/search/StyledSearch";
import moment from "moment";
import { noTask } from "src/assets";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "src/pages/Loader";
import EditSaleSidebar from "./components/EditSaleSidebar";
import FilterByProduct from "./components/FilterByProduct";
import PaidStatusPopover from "./components/PaidStatusPopover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreMenuPopover from "./components/MoreMenuPopover";
import SaleListAccordion from "./components/SaleListAccordion";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import AnswerSidebar from "./components/AnswerSidebar";
import AccordionMenuPopover from "./components/AccordionMenuPopover";
import { ButtonSelect } from "src/components";
import SaleSection from "./components/SaleSection";
import SaleSearch from "./components/SaleSearch";
const AllSalesListing = () => {
  const SALE_SORT_OPTIONS = [
    {
      label: "None",
      value: "none",
      onClick: (_data, _index) => onSortClick(_data, _index),
    },
    {
      label: "Due Date",
      value: "due-date",
      onClick: (_data, _index) => onSortClick(_data, _index),
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [salesList, setSalesList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [saleSortSelectedIndex, setSaleSortSelectedIndex] = useState(0);
  const [displaySectionSelectedIndex, setDisplaySectionSelectedIndex] =
    useState(0);
  const [mySalesOnly, setMySalesOnly] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [importType, setImportType] = useState("general");
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, seIsSidebarOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [isAnswerSidebarOpen, setIsAnswerSidebarOpen] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [associateList, setAssociateList] = useState([]);
  const [activeSectionList, setActiveSectionList] = useState([]);
  const [activeProductList, setActiveProductList] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [accordionMenuAnchorEl, setAccordionMenuAnchorEl] = useState(null);
  const [isAccordionMenuOpen, setIsAccordionMenuOpen] = useState(false);
  const [delSaleDialog, setDelSaleDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [saleFileData, setSaleFileData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [isMoveUpButtonDisabled, setIsMoveUpButtonDisabled] = useState(false);
  const [isMoveDownButtonDisabled, setIsMoveDownButtonDisabled] =
    useState(false);
  const [selectedAns, setSelectedAns] = useState(undefined);
  const [saleFile, setSaleFile] = useState("");
  const [day, setDay] = useState("");
  const [product, setProduct] = useState("");
  const [section, setSection] = useState("");
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const onSortClick = (_option, _index) => {
    console.log(_option);
    setSaleSortSelectedIndex(Number(_index));
  };
  const prevSections = useRef([]);
  const prevProducts = useRef([]);
  const isSelected = (id) => {
    if (prevProducts.current) {
      let selected = false;
      prevProducts.current.forEach((product) => {
        if (String(product.product_id) === String(id)) {
          if (product.isSelected === true) {
            selected = true;
          }
        }
      });
      return selected;
    }
  };

  const getCommaSeparated = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSelectDisplaySection = (_option, _index) => {
    setDisplaySectionSelectedIndex(_index);
    console.log(_option, _index, "onclick");
    navigate("/sales/all", {
      state: {
        display_section_id: _option.value,
      },
    });
  };
  const getAllSalesListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await allSalesListing(formData);
    if (result.code === 200) {
      console.log(result, "all-sales");
      let section_list = result.sections_list.map((section, i) => {
        return {
          ...section,
          ["expanded"]: i === 0,
          ["section_sales"]: section.section_sales.map((item) => {
            return {
              ...item,
              ["amount"]: getCommaSeparated(Number(item.amount)),
            };
          }),
        };
      });
      console.log(section_list, "modif");
      setSectionList(section_list);
      if (result.all_products) {
        setProductOptions(
          result.all_products.map((item) => {
            return {
              product_id: item.product_id,
              label: item.product_name,
              isSelected: isSelected(item.product_id),
            };
          })
        );
      }
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getActiveSectionList = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeSaleSectionListing(formData);
    if (result.code === 200) {
      console.log(result, "active-section-list");
      //   let filtered = result.products_list.filter((product) => String(product.status) !== '0');
      setActiveSectionList(result.active_sale_section);
    } else {
      console.log(result, "active-section-list");
    }
  };
  const handleChangeSearchValue = (value) => {
    setSearchValue(value);
  };

  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === "-") {
        date_safari = date_safari.replace("-", "/");
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(
        moment(date_safari).subtract(difference_safari, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(
        moment(serverDate).subtract(difference, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");

      return setTime;
    }
  };

  const handleDeleteSale = async () => {
    console.log(selectedRow, "del");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", selectedRow.id);
    console.log(...formData, "del-sale-req");
    setIsDeleting(true);
    const result = await deleteSaleByManager(formData);
    if (result.code === 200) {
      setIsDeleting(false);
      enqueueSnackbar("Sale deleted", { variant: "success" });
      setDelSaleDialog(false);
      setIsMenuOpen(false);
      getAllSalesListing();
    } else {
      setIsDeleting(false);
      setDelSaleDialog(false);
      setIsMenuOpen(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const closeSidebar = () => {
    seIsSidebarOpen(false);
  };
  const openSidebar = (data) => {
    setSelectedItem(data);
    seIsSidebarOpen(true);
  };

  const handleSelectProduct = (value, data) => {
    // console.log(value, 'val');
    const { product_id } = value;
    productOptions.map((project, index) => {
      if (String(project.product_id) === String(product_id)) {
        let options = [...productOptions];
        options[index] = { ...project, ["isSelected"]: true };

        setProductOptions(options);
        prevProducts.current = options;
      }
    });
  };
  const handleUnselectProduct = (value, data) => {
    const { product_id } = value;
    productOptions.map((project, index) => {
      if (String(project.product_id) === String(product_id)) {
        let options = [...productOptions];
        options[index] = { ...project, ["isSelected"]: false };

        setProductOptions(options);
        prevProducts.current = options;
      }
    });
  };
  const handleOpenMenu = (e, sale_item) => {
    console.log(sale_item, "target-task");
    setMenuAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
    setSelectedRow(sale_item);
  };
  const handleOpenAccordionMenu = (payload) => {
    const { event, section } = payload;
    console.log(section, "target-section");
    setAccordionMenuAnchorEl(event.currentTarget);
    setIsAccordionMenuOpen(true);
    setSelectedSection(section);
    let is_first = false;
    let is_last = false;
    sectionList.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === 0) {
          is_first = true;
        }
      }
    });
    if (is_first) {
      setIsMoveUpButtonDisabled(true);
    }
    if (!is_first) {
      setIsMoveUpButtonDisabled(false);
    }
    sectionList.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === sectionList.length - 1) {
          is_last = true;
        }
      }
    });
    if (is_last) {
      setIsMoveDownButtonDisabled(true);
    }
    if (!is_last) {
      setIsMoveDownButtonDisabled(false);
    }
  };
  const handleMoveSection = async (data) => {
    console.log(data, "target-section");
    console.log(selectedRow, "target-row");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", selectedRow.id);
    formData.append("section_id", data.section_id);
    formData.append("client_email", selectedRow.client_email);
    console.log(...formData, "move-to-section-req");
    const result = await moveSaleToSection(formData);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      enqueueSnackbar("Sale moved to section", { variant: "success" });
      getAllSalesListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeSectionOrder = async (data, type) => {
    console.log(selectedSection, "target-row");
    let order = undefined;
    if (type === 1) {
      order = Number(selectedSection.section_order) - 1;
    }
    if (type === 2) {
      order = Number(selectedSection.section_order) + 1;
    }
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("section_order", order);
    formData.append("sale_section_id", selectedSection.section_id);
    console.log(...formData, "move-section-req");
    setIsAccordionMenuOpen(false);
    const result = await changeSectionOrder(formData);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      enqueueSnackbar(`Section moved ${type === 1 ? "up" : "down"}`, {
        variant: "success",
      });
      getAllSalesListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getSections = () => {
    return sectionList;
    // return saleSections;
  };
  const getDisplaySection = () => {
    let section = sectionList[displaySectionSelectedIndex - 1];
    if (!section) {
      return undefined;
    }
    return section;
  };

  const goToAddSale = () => {
    navigate("/add-sale");
  };

  const getActiveProductList = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeProductListing(formData);
    if (result.code === 200) {
      console.log(result, "product-list");
      let filtered = result.products_list.filter(
        (product) => String(product.status) !== "0"
      );
      setActiveProductList(filtered);
    } else {
      console.log(result, "product-list");
    }
  };
  const getActiveAssociateList = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await allowedSalesActiveTeam(formData);
    if (result.code === 200) {
      console.log(result, "associate-list");
      let associates = result.manager_team_list.map((team) => {
        return { ...team, isSelected: false };
      });
      setAssociateList(associates);
    } else {
      console.log(result, "associate-list");
    }
  };
  const handleFileChange = (e) => {
    console.log(e.target.files, "files--");
    console.log(e.target.files.length, "files--le");
    if (e.target.files.length === 0) {
      setSaleFile("");
      setSaleFileData(null);
      return;
    }
    if (e.target.files.length !== 0) {
      if (e.target.files[0]) {
        console.log(e.target.files[0], "ff");
        if (
          String(e.target.files[0].type) === "application/vnd.ms-excel" ||
          String(e.target.files[0].type) === "text/csv"
        ) {
          setSaleFile(e.target.value);
          setSaleFileData(e.target.files);
          return;
        } else {
          enqueueSnackbar("Please chose a CSV file", { variant: "error" });
          setSaleFile("");
          setSaleFileData(null);
        }
      }
    }
  };
  const handleCancelFile = (e) => {
    setSaleFile("");
    setSaleFileData(null);
    setIsFileDialogOpen(false);
    setProduct("");
    setSection("");
    setDay("");
  };
  const handleUploadFile = async () => {
    console.log(saleFile, "to-upload");
    console.log(saleFileData, "to-upload");
    if (saleFile === "") {
      enqueueSnackbar("No file chosen", { variant: "error" });
      return;
    }
    setIsUploadingFile(true);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("csv", saleFileData[0]);
    console.log(...formData, "sale-file-req");
    const result = await uploadSaleFile(formData);
    if (result.code === 200) {
      setIsUploadingFile(false);
      enqueueSnackbar("File uploaded", { variant: "success" });
      setIsFileDialogOpen(false);
      getAllSalesListing();
      setSaleFile("");
      setSaleFileData(null);
    } else {
      setIsUploadingFile(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (saleFile === "") {
      enqueueSnackbar("No file chosen", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("csv", saleFileData[0]);
    formData.append("section_id", section);
    formData.append("product_id", product);
    formData.append("status", importType);
    console.log(...formData, "sale-file-req");
    if (String(day) === "1") {
      setIsUploadingFile(true);
      const result = await importDay1Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (String(day) === "2") {
      setIsUploadingFile(true);
      const result = await importDay2Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (String(day) === "3") {
      setIsUploadingFile(true);
      const result = await importDay3Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (String(day) === "4") {
      setIsUploadingFile(true);
      const result = await importDay4Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (String(day) === "5") {
      setIsUploadingFile(true);
      const result = await importDay5Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    if (String(day) === "6") {
      setIsUploadingFile(true);
      const result = await importDay6Sales(formData);
      if (result.code === 200) {
        setIsUploadingFile(false);
        enqueueSnackbar("File uploaded", { variant: "success" });
        setIsFileDialogOpen(false);
        getAllSalesListing();
        setSaleFile("");
        setSaleFileData(null);
        setProduct("");
        setSection("");
        setDay("");
      } else {
        setIsUploadingFile(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const getTitle = () => {
    if (saleFileData) {
      if (saleFileData[0]) {
        return saleFileData[0].name;
      }
      return "No file chosen yet.";
    } else {
      return "No file chosen yet.";
    }
  };

  const handleCloseImport = () => {
    setImportOpen(null);
  };
  const handleClickImportOption = (_type) => {
    if (_type === "general") {
      setImportType("general");
    }
    if (_type === "facebook") {
      setImportType("facebook");
    }
    handleCloseImport();
    setIsFileDialogOpen(true);
  };
  const handleOpenImport = (e) => {
    setImportOpen(e.currentTarget);
  };
  const handleSectionAction = (action) => {
    console.log(action, "handleSectionAction");
    const { action_title, payload } = action;

    if (action_title === "open_section_menu") {
      handleOpenAccordionMenu(payload);
    }
  };

  const handleSaleStatusChange = async (payload) => {
    const { sale_item } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }
    const updated_status = payload.event.target.checked === true ? "1" : "0";
    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        console.log(sale.id, "found??");
        return { ...sale, completed_status: updated_status };
      }
      return sale;
    });
    const updated_section = {
      ...target_section,
      section_sales: updated_sales,
    };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append("completed_status", updated_status);
    const result = await updateSaleStatus(formData);
    if (result.code === 200) {
      enqueueSnackbar(
        `Sale marked as ${updated_status === "1" ? "complete" : "incomplete"}`,
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getAllSalesListing();
    }
  };
  const handleSaleTargetDateChange = async (payload) => {
    const { sale_item } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }

    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return { ...sale, sale_target_date: payload.date };
      }
      return sale;
    });
    const updated_section = {
      ...target_section,
      section_sales: updated_sales,
    };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append(
      "sale_target_date",
      moment(payload.date).format("YYYY-MM-DD")
    );
    console.log(...formData, "update-sale-date-req");
    const result = await updateSaleTargetDate(formData);
    if (result.code === 200) {
      enqueueSnackbar("Due Date Updated", { variant: "success" });
    } else {
      getAllSalesListing();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSaleType = async (payload) => {
    const { sale_item } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }

    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return { ...sale, sale_lead_types: payload.value };
      }
      return sale;
    });
    const updated_section = {
      ...target_section,
      section_sales: updated_sales,
    };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append("sale_lead_types", payload.value);
    const result = await updateSaleType(formData);
    if (result.code === 200) {
      enqueueSnackbar("Sale Type Updated", { variant: "success" });
    } else {
      getAllSalesListing();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeOptionalAssociates = async (payload) => {
    const { sale_item, member_action, member } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }
    let updated_optional_associates = [];
    const getUpdatedAssociates = (members) => {
      const users = JSON.parse(members);
      if (!users) {
        return members;
      }
      if (member_action === "pop") {
        let updated = users.filter(
          (u_id) => String(u_id) !== String(member.user_id)
        );
        updated_optional_associates = [...updated];
        return JSON.stringify(updated);
      }
      if (member_action === "push") {
        let copy = [...users];
        copy.push(member.user_id);
        updated_optional_associates = [...copy];
        return JSON.stringify(copy);
      }
      return members;
    };
    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return {
          ...sale,
          optional_associate: getUpdatedAssociates(sale.optional_associate),
        };
      }
      return sale;
    });
    const updated_section = {
      ...target_section,
      section_sales: updated_sales,
    };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    updated_optional_associates.forEach((u_id, i) => {
      formData.append(`optional_associate[${i}]`, u_id);
    });
    console.log(...formData, "opt-assoc-upd");

    const result = await updateOptionalSaleAssociates(formData);
    console.log(result, "res-opt-ast");
    if (result.code === 200) {
      enqueueSnackbar("Other Associates updated successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getAllSalesListing();
    }
  };
  const handleChangeMainAssociate = async (payload) => {
    const { sale_item, member_action, member } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }
    const { user_id, image } = member;
    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return {
          ...sale,
          associate_id: user_id,
          image: image,
        };
      }
      return sale;
    });
    const updated_section = {
      ...target_section,
      section_sales: updated_sales,
    };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append("associate_id", user_id);
    console.log(...formData, "update-associate-req");
    const result = await updateSaleAssociate(formData);
    console.log(result, "res-opt-ast");
    if (result.code === 200) {
      enqueueSnackbar("Main Associate updated successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getAllSalesListing();
    }
  };
  const handleSaleAction = (action) => {
    console.log(action, "handleSaleAction");
    const { action_title, payload } = action;

    if (!action_title || !payload) {
      return;
    }
    if (action_title === "open_sale_card_menu") {
      setSelectedRow(payload.sale_item);
      handleOpenMenu(payload.event, payload.sale_item);
    }
    if (action_title === "open_sale_card_sidebar") {
      setSelectedRow(payload.sale_item);
      openSidebar(payload.sale_item);
    }
    if (action_title === "open_answer_detail") {
      setSelectedAns(payload.answer_detail);
      setIsAnswerSidebarOpen(true);
    }
    if (action_title === "sale_status_change") {
      handleSaleStatusChange(payload);
    }
    if (action_title === "sale_target_date_change") {
      handleSaleTargetDateChange(payload);
    }
    if (action_title === "change_sale_type") {
      handleSaleType(payload);
    }
    if (action_title === "change_optional_associates") {
      handleChangeOptionalAssociates(payload);
    }
    if (action_title === "change_main_associate") {
      handleChangeMainAssociate(payload);
    }
  };

  useEffect(() => {
    getActiveAssociateList();
    getAllSalesListing();
    getActiveSectionList();
    getActiveProductList();
    // fetchSectionListing();
  }, []);

  let is_allow = localStorage.getItem("allowViewSales");
  if (!is_allow) {
    return <Navigate to="/project" />;
  }
  if (String(is_allow) === "0") {
    return <Navigate to="/project" />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <Page title="All Sales | Support Task Portal">
      <Container maxWidth="xl">
        <div className="row main-header">
          <div
            className="col-6 pl-0 align-items-center"
            // className='col-lg-4 col-md-12 col-sm-12 pl-0 align-items-center'
            style={{ display: "flex" }}
          >
            <Tooltip title="Click to View Description">
              <span role="button">
                <Typography
                  style={{ fontsize: 16, fontWeight: "bold", paddingLeft: 8 }}
                >
                  All Sales Listing
                </Typography>
              </span>
            </Tooltip>
          </div>
          <div
            className="col-6 pl-0 align-items-center justify-content-end"
            style={{ display: "flex" }}
          >
            <div className="mr-2">
              <Tooltip title="import csv file" arrow>
                <Button
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  component="span"
                  // onClick={() => setIsFileDialogOpen(true)}
                  onClick={handleOpenImport}
                  variant="contained"
                  color="primary"
                  startIcon={<Icon icon="bytesize:import" />}
                >
                  Import
                </Button>
              </Tooltip>
              <Menu
                keepMounted
                anchorEl={importOpen}
                open={Boolean(importOpen)}
                onClose={handleCloseImport}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem
                  onClick={() => handleClickImportOption("general")}
                  sx={{ typography: "body2", mt: 0.3 }}
                >
                  <Typography>Upload JOT form csv</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClickImportOption("facebook")}
                  sx={{ typography: "body2" }}
                >
                  <Typography>Upload Facebook form csv</Typography>
                </MenuItem>
              </Menu>
            </div>
            <span role="button">
              <Button
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                variant="contained"
                type="submit"
                onClick={goToAddSale}
              >
                Add Sale
              </Button>
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <div>
            <SaleSearch
              placeHolder="Search..."
              query={searchValue}
              onChange={handleChangeSearchValue}
            />
          </div>
          <div className="ml-auto d-flex">
            {/* <div className="mr-2">
              <ButtonSelect
                title="Display Section"
                options={getDisplaySectionsList()}
                selectedIndex={displaySectionSelectedIndex}
              />
            </div> */}
            <div className="mr-2">
              <ButtonSelect
                title="Sort by"
                options={SALE_SORT_OPTIONS}
                selectedIndex={saleSortSelectedIndex}
              />
            </div>
            <div className="mr-2">
              <FilterByProduct
                onSelectProduct={handleSelectProduct}
                onUnselectProduct={handleUnselectProduct}
                productList={productOptions}
              />
            </div>
            {/* <div className='ml-auto'>
              <Tooltip title='Show only my sale leads'>
                <div className='ml-auto'>
                  My Sales :
                  <Switch
                    checked={mySalesOnly}
                    onChange={(e) => setMySalesOnly(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </Tooltip>
            </div> */}
          </div>
        </div>
        {/* dev */}
        <div className="mt-3">
          <Stack spacing={2}>
          
            {sectionList.map((section) => (
              <SaleSection
                section={section}
                query={searchValue}
                key={section.section_id}
                onSaleAction={handleSaleAction}
                onSectionAction={handleSectionAction}
                associates={associateList}
                filters={{
                  selectedProducts: productOptions.filter(
                    (p) => p.isSelected === true
                  ),
                  sorting: SALE_SORT_OPTIONS[saleSortSelectedIndex],
                }}
              />
            ))}
          </Stack>
        </div>

        {/* sidebars */}
        <EditSaleSidebar
          selectedItem={selectedItem}
          isOpenEditSaleSidebar={isSidebarOpen}
          onCloseSaleSidebar={closeSidebar}
          getAllSalesListing={getAllSalesListing}
          prevSections={prevSections}
          // sections={sectionList}
          sections={getSections()}
        />
        <AnswerSidebar
          selectedAns={selectedAns}
          isOpen={isAnswerSidebarOpen}
          onClose={() => setIsAnswerSidebarOpen(false)}
        />
        {/* Popovers */}
        <MoreMenuPopover
          isOpen={isMenuOpen}
          onOpen={() => setIsMenuOpen(true)}
          onClose={() => setIsMenuOpen(false)}
          anchorEl={menuAnchorEl}
          selectedRow={selectedRow}
          // sections={sectionList}
          openDelSaleDialog={() => setDelSaleDialog(true)}
          sections={getSections()}
          handleMoveSection={handleMoveSection}
        />
        <AccordionMenuPopover
          isOpen={isAccordionMenuOpen}
          onOpen={() => setIsAccordionMenuOpen(true)}
          onClose={() => setIsAccordionMenuOpen(false)}
          anchorEl={accordionMenuAnchorEl}
          selectedSection={selectedSection}
          handleChangeSectionOrder={handleChangeSectionOrder}
          sectionList={sectionList}
          isMoveUpButtonDisabled={isMoveUpButtonDisabled}
          isMoveDownButtonDisabled={isMoveDownButtonDisabled}
        />
        {/* dialogs */}
        <Dialog
          open={delSaleDialog}
          onClose={() => setDelSaleDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Sale</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this sale?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => handleDeleteSale()}>Yes</Button> */}
            <LoadingButton
              onClick={() => handleDeleteSale()}
              loading={isDeleting}
            >
              Yes
            </LoadingButton>
            <Button onClick={() => setDelSaleDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* file upload dialog */}
        <Dialog
          open={isFileDialogOpen}
          onClose={handleCancelFile}
          aria-labelledby="file-dialog-title"
          aria-describedby="file-dialog-description"
          maxWidth="lg"
          fullWidth
          // PaperProps={{ sx: { width: { md: '40rem', sm: '30rem', xs: '25rem' } } }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="file-dialog-title">Upload CSV File</DialogTitle>
            <DialogContent>
              <DialogContentText id="file-dialog-description">
                {/* {saleFileData ? saleFileData[0].name : 'No file chosen yet.'} */}
                {getTitle()}
              </DialogContentText>
              <DialogContent
              // style={{ minWidth: '40rem' }}
              >
                <div className="row align-items-center">
                  <div className="col-md-3 mb-2 mb-md-0">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-day-select-label">Day</InputLabel>
                      <Select
                        required
                        size="medium"
                        id="day"
                        value={day}
                        MenuProps={{ sx: { maxHeight: "20rem" } }}
                        label="Day"
                        name="Day"
                        onChange={(e) => setDay(e.target.value)}
                        fullWidth
                      >
                        <MenuItem value="1">Day 1</MenuItem>
                        <MenuItem value="2">Day 2</MenuItem>
                        <MenuItem value="3">Day 3</MenuItem>
                        <MenuItem value="4">Day 4</MenuItem>
                        <MenuItem value="5">Day 5</MenuItem>
                        <MenuItem value="6">Day 6</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 mb-2 mb-md-0">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-section-select-label">
                        Section
                      </InputLabel>
                      <Select
                        required
                        size="medium"
                        id="section"
                        value={section}
                        MenuProps={{ sx: { maxHeight: "20rem" } }}
                        label="Section"
                        name="Section"
                        onChange={(e) => setSection(e.target.value)}
                        fullWidth
                      >
                        {activeSectionList.map((item, index) => (
                          <MenuItem key={index} value={item.sale_section_id}>
                            {item.sale_section_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 mb-2 mb-md-0">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-section-select-label">
                        Product
                      </InputLabel>
                      <Select
                        required
                        size="medium"
                        id="product"
                        value={product}
                        MenuProps={{ sx: { maxHeight: "20rem" } }}
                        label="Product"
                        name="product"
                        onChange={(e) => setProduct(e.target.value)}
                        fullWidth
                      >
                        {activeProductList.map((item, index) => (
                          <MenuItem
                            MenuProps={{ sx: { maxHeight: "20rem" } }}
                            key={index}
                            value={item.product_id}
                          >
                            {item.product_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginBottom: 0 }}
                      // style={{ display: 'flex', width: 'fit-content', marginBottom: 0 }}
                    >
                      <input
                        accept=".csv,application/vnd.ms-excel"
                        // accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        hidden
                        id="contained-button-file"
                        type="file"
                        value={saleFile}
                        onChange={handleFileChange}
                      />
                      <Button
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          height: "52px",
                        }}
                        component="span"
                        variant="contained"
                        color="primary"
                        className="m-auto"
                        size="medium"
                        startIcon={
                          <Icon icon="ant-design:file-excel-outlined" />
                        }
                      >
                        Chose File
                      </Button>
                    </label>
                  </div>
                </div>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                type="submit"
                loading={isUploadingFile}
                // onClick={() => handleUploadFile()}
              >
                Upload
              </LoadingButton>
              <Button onClick={handleCancelFile}>Cancel</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </Page>
  );
};

export default AllSalesListing;
