import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Typography, IconButton, Tooltip, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { baseUri } from '../../../config/config';
// components
import MenuPopover from '../../../components/MenuPopover';

function AddMembers(props) {
  const anchorRef = useRef(null);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  // const [taskstatus, setTaskStatus] = useState(LANGS[0]);

  const handleClose = () => {
    setOpenMemberSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenMemberSelect(true);
  };

  const handleAdd = (value) => {
    props.onAddMember(value, props.onId);
  };

  const handleDelete = (value) => {
    props.onDeleteMember(value, props.onId);
  };

  const getSelectedMember = (team) => {
    const updatedList = props.membersData.map((x, i) => {
      const findMember = team.find((t) => String(x.user_id) === String(t.user_id));
      if (findMember) {
        return {
          ...x,
          isSelected: true,
        };
      }

      return {
        ...x,
        isSelected: false,
      };
    });
    return updatedList;
  };

  return (
    <>
      <Tooltip title='Add Member'>
        <Avatar
          ref={anchorRef}
          onClick={() => handleOpenMemberSelect()}
          style={{ width: 30, height: 30, cursor: 'pointer' }}
        >
          <AddIcon style={{ color: 'green' }} />
        </Avatar>
      </Tooltip>
      <MenuPopover open={openMemberSelect} onClose={handleClose} anchorEl={anchorRef.current}>
        {/* <ArrowStyle className="arrow" /> */}
        {/* <div className="input-field">
        <input
          placeholder="Search members"
          style={{
            border: '1px solid #c5c5c5',
            borderRadius: 5,
            width: '100%',
            paddingLeft: 8
          }}
          className="search-input"
        />
      </div> */}
        <List>
          {getSelectedMember(props.assignMembers).length > 0 ? (
            getSelectedMember(props.assignMembers).map((member, index) => (
              // <ListItem>
              //   <ListItemAvatar>
              //     <Avatar alt="Remy Sharp">{x.name.substring(0, 1)}</Avatar>
              //   </ListItemAvatar>
              //   <ListItemText primary={x.name} />
              // </ListItem>
              <React.Fragment key={index}>
                {member.isSelected === false ? (
                  <ListItem
                    onClick={() => handleAdd(member)}
                    style={{ cursor: 'pointer' }}
                    className='list-hov'
                  >
                    <ListItemIcon>
                      {member.image !== '' ? (
                        <Avatar
                          alt='Remy Sharp'
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                ) : (
                  <ListItem
                    style={{
                      borderLeft: '2px solid #00AB55',
                      borderRadius: 3,
                      backgroundColor: 'rgba(0, 171, 85, 0.08)',
                      margin: 2,
                    }}
                    secondaryAction={
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => handleDelete(member)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      {member.image !== '' ? (
                        <Avatar
                          alt='Remy Sharp'
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem disabled>
              <ListItemText>
                <Typography style={{ fontSize: '0.7rem' }}>Team members not added</Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </MenuPopover>
    </>
  );
}

export default AddMembers;
