import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import barchartfill from '@iconify/icons-eva/bar-chart-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'projects',
    path: '/projects',
    icon: getIcon(barchartfill),
  },
  {
    title: 'team',
    path: '/team',
    icon: getIcon(peopleFill),
  },
  {
    title: 'clients',
    path: '/clients',
    icon: getIcon('fa-solid:users'),
  },
  {
    title: 'Project Categories',
    path: '/project_category',
    icon: getIcon('clarity:bullet-list-line'),
  },
  {
    title: 'Project templates',
    path: '/project_templates',
    icon: getIcon('fluent:clipboard-task-24-filled'),
  },
  {
    title: 'Departments',
    path: '/department',
    icon: getIcon(fileTextFill),
  },
  {
    title: 'Team Chat',
    path: '/chat',
    icon: getIcon('healthicons:i-groups-perspective-crowd-outline'),
    children: [
      {
        title: 'Chat Groups',
        path: '/chat/chat-groups',
        icon: getIcon('healthicons:i-groups-perspective-crowd-outline'),
      },
      {
        title: 'Team Chat',
        path: '/chat/chat-messages',
        icon: getIcon('bi:chat-left-dots'),
      },
    ],
  },


  // {
  //   title: 'Sales Sections',
  //   path: '/all-sales-sections',
  //   icon: getIcon('carbon:list-boxes'),
  // },
  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
