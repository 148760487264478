import * as React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState, useRef } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// material
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Container, Stack, Typography, Button, Box, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// components
import Page from '../../../components/Page';
import TemplateTaskSidebar from './components/TemplateTaskSidebar';
import Sekeleton from '../../../components/Sekeleton';
import SendIcon from '@mui/icons-material/Send';
import { noTask } from 'src/assets';
import PriorityPopover from 'src/pages/Tasks/components/Priority';
import StatusPopover from 'src/pages/Tasks/components/Status';
import {
  getTemplateTasks,
  deleteTemplateTask,
  changeTemplateTaskPriority,
  updateTemplateTaskDetail,
  addTemplateTask,
  changeTemplateTaskOrder,
  getTemplateTasksV1,
  getTemplateSectionsList,
  addTemplateTaskV1,
  addTemplateSection,
  updateTemplateSectionOrder,
} from '../../../DAL/Project/Project';
import { set_blank_target } from 'src/utils';
// import TaskCard from 'src/components/TaskCard';
import PopoverSectionMenu from 'src/pages/Tasks/components/PopoverSectionMenu';
import { LoadingButton } from '@mui/lab';
import TemplateAccordion from '../components/TemplateAccordion';
import { result } from 'lodash';
import TempAccordionMenu from './components/TempAccordionMenu';
import SectionEditSidebar from 'src/pages/Tasks/components/SectionEditSidebar';
import SectionSelectV2 from 'src/pages/Tasks/components/SectionSelectV2';
import TempSectionEditSidebar from '../components/TempSectionEditSidebar';
import { AnimatePresence } from 'framer-motion';

export default function TemplateTaskListing(props) {
  /* <<======================= STATES =======================>> */
  const navigate = useNavigate();
  const params = useLocation();
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });
  const { id } = useParams();
  const templateDetails = params.state;
  const { project_id } = templateDetails;
  const { project_temp_id } = templateDetails;
  const { enqueueSnackbar } = useSnackbar();
  const anchorTotalMembers = useRef(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [taskStats, setTaskStats] = useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);
  const [filterData, setFilterData] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    not_started: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [taskSectionsListing, setTaskSectionsListing] = useState([]);
  const [taskField, setTaskField] = React.useState(false);
  const [taskName, setTaskName] = React.useState('');
  const [selectedRow, setRowData] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const [projectMembers, setProjectMembers] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [taskData, setTaskData] = React.useState([]);
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  /*  */
  const prevSections = useRef([]);
  const hasExpanded = useRef(false);
  const [accordionMenuAnchorEl, setAccordionMenuAnchorEl] = useState(null);
  const [selectedTargetSection, setSelectedTargetSection] = useState(null);
  const [isAccordionMenuOpen, setIsAccordionMenuOpen] = useState(false);
  const [isSectionSidebarOpen, setIsSectionSidebarOpen] = useState(false);
  const [isMoveUpButtonDisabled, setIsMoveUpButtonDisabled] = useState(false);
  const [isMoveDownButtonDisabled, setIsMoveDownButtonDisabled] = useState(false);
  const [taskSectionsData, setTaskSectionsData] = useState([]);
  /* <<======================= HANDLING =======================>> */
  const getUpperSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (String(section.section_id) === String(selectedTargetSection.section_id)) {
          target_index = index - 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const getLowerSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (String(section.section_id) === String(selectedTargetSection.section_id)) {
          target_index = index + 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const handleChangeSectionOrder = async (data, type) => {
    console.log(selectedTargetSection, 'target-row');
    let order = undefined;
    let swap_section_id = undefined;
    if (type === 1) {
      const upper_section = getUpperSection();
      console.log(upper_section, 'needed');
      order = Number(selectedTargetSection.section_order) - 1;
      swap_section_id = upper_section.section_id;
    }
    if (type === 2) {
      const lower_section = getLowerSection();
      console.log(lower_section, 'needed');
      order = Number(selectedTargetSection.section_order) + 1;
      swap_section_id = lower_section.section_id;
    }

    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('section_order', order);
    formData.append('swap_section_id', swap_section_id);
    formData.append('temp_section_id', selectedTargetSection.section_id);
    console.log(...formData, 'move-section-req');
    setIsAccordionMenuOpen(false);
    const result = await updateTemplateSectionOrder(formData);
    if (result.code === 200) {
      console.log(result, 'move-to-res');
      enqueueSnackbar(`Section moved ${type === 1 ? 'up' : 'down'}`, { variant: 'success' });
      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const expandSection = (id) => {
    let changed_expand_state = taskSectionsData.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      }
      return item;
    });
    prevSections.current = changed_expand_state;
    setTaskSectionsData((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: !item.expanded,
          };
        }
        return item;
      })
    );
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: '<p>No Description added!</p>' };
    }

    return { __html: html };
  };
  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };
  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ':') {
      return '2';
    }
    return '1';
  };
  const handleSubmitUpdateTask = async (name, description, data) => {
    const task_Type = getTaskType(name);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_temp_id', data.taskTempId);
    formData.append('task_text', name);
    formData.append('description', description);
    formData.append('task_type', task_Type);
    console.log(...formData, 'form-data');
    const result = await updateTemplateTaskDetail(formData);
    if (result.code === 200) {
      handleCloseUpdate();
      handleCloseEditTask();
      fetchTaskListing();
      enqueueSnackbar('Template task details updated', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleOpenEditTask = (data) => {
    console.log(data, 'handle-edit');
    setOpenEditTask(true);
    setRowData(data);
  };
  const verifiedListing = false;
  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };
  const removeColon = (text) => {
    let target_text = text;
    for (let i = 0; i < text.length; i++) {
      target_text = target_text.replace(':', '');
    }
    return target_text;
  };
  const handleResetEditTask = () => {};
  const handleAddTask = async (e) => {
    e.preventDefault();
    if (!taskName) {
      enqueueSnackbar('task should not be empty', { variant: 'error' });
      return;
    }
    const task_Type = getTaskType(taskName);
    if (String(task_Type) === '2') {
      const removed_colon = removeColon(taskName.trim());
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('task_name', removed_colon);
      formData.append('project_id', project_id);
      formData.append('project_temp_id', project_temp_id);
      console.log(...formData, 'addSection-request');
      // return;
      const result = await addTemplateSection(formData);
      console.log(result, 'section-add');
      if (result.code === 200) {
        console.log('template-section-added');
        setTaskField(false);
        fetchTaskListing();
        getTemplateSections();
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }

      setTaskName('');
      setTaskField(false);
    }
    if (String(task_Type) === '1') {
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('task_name', taskName);
      formData.append('project_id', project_id);
      formData.append('project_temp_id', project_temp_id);
      formData.append('task_type', task_Type);
      if (taskSectionsListing) {
        if (taskSectionsListing[selectedSectionIndex]) {
          let section = taskSectionsListing[selectedSectionIndex];
          console.log(section, 'target-section');
          formData.append('section_id', section.id);
        }
      }
      console.log(...formData, 'addTask-request');
      // return;
      const result = await addTemplateTaskV1(formData);
      console.log(result, 'task-add');
      if (result.code === 200) {
        console.log('template-task-added');
        setTaskField(false);
        fetchTaskListing();
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }

      setTaskName('');
      setTaskField(false);
    }
  };

  const handleDeleteTask = async () => {
    //
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_temp_id', selectedRow.taskTempId);
    formData.append('project_temp_id', project_temp_id);
    formData.append('task_order', selectedRow.taskOrder);
    console.log(...formData, 'del-task-req');
    console.log(selectedRow, 'sl');
    // return;
    setIsDeletingTask(true);
    const result = await deleteTemplateTask(formData);
    if (result.code === 200) {
      setIsDeletingTask(false);
      fetchTaskListing();
      handleCloseDialog();
      handleCloseSectionMenu();
      enqueueSnackbar('Template task deleted', { variant: 'success' });
    } else {
      setIsDeletingTask(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const handleOpenDialog = (data, i) => {
    console.log(selectedRow, 'specific');
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const showAddTask = () => {
    setTaskField(!taskField);
  };

  const handlePriority = async (value, data) => {
    console.log(value, data, 'priority--');

    setTaskData((task) =>
      task.map((x) => {
        if (x.taskTempId === data.taskTempId) {
          return {
            ...x,
            priority: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_priority', value);
    formData.append('task_temp_id', data.taskTempId);

    const result = await changeTemplateTaskPriority(formData);
    if (result.code === 200) {
      enqueueSnackbar('Template task priority changed', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      fetchTaskListing();
    }
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    let filtered = [];
    taskData.map((task, index) => {
      // console.log(task, 'tobe')
      if (String(task.visibilityStatus) !== String(0)) {
        // console.log(task, 'private')
        filtered.push(task);
      }
    });
    const items = Array.from(taskData);
    const [recordedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, recordedItem);
    setTaskData(items);
    console.log('order-changed-locally');
    let formData = new FormData();
    let destination = taskData[result.destination.index];
    console.log(destination, 'destination');
    console.log(recordedItem, 'recordedItem');
    console.log(result, 'res');
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_temp_id', project_temp_id);
    formData.append('task_order', items.length - result.destination.index);
    formData.append('temp_task_id', destination.taskTempId);
    console.log(...formData, 'change-order-req');

    const res = await changeTemplateTaskOrder(formData);
    if (res.code === 200) {
      enqueueSnackbar('Template task order changed', { variant: 'success' });
    } else {
      enqueueSnackbar(res.message, { variant: 'error' });
    }
  };
  const getTemplateSections = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_temp_id', project_temp_id);
    const result = await getTemplateSectionsList(formData);
    console.log(result, 'sections-list');
    if (result.code === 200) {
      setTaskSectionsListing(result.template_section_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      //  setIsWaiting(false);
    }
  };
  const isExpanded = (id, _index) => {
    if (hasExpanded.current === false) {
      if (_index === 0) {
        return true;
      }
    }
    if (prevSections.current) {
      let expand = false;
      prevSections.current.forEach((section, index) => {
        if (String(section.section_id) === String(id)) {
          expand = section.expanded;
        }
      });
      console.log('i returned loop', expand);
      return expand;
    }
    console.log('i returned ', false);
    hasExpanded.current = true;
    return false;
  };

  const fetchTaskListing = async () => {
    const get_modified = (tasks) => {
      const result = tasks.map((task, i) => {
        return {
          task_id: task.task_id,
          task: task.task_name,
          // targetDate: x.target_date === '0000-00-00' ? '' : x.target_date,
          endDate: '',
          status: task.task_status,
          priority: task.task_priority,
          taskOrder: task.task_order,
          detailVisible: true,
          members: task.assign_member,
          images: task.task_images,
          visibilityStatus: true,
          // visibilityStatus: task.vasibility_status,
          description: task.task_distribution,
          taskTempId: task.task_temp_id,
          taskType: task.task_type,
          sectionId: task.section_id,
        };
      });
      return result;
    };
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', project_id);
    formData.append('project_temp_id', project_temp_id);

    const result = await getTemplateTasksV1(formData);
    console.log(result, 'task-list');
    if (result.code === 200) {
      let section_data = result.template_section_list.map((section, index) => {
        return {
          ...section,
          expanded: isExpanded(section.section_id, index),
          section_task: get_modified(section.section_task),
        };
      });
      console.log(section_data, 'got');
      setTaskSectionsData(section_data);
      setIsWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIsWaiting(false);
    }
  };
  const setFirstLocalStorage = () => {
    if (localStorage.getItem(`project_${id}`)) {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
          chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
          filterData: JSON.parse(localStorage.getItem(`project_${id}`)).filterData,
        })
      );
    } else {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          filter: [],
          chipData: [],
          filterData: [],
        })
      );
    }
  };

  const handleTaskInputBlur = () => {
    if (taskName === '') {
      setTaskField(false);
    }
  };
  const showAddTaskButton = () => {
    if (taskName === '') {
      return false;
    }
    return true;
  };
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, 'target-task');
    console.log(index, 'target-index');
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target('task-description-html');
    }, 500);
  };
  const isDragDisabled = () => false;
  const getFilteredTasks = (data) => data;
  const handleOpenAccordionMenu = (e, section, index) => {
    console.log(section, 'target-section');
    setAccordionMenuAnchorEl(e.currentTarget);
    setIsAccordionMenuOpen(true);
    setSelectedTargetSection(section);
    let is_first = false;
    let is_last = false;
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === 0) {
          is_first = true;
        }
      }
    });
    if (is_first) {
      setIsMoveUpButtonDisabled(true);
    }
    if (!is_first) {
      setIsMoveUpButtonDisabled(false);
    }
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === taskSectionsData.length - 1) {
          is_last = true;
        }
      }
    });
    if (is_last) {
      setIsMoveDownButtonDisabled(true);
    }
    if (!is_last) {
      setIsMoveDownButtonDisabled(false);
    }
  };
  React.useEffect(() => {
    setFirstLocalStorage();
    setIsWaiting(true);
    fetchTaskListing();
    getTemplateSections();
  }, []);

  /* <<======================= JSX =======================>> */
  return (
    <Page title='Template Tasks | Support Task Portal'>
      <Container maxWidth='xl' style={{ backgroundColor: '#fff' }}>
        <div className='row main-header'>
          <div
            className='col-lg-4 col-md-12 col-sm-12 pl-0 align-items-center'
            style={{ display: 'flex' }}
          >
            <IconButton onClick={() => navigate('/project_templates')}>
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title='Project Name'>
              <Typography style={{ fontsize: 16, fontWeight: 'bold', paddingLeft: 8 }}>
                {location.state === null ? projectDetail.project_name : location.state.project_name}
              </Typography>
            </Tooltip>
          </div>
        </div>

        <Stack
          direction='row'
          flexWrap='wrap-reverse'
          alignItems='center'
          justifyContent='flex-end'
        >
          <Stack direction='row' spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <TemplateTaskSidebar
              isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onOpenEditTask={handleOpenEditTask}
              onCloseEditTask={handleCloseEditTask}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
            />
          </Stack>
        </Stack>
        <div className='row'>
          <div className='col-md-9 '>
            {taskField === false ? (
              <div
                style={{
                  display: 'flex',
                  border: '1px dashed black',
                  width: '100%',
                  padding: 15,
                  borderRadius: 10,
                }}
                role='button'
                tabIndex='0'
                onClick={() => showAddTask()}
                onKeyDown={() => showAddTask()}
              >
                <AddIcon style={{ color: 'green' }} />
                <Typography sx={{ ml: 1 }}>Create Template Task</Typography>
              </div>
            ) : (
              <form className='w-100'>
                <TextField
                  label='Task'
                  fullWidth
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyPress={(event) => (event.key === 'Enter' ? handleAddTask(event) : null)}
                  autoFocus
                  onBlur={() => handleTaskInputBlur()}
                />
              </form>
            )}
            {showAddTaskButton() && (
              <Tooltip title='Add template task'>
                <span style={{ position: 'absolute', zIndex: 2, right: 18, top: 8 }}>
                  <IconButton onClick={(e) => handleAddTask(e)}>
                    <SendIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
          <div className='col-md-3'>
            <SectionSelectV2
              data={[]}
              onChange={(data) => console.log(data)}
              selectedIndex={selectedSectionIndex}
              setSelectedIndex={setSelectedSectionIndex}
              setSelectedSection={setSelectedSection}
              taskSectionsListing={taskSectionsListing}
            />
          </div>
        </div>
        {isWaiting === false ? (
          taskSectionsData.map((section, index) => {
            return (
              <TemplateAccordion
                key={index}
                // data={getFilteredList(section.section_tasks)}
                setTaskSectionsData={setTaskSectionsData}
                openAccordionMenu={handleOpenAccordionMenu}
                fetchTaskListing={fetchTaskListing}
                expandSection={expandSection}
                section={section}
                data={taskSectionsData}
                handleOpenEditTask={handleOpenEditTask}
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                openEditTask={handleOpenEditTask}
                setOpenEditTask={setOpenEditTask}
                filterData={filterData}
                setFilterData={setFilterData}
                taskField={taskField}
                setTaskField={setTaskField}
                taskName={taskName}
                setTaskName={setTaskName}
                selectedRow={selectedRow}
                setRowData={setRowData}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                projectMembers={projectMembers}
                setProjectMembers={setProjectMembers}
                projectDetail={projectDetail}
                setProjectDetail={setProjectDetail}
                taskData={taskData}
                setTaskData={setTaskData}
                sectionMenuAnchorEl={sectionMenuAnchorEl}
                setSectionMenuAnchorEl={setSectionMenuAnchorEl}
                isSectionMenuOpen={isSectionMenuOpen}
                setIsSectionMenuOpen={setIsSectionMenuOpen}
                isDeletingTask={isDeletingTask}
                setIsDeletingTask={setIsDeletingTask}
              />
            );
          })
        ) : (
          <Sekeleton totalTask={projectStats.all} />
        )}

        {/* ============================================== Add Project component========================= */}

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <LoadingButton loading={isDeletingTask} onClick={handleDeleteTask}>
              Yes, Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* <========Popovers========> */}
        <PopoverSectionMenu
          isOpen={isSectionMenuOpen}
          onOpen={handleOpenSectionMenu}
          onClose={handleCloseSectionMenu}
          anchorEl={sectionMenuAnchorEl}
          setAnchorEl={handleSectionAnchorEl}
          handleOpenDialog={handleOpenDialog}
          // sections={taskSections}
          hasSections={false}
          // handleMoveSection={handleMoveSection}
          selectedRow={selectedRow}
          isDragDisabled={false}
        />
        <TempAccordionMenu
          isOpen={isAccordionMenuOpen}
          onOpen={() => setIsAccordionMenuOpen(true)}
          onClose={() => setIsAccordionMenuOpen(false)}
          anchorEl={accordionMenuAnchorEl}
          selectedSection={selectedTargetSection}
          handleChangeSectionOrder={handleChangeSectionOrder}
          sectionList={taskSectionsData}
          isMoveUpButtonDisabled={isMoveUpButtonDisabled}
          isMoveDownButtonDisabled={isMoveDownButtonDisabled}
          fetchTaskListing={fetchTaskListing}
          openEditSection={() => {
            setIsSectionSidebarOpen(true);
            setIsAccordionMenuOpen(false);
          }}
          setSelectedSectionIndex={setSelectedSectionIndex}
          project_temp_id={project_temp_id}
          project_id={project_id}
          getTaskSections={getTemplateSections}
        />
        <TempSectionEditSidebar
          isOpen={isSectionSidebarOpen}
          onClose={() => setIsSectionSidebarOpen(false)}
          isSectionData={selectedTargetSection}
          fetchTaskListing={fetchTaskListing}
        />
      </Container>
    </Page>
  );
}
