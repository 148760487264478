import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { addProduct, editProduct, productDetail } from "src/DAL/Sales/Sales";
import { useSnackbar } from "notistack";
import Loader from "../Loader";
import Page from "src/components/Page";

const EditSaleLeadProduct = () => {
  const navigate = useNavigate();
  const [detail, setDetail] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (detail.product_name?.trim() === "") {
      enqueueSnackbar("Product name cannot be empty", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("product_name", detail.product_name?.trim());
    formData.append("product_id", detail.product_id.trim());
    formData.append("status", detail.status);
    console.log(...formData, "upd-req");

    setLoading(true);
    const result = await editProduct(formData);
    setLoading(false);
    if (result.code === 200) {
      enqueueSnackbar("Product added successfully", { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProductDetail = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("product_id", id);
    const result = await productDetail(formData);
    if (result.code === 200) {
      // if (String(result.product_Data.is_internal) !== "1") {
      //   enqueueSnackbar("Not allowed", { variant: "error" });
      //   navigate("/projects/categories", { replace: true });
      //   return;
      // }
      setDetail(result.product_Data);

      setLoading(false);
    } else {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useLayoutEffect(() => {
    getProductDetail();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page title="Edit Product | Support Task Portal">
      <Container maxWidth="lg">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
          Edit product
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={8}>
              <Stack direction="column" spacing={3}>
                <TextField
                  value={detail.product_name}
                  onChange={(e) =>
                    setDetail((prev) => {
                      return { ...prev, product_name: e.target.value };
                    })
                  }
                  variant="outlined"
                  label="Product name"
                />
                <div>
                  Status :
                  <Switch
                    checked={detail.status === "1"}
                    onChange={(e) =>
                      setDetail((prev) => {
                        return {
                          ...prev,
                          status: e.target.checked === true ? "1" : "0",
                        };
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "fit-content" }}
                  >
                    Update
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

export default EditSaleLeadProduct;
