import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import HomeLayout from "./layouts/HomeLayout";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ProjectListing,
  AddProject,
  EditProject,
  TaskListing,
  ForgetPassword,
  Team,
  AddTeam,
  EditTeam,
  Departments,
  AddDepartment,
  EditDepartment,
  TeamChangePassword,
  UserProfile,
  ProjectCategory,
  AddCategory,
  EditCategory,
  AddProduct,
  SaleLeadProducts,
  EditSaleLeadProduct,
} from "./pages/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
// import Blog from './pages/Blog';
import User from "./pages/User";
import NotFound from "./pages/Page404";
import TemplateListing from "./pages/Project_templates/TemplateListing";
import EditProjectTemplate from "./pages/Project_templates/components/EditProjectTemplate";
import TemplateTaskListing from "./pages/Project_templates/Tasks/TemplateTaskListing";
import CreateNewTemplate from "./pages/Project_templates/CreateNewTemplate";
import GlobalTaskListing from "./pages/GlobalTaskList/GloabalTaskListing";
import TaskLinkController from "./pages/controller/TaskLinkController";
import VerifiedTasks from "./pages/Tasks/components/VerifiedTasks";
import AllSalesListing from "./pages/sales/AllSalesListing";
import SaleSections from "./pages/sales/SaleSections";
import AddSale from "./pages/sales/components/AddSale";
import CollapsableTaskListing from "./pages/Tasks/CollapsableTaskListing";
import Clients from "./pages/Clients/Clients";
import AddClient from "./pages/Clients/AddClient";
import EditClient from "./pages/Clients/EditClient";
import { TeamChat, TeamChatDetail } from "./pages/teamChat";
import ChatGroups from "./pages/chatGroups/ChatGroups";
import ProjectCategoryList from "./pages/ProjectCategoryList/ProjectCategoryList";
import ArchivedProjectList from "./pages/Archived-projects/ArchivedProjectList";
import AllNotes from "./pages/Notes/AllNotes";
import AddNotes from "./pages/Notes/AddNotes";
// import ProjectTemplates from './pages/Project_templates/ProjectTemplates';
// import TemplateTaskListing from './pages/Project_templates/TemplateTaskListing';
// import EditProjectTemplate from './pages/Project_templates/EditProjectTemplate';

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/projects/categories"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
const ChildrenLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/projects") {
      navigate("/projects/categories", { replace: true });
    }
  });
  return <Outlet />;
};
export default function Router() {
  return (
    <Routes>
      {/* <Route path="/task" element={<HomeLayout />}>
        <Route path="/task" element={<TaskListing />} />
      </Route> */}
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/dashboard" element={<DashboardApp />} /> */}
        <Route path="projects" element={<ChildrenLayout />}>
          <Route path="categories" element={<ProjectCategoryList />} />
          <Route path="categories/:id" element={<ProjectListing />} />
          <Route path="project/:id" element={<CollapsableTaskListing />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="edit-project/:id" element={<EditProject />} />
          <Route path="verified-tasks" element={<VerifiedTasks />} />
          <Route path="archived-projects" element={<ArchivedProjectList />} />
        </Route>
        <Route path="/test-collapse/:id" element={<TaskListing />} />
        {/*  */}
        <Route
          path="/project/:project_id/:task_id"
          element={<TaskLinkController />}
        />
        <Route path="/team" element={<Team />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/global_task_search" element={<GlobalTaskListing />} />
        <Route path="/team/add_team" element={<AddTeam />} />
        <Route path="/clients/add-client" element={<AddClient />} />
        <Route path="/team/edit_team/:id" element={<EditTeam />} />
        <Route path="/clients/edit-client/:id" element={<EditClient />} />
        <Route
          path="/team/change_password/:id"
          element={<TeamChangePassword />}
        />
        <Route path="/department" element={<Departments />} />
        <Route path="/department/add_department" element={<AddDepartment />} />
        <Route
          path="/department/edit_department/:id"
          element={<EditDepartment />}
        />
        <Route path="/project_category" element={<ProjectCategory />} />
        <Route
          path="/project_category/add_category"
          element={<AddCategory />}
        />
        <Route
          path="/project_category/edit_category/:id"
          element={<EditCategory />}
        />
        {/* team chat */}
        <Route path="/chat/chat-groups" element={<ChatGroups />} />
        <Route path="/chat/chat-messages" element={<TeamChat />} />
        <Route
          path="/chat/chat-messages/detail/:id"
          element={<TeamChatDetail />}
        />

        {/* sale leads */}
        <Route path="/sales/all" element={<AllSalesListing />} />
        <Route path="/sales/sections" element={<SaleSections />} />
        <Route path="/add-sale" element={<AddSale />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/sales/products" element={<SaleLeadProducts />} />
        <Route path="/edit-product/:id" element={<EditSaleLeadProduct />} />
        {/* <Route path='/all-sales-listing' element={<AllSalesListing />} />
        <Route path='/all-sales-sections' element={<SaleSections />} />
        <Route path='/add-sale' element={<AddSale />} /> */}



        {/* Notes Add */}
        <Route path="/Notes/AllNotes" element={<AllNotes />} />
        <Route path="/Notes/AddNotes" element={<AddNotes />} />
        


        {/* project templates */}
        <Route path="/project_templates" element={<TemplateListing />} />
        <Route
          path="/project_templates/:id"
          element={<TemplateTaskListing />}
        />
        <Route
          path="/project_templates/edit_template/:id"
          element={<EditProjectTemplate />}
        />
        <Route
          path="/project_templates/new_template"
          element={<CreateNewTemplate />}
        />

        <Route path="/user_profile" element={<UserProfile />} />
        {/* <Route path="/user" element={<User />} />
        <Route path="/products" element={<Products />} />
        <Route path="/blog" element={<Blog />} />  */}
      </Route>

      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>



      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
