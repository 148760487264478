// import React from "react";

// const MainAssociatePicker = () => {
//   return <div>MainAssociatePicker</div>;
// };

// export default MainAssociatePicker;
import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Typography, IconButton, Tooltip, Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { baseUri } from "../../../config/config";
import { updateSaleAssociate } from "src/DAL/Project/Project";
import { useSnackbar } from "notistack";
// components
import MenuPopover from "../../../components/MenuPopover";

function MainAssociatePicker(props) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  // const [taskstatus, setTaskStatus] = useState(LANGS[0]);

  const handleClose = () => {
    setOpenMemberSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenMemberSelect(true);
  };

  const handleChangeAssociate = async (member) => {
    setOpenMemberSelect(false);
    props.onChange(member);
  };

  const getSelectedMember = (team) => {
    let new_data = props.membersData.map((member) => {
      if (String(member.user_id) === String(props.row.associate_id)) {
        return { ...member, isSelected: true };
      }
      return { ...member, isSelected: false };
    });
    // console.log(new_data, 'new-data');
    return new_data;
  };
  const showAvatar = (data) => {
    if (data.associate_id === null) {
      return false;
    }
    if (String(data.associate_id) === "0") {
      return false;
    }
    if (data.associate_id !== null && data.team_first_name === null) {
      return false;
    }
    if (String(data.associate_id) !== "0" && data.team_first_name === null) {
      return false;
    }
    return true;
  };
  return (
    <>
      {showAvatar(props.row) ? (
        <Tooltip
          arrow
          title={`Main Associate ${
            props.row.team_first_name + " " + props.row.team_last_name
          }`}
          placement="bottom"
        >
          {props.row.image !== "" ? (
            <Avatar
              role="button"
              alt="Remy Sharp"
              src={baseUri + props.row.image}
              style={{ height: 30, width: 30 }}
              ref={anchorRef}
              onClick={() => handleOpenMemberSelect()}

              // className='ml-2'
            />
          ) : (
            <Avatar
              ref={anchorRef}
              onClick={() => handleOpenMemberSelect()}
              role="button"
              alt="Remy Sharp"
              style={{ height: 30, width: 30 }}
            >
              {props.row.team_first_name.substring(0, 1)}
            </Avatar>
          )}
        </Tooltip>
      ) : (
        <Tooltip title="Update Main Associate">
          <Avatar
            ref={anchorRef}
            onClick={() => handleOpenMemberSelect()}
            style={{ width: 30, height: 30, cursor: "pointer" }}
          >
            <AddIcon style={{ color: "green" }} />
          </Avatar>
        </Tooltip>
      )}
      <MenuPopover
        open={openMemberSelect}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <List>
          {getSelectedMember(props.membersData).length > 0 ? (
            getSelectedMember(props.membersData).map((member, index) => (
              <React.Fragment key={index}>
                {member.isSelected === false ? (
                  <ListItem
                    onClick={() => handleChangeAssociate(member)}
                    style={{ cursor: "pointer" }}
                    className="list-hov"
                  >
                    <ListItemIcon>
                      {member.image !== "" ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar
                          alt="Remy Sharp"
                          style={{ height: 30, width: 30 }}
                        >
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                ) : (
                  <ListItem
                    // onClick={() => handleChangeAssociate(member)}
                    style={{
                      borderLeft: "2px solid #00AB55",
                      borderRadius: 3,
                      backgroundColor: "rgba(0, 171, 85, 0.08)",
                      margin: 2,
                    }}
                  >
                    <ListItemIcon>
                      {member.image !== "" ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar
                          alt="Remy Sharp"
                          style={{ height: 30, width: 30 }}
                        >
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem disabled>
              <ListItemText>
                <Typography style={{ fontSize: "0.7rem" }}>
                  No members found
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </MenuPopover>
    </>
  );
}

export default MainAssociatePicker;
