import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import moment from 'moment';
// import DateAdapter from '@mui/lab/AdapterMoment';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { activeTeamList } from 'src/DAL/Project/Project';
import { chatGroupDetail } from 'src/DAL/chat/chat';
import { baseUri } from 'src/config/config';
import { useSocket } from 'src/Hooks/socketContext';
import { uniqBy } from 'lodash';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditGroupDialog({
  onEditGroup,
  isOpen,
  toggle,
  currentGroup,
  isEditing,
  isDeleting,
  handleNotification,
}) {
  const [projectTeamList, setProjectTeamList] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [startTime, setStartTime] = React.useState('');
  const { sendChatGroupNotification } = useSocket();
  const [endTime, setEndTime] = React.useState('');
  const [groupName, setGroupName] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentGroupDetail, setCurrentGroupDetail] = React.useState({});
  const [membersData, setMembersData] = useState([]);
  const fileChangedHandler = async (e) => {
    if (!e.target) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files[0]) {
      // setProfileImage('');
      return;
    }
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };
  const handleClose = () => {
    setStartTime('');
    setEndTime('');
    setMembers([]);
    setGroupName('');
    setProfileImage('');
    setImage('');
    toggle();
    // setOpen(false);
  };
  const handleChange = (data) => {
    console.log(data);
    setMembers(data);
    // const {
    //   target: { value }
    // } = event;
    // setMembers(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };
  const handleChangeStartTime = (newValue) => {
    console.log(newValue);
    console.log(moment(newValue).format('hh:mm a'));
    setStartTime(newValue);
  };
  const handleChangeEndTime = (newValue) => {
    console.log(newValue);
    console.log(moment(newValue).format('hh:mm a'));
    setEndTime(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(currentGroup.team, 'cur team');
    console.log(members, 'edit members');
    let merged = [...currentGroup.team, ...members];
    let unique_members = uniqBy(merged, function (e) {
      return e.user_id;
    });
    console.log(unique_members, 'unique');
    if (!currentGroup) {
      return;
    }
    if (members.length < 1) {
      enqueueSnackbar('Please chose at least one member', { variant: 'error' });
      return;
    }
    if (startTime === '') {
      enqueueSnackbar('Please select chat start time', { variant: 'error' });
      return;
    }
    if (endTime === '') {
      enqueueSnackbar('Please select chat end time', { variant: 'error' });
      return;
    }
    let valid_start_time = moment(startTime).isValid();
    let valid_end_time = moment(endTime).isValid();
    if (!valid_start_time) {
      enqueueSnackbar('invalid chat start time', { variant: 'error' });
      return;
    }
    if (!valid_end_time) {
      enqueueSnackbar('invalid chat end time', { variant: 'error' });
      return;
    }
    let chat_time_diff = moment(startTime).diff(moment(endTime), 'minutes');

    console.log(chat_time_diff, 'diff');
    console.log(valid_start_time, valid_end_time);
    if (chat_time_diff === 0) {
      enqueueSnackbar('chat start/end time cannot be same', { variant: 'error' });
      return;
    }
    const id = currentGroup.id;
    console.log('submit');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_name', groupName);
    data.append('group_id', id);
    members.map((member, index) => {
      data.append(`team[${index}]`, member.id);
    });
    data.append('chat_start_time', startTime);
    data.append('chat_end_time', endTime);
    if (Image) {
      data.append('group_image', Image);
    }
    // console.log(...data, 'add-group-req');
    const result = await onEditGroup(data);
    console.log(result, 'sss');
    if (result === true) {
      unique_members.forEach((item) => {
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.receiver_id = item.user_id;
        payload.notification_type = 'chat_group';
        payload.notification_action = 'edit_group';
        console.log(payload, 'payload-team');
        sendChatGroupNotification(payload);
      });
    }
  };
  const getGroupDetail = async () => {
    console.log(currentGroup, 'cgrp');
    if (!currentGroup) {
      return;
    }
    const id = currentGroup.id;
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_id', id);
    setIsLoading(true);
    const result = await chatGroupDetail(data);

    console.log(result, 'group-detail');
    if (result.code === 200) {
      const { group_data } = result;
      setIsLoading(false);
      setCurrentGroupDetail({ detail: result.group_data, messages: result.group_message_list });

      setStartTime(group_data.chat_start_time);
      setEndTime(group_data.chat_end_time);
      setMembers(group_data.team);
      setGroupName(group_data.group_name);
      setProfileImage(baseUri + group_data.group_image);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await activeTeamList(formData);
    console.log(result, 'active-team');
    if (result.code === 200) {
      setMembersData(result.Team_member_list);
      console.log(result.Team_member_list, 'overall listing');
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      getGroupDetail();
      fetchTeamListing();
    }
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      //   onClose={handleClose}
      aria-labelledby='file-dialog-title'
      aria-describedby='file-dialog-description'
      maxWidth='md'
      fullWidth
      // PaperProps={{ sx: { width: { md: '40rem', sm: '30rem', xs: '25rem' } } }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id='file-dialog-title'>Edit Group</DialogTitle>
        <DialogContent>
          <DialogContentText id='file-dialog-description'></DialogContentText>
          <DialogContent>
            {!isLoading ? (
              <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type='text'
                    value={groupName}
                    variant='outlined'
                    label='Group name'
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id='tags-filled'
                    options={membersData}
                    value={members}
                    // defaultValue={members}
                    getOptionLabel={(option) => `${option.first_name}  ${' '}  ${option.last_name}`}
                    filterSelectedOptions
                    // getOptionSelected={(option, value) => option.id === value.id}
                    // filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, value) => handleChange(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={`${option.first_name}${' '}${option.last_name}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='Select Team Members' />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      views={['hours', 'minutes']}
                      required
                      onChange={(newValue) => {
                        handleChangeStartTime(newValue);
                      }}
                      id='start-time'
                      label='Chat Start Time'
                      value={startTime}
                      // value={startTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      //   sx={{ width: 150 }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      views={['hours', 'minutes']}
                      required
                      onChange={(newValue) => {
                        handleChangeEndTime(newValue);
                      }}
                      id='start-time'
                      label='Chat Start Time'
                      value={endTime}
                      // value={startTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      //   sx={{ width: 150 }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      <img
                        className='m-auto'
                        width='140px'
                        height='140px'
                        src={file}
                        alt='group image'
                      />
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept='image/png,image/jpeg,image/jpg'
                    hidden
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                    <Button
                      component='span'
                      fullWidth
                      variant='outlined'
                      color='primary'
                      startIcon={<DriveFolderUploadIcon />}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
              </Grid>
            ) : (
              <div className='center-progress'>
                <CircularProgress />
              </div>
            )}
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type='submit'
            loading={isEditing}
            // onClick={() => handleUploadFile()}
          >
            Edit
          </LoadingButton>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
