import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Tooltip,
  Typography,
  IconButton,
  Box,
  Grid,
  Collapse,
  Stack,
} from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Iconify, SaleCard } from "..";
import { noTask } from "src/assets";
import { Iconify } from "src/components";
import SaleCard from "./SaleCard";
import { useLocation, useNavigate } from "react-router-dom";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
const icon_color = "#66727c";

const SaleSectionUnOptimized = ({
  section,
  query,
  filters,
  onSectionAction,
  onSaleAction,
  sectionSales,
  associates,
}) => {
  const {
    expanded,
    is_default,
    section_id,
    section_name,
    section_order,
    section_sales,
  } = section;
  console.log(section, "sectionsectionsectionsection");

  const [show, setShow] = useState(false);
  const sectionWrapperRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state;
  const [csvDate, setCsvDate] = useState([]);
  // console.log(section, "section--");
  // console.log(filters, "filters--");
  /* <<--------------------------------------------------------->> */
  const handleExpand = () => {
    setShow(true);
  };
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const handleCollapse = () => {
    setShow(false);
  };
  const handleSectionMenu = (e) => {
    e.stopPropagation();
    const payload = {
      action_title: "open_section_menu",
      payload: {
        event: e,
        section: section,
      },
    };
    onSectionAction(payload);
  };
  const GetCSVData = () => {
    // csvDate.push([`${section.section_name}`])
    let list = [];
    list.push(["Section Name", `${section.section_name}`]);
    if (section.section_sales && section.section_sales.length > 0) {
      section.section_sales.map((sale_section) => {
        list.push(["", ""]);
        list.push(["Title", sale_section.sale_title]);
        list.push([
          "Client Name",
          "Client Phone",
          "Client Email",
          "Main assosiate Name",
          "Main asosiate Email",
        ]);
        list.push([
          sale_section.first_name + " " + sale_section.last_name,
          sale_section.phone,
          sale_section.client_email,
          sale_section.team_first_name + " " + sale_section.team_last_name,
          sale_section.associate_email,
        ]);
        // list.push(["Client Phone", sale_section.phone]);
        // list.push(["Client Email", sale_section.client_email]);
        // list.push([
        //   "Main assosiate Name",
        //   sale_section.team_first_name + " " + sale_section.team_last_name,
        // ]);
        // list.push(["Main asosiate Email", sale_section.associate_email]);
        sale_section.day1 && list.push(["Day 1", sale_section.day1.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        sale_section.day2 && list.push(["Day 2", sale_section.day2.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        sale_section.day3 && list.push(["Day 3", sale_section.day3.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        sale_section.day4 && list.push(["Day 4", sale_section.day4.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        sale_section.day5 && list.push(["Day 5", sale_section.day5.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        sale_section.day6 && list.push(["Day 6", sale_section.day6.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()]);
        list.push(["", ""]);
      });

      setCsvDate([...list]);

      // return list
    }
  };
  const handleScroll = () => {
    if (!locationState) {
      return;
    }
    if (locationState.display_section_id) {
      if (
        String(locationState.display_section_id) === String(section.section_id)
      ) {
        const el = sectionWrapperRef.current;
        el.scrollIntoView({
          block: "center",
        });
        el.style.transition = "all .5s linear";
        el.style.outline = "solid 3px rgb(10, 238, 124)";
        setTimeout(() => {
          el.style.outline = "none";
        }, 3000);
        navigate("/sales/all", { replace: true, state: null });
      }
    }
  };
  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 500);
    GetCSVData();
  }, []);
  /* <<--------------------------------------------------------->> */
  return (
    <div ref={sectionWrapperRef} className="section-wrapper">
      <Grid
        alignItems="center"
        container
        className="pointer"
        onClick={handleToggle}
      >
        <Grid item xs={8} sm={9} md={10}>
          <Typography className="theme-text-color sale-section-name">
            {section.section_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {" "}
            {show == true && (
              <CSVLink
                style={{ textDecoration: "none", marginBottom: 5 }}
                data={csvDate}
              >
                <span
                  style={{ padding: 4, fontSize: 11 }}
                  className="def-sale-section-count"
                >
                  Export csv
                </span>
              </CSVLink>
            )}
            <span className="def-sale-section-count">
              {sectionSales.length}
            </span>
            <div style={{ transform: "translateY(-2px)" }}>
              <Iconify
                style={{ color: icon_color }}
                icon={show ? "ep:arrow-up-bold" : "ep:arrow-down-bold"}
              />
            </div>
            <div
              role="button"
              onClick={handleSectionMenu}
              style={{ transform: "translateY(-2px)" }}
            >
              <MoreVertIcon style={{ color: icon_color }} />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={show}>
        <Stack spacing={2} pt={2}>
          {sectionSales.map((sale) => (
            <SaleCard
              associates={associates}
              onSaleAction={onSaleAction}
              key={sale.id}
              sale={sale}
            />
          ))}
          {sectionSales.length === 0 && (
            <div className="col-12 mt-5">
              <img
                src={noTask}
                alt="no task"
                width="95px"
                style={{ margin: "auto" }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Sales Found
              </Typography>
            </div>
          )}
        </Stack>
      </Collapse>
    </div>
  );
};
const SaleSection = ({
  section,
  query,
  filters,
  onSectionAction,
  onSaleAction,
  associates,
}) => {
  // console.log(filters, "filters--");
  const sectionSales = useMemo(
    () => handleFilter(),
    [query, filters, associates, section.sectionSales]
  );
  function handleFilter() {
    let filtered = section.section_sales.filter(
      (item) =>
        String(item.sale_title)
          .toLocaleLowerCase()
          .indexOf(String(query).toLocaleLowerCase()) !== -1
    );

    if (String(filters.sorting.value) === "due-date") {
      const getDate = (item) => {
        if (!item.sale_target_date) {
          return "";
        }
        if (!item) {
          return "";
        }
        return item.sale_target_date;
      };
      const sorted = [...filtered].sort((a, b) => {
        if (!a.sale_target_date || b.sale_target_date === "") {
          return 1;
        }
        let targetDateA = getDate(a);
        let targetDateB = getDate(b);
        let result = moment(targetDateA).isAfter(targetDateB);
        if (result === true) {
          return 1;
        }
        if (result === false) {
          return -1;
        }
        return 0;
      });
      filtered = [...sorted];
    }

    if (filters.selectedProducts.length > 0) {
      let matched = [];
      filters.selectedProducts.forEach((prod) => {
        let match = filtered.filter(
          (item) => String(item.product_id) === String(prod.product_id)
        );
        if (match.length != 0) {
          matched = [...matched, ...match];
        }
      });
      filtered = [...matched];
    }

    return filtered;
  }
  const optimized = useMemo(
    () => (
      <SaleSectionUnOptimized
        section={section}
        query={query}
        filters={filters}
        onSectionAction={onSectionAction}
        onSaleAction={onSaleAction}
        associates={associates}
        sectionSales={sectionSales}
      />
    ),
    [section, associates, sectionSales]
  );
  /* <<--------------------------------------------------------->> */
  /* <<--------------------------------------------------------->> */
  return optimized;
};

export default SaleSection;
