import {
  Avatar,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Page from 'src/components/Page';
import { baseUri } from 'src/config/config';
import ChatTabs from './components/ChatTabs';
import GroupList from './components/GroupList';
import TeamList from './components/TeamList';
import AddIcon from '@mui/icons-material/Add';
import AddGroupDialog from './components/AddGroupDialog';
import ScrollToBottom from 'react-scroll-to-bottom';
import {
  createGroup,
  chatGroupList,
  chatGroupDetail,
  deleteChatGroup,
  editChatGroup,
  sendGroupChatMessage,
  detailWithMessages,
  initialChatMessage,
  detailDirectMessages,
  sendDirectMessage,
} from 'src/DAL/chat/chat';
import { useSnackbar } from 'notistack';
import Scrollbar from 'src/components/Scrollbar';
import ChatHeader from './components/ChatHeader';
import CkEditor from 'src/components/editor/CKEditor';
import SendIcon from '@mui/icons-material/Send';
import ChatMessageItem from './components/ChatMessageItem';
import StyledChatSearch from 'src/components/search/StyledChatSearch';
import { set_blank_target } from 'src/utils/DomUtils';
import { filter } from 'lodash';
//   const [groups, setGroups] = useState([
//     { id: '4', name: 'Dev Group', image: '' },
//     { id: '5', name: 'Work Group', image: '' },
//     { id: '6', name: 'Fun Grup', image: '' },
//   ]);
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import NewMessageDialog from './components/NewMessageDialog';
import { RestoreFromTrashOutlined } from '@mui/icons-material';
import NewMessageSidebar from './components/NewMessageSidebar';
const TeamChatDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname, state } = useLocation();
  const [groupTab, setGroupTab] = useState(true);
  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [isInitializingChat, setIsInitializingChat] = useState(false);
  const [isLoadingGroupList, setIsLoadingGroupList] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const [currentGroupDetail, setCurrentGroupDetail] = useState(null);
  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  console.log(state, 'loc-state');
  const handleLocalGroupSearch = (input, array) => {
    let filtered = filter(
      array,
      (item) => item.group_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    console.log(filtered, 'filtered-group');
    return filtered;
  };
  const handleLocalChatSearch = (input, array) => {
    const get_merged = (item) => {
      return `${item.first_name} ${item.last_name}`;
    };
    let filtered = filter(
      array,
      (item) => get_merged(item).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    console.log(filtered, 'filtered-direct');
    return filtered;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const getFilteredGroupList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === '') {
      return data;
    }
    if (searchValue !== '') {
      let filtered = handleLocalGroupSearch(searchValue, data);
      return filtered;
    }
  };
  const getFilteredChatList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === '') {
      return data;
    }
    if (searchValue !== '') {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!currentGroupDetail) {
      return;
    }
    if (!message || message === '') {
      enqueueSnackbar('Message cannot be empty', { variant: 'error' });
      return;
    }
    if (state.chat_type === '1') {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('message', message.trim());
      data.append('chat_id', currentGroupDetail.detail.id);
      console.log(...data, 'send-direct-msg-req');
      setIsSendingMessage(true);
      const result = await sendDirectMessage(data);
      console.log(result, 'send-message-res');
      if (result.code === 200) {
        setIsSendingMessage(false);
        setMessage('');
        let show_loader = false;
        getDetailDirectMessages(currentGroupDetail.detail.id, show_loader);
      } else {
        setIsSendingMessage(false);
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
    if (state.chat_type === '0') {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('message', message.trim());
      data.append('group_creator_id', currentGroupDetail.detail.group_creator_id);
      data.append('group_id', currentGroupDetail.detail.id);
      console.log(...data, 'send-msg-req');
      setIsSendingMessage(true);
      const result = await sendGroupChatMessage(data);
      console.log(result, 'send-message-res');
      if (result.code === 200) {
        setIsSendingMessage(false);
        setMessage('');
        let show_loader = false;
        getGroupDetailWithMessages(currentGroupDetail.detail.id, show_loader);
      } else {
        setIsSendingMessage(false);
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
  };
  const setSelectedGroup = (data) => {
    setCurrentGroupDetail(data);
  };
  const { enqueueSnackbar } = useSnackbar();
  const toggleTabs = (value) => {
    setGroupTab(value);
  };
  const toggleAddGroup = () => {
    setAddGroupOpen(!addGroupOpen);
  };

  const [groups, setGroups] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const handleInitializeMessage = async (form_data) => {
    console.log(...form_data, 'add-group-req');
    setIsInitializingChat(true);
    const result = await initialChatMessage(form_data);
    if (result.code === 200) {
      setIsInitializingChat(false);

      toggleAddGroup();
      getChatGroupList();
      return true;
    } else {
      setIsInitializingChat(false);
      enqueueSnackbar(result.message, { variant: 'error' });
      return false;
    }
  };

  const getChatGroupList = async () => {
    const { id } = params;
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    setIsLoadingGroupList(true);
    const result = await chatGroupList(data);
    console.log(result, 'chat-group-list');
    if (result.code === 200) {
      setIsLoadingGroupList(false);
      setGroups(result.group_list);
      setDirectMessages(result.chat_list);
      if (result.group_list.length < 1) {
        // setCurrentGroupDetail(null);
        return;
      }
    } else {
      setIsLoadingGroupList(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getGroupDetailWithMessages = async (id, show_loader) => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_id', id);
    if (show_loader) {
      setIsLoadingDetail(true);
    }
    const result = await detailWithMessages(data);

    console.log(result, 'group-detail');
    if (result.code === 200) {
      setIsLoadingDetail(false);
      setCurrentGroupDetail({ detail: result.group_data, messages: result.group_message_list });
      setTimeout(() => {
        handleBlankTarget('message-items-container');
      }, 800);
    } else {
      setIsLoadingDetail(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getDetailDirectMessages = async (id, show_loader) => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('reciever_id', state.sender_id);
    data.append('sender_id', state.receiver_id);
    if (show_loader) {
      setIsLoadingDetail(true);
    }
    const result = await detailDirectMessages(data);

    console.log(result, 'direct-messages-detail');
    if (result.code === 200) {
      setIsLoadingDetail(false);
      setCurrentGroupDetail({ detail: result.message_data, messages: result.messages_list });
      setTimeout(() => {
        handleBlankTarget('message-items-container');
      }, 800);
    } else {
      setIsLoadingDetail(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getMessages = () => {
    const isCreator = (item) => {
      if (!item) {
        return false;
      }
      if (String(item.user_id) === String(localStorage.getItem('user_id'))) {
        return true;
      }
      return false;
    };
    if (!currentGroupDetail) {
      return [];
    }
    let messages = currentGroupDetail.messages.map((item) => {
      return { ...item, isCreator: isCreator(item) };
    });
    return messages;
  };
  const isMsgLarge = (text) => {
    if (!text) {
      return false;
    }
    let length = text.length;
    if (length >= 300) {
      return true;
    }
    return false;
  };
  const handleBlankTarget = (id) => {
    set_blank_target(id);
    // setTimeout(() => {
    //   set_blank_target('task-description-html');
    // }, 500);
  };
  React.useEffect(() => {
    console.log(currentGroupDetail, 'currentGroupDetail--');
  }, [currentGroupDetail]);

  React.useEffect(() => {
    console.log(pathname, 'pathname--');
    let show_loader = true;
    if (!state) {
      navigate('/chat/chat-messages', { replace: true });
      return;
    }
    if (state.chat_type === '1') {
      console.log('--get team chat');
      getDetailDirectMessages(params.id, show_loader);
    }
    if (state.chat_type === '0') {
      getGroupDetailWithMessages(params.id, show_loader);
      console.log('--get group chat');
    }
  }, [pathname]);
  React.useEffect(() => {
    getChatGroupList();
  }, []);

  return (
    <Page title='Team Chat' style={{ padding: 0 }}>
      <Container maxWidth='xl'>
        <div className='row chat-container common-border'>
          <div className='col-3 chat-users common-border'>
            <div className='chat-tabs'>
              <div className='d-flex w-100 position-relative' style={{ bottom: '4px' }}>
                <span className='w-100'>
                  <StyledChatSearch
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    placeHolder='Search...'
                  />
                </span>
              </div>
              {/* <ChatTabs groupTabActive={groupTab} toggleTabs={toggleTabs} /> */}
            </div>
            {/* <Divider className='mt-1' /> */}
            <Scrollbar>
              <GroupList
                isLoadingGroups={isLoadingGroupList}
                isLoadingDetail={isLoadingDetail}
                onGetDetail={getGroupDetailWithMessages}
                groups={getFilteredGroupList(groups)}
                // groups={groups}
                directMessages={getFilteredChatList(directMessages)}
              />
            </Scrollbar>
            {!isLoadingGroupList && (
              <>
                {groups.length === 0 && directMessages.length === 0 && (
                  <div className='center-progress'>
                    <Typography textAlign='center' fontWeight='bold'>
                      No messages yet.
                    </Typography>
                  </div>
                )}
              </>
            )}
            {isLoadingGroupList && (
              <div className='center-progress'>
                <CircularProgress />
              </div>
            )}

            <Tooltip title='New Message' arrow>
              <IconButton onClick={toggleAddGroup} className='add-chat-btn' aria-label='add'>
                <AddIcon htmlColor='#fff' />
              </IconButton>
            </Tooltip>
          </div>
          <div className='col-9 chat-messages common-border'>
            <div className='chat-tabs'>
              <ChatHeader
                currentGroup={currentGroupDetail}
                groupTabActive={groupTab}
                toggleTabs={toggleTabs}
                getChatGroupList={getChatGroupList}
                getGroupDetailWithMessages={getGroupDetailWithMessages}
                setSelectedGroup={setSelectedGroup}
              />
            </div>
            <div className='chat-messages-parent'>
              <div className='messages-container' id='message-items-container'>
                {!isLoadingDetail && (
                  <ScrollToBottom
                    initialScrollBehavior='auto'
                    behavior='auto'
                    followButtonClassName='hidden-element'
                    mode='bottom'
                    className='messages-container'
                  >
                    {getMessages().map((item, i) => (
                      <ChatMessageItem key={i} item={item} />
                    ))}
                  </ScrollToBottom>
                )}

                {isLoadingDetail && (
                  <div className='center-progress'>
                    <CircularProgress />
                  </div>
                )}
                {!isLoadingDetail && (
                  <>
                    {getMessages().length < 1 && (
                      <div className='center-progress'>
                        <Typography textAlign='center' fontWeight='bold'>
                          No messages yet.
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className='chat-bottom-bar'>
                <div className='message-editor'>
                  <CkEditor content={message} setContent={(value) => setMessage(value)} />
                  <div className='hidden-div-editor'></div>
                </div>
                <div
                  className={`${
                    isMsgLarge(message) ? 'send-message-btn-left' : 'send-message-btn'
                  }`}
                >
                  {/* <div className='send-message-btn'> */}
                  <Tooltip title='Send message'>
                    <span>
                      <IconButton
                        style={{ backgroundColor: '#a8e6b07a' }}
                        disabled={isSendingMessage}
                        onClick={(e) => handleSendMessage(e)}
                      >
                        {isSendingMessage ? (
                          <CircularProgress style={{ width: '20px', height: '20px' }} />
                        ) : (
                          <SendIcon style={{ position: 'relative', left: '2px' }} />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          {/* add group/chat */}
        </div>
        {/* dialogs */}
        {/* <NewMessageDialog
          isLoading={isInitializingChat}
          isOpen={addGroupOpen}
          toggle={toggleAddGroup}
          onInitializeMessage={handleInitializeMessage}
        /> */}
        {/* sidebars */}
        <NewMessageSidebar
          isLoading={isInitializingChat}
          isOpen={addGroupOpen}
          toggle={toggleAddGroup}
          onInitializeMessage={handleInitializeMessage}
        />
      </Container>
    </Page>
  );
};

export default TeamChatDetail;
