import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
  FormControl,
  TextField,
  Tooltip,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  ListItem,
  List,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { makeStyles } from '@mui/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScrollToBottom from 'react-scroll-to-bottom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { Menu } from '@szhsin/react-menu';
//
import Scrollbar from 'src/components/Scrollbar';
import {
  uploadTaskFiles,
  removeTaskFiles,
  detailTaskFiles,
  addTaskComments,
  taskComments,
  EditComment,
  deleteComment,
  updateTaskSection,
} from 'src/DAL/Project/Project';
import { baseUri } from 'src/config/config';
import ConfirmDeleteComment from 'src/components/Confirmation/ConfirmDeleteComment';
import CommentPopover from 'src/components/Popovers/CommentPopover';
import { LoadingButton, timelineDotClasses } from '@mui/lab';
import CkEditor from 'src/components/editor/CKEditor';
import { set_blank_target } from 'src/utils/DomUtils';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const PRIORITY_OPTIONS = [
  { label: 'Critical', value: 'critical', color: 'error', filterValue: '0' },
  { label: 'High', value: 'high', color: 'primary', filterValue: '1' },
  { label: 'Medium', value: 'medium', color: 'success', filterValue: '2' },
  { label: 'Low', value: 'low', color: 'info', filterValue: '3' },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  {
    label: 'Not Started',
    value: 'not_started',
    color: 'success',
    filterValue: '0',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'secondary',
    filterValue: '1',
  },
  {
    label: 'In Review',
    value: 'in_review',
    color: 'warning',
    filterValue: '2',
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'primary',
    filterValue: '3',
  },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

SectionEditSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginLeft: '.5rem',
    marginRight: '.1rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

export default function SectionEditSidebar({ isOpen, onClose, isSectionData, fetchTaskListing }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateName, setUpdateName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('section_name', updateName);
    formData.append('task_section_id', isSectionData ? isSectionData.section_id : '');
    const result = await updateTaskSection(formData);
    if (result.code === 200) {
      setIsUpdating(false);
      enqueueSnackbar('Section updated', { variant: 'success' });
      fetchTaskListing();
      onClose();
    } else {
      setIsUpdating(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (isSectionData) {
        setUpdateName(isSectionData.section_name);
      }
    }
    if (!isOpen) {
      setUpdateName('');
    }
  }, [isOpen]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={onClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <textarea
                style={{
                  border: 'hidden',
                  backgroundColor: 'rgb(0 42 21 / 8%) ',
                  fontSize: 12,
                  minHeight: '15vh',
                }}
                // rows="3"
                className='form-control text-area-task'
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
                id='exampleFormControlInput1'
              />
              <LoadingButton
                type='submit'
                className='mt-3'
                loading={isUpdating}
                fullWidth
                variant='outlined'
              >
                Update
              </LoadingButton>
            </form>
          </Stack>
        </Scrollbar>

        {/* confirmation dialog */}
      </Drawer>
    </>
  );
}
