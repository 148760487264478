import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
//components
import PriorityPopover from "../pages/Tasks/components/Priority";
import StatusPopover from "../pages/Tasks/components/Status";
import AddMembers from "../pages/Tasks/components/AddMembers";
import MembersListing from "../pages/Tasks/components/MembersListing";
import { noTask } from "../assets/";

//icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CommentIcon from "@mui/icons-material/Comment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CircleIcon from "@mui/icons-material/Circle";
//API's
import {
  changeStatus,
  changePriority,
  addTaskMembers,
  deleteTaskMembers,
  addTaskTargetDate,
} from "../DAL/Project/Project";
import { baseUri } from "../config/config";
//hooks
import { useSocket } from "../Hooks/socketContext";
import { useSnackbar } from "notistack";
// other
import DatePicker from "react-datepicker";
import { filter } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { set_blank_target } from "src/utils/DomUtils";
import { TaskDateTimePicker } from ".";
const TaskCard = ({
  provided,
  taskData,
  setTaskData,
  fetchTaskListing,
  taskSortStatus,
  searchValue,
  setSearchValue,
  setRowData,
  setIsCommentDrawerOpen,
  projectMembers,
  projectDetail,
  setSectionMenuAnchorEl,
  setIsSectionMenuOpen,
  setOpenEditTask,
  isTemplateListing,
  verifiedListing,
  //template task functions
  updateTemplateTask,
  updateTemplateTaskPriority,
  openEditTask,
  closeEditTask,
  //comment api and check formData
}) => {
  //hooks
  const { sendSocketNotification } = useSocket();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const shouldStatusOpen = () => {
    let should = true;
    if (isTemplateListing) {
      should = false;
    }
    if (verifiedListing) {
      should = true;
    }
    return should;
  };
  //status handling
  const handleStatusChecked = async (status, data) => {
    if (isTemplateListing) {
      console.log("handle template task");
      return;
    }
    if (status === true) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 3,
            };
          }
          return x;
        })
      );

      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 3);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        enqueueSnackbar("Task status changed", { variant: "success" });
        data.members.map((z, k) => {
          const payload = {
            receiver_id: z.user_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
          return z;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 1,
            };
          }
          return x;
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 1);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        enqueueSnackbar("Task status changed", { variant: "success" });
        data.members.map((z, k) => {
          const payload = {
            receiver_id: z.user_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
          return z;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing();
      }
    }
  };
  const handleTaskStatus = async (value, data) => {
    if (isTemplateListing) {
      console.log("handle template task");
      return;
    }
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            status: value,
          };
        }
        return x;
      })
    );

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", data.task_id);
    console.log(...formData, "change-task-status-req");
    const result = await changeStatus(formData);
    if (result.code === 200) {
      // enqueueSnackbar('Task status changed', { variant: 'success' });
      if (verifiedListing === true) {
        if (String(value) !== "5") {
          let message = "Task moved back to project";
          showCustomSnackbar(data.task_id, message);
          // enqueueSnackbar('Task moved back to project', { variant: 'info' });
        }
      }
      if (!verifiedListing) {
        if (String(value) === "5") {
          let message = "Task moved to verified list";
          showCustomSnackbar(data.task_id, message);
          // enqueueSnackbar('Task moved to verified list', { variant: 'info' });
        } else enqueueSnackbar("Task status changed", { variant: "success" });
      }
      data.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Status has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };

        sendSocketNotification(payload);
        return z;
      });

      fetchTaskListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  const navigateToVerifiedListing = (taskId) => {
    navigate(`/verified-tasks`, {
      state: { project_id: id, project_detail: projectDetail, task_id: taskId },
    });
  };
  const navigateToProject = (taskId) => {
    navigate(
      `/projects/project-categories-list/project/${location.state.project_id}`,
      {
        state: {
          project_id: location.state.project_id,
          project_detail: projectDetail,
          task_id: taskId,
        },
      }
    );
  };
  const showCustomSnackbar = (taskId, message) => {
    const action = (key) => (
      <>
        {!verifiedListing ? (
          <Button
            onClick={() => {
              // console.log(`Moved task id is ${taskId}`);
              navigateToVerifiedListing(taskId);
            }}
            style={{ color: "white" }}
          >
            View
          </Button>
        ) : (
          <Button
            onClick={() => {
              // console.log(`Moved task id is ${taskId}`);
              navigateToProject(taskId);
            }}
            style={{ color: "white" }}
          >
            View
          </Button>
        )}
      </>
    );
    enqueueSnackbar(message, {
      variant: "info",
      autoHideDuration: 3000,
      action: action,
    });
  };
  //priority handling
  const handlePriority = async (value, data) => {
    if (isTemplateListing) {
      console.log("handle template task");
      updateTemplateTaskPriority(value, data);
      return;
    }
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            priority: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_priority", value);
    formData.append("task_id", data.task_id);

    const result = await changePriority(formData);
    if (result.code === 200) {
      enqueueSnackbar("Task priority changed", { variant: "success" });
      data.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Priority has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
        return z;
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //members handling
  const handleAddMembers = async (value, data) => {
    setTaskData((tasks) =>
      tasks.map((task) => {
        if (task.task_id === data.task_id) {
          task.members.push(value);
          return task;
        }
        return task;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", data.task_id);
    console.log(data, "add-member");
    const result = await addTaskMembers(formData);
    if (result.code === 200) {
      data.members.map((z, k) => {
        if (String(z.user_id) === String(value.user_id)) {
          console.log(z, "sent-to");
          const payload = {
            receiver_id: z.user_id,
            message: "You are added in another task",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
        }
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  const handleDeleteMembers = async (value, data) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            members: x.members.filter((y) => y.user_id !== value.user_id),
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", data.task_id);

    const result = await deleteTaskMembers(formData);
    if (result.code === 200) {
      data.members.map((z, k) => {
        if (String(z.user_id) === String(value.user_id)) {
          console.log(z, "sent-to");
          const payload = {
            receiver_id: z.user_id,
            message: "You are removed from one task",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          sendSocketNotification(payload);
        }
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //handle date
  const onTargetDateTimeChange = (new_date_time, task) => {
    console.log(new_date_time, "new-date");

    handleTargetDate(new_date_time, task);
  };
  const handleTargetDate = async (date, data) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            targetDate: date,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", data.task_id);
    formData.append("target_date", date);
    // formData.append('target_date', moment(date).format('YYYY-MM-DD'));
    const result = await addTaskTargetDate(formData);
    if (result.code === 200) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              targetDate: date,
            };
          }
          return x;
        })
      );
      data.members.map((z, k) => {
        const payload = {
          receiver_id: z.user_id,
          message: "Your Task Target date has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        sendSocketNotification(payload);
        return z;
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //filtering
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_task) => _task.task.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredTasks = (data) => {
    if (isTemplateListing) {
      return data;
    }
    let filtered = [];
    data.map((task, index) => {
      if (String(task.visibilityStatus) !== String(0)) {
        filtered.push(task);
      }
    });
    if (taskSortStatus?.status === true) {
      console.log(taskSortStatus?.type, taskSortStatus?.value, "atf");
      const sorted = handleSort(
        taskSortStatus?.type,
        taskSortStatus?.value,
        filtered
      );
      console.log(sorted, "sorted--");
      const filtered_local = handleLocalTaskSearch(searchValue, sorted);
      return filtered_local;
    }
    const filtered_ = handleLocalTaskSearch(searchValue, filtered);
    return filtered_;
  };
  //section handling
  const isSection = (task) => {
    let is_section = false;
    if (String(task.taskType) === String(2)) {
      is_section = true;
    }
    return is_section;
  };
  //toggles
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, "target-task");
    console.log(index, "target-index");
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleOpenEditTask = (data) => {
    console.log(data, "handle-edit");
    setRowData(data);
    setOpenEditTask(true);
  };
  //drawers
  const openCommentsDrawer = (data) => {
    console.log(data, "handle-comment-open");
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  //sorting
  const handleSort = (type, option, data) => {
    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === "oldest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === "newest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
      //
    }
  };

  //drag handling
  const isDragDisabled = () => {
    if (isTemplateListing) {
      return false;
    }
    let disabled = false;
    let filter = JSON.parse(localStorage.getItem(`project_${id}`))?.filter;
    if (filter) {
      if (filter.length > 0) {
        disabled = true;
      }
    }
    if (taskSortStatus?.status === true) {
      disabled = true;
    }
    if (searchValue !== "") {
      disabled = true;
    }
    return disabled;
  };

  //other
  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = id;
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: "<p>No Description added!</p>" };
    }

    return { __html: html };
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("task-description-html");
    }, 500);
  };
  const showNewCommentIndicator = (count) => {
    if (!count) return false;
    let show = false;
    let unread_count = Number(count);
    if (unread_count > 0) {
      return true;
    }
  };
  const getUnreadCount = (count) => {
    let message = "0 unread comments";
    if (Number(count) === 1) {
      message = `${count} unread comment`;
      return message;
    }
    if (Number(count) > 0) {
      message = `${count} unread comments`;
      return message;
    }
    return message;
  };
  return (
    <div className="mt-3" {...provided.droppableProps} ref={provided.innerRef}>
      {taskData.length > 0 ? (
        getFilteredTasks(taskData).map((task, i) => (
          <Draggable
            key={isTemplateListing ? task.taskTempId : task.task_id}
            draggableId={isTemplateListing ? task.taskTempId : task.task_id}
            index={i}
          >
            {(provided, snapshot) => (
              <div
                className="row"
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <div className="col-12">
                  <Box
                    id={task.task_id}
                    sx={{ px: 5, py: 1, boxShadow: isSection(task) ? 0 : 3 }}
                    className={
                      snapshot.isDragging
                        ? "onDrag col-12 relative "
                        : `not-drag col-12 relative  ${
                            isSection(task) && "section-card-div"
                          }`
                    }
                    role="button"
                    tabIndex="0"
                    style={{
                      borderLeft: "3px solid transparent",
                      borderRadius: "4px",
                      // marginBlock: '10px',
                      marginTop: "10px",
                      marginBottom: "10px",
                      paddingBlock: "5px",
                      paddingBottom: "10px",
                    }}
                  >
                    {!isSection(task) ? (
                      <div className="box-inner-div">
                        <span
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        >
                          {!verifiedListing && (
                            <>
                              {isDragDisabled() ? (
                                <Tooltip title="please remove filter">
                                  <IconButton style={{ float: "right" }}>
                                    <DragIndicatorIcon
                                      style={{ color: "#C4CDD5" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <IconButton
                                  {...provided.dragHandleProps}
                                  style={{ float: "right" }}
                                >
                                  <DragIndicatorIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                          <IconButton
                            onClick={(e) => handleOpenSectionMenu(e, task, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </span>
                        <div
                          className="d-flex  align-items-center"
                          style={{ paddingBottom: "5px" }}
                        >
                          {!isTemplateListing && !verifiedListing && (
                            <span style={{ marginLeft: "-9px" }}>
                              <Checkbox
                                // style={{ paddingLeft: '0px' }}
                                checked={
                                  String(task.status) === String(3) ||
                                  (String(task.status) === String(5) && true)
                                }
                                onChange={(e) =>
                                  handleStatusChecked(e.target.checked, task)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          )}

                          <Tooltip
                            arrow
                            placement="top"
                            onOpen={(e) => handleOpenTooltip(e)}
                            title={
                              <div id="task-description-tooltip">
                                <div
                                  id="task-description-html"
                                  dangerouslySetInnerHTML={getTooltipHtml(
                                    task.description
                                  )}
                                ></div>
                              </div>
                            }
                          >
                            <Typography
                              component="span"
                              onClick={() =>
                                isTemplateListing
                                  ? openEditTask(task)
                                  : handleOpenEditTask(task)
                              }
                              sx={{
                                paddingRight: "4rem",
                                // marginLeft: verifiedListing ? '8px' : '',
                                marginTop: verifiedListing ? "4px" : "",
                              }}
                              className={`${isTemplateListing && " mt-1"}`}
                            >
                              {task.task}
                            </Typography>
                          </Tooltip>
                        </div>

                        {task.detailVisible === true ? (
                          <div className="row align-items-center pb-1 ">
                            {/* ================================================= date picker ===================================== */}

                            {!isTemplateListing && (
                              <div
                                className="col-md-4 mb-2 mb-md-0"
                                style={{ paddingLeft: "17px" }}
                              >
                                <span>
                                  <TaskDateTimePicker
                                    value={task.targetDate}
                                    onChange={(new_date) =>
                                      onTargetDateTimeChange(new_date, task)
                                    }
                                    task={task}
                                  />
                                </span>
                                {/* <div className='col-lg-2 col-md-3 col-sm-3'> */}
                                {/* {task.targetDate === '' ? (
                                  <DatePicker
                                    value={task.targetDate}
                                    onSelect={(date) => handleTargetDate(date, task)}
                                    minDate={new Date()}
                                    targetTask={task}
                                    customInput={<ExampleCustomInput targetTask={task} />}
                                  />
                                ) : (
                                  <DatePicker
                                    selected={new Date(task.targetDate)}
                                    onSelect={(date) => handleTargetDate(date, task)}
                                    minDate={new Date()}
                                    targetTask={task}
                                    customInput={<ExampleCustomInput targetTask={task} />}
                                  />
                                )} */}
                              </div>
                            )}

                            <div
                              className="col-md-4 mb-2 mb-md-0"
                              style={{ paddingLeft: "17px" }}
                            >
                              <div
                                className={`d-flex align-items-center ${
                                  isTemplateListing
                                    ? "justify-content-center"
                                    : "justify-content-start"
                                }`}
                              >
                                {!isTemplateListing && (
                                  <div className="mr-1">
                                    {/* <div className='col-lg-1 col-md-3 col-sm-3 '> */}
                                    <Tooltip
                                      title={`Created by ${task.taskCreatorName}`}
                                      placement="bottom"
                                    >
                                      {task.taskCreatorImage !== "" ? (
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={baseUri + task.taskCreatorImage}
                                          style={{ height: 30, width: 30 }}
                                        />
                                      ) : (
                                        <Avatar
                                          alt="Remy Sharp"
                                          style={{ height: 30, width: 30 }}
                                        >
                                          {task.taskCreatorName.substring(0, 1)}
                                        </Avatar>
                                      )}
                                    </Tooltip>
                                  </div>
                                )}

                                <div className="mr-2">
                                  <PriorityPopover
                                    priority={task.priority}
                                    id={task}
                                    handleTaskPriority={handlePriority}
                                    shouldOpen={verifiedListing ? false : true}
                                  />
                                </div>

                                <div className="mr-2">
                                  <StatusPopover
                                    status={task.status}
                                    id={task}
                                    handleStatus={handleTaskStatus}
                                    onStatusChecked={handleStatusChecked}
                                    shouldOpen={
                                      isTemplateListing ? false : true
                                    }
                                  />
                                </div>

                                {!isTemplateListing && (
                                  <div className="mr-1">
                                    <div className="d-flex  align-items-center">
                                      <div
                                        className="d-flex align-items-center relative"
                                        style={{
                                          marginLeft: "auto",
                                          position: "relative",
                                        }}
                                      >
                                        <Tooltip
                                          onClick={() =>
                                            openCommentsDrawer(task)
                                          }
                                          // title='Comments'
                                          title={getUnreadCount(
                                            task.unreadComments
                                          )}
                                        >
                                          <CommentIcon
                                            style={{ fontSize: "1em" }}
                                            // style={{ fontSize: '0.8em' }}
                                            htmlColor="#9f9f9f"
                                          />
                                        </Tooltip>
                                        {showNewCommentIndicator(
                                          task.unreadComments
                                        ) && (
                                          <span className="new-comment-indicator">
                                            <CircleIcon
                                              htmlColor="red"
                                              style={{ fontSize: "0.5rem" }}
                                            />
                                          </span>
                                        )}
                                        <Tooltip
                                          // title='Comments'
                                          title={getUnreadCount(
                                            task.unreadComments
                                          )}
                                        >
                                          <div
                                            style={
                                              {
                                                // marginRight: '.4rem',
                                              }
                                            }
                                          >
                                            <Typography
                                              onClick={() =>
                                                openCommentsDrawer(task)
                                              }
                                              color="black"
                                              sx={{
                                                cursor: "pointer",
                                                fontSize: 12,
                                              }}
                                              style={{ paddingInline: ".1rem" }}
                                            >
                                              {task.commentsCount}
                                            </Typography>
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!verifiedListing && (
                                  <>
                                    {String(task.visibilityStatus) ===
                                      String(1) && (
                                      <div
                                        onClick={() => handleCopyTaskLink(task)}
                                        className="mr-2"
                                      >
                                        <Tooltip title="Copy task link">
                                          <ContentCopyIcon
                                            style={{
                                              // marginLeft: '1rem',
                                              color: "grey",
                                              fontSize: "0.9rem",
                                            }}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            {!isTemplateListing && (
                              <div
                                className="col-md-4 d-flex"
                                style={{ paddingLeft: "17px" }}
                              >
                                {!verifiedListing && (
                                  <AddMembers
                                    onAddMember={handleAddMembers}
                                    onDeleteMember={handleDeleteMembers}
                                    membersData={projectMembers}
                                    onId={task}
                                    assignMembers={task.members}
                                  />
                                )}
                                <MembersListing
                                  onId={task}
                                  onAddMember={handleAddMembers}
                                  onDeleteMember={handleDeleteMembers}
                                  membersData={projectMembers}
                                  assignMembers={task.members}
                                  membersList={task.members}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="box-inner-div section-title-div">
                        <span
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                          }}
                        >
                          {!verifiedListing && (
                            <>
                              {isDragDisabled() ? (
                                <Tooltip title="please remove filter">
                                  <IconButton style={{ float: "right" }}>
                                    <DragIndicatorIcon
                                      style={{ color: "#C4CDD5" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <IconButton
                                  {...provided.dragHandleProps}
                                  style={{ float: "right" }}
                                >
                                  <DragIndicatorIcon htmlColor="black" />
                                </IconButton>
                              )}
                            </>
                          )}
                          <IconButton
                            onClick={(e) => handleOpenSectionMenu(e, task, i)}
                          >
                            <MoreVertIcon htmlColor="black" />
                          </IconButton>
                        </span>

                        {task.detailVisible === true ? (
                          <div className="row align-items-center pb-1">
                            {/* ================================================= date picker ===================================== */}

                            <div
                              className="col"
                              // style={{ paddingLeft: '.5rem' }}
                            >
                              <Typography
                                onClick={() =>
                                  isTemplateListing
                                    ? openEditTask(task)
                                    : handleOpenEditTask(task)
                                }
                                textAlign="left"
                                style={{
                                  fontsize: 16,
                                  fontWeight: "bold",
                                  paddingRight: "4rem",
                                }}
                              >
                                {task.task}
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            )}
          </Draggable>
        ))
      ) : (
        <div className="col-12 mt-5">
          <img
            width="124px"
            height="124px"
            src={noTask}
            alt="no task"
            style={{ margin: "auto" }}
          />
          <Typography
            style={{ textAlign: "center", paddingLeft: 50, paddingTop: 10 }}
          >
            No Task Found
          </Typography>
        </div>
      )}
      {/* {provided.placeholder} */}
    </div>
  );
};
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
export default TaskCard;
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip title="Target Date">
      {value !== "" ? (
        <>
          <a
            role="button"
            tabIndex="0"
            className="btn"
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              //  color: 'green',
              color: getDateColor(value, targetTask.status),
              padding: 0,
            }}
          >
            {moment(value).format("DD MMM, YYYY")}
          </a>
          {showDangerIcon(value, targetTask.status) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          className="btn"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{
            textDecorationLine: "underline",
            fontSize: 12,
            padding: 0,
            //  color: 'green'
            color: getDateColor(value),
          }}
        >
          Select date
        </a>
      )}
    </Tooltip>
  )
);
