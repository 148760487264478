import * as React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useSnackbar } from "notistack";
// material
import {
  Container,
  CircularProgress,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// components
import CkEditor from "src/components/editor/CKEditor";
import Page from "../../components/Page";
import { ProjectList } from "../../components/_dashboard/projects";
import AddProject from "./AddProject";
import {
  projectListing,
  deleteProject,
  activeProjectCategories,
  archiveProject,
  reOrderProject,
  changeProjectImportantStatus,
  reorderProjects,
} from "../../DAL/Project/Project";
import ProjectFilter from "src/components/_dashboard/projects/ProjectFilter";
import {
  _get_project_categories_from_localStorage,
  _set_data_in_localStorage,
  _set_project_categories_in_localStorage,
} from "src/DAL/LocalStorage/LocalStorage";
import StyledSearch from "src/components/search/StyledSearch";
import { filter } from "lodash";
/* <<======================= STYLING =======================>> */

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProjectListing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params, "params");
  const { enqueueSnackbar } = useSnackbar();
  /* <<======================= STATES =======================>> */
  const [isWaiting, setIsWaiting] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [reorderState, setReorderState] = React.useState(false);
  const location = useLocation();
  console.log(location.state, "state-");
  const [data, setData] = React.useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  /* <<======================= HANDLING =======================>> */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const projectDelete = async (id) => {
    setIsDeleting(true);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    const result = await deleteProject(formData);
    if (result.code === 200) {
      enqueueSnackbar("Project deleted", { variant: "success" });
      setIsDeleting(false);
      fetchListing();
    } else {
      setIsDeleting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const fetchListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    if (String(params.id) !== "all") {
      formData.append("category_id", params.id);
    }
    console.log(...formData, "proj-list-req");
    const result = await projectListing(formData);
    console.log(result, "project-listing");
    if (result.code === 200) {
      setData(result.Project_list);
      setIsWaiting(false);
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getActiveList = async () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeProjectCategories(formData);
    if (result.code === 200) {
      console.log(result,'getActiveList')
      let selected_categories = _get_project_categories_from_localStorage();

      selected_categories = selected_categories ? selected_categories : [];

      const categories = result.category_list.map((c) => {
        if (selected_categories.includes(String(c.id))) {
          return { ...c, is_checked: true };
        }
        return { ...c, is_checked: false };
      });
      setCategoriesList(categories);
      fetchListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeInCategories = (id, value) => {
    const categories = categoriesList.map((category) => {
      if (String(category.id) === String(id)) {
        return { ...category, is_checked: value };
      } else {
        return category;
      }
    });

    let selected_categories_id = [];

    categories.forEach((category) => {
      if (category.is_checked) {
        selected_categories_id.push(category.id);
      }
    });

    _set_project_categories_in_localStorage(selected_categories_id);
    setCategoriesList(categories);
  };
  const getFilteredProjects = () => {
    const filtered = handleLocalTaskSearch(searchValue, data);
    return filtered;
  };
  // const getFilteredProjects = () => {
  //   const selected_categories = categoriesList.filter((c) => c.is_checked);

  //   if (selected_categories.length === 0) {
  //     const filtered = handleLocalTaskSearch(searchValue, data);
  //     return filtered;
  //     return data;
  //   }

  //   const projects = data.map((project) => {
  //     let is_allowed = false;

  //     categoriesList.map((category) => {
  //       if (String(project.project_category) === String(category.id)) {
  //         if (category.is_checked) {
  //           is_allowed = true;
  //         }
  //       }
  //     });

  //     return { ...project, is_allowed };
  //   });

  //   const allowed_projects = projects.filter((p) => p.is_allowed);
  //   const filtered_ = handleLocalTaskSearch(searchValue, allowed_projects);
  //   return filtered_;
  //   return allowed_projects;
  // };
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_project) =>
        _project.project_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleResetFilter = () => {
    const categories = categoriesList.map((c) => {
      return { ...c, is_checked: false };
    });
    setCategoriesList(categories);
    _set_project_categories_in_localStorage([]);
    setOpenFilter(false);
  };
  const handleApplyFilter = () => {
    //
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleArchiveProject = async (selectedProject) => {
    console.log(selectedProject);
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", selectedProject.id);
    formData.append("is_archive", "1");
    console.log(...formData, "archive-req");
    let result = await archiveProject(formData);
    if (result.code === 200) {
      enqueueSnackbar("Project Archived", { variant: "success" });
      console.log(data, "data");
      setData((prev) => {
        return prev.filter(
          (data) => String(data.id) !== String(selectedProject.id)
        );
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const toggleReOrder = (value) => {
    setReorderState(value);
  };
  const onChangeAsImportant = async (type, project) => {
    console.log(type, "type");
    console.log(project, "project");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", project.id);
    formData.append("is_important", type);
    setData((prev) =>
      prev.map((proj) => {
        if (String(proj.id) === String(project.id)) {
          return { ...proj, is_important: type };
        }
        return proj;
      })
    );
    const result = await changeProjectImportantStatus(formData);
    getActiveList();
    if (result.code === 200) {
      if (type === "0") {
        enqueueSnackbar("Project removed as important", { variant: "success" });
      } else {
        enqueueSnackbar("Project marked as important", { variant: "success" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAfterSort = async (updatedOrder, source, destination) => {
    console.log(source, "source");
    console.log(destination, "destination");
    setData(updatedOrder);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", source.id);
    formData.append("project_order", destination.project_order);
    console.log(...formData, "reorder-req");
    const result = await reorderProjects(formData);
    console.log(result, "reorder-res");
    getActiveList();
    if (result.code === 200) {
      enqueueSnackbar("Project order updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  React.useEffect(() => {
    getActiveList();
  }, []);
  if (isWaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <Page title="Projects | Support Task Portal">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" mb={3}>
          <IconButton onClick={() => navigate(-1)} className="mr-2">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{location.state?.catagory_name} </Typography>
          <div className="d-flex ml-auto">
            {/*   <Button
              className='mr-2'
              onClick={() => setReorderState((prev) => !prev)}
              variant='contained'
            >
              Toggle Reorder
            </Button> */}
            <Button
              variant="contained"
              onClick={() => navigate("/projects/add-project")}
              startIcon={<Icon icon={plusFill} />}
              className="ml-auto"
            >
              New Project
            </Button>
          </div>
        </Stack>
        {/* <Stack direction='row' alignItems='center' justifyContent='flex-end' mb={3}>
          <ProjectFilter
            categoryList={categoriesList}
            handleChangeInCategories={handleChangeInCategories}
            onCloseFilter={handleCloseFilter}
            onOpenFilter={handleOpenFilter}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            applyFilter={handleApplyFilter}
          />
        </Stack> */}
        <Stack mb={3} direction="row" alignItems="center">
          <div className="d-flex w-100 align-items-center">
            <span>
              <StyledSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeHolder="Search Project..."
              />
            </span>
          </div>
        </Stack>
        <ProjectList
          projects={getFilteredProjects()}
          isDeleting={isDeleting}
          onDeleteProject={projectDelete}
          onChangeAsImportant={onChangeAsImportant}
          onArchive={handleArchiveProject}
          dragDisabled={searchValue === ""}
          afterSort={handleAfterSort}
        />

        {/* ============================================== Add Project component========================= */}

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <IconButton
            onClick={handleCloseDialog}
            style={{ marginLeft: "auto" }}
          >
            <HighlightOffIcon />
          </IconButton>
          <DialogTitle style={{ textAlign: "center" }}>Add Project</DialogTitle>
          <DialogContent>
            <AddProject />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseDialog}>Cancel</Button> */}
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
