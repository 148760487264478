import {
  Container,
  IconButton,
  MenuItem,
  Button,
  Typography,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Chip,
  OutlinedInput,
  FormLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect } from "react";
import Page from "src/components/Page";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CkEditor from "src/components/editor/CKEditor";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  activeProductListing,
  activeSaleSectionListing,
  addSaleByManager,
  allowedSalesActiveTeam,
  userProfile,
} from "src/DAL/Project/Project";
import Loader from "src/pages/Loader";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
const AddSale = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    // associateEmail: '',
    clientEmail: "",
    associateId: "",
    productId: "",
    sectionId: "",
    saleDescription: "",
    firstName: "",
    lastName: "",
    status: "1",
    paidStatus: "",
    phone: "",
  });
  const [productList, setProductList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedAssociates, setSelectedAssociates] = useState([]);
  const [associateList, setAssociateList] = useState([]);
  const [loaders, setLoaders] = useState({
    productList: false,
    sectionList: false,
    userProfile: false,
    teamList: false,
  });
  const [status, setStatus] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [paidStatus, setPaidStatus] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs);
    const formData = new FormData();
    let client_email = inputs.clientEmail;
    client_email = client_email.trim();
    client_email = client_email.toLowerCase();
    console.log(selectedAssociates, "slectd-asc");
    selectedAssociates.forEach((item, i) => {
      formData.append(`optional_associate[${i}]`, item.user_id);
    });
    formData.append("token", localStorage.getItem("token"));
    formData.append("first_name", inputs.firstName);
    formData.append("last_name", inputs.lastName);
    formData.append("client_email", client_email);
    formData.append("associate_id", inputs.associateId);
    formData.append("product_id", inputs.productId);
    formData.append("status", inputs.status);
    formData.append("paid_status", inputs.paidStatus);
    formData.append("section_id", inputs.sectionId);
    formData.append("sale_description", inputs.saleDescription);
    formData.append("phone", inputs.phone);
    console.log(...formData, "add-sale-req");
    setIsAdding(true);
    const result = await addSaleByManager(formData);
    if (result.code === 200) {
      setIsAdding(false);
      console.log(result);
      enqueueSnackbar("Sale added", { variant: "success" });
      navigate(-1);
    } else {
      setIsAdding(false);
      console.log(result);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChange = (e) => {
    const { target } = e;
    setInputs({ ...inputs, [target.id]: target.value.trim() });
  };
  const getActiveSectionList = async () => {
    setLoaders({ ...loaders, ["sectionList"]: true });
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeSaleSectionListing(formData);
    if (result.code === 200) {
      setLoaders({ ...loaders, ["sectionList"]: false });
      console.log(result, "section-list");
      //   let filtered = result.products_list.filter((product) => String(product.status) !== '0');
      setSectionList(result.active_sale_section);
    } else {
      setLoaders({ ...loaders, ["sectionList"]: false });
      console.log(result, "section-list");
    }
  };
  const getActiveProductList = async () => {
    setLoaders({ ...loaders, ["productList"]: true });
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeProductListing(formData);
    if (result.code === 200) {
      setLoaders({ ...loaders, ["productList"]: false });
      console.log(result, "product-list");
      let filtered = result.products_list.filter(
        (product) => String(product.status) !== "0"
      );
      setProductList(filtered);
    } else {
      setLoaders({ ...loaders, ["productList"]: false });
      console.log(result, "product-list");
    }
  };
  const getActiveAssociateList = async () => {
    setLoaders({ ...loaders, ["teamList"]: true });
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await allowedSalesActiveTeam(formData);
    if (result.code === 200) {
      setLoaders({ ...loaders, ["teamList"]: false });
      console.log(result, "associate-list");
      setAssociateList(result.manager_team_list);
    } else {
      setLoaders({ ...loaders, ["teamList"]: false });
      console.log(result, "associate-list");
    }
  };
  const handleChangeNumber = (e) => {
    let target_text = e.target.value;
    if (target_text === " ") {
      return;
    }
    const specialChars = new RegExp(/[`!@#$%^&*()_\=\[\]{};':"\\|,.<>\/?~]/);
    // const specialChars = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    const special_char_result = specialChars.test(target_text);
    // console.log(special_char_result, 'rez-special');
    const reg = new RegExp(/[a-zA-Z]+/g);
    const result = reg.test(target_text);
    if (result === false && special_char_result === false) {
      setInputs({ ...inputs, ["phone"]: target_text.trim() });
    }
  };
  useEffect(() => {
    getActiveSectionList();
    getActiveProductList();
    getActiveAssociateList();
  }, []);
  let is_allow = localStorage.getItem("allowViewSales");
  if (!is_allow) {
    return <Navigate to="/project" />;
  }
  if (String(is_allow) === "0") {
    return <Navigate to="/project" />;
  }
  if (
    loaders.productList === true ||
    loaders.sectionList === true ||
    loaders.teamList === true
  ) {
    return <Loader />;
  }
  return (
    <Page title="Add Sale">
      <Container maxWidth="xl">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
          Add Sale
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} wrap="wrap" flexWrap="wrap">
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                required
                value={inputs.firstName}
                onChange={(e) => handleChange(e)}
                id="firstName"
                label="Client First Name"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                required
                value={inputs.lastName}
                onChange={(e) => handleChange(e)}
                id="lastName"
                label="Client Last Name"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                required
                value={inputs.clientEmail}
                onChange={(e) => handleChange(e)}
                id="clientEmail"
                label="Client Email"
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                value={inputs.phone}
                onChange={(e) => handleChangeNumber(e)}
                id="phone"
                label="Phone"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.productId}
                  MenuProps={{ sx: { maxHeight: "20rem" } }}
                  label="Product"
                  onChange={(e) =>
                    setInputs({ ...inputs, ["productId"]: e.target.value })
                  }
                >
                  {productList.map((product, i) => (
                    <MenuItem key={i} value={product.product_id}>
                      {product.product_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                <Select
                  id="sectionId"
                  value={inputs.sectionId}
                  MenuProps={{ sx: { maxHeight: "20rem" } }}
                  label="Product"
                  name="Product"
                  onChange={(e) =>
                    setInputs({ ...inputs, ["sectionId"]: e.target.value })
                  }
                >
                  {sectionList.map((section, i) => (
                    <MenuItem key={i} value={section.sale_section_id}>
                      {section.sale_section_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Paid Status
                </InputLabel>
                <Select
                  id="paidStatus"
                  value={inputs.paidStatus}
                  MenuProps={{ sx: { maxHeight: "20rem" } }}
                  label="Paid Status"
                  name="Paid Status"
                  onChange={(e) =>
                    setInputs({ ...inputs, ["paidStatus"]: e.target.value })
                  }
                >
                  <MenuItem value="0">Free</MenuItem>
                  <MenuItem value="1">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Main Associate
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="associateId"
                  value={inputs.associateId}
                  label="Main Associate"
                  onChange={(e) =>
                    setInputs({ ...inputs, ["associateId"]: e.target.value })
                  }
                >
                  {associateList.map((team, i) => (
                    <MenuItem key={i} value={team.user_id}>
                      {team.first_name + " " + team.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={associateList.map((item) => {
                    return { ...item, title: item.first_name + item.last_name };
                  })}
                  value={selectedAssociates}
                  getOptionLabel={(option) =>
                    `${option.first_name}  ${" "}  ${option.last_name}`
                  }
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    String(option.user_id) === String(value.user_id)
                  }
                  onChange={(event, value) => setSelectedAssociates(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={`${option.first_name}${" "}${option.last_name}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Other Associates"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Sale Description :</FormLabel>
              <div className="product-description w-100">
                <CkEditor
                  content={inputs.saleDescription}
                  setContent={(value) =>
                    setInputs({ ...inputs, ["saleDescription"]: value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingButton
                size="medium"
                type="submit"
                color="primary"
                variant="contained"
                loading={isAdding}
              >
                Add Sale
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

export default AddSale;
