import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

const TaskCreateField = ({ onCreateTask }) => {
  const [taskName, setTaskName] = useState("");
  const [taskField, setTaskField] = useState(false);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showAddTaskButton = () => {
    if (taskName === "") {
      return false;
    }
    return true;
  };
  const handleTaskInputBlur = () => {
    if (taskName === "") {
      setTaskField(false);
    }
  };
  const showAddTask = () => {
    setTaskField(!taskField);
  };

  const handleAddTask = async (e) => {
    e.preventDefault();
    if (!taskName) {
      enqueueSnackbar("task should not be empty", { variant: "error" });
      return;
    }
    if (isCreatingTask) {
      return;
    }
    setIsCreatingTask(true);
    const result = await onCreateTask(taskName);
    setIsCreatingTask(false);
    setTaskName("");
    setTaskField(false);
    console.log(result);
  };
  return (
    <div className="col-sm-9 px-0">
      {taskField === false ? (
        <div
          style={{
            display: "flex",
            border: "1px dashed black",
            width: "100%",
            padding: 15,
            borderRadius: 10,
          }}
          role="button"
          tabIndex="0"
          onClick={() => showAddTask()}
          onKeyDown={() => showAddTask()}
        >
          <AddIcon style={{ color: "green" }} />
          <Typography sx={{ ml: 1 }}>Create Task</Typography>
        </div>
      ) : (
        <form className="w-100">
          <TextField
            label="Task"
            fullWidth={true}
            value={taskName}
            disabled={isCreatingTask}
            onChange={(e) => setTaskName(e.target.value)}
            onKeyPress={(event) =>
              event.key === "Enter" ? handleAddTask(event) : null
            }
            autoFocus
            onBlur={() => {
              handleTaskInputBlur();
            }}
          />
        </form>
      )}
      {showAddTaskButton() && (
        <Tooltip title="Add task">
          <span style={{ position: "absolute", zIndex: 2, right: 4, top: 8 }}>
            <IconButton
              disabled={isCreatingTask}
              onClick={(e) => handleAddTask(e)}
            >
              {isCreatingTask ? (
                <CircularProgress style={{ width: "20px", height: "20px" }} />
              ) : (
                <SendIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default TaskCreateField;
