import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import {
  Container,
  TextField,
  Grid,
  IconButton,
  MenuItem,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addTeam, activeDepartmentList } from '../../DAL/Team/Team';
import { departmentList } from '../../DAL/Department/Department';
import { activeTeamList } from '../../DAL/Project/Project';
const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 300,
  },
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));

function AddClient(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [role, setRole] = useState('');
  const [Image, setimage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [status, setStatus] = useState(true);
  const [allowStatus, setAllowStatus] = useState(true);
  const [allowChat, setAllowChat] = useState(false);
  const [allowChangeTaskOrder, setAllowChangeTaskOrder] = useState(false);
  const [allowViewSales, setAllowViewSales] = useState(false);
  const [members, setMembers] = useState([]);
  const [projectTeamList, setProjectTeamList] = useState([]);
  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    setMembers(
      // On autofill we get the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDepartment(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let allowProject = '0';
    if (allowStatus === true) {
      allowProject = '1';
    }
    let allow_view_sales = '0';
    if (allowViewSales === true) {
      allow_view_sales = '1';
    }

    let allowChangeOrder = '0';
    if (allowChangeTaskOrder === true) {
      allowChangeOrder = '1';
    }

    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    let _allow_chat;
    if (allowChat === true) {
      _allow_chat = 1;
    } else {
      _allow_chat = 0;
    }
    // setIswaiting(true);
    let team_email = email.trim();
    team_email = team_email.toLowerCase();
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', team_email);
    // formData.append('email', email);
    formData.append('password', password);
    formData.append('role', '');
    formData.append('status', _status);
    formData.append('allow_project', allowProject);
    formData.append('allow_reorder_task', allowChangeOrder);
    formData.append('sales_option', allow_view_sales);
    formData.append('type', '1');
    formData.append('allow_chat', _allow_chat);
    if (allowChat) {
      members.map((item, i) => {
        formData.append(`team[${i}]`, item.user_id);
      });
    }
    if (Image) {
      formData.append('team_image', Image);
    }
    if (allowChat) {
      if (members.length === 0) {
        enqueueSnackbar('Please choose at least one member', { variant: 'error' });
        return;
      }
    }
    console.log(...formData, 'create-client-request');
    setIswaiting(true);
    const result = await addTeam(formData);
    if (result.code === 200) {
      setIswaiting(false);
      navigate(-1);
      enqueueSnackbar('Client added', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
  };
  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await activeTeamList(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setProjectTeamList(result.Team_member_list);
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    fetchTeamListing();
  }, []);
  if (iswaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }

  return (
    <>
      <Container maxWidth='lg'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Add Client
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id='name'
                label='First Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id='name'
                label='Last Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id='email'
                label='Email'
                type='email'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='password'
                label='Password'
                type='password'
                fullWidth
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12} md={6} className='d-flex justify-content-start align-items-center'>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className='d-flex justify-content-md-end justify-content-start align-items-center'
            >
              Allow create project :
              <Switch
                checked={allowStatus}
                onChange={(e) => setAllowStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className='d-flex justify-content-md-start justify-content-start align-items-center'
            >
              Drag-Drop/Section:
              <Switch
                checked={allowChangeTaskOrder}
                onChange={(e) => setAllowChangeTaskOrder(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className='d-flex justify-content-md-end justify-content-start align-items-center'
            >
              Allow Chat:
              <Switch
                checked={allowChat}
                onChange={(e) => setAllowChat(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            {localStorage.getItem('allowViewSales') === '1' && (
              <Grid item xs={6} className='d-flex align-items-center justify-content-start'>
                Allow View Sales:
                <Switch
                  checked={allowViewSales}
                  onChange={(e) => setAllowViewSales(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            )}
            {allowChat && (
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id='demo-multiple-chip-label'>Team Member</InputLabel>
                  <Select
                    labelId='demo-multiple-chip-label'
                    id='demo-multiple-chip'
                    multiple
                    value={members}
                    onChange={handleChangeMembers}
                    input={<OutlinedInput id='select-multiple-chip' label='Team Member' />}
                    renderValue={(selected) => (
                      <>
                        {selected.map((value) => (
                          <Chip
                            key={value.id}
                            label={`${value.first_name}${' '} ${value.last_name}`}
                          />
                        ))}
                      </>
                    )}
                  >
                    {projectTeamList.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x.first_name} {(' ', x.last_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team member' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label
                htmlFor='contained-button-file'
                className='d-flex align-items-center justify-content-center'
              >
                <Button
                  component='span'
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                  className='m-auto'
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Add Client
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default AddClient;
