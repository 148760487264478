import React from 'react';
import { Icon } from '@iconify/react';
import { REACTION_ICONS, STATIC_REACTION_RESPONSE } from 'src/constants';
import HtmlTooltip from './HtmlTooltip';
import { Typography } from '@mui/material';
const MessageReactions = ({ data }) => {
  return (
    <div className='message-reactions-parent pointer'>
      {data.map((item, i) => (
        <div key={i} style={{ marginInline: '0.1rem' }}>
          <HtmlTooltip
            title={
              <React.Fragment>
                {item.user.map((user) => (
                  <Typography variant='caption'>
                    {user.first_name + ' ' + user.last_name}
                  </Typography>
                ))}
              </React.Fragment>
            }
          >
            <Icon icon={item.icon} />
          </HtmlTooltip>
        </div>
      ))}
    </div>
  );
};

export default MessageReactions;
