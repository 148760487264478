import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// import DateAdapter from '@mui/lab/AdapterMoment';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LoadingButton } from '@mui/lab';
import {
  Chip,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { activeTeamList } from 'src/DAL/Project/Project';
import moment from 'moment';
import { useSocket } from 'src/Hooks/socketContext';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddGroupDialog({ onCreateGroup, isOpen, toggle, isLoading }) {
  const [projectTeamList, setProjectTeamList] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const { sendChatGroupNotification } = useSocket();
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [groupName, setGroupName] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const fileChangedHandler = async (e) => {
    if (!e.target) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files[0]) {
      // setProfileImage('');
      return;
    }
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };
  const handleClose = () => {
    setStartTime('');
    setEndTime('');
    setMembers([]);
    setGroupName('');
    setProfileImage('');
    setImage('');
    toggle();
    // setOpen(false);
  };
  const handleChangeStartTime = (newValue) => {
    console.log(newValue);
    console.log(moment(newValue).format('hh:mm a'));
    setStartTime(newValue);
  };
  const handleChangeEndTime = (newValue) => {
    console.log(moment(newValue).format('hh:mm a'));
    console.log(newValue);
    setEndTime(newValue);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_name', groupName);
    members.map((member, index) => {
      data.append(`team[${index}]`, member.id);
    });
    data.append('chat_start_time', startTime);
    data.append('chat_end_time', endTime);
    if (Image) {
      data.append('group_image', Image);
    }
    // console.log(...data, 'add-group-req');
    if (startTime === '') {
      enqueueSnackbar('Please select chat start time', { variant: 'error' });
      return;
    }
    if (endTime === '') {
      enqueueSnackbar('Please select chat end time', { variant: 'error' });
      return;
    }
    let valid_start_time = moment(startTime).isValid();
    let valid_end_time = moment(endTime).isValid();
    if (!valid_start_time) {
      enqueueSnackbar('invalid chat start time', { variant: 'error' });
      return;
    }
    if (!valid_end_time) {
      enqueueSnackbar('invalid chat end time', { variant: 'error' });
      return;
    }
    let chat_time_diff = startTime.diff(endTime, 'minutes');
    console.log(chat_time_diff, 'diff');
    console.log(valid_start_time, valid_end_time);
    if (chat_time_diff === 0) {
      enqueueSnackbar('chat start/end time difference cannot be 0', { variant: 'error' });
      return;
    }
    let result = onCreateGroup(data);
    if (result) {
      members.forEach((item) => {
        const payload = {};
        const chat_info = { groupName: groupName, startTime: startTime, endTime: endTime };
        payload.chat_info = chat_info;
        payload.receiver_id = item.user_id;
        payload.notification_type = 'chat_group';
        payload.notification_action = 'add_group';
        console.log(payload, 'payload-team');
        sendChatGroupNotification(payload);
      });
      setStartTime('');
      setEndTime('');
      setMembers([]);
      setGroupName('');
      setProfileImage('');
      setImage('');
    }
  };
  const fetchListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await activeTeamList(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setProjectTeamList(result.Team_member_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMembers(
      // On autofill we get the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  React.useEffect(() => {
    if (isOpen) {
      fetchListing();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      //   onClose={handleClose}
      aria-labelledby='file-dialog-title'
      aria-describedby='file-dialog-description'
      maxWidth='md'
      fullWidth
      // PaperProps={{ sx: { width: { md: '40rem', sm: '30rem', xs: '25rem' } } }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id='file-dialog-title'>Create Group</DialogTitle>
        <DialogContent>
          <DialogContentText id='file-dialog-description'></DialogContentText>
          <DialogContent>
            <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type='text'
                  value={groupName}
                  variant='outlined'
                  label='Group name'
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth>
                  <InputLabel id='demo-multiple-chip-label'>Team Member</InputLabel>
                  <Select
                    labelId='demo-multiple-chip-label'
                    id='demo-multiple-chip'
                    multiple
                    size='medium'
                    value={members}
                    onChange={handleChange}
                    input={
                      <OutlinedInput size='medium' id='select-multiple-chip' label='Team Member' />
                    }
                    renderValue={(selected) => (
                      <>
                        {selected.map((value) => (
                          <Chip
                            key={value.id}
                            label={`${value.first_name}${' '} ${value.last_name}`}
                          />
                        ))}
                      </>
                    )}
                  >
                    {projectTeamList.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x.first_name} {(' ', x.last_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    views={['hours', 'minutes']}
                    required
                    onChange={(newValue) => {
                      handleChangeStartTime(newValue);
                    }}
                    id='start-time'
                    label='Chat Start Time'
                    value={startTime}
                    // value={startTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    //   sx={{ width: 150 }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    views={['hours', 'minutes']}
                    required
                    onChange={(newValue) => {
                      handleChangeEndTime(newValue);
                    }}
                    id='end-time'
                    label='Chat End Time'
                    type='time'
                    value={endTime}
                    // value={endTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    //   sx={{ width: 150 }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                {file ? (
                  <>
                    <img
                      className='m-auto'
                      width='120px'
                      height='120px'
                      src={file}
                      alt='team members'
                    />
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept='image/png,image/jpeg,image/jpg'
                  hidden
                  id='contained-button-file'
                  multiple
                  type='file'
                  onChange={fileChangedHandler}
                />
                <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                  <Button
                    component='span'
                    fullWidth
                    variant='outlined'
                    color='primary'
                    startIcon={<DriveFolderUploadIcon />}
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type='submit'
            loading={isLoading}
            // onClick={() => handleUploadFile()}
          >
            Create
          </LoadingButton>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
